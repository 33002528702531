import { useDebounceEffect } from 'ahooks';
import { useCallback, useRef, useState } from 'react';

export const useApiState = <T extends unknown>(props: {
  getApi: () => Promise<T>;
  defaultValue?: T;
  enable?: boolean;
}): [T | undefined, () => Promise<void>, boolean] => {
  const { getApi, defaultValue, enable = true } = props;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<T | undefined>(defaultValue);

  const refresh = useCallback(async () => {
    setLoading(true);
    const res = await getApi();
    setState(res);
    setLoading(false);
  }, [getApi]);

  useDebounceEffect(
    () => {
      if (enable) {
        void refresh();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [enable],
    { wait: 200, leading: true, trailing: false }
  );

  return [state, refresh, loading];
};

export const useGetCacheApi = <Params extends any[], Result>(
  api: (...params: Params) => Promise<Result>
) => {
  const cacheRef = useRef<Map<string, { result: Result; timestamp: number }>>(new Map());

  const getCacheApi = useCallback(
    async (...params: Params): Promise<Result> => {
      const cacheKey = JSON.stringify(params);

      const cachedData = cacheRef.current.get(cacheKey);

      if (cachedData) {
        return cachedData.result;
      }

      const result = await api(...params);

      cacheRef.current.set(cacheKey, {
        result,
        timestamp: Date.now(),
      });

      return result;
    },
    [api]
  );

  return getCacheApi;
};
