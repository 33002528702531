import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { ROLE_WEIGHT, usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentUser } from 'src/hooks/user';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { getDiffPermissions } from 'src/utils/permission-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { isSamePermission, setPermission } from '../helper';
import type { ShareProps, User } from '../types';

// 埋点对应的 change_to 名字
const changeTo = {
  [PermissionRole.EDITOR]: 'can edit',
  [PermissionRole.WRITER]: 'can write',
  [PermissionRole.READER]: 'can view',
  [PermissionRole.NONE]: 'no access',
  [PermissionRole.COMMENTER]: 'can comment',
};

export const useSharePanel = (uuid: string) => {
  const closeModal = useCloseModal();
  const currentSpace = useCurrentSpace();
  const currentUser = useCurrentUser();
  const block = usePickBlock(uuid, ['permissions']);
  const openModal = useOpenModal();
  const { permissions, role, isRestricted } = usePermissions(uuid, { wait: 300 });
  const { permissions: parentPermissions } = usePermissions(block?.parentId ?? uuid);
  const isGuest = useIsGuest();

  const { spacePermission, groupPermissions, userPermissions } = getDiffPermissions(permissions);

  if (!block) return null;

  let finalPermissions: PermissionDTO[] = [...groupPermissions];

  if (userPermissions.length > 1) {
    finalPermissions = [...groupPermissions, ...userPermissions];
  }

  if (!isGuest) {
    if (spacePermission) {
      finalPermissions.unshift(spacePermission);
    } else {
      finalPermissions.unshift({ type: PermissionType.SPACE, role: PermissionRole.NONE });
    }
  }

  const restrict = (role: PermissionRole, permission: User, isInSpaceOrGroup: boolean) => {
    const parentPermission = parentPermissions.find((p) => isSamePermission(p, permission));

    const addOrRemoveMyself = () => {
      if (!isInSpaceOrGroup) return;

      const hasMe = block.permissions.some((p) => p.userId === currentUser.uuid);

      if (permission.role === PermissionRole.EDITOR && !hasMe) {
        setBlockPermission(uuid, {
          type: PermissionType.USER,
          role: PermissionRole.EDITOR,
          userId: currentUser.uuid,
        });
        return;
      }

      if (role === PermissionRole.EDITOR && hasMe) {
        removeBlockPermission(uuid, {
          type: PermissionType.USER,
          role: PermissionRole.NONE,
          userId: currentUser.uuid,
        });
      }
    };

    if (
      !isRestricted &&
      block.parentId !== currentSpace.uuid &&
      parentPermission &&
      parentPermission.role &&
      ROLE_WEIGHT[role] < ROLE_WEIGHT[parentPermission.role]
    ) {
      const confirm = () => {
        addOrRemoveMyself();
        permissions.forEach((p) => {
          if (isSamePermission(p, permission)) {
            setPermission(uuid, { ...permission, role });
          } else {
            setPermission(uuid, p);
          }
        });
        setBlockPermission(uuid, { type: PermissionType.RESTRICTED });
        dispatch(TRANSACTION_FIRE());
        bizTracker.event('change_authority', { change_to: changeTo[role] });
      };

      openModal.warning({
        title: (
          <div className="px-5 mb-5 w-80">
            <div>确认进行权限变更？</div>
            <div>变更后，将不再继承上级页面的协作及分享设置</div>
          </div>
        ),
        confirmText: '确认',
        confirm,
      });

      return;
    }

    addOrRemoveMyself();
    setPermission(uuid, { ...permission, role });
    dispatch(TRANSACTION_FIRE());
    bizTracker.event('change_authority', { change_to: changeTo[role] });
  };

  const onChange: ShareProps['onChange'] = (role, permission) => {
    const editorPermissions = permissions.filter((o) => o.role === PermissionRole.EDITOR);

    // 这里与 notion 不同， notion 只在根页面才去做这个判断
    if (permission.type === PermissionType.USER) {
      if (editorPermissions.length === 1) {
        const editorPermission = editorPermissions[0];
        if (editorPermission && isSamePermission(editorPermission, permission)) {
          openModal.warning({
            title: (
              <div className="px-5 mb-5 w-80">
                <div>需要至少指定1名成员或成员组为「所有权限」才能进行此操作</div>
              </div>
            ),
            noCancel: true,
            confirmText: '知道了',
            colorType: 'secondary',
          });

          return;
        }
      }
    }

    let isInSpaceOrGroup = permission.type === PermissionType.SPACE;

    if (permission.type === PermissionType.GROUP) {
      const { groupId } = permission;
      if (groupId) {
        const group = currentSpace.permissionGroups?.find((gp) => gp.id === groupId);
        if (group) {
          isInSpaceOrGroup = group.userIds.includes(currentUser.uuid);
        }
      }
    }

    if (permission.userId === currentUser.uuid) {
      const confirm = () => {
        restrict(role, permission, isInSpaceOrGroup);
        closeModal('inviteConfirmModal');
      };

      openModal.warning({
        title: (
          <div className="px-5 mb-5 w-80">
            <div>确认更改自己协作权限？</div>
          </div>
        ),
        confirmText: '确认',
        confirm,
      });

      return;
    }

    restrict(role, permission, isInSpaceOrGroup);
  };

  return { role, block, onChange, permissions: finalPermissions };
};
