import * as Sentry from '@sentry/react';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from 'src/common/components/button';
import { useOpenModal } from 'src/common/components/next-modal';
import { PRODUCT_NAME } from 'src/const/title';
import { getActivity } from 'src/hooks/activities/use-activity';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { createActivityModalAnchor } from '../activities/create-activity-modal-anchor';

export enum TaskProgressType {
  FINISH_BASIC_TASK = 'FINISH_BASIC_TASK',
  NOTIFICATION = 'NOTIFICATION',
}

interface Props {
  onCloseModal: () => void;
  type: TaskProgressType;
}

export const TaskProgress: FC<Props> = ({ type, onCloseModal }) => {
  const openSettingModal = useOpenSettingModal();

  const handleClick = () => {
    onCloseModal();
    openSettingModal({
      defaultMenuType: SettingMenuType.integral,
      from: OpenSettingFrom.new_user_task,
    });
  };
  return (
    <div className="next-modal w-[332px] rounded p-[30px]">
      <div className="text-t1-medium">
        {type === TaskProgressType.FINISH_BASIC_TASK && (
          <>
            👏 从入门到精通，就差这一步！ 恭喜完成 {PRODUCT_NAME} 「基础入门」继续挑战
            <span className="text-active_color">「进阶任务」</span>
          </>
        )}
        {type === TaskProgressType.NOTIFICATION && (
          <>🤑 新手特惠！ 1元即可升级专业版会员！ 限时活动，手慢无！</>
        )}
      </div>
      <div className="flex items-center mt-[30px]">
        <Button colorType="black" onClick={onCloseModal} className="border-grey5">
          自我探索
        </Button>
        <Button colorType="active" className="ml-2.5" onClick={handleClick}>
          马上出发
        </Button>
      </div>
    </div>
  );
};

export const useOpenOneTaskModal = () => {
  const openModal = useOpenModal();
  const openSettingModal = useOpenSettingModal();

  return useCallback(
    (taskId: string) => {
      return new Promise((resolve) => {
        const activity = getActivity(taskId);
        openModal.modal({
          closeAfterCallBack: () => {
            resolve(0);
          },
          content: ({ onCloseModal }) => {
            return (
              <div className="next-modal w-[400px] p-[30px] flex flex-col">
                <p className="text-center text-[70px] leading-[70px]">😀</p>
                <p className="text-t1-medium mt-2.5 text-center">恭喜你</p>
                <p className="text-t2 text-center mt-2.5">
                  成功解锁首个「新手任务」并获得 ⭐+{activity?.score} 积分 <br />
                  （积分可累积抵现兑换专业版会员）
                </p>
                <Button
                  colorType="active"
                  className="mt-10 flex-1 p-1"
                  onClick={() => {
                    onCloseModal();
                    resolve(0);
                    openSettingModal({
                      defaultMenuType: SettingMenuType.integral,
                      from: OpenSettingFrom.new_user_task,
                    });
                  }}
                >
                  解锁更多
                </Button>
              </div>
            );
          },
        });
      }).catch((err) => {
        Sentry.captureException(err);
      });
    },
    [openModal, openSettingModal]
  );
};

export const useOpenTaskProgress = () => {
  const openModal = useOpenModal();

  return useCallback(
    (type: TaskProgressType) => {
      const activityModalAnchor = createActivityModalAnchor();

      openModal.dropdown({
        // autoClose: false,
        popcorn: activityModalAnchor.popcorn,
        placement: 'top-start',
        offset: [-50, 0],
        closeAfterCallBack: activityModalAnchor.remove,
        content: ({ onCloseModal }) => {
          return <TaskProgress type={type} onCloseModal={onCloseModal} />;
        },
      });
    },
    [openModal]
  );
};
