import { setPermission as _setPermission } from '@flowus/common/block/permissions';
import { URL_SPLIT_TEXT } from '@flowus/common/const';
import { createModel, useModel } from '@flowus/common/create-model';
import { SearchParams } from '@flowus/login/const';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionType } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { segmentsToText } from 'src/editor/utils/editor';
import { useSpaceDomainPath } from 'src/hooks/space';
import { useCurrentUser } from 'src/hooks/user';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import type { User } from './types';
import { Panel, SHARE_PANEL_OFFSET } from './types';

export const setPermission = (uuid: string, item: User) => {
  _setPermission(uuid, item, {
    removeBlockPermission,
    setBlockPermission,
  });

  // const permission: PermissionDTO = {
  //   role: item.role,
  //   type: item.type,
  // };

  // if (item.type === PermissionType.GROUP) {
  //   permission.groupId = item.groupId;
  // }

  // if (item.type === PermissionType.USER) {
  //   if (item.isGuest) {
  //     permission.isGuest = item.isGuest;
  //   }
  //   permission.userId = item.userId;
  // }

  // if (item.role === PermissionRole.NONE) {
  //   removeBlockPermission(uuid, permission);
  // } else {
  //   setBlockPermission(uuid, permission);
  // }
};

export const isSamePermission = (a: PermissionDTO, b: PermissionDTO) => {
  return (
    (a.type === PermissionType.SPACE && a.type === b.type) ||
    (a.type === PermissionType.GROUP && a.groupId === b.groupId) ||
    (a.type === PermissionType.USER && a.userId === b.userId)
  );
};

export const useGetSharePageUrl = (uuid: string) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const domainPath = useSpaceDomainPath(block?.spaceId);
  const currentUser = useCurrentUser();

  return useCallback(
    (params?: { embed?: boolean; inviteCode?: string; password?: string }) => {
      const { embed, inviteCode = currentUser.inviteCode, password } = params ?? {};

      const urlObj = new URL(`${getLocationOrigin()}${domainPath}/share/${uuid}`);
      const text = `\n${URL_SPLIT_TEXT}${segmentsToText(block?.data.segments)}`;

      if (embed) {
        urlObj.searchParams.set(SearchParams.embed, 'true');
      }

      if (inviteCode) {
        urlObj.searchParams.set(SearchParams.inviteCode, inviteCode);
      }

      if (password) {
        urlObj.searchParams.set(SearchParams.password, password);
      }

      const url = urlObj.href;

      return {
        url,
        text,
        textAndUrl: `${url}${text}`,
      };
    },
    [block?.data.segments, currentUser.inviteCode, domainPath, uuid]
  );
};

const useSharePanelContext = ({ uuid }: { uuid: string }) => {
  const [x, setX] = useState(0);
  const [panel, setPanel] = useState<Panel>(Panel.SEARCH_USER);

  const onChangeTab = (panel: Panel) => {
    setPanel(panel);
    setX(-SHARE_PANEL_OFFSET);
  };
  const backToMainPanel = () => setX(0);
  return { x, onChangeTab, panel, setX, uuid, backToMainPanel };
};

export const SharePanelModel = createModel(useSharePanelContext);
export const useSharePanelModel = () => useModel(SharePanelModel);

export const SharePanelHeader = (props: { title?: ReactNode }) => {
  const { backToMainPanel } = useSharePanelModel();
  const { title } = props;

  return (
    <div className="p-4 flex items-center text-t2 space-x-2 border-b">
      <Icon name="IcArrowLeft01" size="small" className="text-grey3" onClick={backToMainPanel} />
      <span>{title}</span>
    </div>
  );
};
