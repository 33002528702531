import type { FC } from 'react';
import { Switch } from 'src/common/components/switch';
import { useCurrentSpace } from 'src/hooks/space';

import { Option } from '../option';
import { SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';
import { isPersonalSpace } from 'src/hooks/space/use-is-pro-space';

export const TemplateRecommendSetting: FC = () => {
  const {
    spaceSettingState: { enableRecommend, setRecommend },
  } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  if (isPersonalSpace(currentSpace.planType)) return null;

  if (__PRIVATE__) return null;
  return (
    <Option
      title="智能推荐模版"
      description="关闭后，新建空白页面将不进行相关模板推荐"
      rightPart={<Switch open={enableRecommend} onSwitch={setRecommend} />}
    />
  );
};
