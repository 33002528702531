import { lightTagBackgroundColor } from '@flowus/common/block/color/data';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';

interface Props {
  className?: string;
  text?: string;
  absolute?: boolean;
  spanClass?: string;
  onClick?: () => void;
  showIcon?: boolean;
  isLabel?: boolean;
}
export const ProIcon: FC<Props> = (props) => {
  const { spanClass, absolute = true, className, text = 'Pro', showIcon = false, isLabel } = props;
  return (
    <div
      className={cx(
        'flex items-center justify-center px-1 whitespace-nowrap bg-active_color_10 space-x-px text-t5-medium text-[#8F6C28] border-[#FFECAA] border',
        absolute && 'absolute right-[-4px] top-[-8px]',
        className
      )}
      onClick={props.onClick}
      style={{
        background: lightTagBackgroundColor.yellow,
        borderRadius: isLabel ? '10px 10px 10px 2px' : '4px',
      }}
    >
      {showIcon && <Icon name="IcUpgrade" size="xxsmall" className="mr-px" />}
      <span className={spanClass}>{text}</span>
    </div>
  );
};
