import { Deferred } from '@flowus/common/async';
import type { BlockInvitationDTO, PermissionGroupDTO, UserDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { last } from 'lodash-es';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { HOST_NAME } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { globalListenerHelper, PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { HelperIconBox, helperLink } from 'src/components/helper-icon';
import { PRODUCT_NAME } from 'src/const/title';
import { useBlock } from 'src/hooks/block/use-block';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useAllUser } from 'src/hooks/page/use-subscription-data';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useSpaceSecurityState } from 'src/hooks/space/use-space-security-state';
import { useCurrentUser } from 'src/hooks/user';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import { $appUiStateCache, useGuestsList } from 'src/services/app';
import { Regex, SearchParams, ViewPath } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getBlockPageLikeTitle } from 'src/utils/get-untitled-name';
import { useGetPageId } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { getDiffPermissions } from 'src/utils/permission-utils';
import { getPinyin } from 'src/utils/pinyin';
import { searchUser } from 'src/utils/search-util';
import { ImportMember } from 'src/views/main/setting-modal/members-setting/import-member';
import { v4 } from 'uuid';
import { Permissions } from '../components/permissions';
import { UserList } from '../components/user-list';
import type { UserPermission } from '../types';
import { SearchUserModel } from './context';
import { temporaryBatchAndUpdateUsers } from './utils';

const SynergyInvitationModalId = 'SynergyInvitation';
const MultipleImportModalId = 'MultipleImportModal';

// 外部协作者临时用的groupId前缀
export const BATCH_GUEST_PREFIX = 'batch_guest';
export type BatchGuestPermission = PermissionGroupDTO & { markNames: Record<string, string> };

export const SearchUserSelect: FC = () => {
  const {
    uuid,
    backToMainPanel,
    role,
    setRole,
    onConfirm,
    selectUser,
    selectedUsers,
    isForm,
    isAIMember,
    setBatchGuestGroups,
    batchGuestGroups,
  } = useModel(SearchUserModel);

  const openSynergyInvitation = useOpenSynergyInvitation();
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const spaceUsers = useCurrentSpaceUsers();
  const allUsers = useAllUser();
  const guestsList = useGuestsList();

  // isForm为true时，uuid为viewId
  const collectionView = useCollectionView(uuid);
  const { permissions: _permissions } = usePermissions(uuid);
  const permissions = isForm
    ? collectionView?.format.formInviteUserPermissions || []
    : _permissions;

  const { userPermissions, groupPermissions, spacePermission } = getDiffPermissions(permissions);

  const { permissionGroups = [] } = currentSpace;
  const abandonedGuest = useSpaceSecurityState('abandonedGuest');

  const aiMembers = $appUiStateCache.$aiMembers;
  const memberIdsSet = new Set();
  aiMembers.forEach((item) => memberIdsSet.add(item.uuid));

  const users = Object.values(allUsers)
    .map((user) => {
      const permission = userPermissions.find((o) => o.userId === user.uuid);
      return (
        permission || {
          type: PermissionType.USER,
          userId: user.uuid,
        }
      );
    })
    .slice(0, text ? undefined : 5)
    .filter((permission) => !selectedUsers.some((o) => o.userId === permission.userId))
    .filter((permission) => {
      if (!text) return userPermissions.every((o) => o.userId !== permission.userId);
      if (!permission.userId) return false;
      const user = allUsers[permission.userId];
      if (!user) return false;
      if (searchUser(user, text)) return true;
      if (
        (Regex.Mobile.test(text) || (__BUILD_IN__ && Regex.Email.test(text))) &&
        user.pinyin.includes(text)
      ) {
        return true;
      }
      return false;
    })
    .map((o, index) => ({ ...o, index }));

  const groupsWithSpace =
    !text && spacePermission && spacePermission.role !== PermissionRole.NONE
      ? []
      : [
          {
            groupId: '',
            type: PermissionType.SPACE,
            name: currentSpace.title,
            userIds: Object.keys(spaceUsers),
            role: spacePermission ? spacePermission.role : PermissionRole.NONE,
          },
        ];

  const groups = groupsWithSpace
    .concat(
      permissionGroups.map((group) => {
        const o = {
          ...group,
          groupId: group.id,
          type: PermissionType.GROUP,
          name: group.name,
          userIds: group.userIds,
        };
        const g = groupPermissions.find((o) => o.groupId === group.id);
        return g ? { ...o, role: g.role } : o;
      }) as typeof groupsWithSpace
    )
    .filter((group) => !selectedUsers.some((o) => o.groupId === group.groupId))
    .filter((group) => {
      if (isAIMember && memberIdsSet.has(group.groupId)) return false;
      if (!text) return groupPermissions.every((o) => o.groupId !== group.groupId);
      if (group.name.toLowerCase().includes(text.toLowerCase())) return true;
      if (getPinyin(group.name).includes(text.replace("'", ''))) return true;
      return group.userIds.some((userId) => {
        const user = spaceUsers[userId];
        if (!user) return false;
        if (searchUser(user, text)) return true;
        return false;
      });
    })
    // index 用于键盘上下选择
    .map((o, index) => ({ ...o, index: index + users.length }));

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Backspace') {
      if (!selectedUsers.length || text.length) return;
      event.preventDefault();
      const lastUser = last(selectedUsers);
      if (lastUser) {
        selectUser(lastUser);
      }
    }
  };

  const onSelectUser = (user: UserPermission) => {
    selectUser(user);
    setText('');
    inputRef.current?.focus();
  };

  const openMultipleImport = (e: MouseEvent) => {
    openModal.dropdown({
      placement: 'bottom',
      offset: [0, 8],
      popcorn: e.currentTarget,
      modalId: MultipleImportModalId,
      content: () => {
        return (
          <ImportMember
            isGuest={true}
            onReceiveGuestList={async (list) => {
              const { userIds, markNames } = await temporaryBatchAndUpdateUsers(list);
              const id = `${BATCH_GUEST_PREFIX}${v4()}`;
              setBatchGuestGroups([
                ...batchGuestGroups,
                {
                  id,
                  name: '批量外部协作者',
                  userIds,
                  markNames,
                },
              ]);
              const item: UserPermission = {
                id,
                name: '批量外部协作者',
                index: -1,
                // @ts-ignore we need groupId
                groupId: id,
                type: PermissionType.GROUP,
              };
              onSelectUser(item);
              openModal.closeModal(MultipleImportModalId);
            }}
          />
        );
      },
    });
  };

  const totalLength = users.length + groups.length;

  useEffect(() => {
    // 用 autoFocus 会影响到动画
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  useEffect(() => {
    const arrow = (event: globalThis.KeyboardEvent) => {
      if (event.code === 'ArrowUp') {
        setActiveIndex((pre) => {
          if (pre <= 0) {
            return totalLength - 1;
          }
          return --pre;
        });
        event.preventDefault();
        return true;
      }

      if (event.code === 'ArrowDown') {
        setActiveIndex((pre) => {
          if (pre >= totalLength - 1) {
            return 0;
          }
          return ++pre;
        });
        event.preventDefault();
        return true;
      }
    };

    const unregister = globalListenerHelper.addEventListener(
      'keydown',
      arrow,
      PRIORITY_DIALOG + 1,
      true
    );

    return unregister;
  }, [totalLength]);

  const guests = users.filter(({ userId }) =>
    userId && (Regex.Mobile.test(text) || (__BUILD_IN__ && Regex.Email.test(text)))
      ? !spaceUsers[userId]
      : guestsList.some((g) => g.userId === userId)
  );

  const innerUsers = users.filter(({ userId }) => {
    if (isAIMember && memberIdsSet.has(userId)) return false;

    return userId && spaceUsers[userId];
  });

  const hasGuest = selectedUsers.some(
    (user) =>
      user.groupId?.startsWith(BATCH_GUEST_PREFIX) || (user.userId && !spaceUsers[user.userId])
  );

  const disabled =
    selectedUsers.length === 0 ||
    // 外部协作者不可设置最高权限
    (!isForm && role === PermissionRole.EDITOR && hasGuest);

  return (
    <>
      {!isAIMember && (
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center">
            <Icon
              name="IcArrowBack"
              size="middle"
              className="animate-click"
              onClick={backToMainPanel}
            />
            <span className="text-t2 ml-1">
              {isForm ? (
                '邀请填写者'
              ) : __BUILD_IN__ ? (
                '邀请协作'
              ) : (
                <HelperIconBox popup="了解协作权限" link={helperLink.invitePage}>
                  邀请协作
                </HelperIconBox>
              )}
            </span>
          </div>
          {!abandonedGuest && (
            <div className="flex space-x-3 text-active_color text-t4">
              <div className="animate-click" onClick={openSynergyInvitation}>
                通过链接邀请
              </div>
              <div hidden={__PRIVATE__} className="animate-click" onClick={openMultipleImport}>
                批量导入
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-wrap px-3 mb-4 space-x-1.5 items-center mx-4 min-h-8 border border-grey6 bg-grey9 focus-within-border rounded text-t2">
        {selectedUsers.length ? (
          selectedUsers.map((user) => {
            let group = permissionGroups.find((p) => p.id === user.groupId);
            if (!group) {
              group = batchGuestGroups.find((p) => p.id === user.groupId);
            }
            return (
              <div
                key={`${user.userId}${user.groupId}`}
                className="flex h-5 bg-grey6 rounded-sm items-center px-1.5 my-1.5"
              >
                <div className="flex">
                  <span className="max-w-[210px] text-ellipsis">{user.name}</span>
                  {group && <div className="flex-shrink-0">{`（${group.userIds.length} 人）`}</div>}
                </div>
                <Icon
                  size="small"
                  onClick={() => onSelectUser(user)}
                  name="IcToastClose"
                  className="ml-2 animate-click cursor-pointer"
                />
              </div>
            );
          })
        ) : (
          <Icon name="IcSearch" size="small" className="mr-1 text-grey4" />
        )}
        <input
          ref={inputRef}
          className="bg-transparent my-1.5 flex-1"
          placeholder={isAIMember ? '搜索成员名/成员组名' : '搜索人员、组、手机号、邮箱'}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const { value } = e.target;
            setText(value);

            const isPhone = Regex.Mobile.test(value);
            const isEmail = Regex.Email.test(value);
            if (isPhone || isEmail) {
              const api = isPhone
                ? request.infra.findUser(value)
                : request.infra.findUser('', value);

              const updateObj: Partial<UserDTO> = isPhone ? { phone: value } : { email: value };
              void api.then(({ user }) => {
                if (user) {
                  dispatch(
                    usersActions.updateSpaceUsers({
                      users: { [user.uuid]: { ...user, ...updateObj } },
                    })
                  );
                } else {
                  dispatch(
                    usersActions.updateSpaceUsers({
                      users: {
                        [value]: { uuid: value, nickname: '', ...updateObj } as UserDTO,
                      },
                    })
                  );
                }
              });
            }
          }}
          value={text}
        />
      </div>

      <div className="p-4 pt-0 max-h-[60vh] space-y-2 overflow-y-auto">
        {!!innerUsers.length && (
          <>
            <div className="text-t2 text-grey3">选择空间成员</div>
            <UserList
              isForm={isForm}
              searchText={text}
              activeIndex={activeIndex}
              selectUser={onSelectUser}
              permissions={innerUsers}
              style={{
                overflow: 'hidden',
                maxHeight: 9999999,
              }}
            />
          </>
        )}
        {!!groups.length && (
          <>
            <div className="text-t2 text-grey3 mt-2">选择空间成员组</div>
            <UserList
              isForm={isForm}
              searchText={text}
              activeIndex={activeIndex}
              selectUser={onSelectUser}
              permissions={groups}
              style={{
                overflow: 'hidden',
                maxHeight: 9999999,
              }}
            />
          </>
        )}
        {!!guests.length && (
          <>
            <div className="text-t2 text-grey3">邀请外部协作者</div>
            <UserList
              isForm={isForm}
              searchText={text}
              activeIndex={activeIndex}
              selectUser={(user) => {
                if (abandonedGuest) {
                  message.warning('禁止邀请外部协作者，请联系管理员开通');
                  return;
                }
                onSelectUser(user);
              }}
              permissions={guests}
            />
          </>
        )}
        {!users.length && !groups.length && <span className="text-t2 text-grey3">无搜索结果</span>}
      </div>

      <div className="shrink-0 flex items-center justify-between p-4 border-t">
        {!isAIMember && (
          <div className="flex items-center">
            {!isForm && (
              <>
                <span className="ml-1 text-t2 mr-3">统一设置权限为</span>
                <Permissions noRemove role={role} onChange={setRole} isGuest={hasGuest} />
              </>
            )}
          </div>
        )}

        <div className="flex items-center ml-auto">
          <Button onClick={backToMainPanel} className="mr-4 text-t2-medium">
            取消
          </Button>
          <Tooltip
            popup={
              disabled && (
                <div className="p-2">
                  {selectedUsers.length === 0 ? '请先选择一个成员' : '外部协作者不可以设置所有权限'}
                </div>
              )
            }
          >
            <Button
              disable={disabled}
              colorType="active"
              onClick={onConfirm}
              className="text-t2-medium text-white"
            >
              确定
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

const SynergyInvitation: FC = () => {
  const pageId = useGetPageId();
  const block = useBlock(pageId);
  const currentUser = useCurrentUser();
  const userName = useUserName(currentUser.uuid);
  const openModal = useOpenModal();
  const [role, setRole] = useState(PermissionRole.WRITER);
  const [invitation, setInvitation] = useState<BlockInvitationDTO>();

  const check = () => {
    const defer = new Deferred();
    openModal.warning({
      title: '重置链接',
      content: '重置链接后之前的链接将失效，确定要重置吗？',
      confirm: async () => {
        defer.resolve();
      },
      cancel: () => {
        defer.reject(new Error('cancel'));
      },
    });
    return defer.promise;
  };

  const resetUrl = async () => {
    const res = await request.infra.resetDocInvitation(pageId, { role });
    setInvitation(res);
    message.success('链接重置成功');
  };

  const onReset = async () => {
    await check();
    await resetUrl();
  };

  const changeRole = async (newRole: PermissionRole) => {
    await check();
    setRole(newRole);
    void resetUrl();
  };

  useEffect(() => {
    void (async () => {
      const res = await request.infra.getDocInvitation(pageId, PermissionRole.WRITER);
      setInvitation(res);
      res.role && setRole(res.role);
    })();
  }, [pageId]);

  const invUrl = `${getLocationOrigin()}${ViewPath.login}?${SearchParams.page}=${pageId}&${
    SearchParams.cooperate
  }=${invitation?.uuid}`;

  const preText = [
    userName,
    '邀请你加入',
    getBlockPageLikeTitle(block?.type, block?.data.segments),
    '的协作',
  ].join(' ');

  const url = [
    preText,
    invUrl,
    `通过邀请链接加入，或复制这段内容打开 ${PRODUCT_NAME} APP 快捷加入`,
  ].join('\n');

  const copyLink = () => {
    void writeTextInClipboard(url);
  };

  return (
    <div className="next-modal flex flex-col w-125 px-7 pb-5">
      <div className="text-h2 py-7">链接邀请外部协作者</div>
      <div className="mb-5 text-grey3 text-t4">
        {dayjs(invitation?.expirationAt).format('YYYY年MM月DD日')}
        前，任何通过此链接邀请的人都可以加入页面协作，到达页面协作人数上限后将无法加入
      </div>

      <Input
        readonly
        value={invitation ? invUrl : '请点击重置链接'}
        placeholder={`https://${HOST_NAME}/XXXXXXXXX`}
        addonAfter={
          <div className="flex">
            <Button className="rounded-none border-y-transparent" onClick={onReset}>
              重置链接
            </Button>
            <Button
              className="rounded-none border-y-transparent border-x-transparent"
              onClick={copyLink}
            >
              复制邀请链接
            </Button>
          </div>
        }
      />
      <div className="flex mt-5 items-end">
        <span className="text-t2 mr-2 链接权限设置">设置链接权限为</span>
        <Permissions noRemove isGuest role={role} onChange={changeRole} />
      </div>
    </div>
  );
};

export const useOpenSynergyInvitation = () => {
  const openModal = useOpenModal();

  return useCallback(
    (e: MouseEvent) => {
      openModal.dropdown({
        popcorn: e.currentTarget,
        modalId: SynergyInvitationModalId,
        placement: 'bottom',
        offset: [0, 8],
        content: () => <SynergyInvitation />,
      });
    },
    [openModal]
  );
};
