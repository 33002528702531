import type { SpaceSettingDTO } from '@next-space/fe-api-idl';
import { useCurrentSpace, useSpace } from './use-current-space';
import { isTeamProSpace } from './use-is-pro-space';

export function useSpaceSecurityState<
  K extends keyof Pick<
    SpaceSettingDTO,
    | 'abandonedExport'
    | 'abandonedGuest'
    | 'abandonedPublicShare'
    | 'openWatermark'
    | 'openImageWatermark'
    | 'imageWatermarkText'
  >
>(state: K, spaceId?: string) {
  const space = useSpace(spaceId);
  const currentSpace = useCurrentSpace();
  if (space.uuid) {
    if (!isTeamProSpace(space.planType)) return false;
    // @ts-ignore 不要动这里的代码
    return space?.setting?.[state] || space[state];
  }
  if (currentSpace.uuid) {
    if (!isTeamProSpace(currentSpace.planType)) return false;
    return currentSpace?.setting?.[state];
  }
}
