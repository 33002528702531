import { GlobalImages } from '@flowus/common';
import { Button } from '@flowus/common/components';
import { message } from '@flowus/common/components/message';
import { Switch } from '@flowus/common/components/switch';
import { DEFAULT_SEO_TITLE_DESCRIPTION, DEFAULT_SEO_TITLE_PLACEHOLDER } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { ViewPath } from '@flowus/common/static-const';
import { segmentsToText } from '@flowus/common/subscribe/util';
import { VITE_DESCRIPTION, VITE_DOCUMENT_TITLE } from '@flowus/login/const';
import { useSetState } from 'ahooks';
import { split } from 'lodash-es';
import { useMemo } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { HOST_NAME } from 'src/common/const';
import { PRODUCT_NAME } from 'src/const/title';
import { usePublicShare } from 'src/hooks/page/use-public-share';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { transaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { getFirstTextByBlock } from 'src/utils/block-utils';
import { getPageInnerTextByDesorption } from 'src/utils/emoji-favicon';
import { usePickBlock } from 'src/utils/pick-block';
import { ProIcon } from 'src/views/main/header/pro-icon';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenAskUpgradeSpace } from 'src/views/main/setting-modal/use-open-ask-upgrade-space';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { SharePanelHeader, useSharePanelModel } from './helper';

export const ShareSeo = () => {
  const currentSpace = useCurrentSpace();
  const { uuid } = useSharePanelModel();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  const openSettingModal = useOpenSettingModal();
  const { set, permissionsState } = usePublicShare(uuid);
  const { backToMainPanel } = useSharePanelModel();
  const block = usePickBlock(uuid, ['data'], ['seoTitle', 'seoDescription', 'segments']);
  const isOpen = permissionsState.allowSeo;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const blockTitle = useMemo(() => segmentsToText(block?.data.segments) || VITE_DOCUMENT_TITLE, []);
  const blockDesc = useMemo(() => {
    if (uuid) {
      const text = getFirstTextByBlock(uuid);
      if (text) {
        return text;
      }
    }
    return getPageInnerTextByDesorption() || VITE_DESCRIPTION;
  }, [uuid]);

  const [state, setState] = useSetState({
    title: block?.data.seoTitle,
    defaultTitle: DEFAULT_SEO_TITLE_PLACEHOLDER,
    desc: block?.data.seoDescription,
    defaultDesc: DEFAULT_SEO_TITLE_DESCRIPTION,
  });

  const changeOpen = () => {
    // 如果设置了密码，需要把seo开关关掉，并且item disable掉，两者不能共存
    if (permissionsState.password) {
      message.error('请先关闭密码');
      return;
    }
    if (isFreeSpaceLimit) {
      openAskUpgradeSpace(() => {
        openSettingModal({
          defaultMenuType: SettingMenuType.upgrade,
          from: OpenSettingFrom.share_seo,
        });
      });
      return;
    }
    void set().setShareSeo();
  };

  const save = () => {
    transaction(() => {
      updateBlock(uuid, {
        data: {
          seoTitle: state.title,
          seoDescription: state.desc,
        },
      });
    });
    backToMainPanel();
  };

  return (
    <div className="text-t2">
      <SharePanelHeader title="搜索引擎推广" />
      <div
        className="flex items-center justify-between px-4 py-2.5 animate-click animate-hover rounded-none"
        onClick={changeOpen}
      >
        <div>
          <div className="space-x-1">
            <span>搜索引擎推广</span>
            {isFreeSpaceLimit && <ProIcon className="inline-block" absolute={false} />}
          </div>
        </div>
        <Switch open={isOpen} onSwitch={changeOpen} />
      </div>
      <div className={cx('px-4 pb-4 space-y-4 mt-2', !isOpen && 'opacity-60')}>
        <div className="space-y-2">
          <div className="text-t2-medium text-grey3">SEO 预览</div>
          <div className="border rounded-md p-4">
            <div className="space-x-2 flex items-center">
              <img src={GlobalImages.favicon} alt={PRODUCT_NAME} className="w-6 h-6" />
              <div className="break-words w-full pr-8">
                <div className="text-t2-medium">{PRODUCT_NAME}</div>
                <div className="text-t5 text-grey3 text-ellipsis">
                  {'https://'}
                  {currentSpace.domain
                    ? `${HOST_NAME}/${currentSpace.domain}${ViewPath.share}${uuid}`
                    : `${HOST_NAME}${ViewPath.share}${uuid}`}
                </div>
              </div>
            </div>
            <div className="break-words text-t1-medium text-active_color my-1">
              {state.title || blockTitle}
            </div>
            <div className="text-t2-medium text-grey2 break-words">{state.desc || blockDesc}</div>
          </div>
        </div>
        <div className="space-y-2">
          <div className="text-t2-medium text-grey3">标题</div>
          <div className="p-4 pr-10 border rounded-md relative">
            <span className="absolute right-4 bottom-4 text-grey3">
              {split(state.title, '').length}
            </span>
            <AutoHeightTextArea
              className="!text-t2-medium"
              readOnly={!isOpen}
              placeholder={state.defaultTitle}
              value={state.title}
              singleLine
              onChange={(e) => setState({ title: e.target.value })}
            />
          </div>
        </div>
        <div className="space-y-2">
          <div className="text-t2-medium text-grey3">描述</div>
          <div className="p-4 pb-10 border rounded-md relative">
            <span className="absolute right-4 bottom-2.5 text-grey3">
              {split(state.desc, '').length}
            </span>
            <AutoHeightTextArea
              className="!text-t2-medium"
              readOnly={!isOpen}
              placeholder={state.defaultDesc}
              value={state.desc}
              onChange={(e) => setState({ desc: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className={cx('pb-4 flex items-center justify-end px-4 gap-x-4')}>
        <Button disable={!isOpen} onClick={backToMainPanel}>
          取消
        </Button>
        <Button disable={!isOpen} colorType="active" onClick={save}>
          保存
        </Button>
      </div>
    </div>
  );
};
