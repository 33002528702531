import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useSharePanelModel } from './helper';
import { SearchUser } from './search-user';
import { ShareMain } from './share-main';
import { SharePassword } from './share-password';
import { SharePay } from './share-pay';
import { ShareSeo } from './share-seo';
import { ShareUserPermissionList } from './share-user-permission-list';
import type { ShareProps } from './types';
import { Panel, SHARE_PANEL_OFFSET } from './types';

export const SharePanel: FC<Pick<ShareProps, 'uuid'>> = ({ uuid }) => {
  const { onChangeTab, panel, setX, x } = useSharePanelModel();

  return (
    <div
      className="sm:mx-3 sm:w-[calc(100vw-24px)] next-modal overflow-hidden"
      style={{ width: SHARE_PANEL_OFFSET }}
    >
      <div className="flex duration-100 ease-in-out" style={{ transform: `translateX(${x}px)` }}>
        <Tab active={x === 0}>
          <ShareMain uuid={uuid} onChangeTab={onChangeTab} />
        </Tab>
        <Tab active={x === -SHARE_PANEL_OFFSET} className="flex flex-col">
          {panel === Panel.SEARCH_USER && (
            <SearchUser uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.ALL_USERS && (
            <ShareUserPermissionList uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.SEO && <ShareSeo />}
          {panel === Panel.PASSWORD && (
            <SharePassword uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.PAY && <SharePay uuid={uuid} backToMainPanel={() => setX(0)} />}
        </Tab>
      </div>
    </div>
  );
};

interface Props {
  active: boolean;
  className?: string;
}

export const Tab: FC<Props> = ({ active, className, children }) => {
  return (
    <div className={cx('flex-shrink-0', className)} style={{ width: SHARE_PANEL_OFFSET }}>
      {active && children}
    </div>
  );
};
