import { cx } from '@flowus/common/cx';
import type { ComponentProps, CSSProperties, FC } from 'react';
import { usePageLayout } from 'src/hooks/page/use-page-layout';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { Images } from 'src/image';
import { updateBlock } from 'src/redux/managers/block/update';
import { bizTracker } from 'src/utils/biz-tracker';
import { OpenSettingFrom } from '../setting-modal/type';
import { useOpenAskUpgradeSpace } from '../setting-modal/use-open-ask-upgrade-space';
import { useOpenUpgradeSpace } from '../setting-modal/use-open-setting-modal';
import { ProIcon } from './pro-icon';
import { LOCAL_LNG, PageFontFamily } from '@flowus/common/const';

interface Props {
  pageId: string;
  className?: string;
  onOperationFinish?: () => void;
  hideLayoutComponent?: boolean;
  hideFontComponent?: boolean;
}

export const LayoutSwitcher: FC<Props> = (props) => {
  useIsDarkMode();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const { fontFormat, pageLayout } = usePageLayout(props.pageId);
  const transaction = useTransaction();
  const onClickFontComponent = (font: typeof fontFormat) => {
    if (
      isFreeSpaceLimit &&
      ['lxgwWenKai', 'sourceHanSerif', 'loraVariable', 'dmMono'].includes(font)
    ) {
      openAskUpgradeSpace(() => {
        openUpgradeSpace(OpenSettingFrom.custom_font_layout);
      });
      return;
    }
    let fontType = 'default';
    if (font === 'lxgwWenKai') {
      fontType = 'LXGW_WenKai';
    } else if (font === 'sourceHanSerif') {
      fontType = 'Source_Han_Serif_SCVF';
    } else if (font === 'loraVariable') {
      fontType = 'loraVariable';
    } else if (font === 'dmMono') {
      fontType = 'dmMono';
    }
    bizTracker.event('font_set', {
      font_type: fontType,
    });
    transaction(() => {
      updateBlock(props.pageId, {
        data: {
          format: {
            fontFormat: font,
          },
        },
      });
    });
    props.onOperationFinish?.();
  };
  const onClickLayoutComponent = (layout: typeof pageLayout) => {
    if (isFreeSpaceLimit && (layout === 'compact' || layout === 'loose')) {
      openAskUpgradeSpace(() => {
        openUpgradeSpace(OpenSettingFrom.custom_font_layout);
      });
      return;
    }
    let layoutType = 'default';
    if (layout === 'compact') {
      layoutType = 'tight';
    } else if (layout === 'loose') {
      layoutType = 'loose';
    }
    bizTracker.event('font_set', {
      layout_set: layoutType,
    });
    transaction(() => {
      updateBlock(props.pageId, {
        data: {
          format: {
            pageLayout: layout,
          },
        },
      });
    });
    props.onOperationFinish?.();
  };
  return (
    <div className={props.className}>
      {!props.hideFontComponent && (
        <>
          <div className="text-t4 text-grey3">{LOCAL_LNG.pageFont}</div>
          <div className="grid grid-cols-3 gap-x-2">
            <FontComponent
              title="息流"
              desc="默认"
              isSelected={fontFormat === 'default'}
              onClick={() => {
                onClickFontComponent('default');
              }}
            />
            <FontComponent
              title="息流"
              desc={__BUILD_IN__ ? 'Lora' : '霞鹜文楷'}
              showPro={isFreeSpaceLimit}
              isSelected={
                __BUILD_IN__ ? fontFormat === 'loraVariable' : fontFormat === 'lxgwWenKai'
              }
              style={{
                fontFamily: __BUILD_IN__ ? PageFontFamily.lora : PageFontFamily.lxgwWenKai,
              }}
              onClick={() => {
                onClickFontComponent(__BUILD_IN__ ? 'loraVariable' : 'lxgwWenKai');
              }}
            />
            <FontComponent
              title="息流"
              desc={__BUILD_IN__ ? 'DMMono' : '思源宋体'}
              showPro={isFreeSpaceLimit}
              isSelected={__BUILD_IN__ ? fontFormat === 'dmMono' : fontFormat === 'sourceHanSerif'}
              style={{
                fontFamily: __BUILD_IN__ ? PageFontFamily.DMMono : PageFontFamily.sourceHanSerif,
              }}
              onClick={() => {
                onClickFontComponent(__BUILD_IN__ ? 'dmMono' : 'sourceHanSerif');
              }}
            />
          </div>
        </>
      )}
      {!props.hideLayoutComponent && (
        <>
          <div className="text-t4 text-grey3 mt-5">页面布局</div>
          <div className="flex justify-between">
            <LayoutComponent
              title="紧密"
              showPro={isFreeSpaceLimit}
              src={Images.compactLayout}
              isSelected={pageLayout === 'compact'}
              onClick={() => {
                onClickLayoutComponent('compact');
              }}
            />
            <LayoutComponent
              title="默认"
              src={Images.defaultLayout}
              isSelected={pageLayout === 'default'}
              onClick={() => {
                onClickLayoutComponent('default');
              }}
            />
            <LayoutComponent
              title="宽松"
              showPro={isFreeSpaceLimit}
              src={Images.looseLayout}
              isSelected={pageLayout === 'loose'}
              onClick={() => {
                onClickLayoutComponent('loose');
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

interface FontComponentProps extends Pick<ComponentProps<'div'>, 'onClick'> {
  title: string;
  desc: string;
  isSelected?: boolean;
  showPro?: boolean;
  style?: CSSProperties;
}
export const FontComponent: FC<FontComponentProps> = (props) => {
  return (
    <div
      className={cx(
        'relative flex flex-col justify-center items-center min-h-[60px] py-1 border border-solid mt-2.5 rounded cursor-pointer',
        {
          'border-active_color': props.isSelected,
          'border-grey6 ': !props.isSelected,
          'bg-active_color/5': props.isSelected,
        }
      )}
      style={props.style}
      onClick={props.onClick}
    >
      <div>
        <div className="text-t2 text-center">{props.title}</div>
        <div className="text-t4 mt-1 text-grey3 text-center">{props.desc}</div>
      </div>
      {props.showPro && <ProIcon isLabel className="-right-2.5 z-10" />}
    </div>
  );
};

interface LayoutComponentProps extends Pick<ComponentProps<'div'>, 'onClick'> {
  title: string;
  src: string;
  isSelected?: boolean;
  showPro?: boolean;
}
export const LayoutComponent: FC<LayoutComponentProps> = (props) => {
  return (
    <div
      className={cx(
        'relative flex flex-col justify-center items-center w-[66px] py-1 min-h-[44px] border-[1px] border-solid mt-2.5 rounded cursor-pointer',
        {
          'border-active_color': props.isSelected,
          'border-grey6 ': !props.isSelected,
          'bg-active_color/5': props.isSelected,
        }
      )}
      style={{
        borderColor: props.isSelected ? '--active_color' : undefined,
      }}
      onClick={props.onClick}
    >
      <div className={cx('flex items-center', __BUILD_IN__ && 'flex-col space-y-1')}>
        <img src={props.src} className="w-5" />
        <div className="text-t4 text-grey3 text-center ml-1">{props.title}</div>
      </div>
      {props.showPro && <ProIcon isLabel className="-right-2.5 z-10" />}
    </div>
  );
};
