import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import type { ReadonlyProp } from 'src/redux/types';

const ON_EDIT = 0;
const ON_DELETE = 1;
export interface MoreMenuProps extends ReadonlyProp {
  className?: string;
  onEdit: () => void;
  onDelete: () => void;
  moreColor?: string;
  templateId?: string;
}
/**
 * 更多菜单
 */
export const MoreMenu: FC<MoreMenuProps> = (props) => {
  const openModal = useOpenModal();
  const handleItemClick = (onCloseModal: () => void) => (item: ListItem<any>, index: number) => {
    onCloseModal();
    switch (index) {
      case ON_EDIT:
        props.onEdit();
        break;
      case ON_DELETE:
        props.onDelete();
        break;
      default:
    }
  };

  const items: ListItem[] = [
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcEditor',
        title: '编辑',
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcTrash',
        title: '删除',
      },
    },
  ];

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        openModal.dropdown({
          popcorn: event.currentTarget,
          placement: 'bottom-start',
          content: ({ onCloseModal }) => (
            <ListView
              className="w-[200px] py-2 next-modal"
              items={items}
              onItemClick={handleItemClick(onCloseModal)}
            />
          ),
        });
      }}
      className={cx('flex justify-center items-center cursor-pointer  self-end', props.className)}
    >
      <Icon size="middle" name="IcMore" style={{ color: props.moreColor || 'white' }} />
    </div>
  );
};
