import { message } from '@flowus/common/components/message';
import { VITE_PREFIX } from '@flowus/common/const';
import type { ModalSchema } from '@flowus/common/next-modal';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { VITE_NOTION_CLIENT_ID } from 'src/env';
import { ViewPath } from 'src/utils';
import { sequence } from 'src/utils/async-utils';
import { enqueueTask } from 'src/utils/tasks';
import { TocType } from 'src/views/main/aside/toc/types';
import { useCommon } from './hook';
import { cx } from '@flowus/common/cx';
import { publicLink } from 'src/common/const';
import { Icon } from 'src/common/components/icon';

export const ImportNotion: FC<ModalSchema.CloseModal> = () => {
  const {
    createDocPage,
    fetchPage,
    currentSpace,
    closeAllModal,
    history,
    openLoading,
    closeLoading,
  } = useCommon();

  const [id, setId] = useState('');

  const onClick = () => {
    const width = 800;
    const height = 800;

    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const redirectUri = encodeURIComponent(`${VITE_PREFIX}${ViewPath.notion}`);

    window.open(
      `https://api.notion.com/v1/oauth/authorize?client_id=${VITE_NOTION_CLIENT_ID}&response_type=code&owner=user&redirect_uri=${redirectUri}`,
      'notion',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const onSubmit = () => {
    if (id.length !== 32) {
      message.error('Notion id 错误, 如果是 database 只需要 ?v= 之前的部分');
      return;
    }

    const pageId = createDocPage(BlockType.PAGE, { parentId: currentSpace.uuid }, TocType.PRIVATE);

    if (!pageId) return;

    openLoading('云端导入中，点击空白处关闭等待，点击屏幕右下角菜单可以查看导入状态');

    void sequence(() =>
      enqueueTask(
        {
          eventName: 'import',
          request: {
            blockId: pageId,
            spaceId: currentSpace.uuid,
            importOptions: {
              type: 'notion',
              notionId: id,
            },
          },
        },
        {
          success: async ({ uuid }) => {
            uuid && void fetchPage(uuid, { force: true });
            closeLoading();
            closeAllModal();
            history.push(`/${pageId}`);
          },
          fail() {
            if (pageId) {
              closeLoading();
              closeAllModal();
              message.warning('Had a bit of a problem getting the task status');
            }
          },
        }
      )
    );
  };
  return (
    <div className="w-[480px] p-6">
      <div className="w-full">
        <div className="flex items-center ">
          <CircleNo orderNum={1} className="self-start mt-0.5" />
          <div className="ml-2">
            <div className="text-t2-medium">授权待导入 Notion 页面</div>
            <div className="text-grey2 text-t4 mt-1">已授权页面可以跳过此步骤</div>
            <Button className="mt-4 h-7 text-t2-medium" colorType="active" onClick={onClick}>
              授权
            </Button>
          </div>
        </div>
        <div className="flex items-center mt-6 w-full">
          <CircleNo orderNum={2} className="self-start mt-0.5" />
          <div className="ml-2 w-full">
            <div className="flex items-center">
              <div className="text-t2-medium">输入已授权 Notion 页面 id</div>
              <a target="_blank" href={publicLink.importFromNotion} className="animate-click">
                <Icon size="small" name="IcInviteHelp" className="text-grey3 ml-2" />
              </a>
            </div>
            <Input
              className="w-full mt-1"
              inputClassName="h-8 "
              placeholder="输入已授权 Notion 页面 id"
              onChange={setId}
              value={id}
            />
            <Button className="mt-4 h-7 text-t2-medium" colorType="active" onClick={onSubmit}>
              导入
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const CircleNo: FC<{ orderNum: number; className?: string }> = (props) => {
  return (
    <div
      className={cx(
        'flex justify-center items-center rounded-full w-4 h-4 bg-active_color/20',
        props.className
      )}
    >
      <span className=" text-active_color text-t4">{props.orderNum}</span>
    </div>
  );
};
