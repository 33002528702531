import Cookies from 'js-cookie';

export const getIsApp = () => Cookies.get('inApp') === 'true';

export const isHuaWei = () => {
  const UA = window.navigator.userAgent.toLocaleLowerCase();
  if (/huawei|honor/.test(UA)) {
    return true;
  }
};
