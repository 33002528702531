import { createCode } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { IconWidget } from 'src/common/components/emoji-widget';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { usePublicShare } from 'src/hooks/page/use-public-share';
import { transaction } from 'src/hooks/use-transaction';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { Checkbox } from '../check-box';
import { SharePanelHeader, useGetSharePageUrl } from './helper';
import type { ShareProps } from './types';

export const SharePassword: FC<Pick<ShareProps, 'uuid' | 'backToMainPanel'>> = (props) => {
  const { uuid, backToMainPanel } = props;
  const { url, text } = useGetSharePageUrl(uuid)();
  const { set, permissionsState } = usePublicShare(uuid);
  const { role, shared, isRestricted, parentId, password: defaultPassword } = permissionsState;

  const isEditor = [PermissionRole.EDITOR].includes(role) && (isRestricted || !parentId);
  const [check, setCheck] = useState(!!defaultPassword);
  const [password, setPassword] = useState(defaultPassword || '');
  const [error, setError] = useState('');

  const randomPassword = () => {
    if (!isEditor) return;
    const code = createCode();
    setPassword(code);
  };

  const save = async () => {
    if (!isEditor) return;

    // 修改成功提示
    // 勾选并设置了密码
    if (check && password) {
      // 并且修改了密码
      if (defaultPassword !== password) {
        message.success('设置成功');
      }
    }
    // 取消了密码，并且之前是勾选的状态
    if (defaultPassword && !check) {
      message.success('取消成功');
    }
    // ----------------------------------------------------------------

    // 埋点
    if (check) {
      // 勾选了，但密码被修改
      if (defaultPassword !== password) {
        bizTracker.event('share_pw_open');
      }
      // 取消勾选，并且之前有密码。说明被修改
    } else if (defaultPassword) {
      bizTracker.event('share_pw_cancel');
    }
    // ----------------------------------------------------------------
    transaction(() => {
      // dispatch
      if (check) {
        void set().setPartialPermission({
          type: PermissionType.PUBLIC,
          allowSeo: false,
          password,
        });
      } else if (shared) {
        void set().setPartialPermission({
          type: PermissionType.PUBLIC,
          allowSeo: false,
          password: undefined,
        });
      }
    });
    // ----------------------------------------------------------------

    // 自动复制链接
    if (shared && password && check) {
      await writeTextInClipboard([`${url}${text}\n`, `访问密码${password}`].join(''), {
        message: '已复制链接和密码',
      });
      bizTracker.event('share_copylink', { is_share: shared });
    }
    // ----------------------------------------------------------------
    dispatch(TRANSACTION_FIRE());
    backToMainPanel();
  };

  const openPassword = (status: boolean) => {
    setCheck(status);
    if (!password && status) {
      randomPassword();
    }
  };

  useEffect(() => {
    if (!/^(([A-z]|[0-9]){4})+$/.test(password) && password) {
      setError('仅支持设置4位英文、数字密码');
    } else {
      if (password) {
        setCheck(true);
      }
      setError('');
    }
  }, [password]);

  return (
    <div className="select-none">
      <SharePanelHeader title="设置分享访问密码" />
      <div className={cx(!isEditor && 'pointer-events-none opacity-30')}>
        <div className="px-4 py-2.5">
          <div className="w-full rounded border border-grey6">
            <Input
              readonly={!isEditor}
              value={password}
              onChange={setPassword}
              maxLength={4}
              className="h-8"
              showBorder={false}
              placeholder="密码"
              addonAfter={
                <div
                  className="h-full items-center flex justify-center bg-white1 px-3 border-l border-grey6 animate-hover rounded-l-none text-t2-medium"
                  onClick={randomPassword}
                >
                  <Icon name="IcRefresh" size="middle" className="mr-2" />
                  随机生成
                </div>
              }
            />
          </div>
          {error && (
            <div className="text-t4 text-red mt-2.5 pointer-events-none px-1">
              <IconWidget inline emojiStr="⚠️" /> 仅支持设置4位英文、数字密码
            </div>
          )}
        </div>
        <div className="px-4 border-t border-grey6 mt-2.5 h-14 flex items-center text-t3 justify-between">
          <div className="flex items-center cursor-pointer" onClick={() => openPassword(!check)}>
            <Checkbox checked={check} size="large" />
            <span className="ml-0.5">启用密码</span>
          </div>
          <Button colorType="active" onClick={save} disable={!!error}>
            确定
          </Button>
        </div>
      </div>
    </div>
  );
};
