import { getFormatImageUrl } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useMemo, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import { getPreviewType, PreviewElement } from 'src/components/file-preview/renderElements';
import { PagePreviewWrapper } from 'src/editor/component/file/page-preview-wrapper';
import { FloatToolbar } from 'src/editor/component/float-toolbar';
import { segmentsToText } from 'src/editor/utils/editor';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useChangeViewMode } from 'src/hooks/block/use-update-view-mode';
import { useReadonly } from 'src/hooks/page';
import { useResource } from 'src/hooks/public/use-resource';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { UploadStatus, ViewModel } from 'src/redux/types';
import { useColumnResizing } from 'src/services/app';
import { useDisableInteractive } from 'src/services/app/hook/use-drop-info';
import { useUploadInfoById } from 'src/services/upload';
import { isInColumnList } from 'src/utils/block-utils';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import type { Instance } from 'tippy.js';
import { File } from '../../component/file';
import { ViewModelCardView, ViewModelNormal, ViewModelPreView } from '../../component/view-mode';
import { getReadableFileSizeString } from '../../utils/size-utils';
import type { BlockElement } from '../core/type';
import { AudioBlockElement } from './audio';
import { ImageBlockElement } from './image';
import { VideoBlockElement } from './video';

// #region start
export const FileBlockElement: BlockElement = memo((props) => {
  const { id: uuid, root, ownerBlockId } = props;
  const fileBlock = usePickBlock(uuid, ['data', 'local'], ['display']);
  const ownerBlock = usePickBlock(ownerBlockId, ['data', 'local'], ['display']);
  const block = ownerBlock || fileBlock;

  // 被风控了
  const { illegal } = usePermissions(block?.uuid);
  useDoneLocalStatus({ uuid });

  if (block?.data.display && !illegal) {
    let Element: BlockElement = () => null;
    switch (block.data.display) {
      case 'image': {
        Element = ImageBlockElement;
        break;
      }
      case 'video': {
        Element = VideoBlockElement;
        break;
      }
      case 'audio': {
        Element = AudioBlockElement;
        break;
      }
      case 'file': {
        return (
          <>
            <ViewNormal {...props} />
            {props.children}
          </>
        );
      }
      default:
    }
    return (
      <>
        <Element id={uuid} root={root} />
        {props.children}
      </>
    );
  } else if (illegal) {
    return (
      <>
        <ViewModelNormal
          {...{
            uuid,
            root,
            ownerBlockId,
            isFile: true,
            title: ILLEGAL_TEXT,
            iconName: getFileIcon(''),
          }}
        />
        {props.children}
      </>
    );
  }

  return (
    <>
      <ViewNormal {...props} />
      {props.children}
    </>
  );
});
// #endregion

// #region file start
export const ViewNormal: BlockElement = ({ id: uuid, root, ownerBlockId }) => {
  const fileBlock = usePickBlock(
    uuid,
    ['data', 'local'],
    ['segments', 'viewMode', 'ossName', 'size', 'localUrl', 'showEmptyCaption', 'link']
  );
  const ownerBlock = usePickBlock(ownerBlockId, ['data'], ['viewMode']);
  const uploadInfo = useUploadInfoById(uuid);
  const readonly = useReadonly(ownerBlockId ?? uuid);
  const fileName = segmentsToText(fileBlock?.data.segments);
  const changeViewModel = useChangeViewMode(ownerBlockId || uuid);
  const disableInteractive = useDisableInteractive();
  // showNotFound情况 暂时应该不会出现
  const showNotFound = Boolean(!fileBlock);
  const uploading = uploadInfo?.status === UploadStatus.uploading;
  const viewMode = (ownerBlock || fileBlock)?.data.viewMode;
  let element: React.ReactNode = <></>;
  let showType: 'default' | 'small' | 'large' | undefined = 'default';

  /** 判断解释
   * 1.没有ossName
   * 2.有link字段(外链)
   * 3.文件失踪或上传失败
   * 4.视图为普通视图或没有设置视图
   */
  if (
    !fileBlock ||
    (!fileBlock.data.ossName && !fileBlock.data.link) ||
    uploading ||
    viewMode === ViewModel.normal ||
    !viewMode
  ) {
    element = (
      <File
        root={root}
        ownerBlockId={ownerBlockId}
        readonly={readonly}
        progress={uploadInfo?.progress ?? -1}
        fileName={fileName}
        size={fileBlock?.data.size}
        showNotFound={showNotFound}
        defaultOpenUploadPanel={Boolean(fileBlock?.local)}
        uuid={uuid}
      />
    );
    if (!fileBlock?.data.ossName && !fileBlock?.data.link) {
      showType = undefined;
    }
  } else if (viewMode === ViewModel.card) {
    element = (
      <ViewCard
        {...{
          root,
          uuid,
          onReplace: () => {},
          ownerBlockId,
          isFile: true,
          showNotFound,
          readonly,
          title: fileName || getUntitledName(BlockType.FILE),
          context: getReadableFileSizeString(fileBlock?.data.size),
          iconName: getFileIcon(getFileNameInfo(fileName).extName),
        }}
      />
    );
    showType = 'small';
  } else if (viewMode === ViewModel.preview) {
    element = (
      <PreView
        {...{
          root,
          uuid,
          onReplace: () => {},
          ownerBlockId,
          isFile: true,
          showNotFound,
          readonly,
          title: fileName || getUntitledName(BlockType.FILE),
          context: getReadableFileSizeString(fileBlock?.data.size),
          iconName: getFileIcon(getFileNameInfo(fileName).extName),
        }}
      />
    );
    showType = 'large';
  }

  return (
    <Tooltip
      theme="next-modal"
      placement="top-end"
      interactive={!disableInteractive}
      interactiveDebounce={0}
      animation={'shift-away'}
      className="my-1"
      popup={
        showType && !readonly && !judgeSharePage() ? (
          <FloatToolbar
            showType={showType}
            onClick={(showType) => {
              switch (showType) {
                case 'default':
                  changeViewModel('normal');
                  break;
                case 'small':
                  changeViewModel('card');
                  break;
                case 'large':
                  changeViewModel('preview');
                  break;
                default:
              }
            }}
          />
        ) : undefined
      }
    >
      {element}
    </Tooltip>
  );
};
// #endregion

// #region view card
interface ViewProps {
  root?: boolean;
  showNotFound?: boolean;
  readonly: boolean;
  uuid: string;
  ownerBlockId?: string;
  title?: string;
}
const ViewCard: FC<ViewProps> = (props) => {
  const { root, uuid, ownerBlockId, showNotFound, readonly } = props;
  const fileBlock = usePickBlock(uuid, ['data'], ['segments', 'size']);
  const fileName = segmentsToText(fileBlock?.data.segments);
  const tippyInstance = useRef<Instance>();
  const pageId = useGetPageId();
  const inColumn = useMemo(() => isInColumnList(uuid, pageId), [pageId, uuid]);

  if (showNotFound) {
    return (
      <ViewModelCardView
        {...{
          root,
          uuid,
          onReplace: () => {},
          ownerBlockId,
          isFile: true,
          title: '文件已被删除',
          context: '0 KB',
          iconName: getFileIcon(),
          readonly,
        }}
      />
    );
  }

  return (
    <Tooltip
      lazyLoad={true}
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      theme="none"
      popupClass="next-modal"
      interactive={true}
      className="w-full"
      offset={[0, 8]}
      placement={inColumn ? 'left' : 'top-start'}
      maxWidth={10000}
      delay={[500, 50]}
      animation={'shift-away'}
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: inColumn
                ? ['left', 'right', 'bottom', 'bottom-start', 'top-start']
                : ['top-start', 'bottom-start', 'left', 'right'],
            },
          },
        ],
      }}
      popup={
        <PagePreviewWrapper
          ownerBlockId={ownerBlockId}
          uuid={uuid}
          onCloseOutsideModel={() => {
            tippyInstance.current?.hide();
          }}
        />
      }
    >
      <ViewModelCardView
        {...{
          root,
          uuid,
          onReplace: () => {},
          ownerBlockId,
          isFile: true,
          readonly,
          title: fileName || getUntitledName(BlockType.FILE),
          context: getReadableFileSizeString(fileBlock?.data.size),
          iconName: getFileIcon(getFileNameInfo(fileName).extName),
        }}
      />
    </Tooltip>
  );
};
// #endregion

// #region preview
const PreView: FC<ViewProps> = (props) => {
  const { uuid, ownerBlockId, root, readonly, title } = props;
  const fileBlock = usePickBlock(uuid, ['data'], ['size']);
  const columnResizing = useColumnResizing();
  const { extName } = getFileNameInfo(title);
  const previewType = getPreviewType(extName);
  const { url, loading } = useResource(uuid);
  const isFreeSpaceLimit = useIsFreeSpaceLimit(fileBlock?.spaceId);
  const openUpgradeSpace = useOpenUpgradeSpace();
  const isEpubLimit = isFreeSpaceLimit && previewType === 'epub';
  const previewUrl = useMemo(() => {
    return getFormatImageUrl(url, extName);
  }, [extName, url]);

  if (loading) {
    return <LoadingContainer />;
  }
  return (
    <ViewModelPreView
      {...{
        uuid,
        root,
        ownerBlockId,
        isFile: true,
        smallHeder: true,
        readonly,
        className: 'group',
        title: !url ? '文件已被删除' : title || getUntitledName(BlockType.FILE),
        context: !url ? '0 KB' : getReadableFileSizeString(fileBlock?.data.size),
        iconName: getFileIcon(extName),
      }}
    >
      {url && previewType && !isEpubLimit ? (
        <div
          className={cx('flex justify-center h-full max-h-full w-full', {
            'pointer-events-none': columnResizing,
          })}
        >
          <PreviewElement
            uuid={uuid}
            downloadUrl={previewUrl}
            useSimpleImg
            embed
            style={{ width: '100%' }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full bg-grey8 text-grey4 text-t2">
          <Icon name="IcUploadImageFaild" size="large" className="mb-2" />
          <div>
            {!url
              ? '文件被删除'
              : isEpubLimit && !judgeSharePage()
              ? '仅专业版/团队版支持预览'
              : '文件不支持预览'}
          </div>
          {isEpubLimit && !judgeSharePage() && (
            <Button
              className="mt-1 text-t2"
              onClick={() => {
                openUpgradeSpace(OpenSettingFrom.view_epub);
              }}
            >
              升级空间
            </Button>
          )}
        </div>
      )}
    </ViewModelPreView>
  );
};
// #endregion
