import { memo } from 'react';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';
import { BlockRenderer } from 'src/editor/editor/uikit/block';

export const SyncNode: MindNodeElement = memo((props) => {
  return (
    <NodeWrapper id={props.id} level={props.level}>
      <BlockRenderer id={props.id} />
      {/* <MindMapRichText
        uuid={props.id}
        className="whitespace-pre-wrap break-words"
        segments={blockNameSegments}
        readonly={true}
      /> */}
    </NodeWrapper>
  );
});
