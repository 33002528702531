import type { FC } from 'react';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import type { FormulaTool } from 'src/hooks/block/use-formula-tool';

interface Props {
  formulaTool: FormulaTool;
  example: string;
  propId: string;
  recordId: string;
}
export const EvalValue: FC<Props> = (props) => {
  const { formulaTool } = props;
  const formulaPreview = formulaTool.validate(props.example, props.recordId, props.propId);
  if (formulaPreview.type === 'error') {
    return null;
  }
  const node =
    typeof formulaPreview.preview === 'string' ? (
      <div>{formulaPreview.preview}</div>
    ) : (
      <div className="rounded flex items-center px-2.5 py-1.5">
        {`${props.example} ==`}
        &nbsp;
        <RichText
          className={'inline-block leading-[20px]'}
          segments={formulaPreview.preview}
          interactable={true}
        />
      </div>
    );
  return <div className="bg-grey6">{node}</div>;
};
