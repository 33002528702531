import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useGetAncestors } from 'src/hooks/block/use-get-ancestors';
import { cleanSelectBlock } from 'src/hooks/utils/clean-select';
import { patchExpand, useExpand } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { $searchParams } from 'src/utils';
import { usePickBlock } from 'src/utils/pick-block';

const menuStyle =
  'w-5 h-6 flex items-center justify-center animate-hover rounded text-grey1 animate-hover';

export const FoldMenu: FC<{ id: string }> = memo(({ id }) => {
  const { expand, toggle } = useFoldMenuState(id, { defaultOpen: true });
  const block = usePickBlock(id, ['subNodes']);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const con = (hoverId?: string) => {
      setIsHover(hoverId === id);
    };
    emitter.on('hoverId', con);
    return () => {
      emitter.off('hoverId', con);
    };
  }, [id]);

  if (!block?.subNodes.length) return null;
  const isExpand = $searchParams.print || expand;

  return (
    <Tooltip
      popup={<span>{isExpand ? '展开' : '收起'}</span>}
      className={cx('absolute -left-5 block-before-icon-top', menuStyle)}
      placement="bottom"
      data-no-cancel-selected
    >
      <Icon
        size="large"
        name="IcToggleBlack"
        onClick={toggle}
        className={cx('transition duration-150 cursor-pointer', {
          'rotate-90 opacity-0': isExpand,
          'opacity-30': isHover,
          'opacity-100': !isExpand,
        })}
      />
    </Tooltip>
  );
});

interface OptionProps {
  defaultOpen?: boolean;
}
export const useFoldMenuState = (uuid: string, option?: OptionProps) => {
  const { defaultOpen = false } = option || {};
  const [expand, setIsExpand] = useExpand(uuid, { defaultOpen });
  const getAncestors = useGetAncestors();
  const getIsSelected = (id: string) => {
    const ancestorBlockIds = getAncestors(id);
    return cache.ui.selectedBlocks.some((i) => ancestorBlockIds.has(i.blockId) || i.blockId === id);
  };

  const toggle = () => {
    setIsExpand(!expand);
    const isSelected = getIsSelected(uuid);
    if (!isSelected) {
      cleanSelectBlock();
      return;
    }
    const current = cache.blocks[uuid];
    if (!current) return;
    const parentBlock = cache.blocks[current.parentId];
    if (!parentBlock) return;
    const uuids: string[] = [];

    parentBlock.subNodes.forEach((i) => {
      const selectedBlock = getIsSelected(i);
      if (selectedBlock) {
        uuids.push(i);
      }
    });
    patchExpand(uuids, !expand);
  };

  return {
    expand,
    toggle,
  };
};
