/** 右键菜单列表 */
import { emitter, Role } from '@flowus/common';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import {
  BlockType,
  CardColorSource,
  CollectionSchemaType,
  CollectionViewType,
  PermissionRole,
  TextType,
} from '@next-space/fe-api-idl';
import { type FC } from 'react';
import { ColorSelector } from 'src/bitable/table-view/cell/select/color-selector';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import type { ListItem } from 'src/common/components/list-view/types';
import { request } from 'src/common/request';
import { useOpenMoveTo } from 'src/components/move-to';
import { useOpenRename } from 'src/components/rename';
import { useOpenDeleteSyncBlockDialog, useOpenUnSyncDialog } from 'src/components/unsync';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { AlignPanel } from 'src/editor/component/align-panel';
import { ColorPickerModule } from 'src/editor/component/color-picker-module';
import { useSelectFile } from 'src/editor/component/file/empty-file';
import { useSelectFolder } from 'src/editor/component/folder/empty-folder';
import type { BlockMenuProps } from 'src/editor/component/menu-list';
import { ColumnPanel } from 'src/editor/component/menu-list/column-panel';
import { TurnBlockList, TurnWhiteList } from 'src/editor/component/menu-list/const';
import { FormatList } from 'src/editor/component/menu-list/format-list';
import { covertBlockToTitle } from 'src/editor/component/menu-list/menu-item';
import { TransformBlockList } from 'src/editor/component/menu-list/transform-block-list';
import { MenuCategory, MenuListActionType } from 'src/editor/component/menu-list/types';
import { PageFormatPanel } from 'src/editor/component/page-format-panel';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useCopyBlockRecord } from 'src/hooks/block/use-copy-block-record';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useShowImageBorder } from 'src/hooks/block/use-show-image-border';
import { useTableConvert } from 'src/hooks/block/use-table-convert';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import type { FocusEditableFun } from 'src/hooks/editor/use-focus-by-id';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { getPermissions, usePermissions } from 'src/hooks/share/use-permissions';
import { useAsyncTransaction, useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { convertBlock } from 'src/redux/managers/block/convert';
import { updateBlock } from 'src/redux/managers/block/update';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock, NextCollectionView } from 'src/redux/types';
import { bizTracker } from 'src/utils/biz-tracker';
import {
  isChartBlock,
  isCollection,
  isLinkPageBlock,
  isLinkTableBlock,
  isMindMap,
  isPageLike,
  supportCaption,
} from 'src/utils/block-type-utils';
import {
  getAlignBlockIds,
  getRefBlock,
  getSyncBlockBackLinkInfo,
  isSyncBlock,
  supportComments,
  supportWordWrap,
} from 'src/utils/block-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import {
  convertSyncReferenceBlockToNormalBlock,
  covertSyncBlockToNormalBlock,
} from 'src/utils/convert-sync-block-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { getDynamicPageId, judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { fixRecognizeText, recognizeImageToText } from 'src/utils/image-util';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { idsToSelectedBlocks, selectedBlocksToIds } from 'src/utils/select-block-util';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import { InlinePage } from '../../../inline/inline-page';
import { AIEditorFrom, AIEditorScene } from '../../ai-editor/const';
import { CheckMatchAIBlockTypes } from '../../ai-editor/get-block-ids-content';
import { useOpenAIEditor } from '../../ai-editor/use-open-ai-editor';
import { useShortcutKeyInsertBlock } from '../hook';
import { PropertyList } from '../property-list';
import { useOpenImageLinkDialog } from './use-open-image-link-dialog';
import { useUpdateChartFormat } from 'src/hooks/chart';
import { Modals } from '@flowus/shared';
import { ChartConfigPanel } from 'src/editor/component/chart/chart-config-panel';

interface BlockMenuListProps extends BlockMenuProps {
  editorKey: string;
  syncId?: string;
  viewId?: string;
  focusEditableAt: FocusEditableFun;
}
const removeDupLine = (items: ListItem[]) => {
  let preLineIndex = -1;
  let visibleIndex = -1;
  for (const item of items) {
    if (!item.isHidden) {
      visibleIndex++;
    }
    if (
      preLineIndex !== -1 &&
      item?.type === ListItemType.LINE &&
      preLineIndex + 1 === visibleIndex
    ) {
      item.isHidden = true;
    }

    if (!item.isHidden && item.type === ListItemType.LINE) {
      preLineIndex = visibleIndex;
    }
  }
};

export const BlockMenuList: FC<BlockMenuListProps> = (props) => {
  const { onCloseModal, blockId, popcorn, focusEditableAt, viewId, syncId } = props;
  const block = usePickBlock(blockId, ['views', 'updatedBy', 'updatedAt', 'data']);
  const pageId = useGetPageId();
  const refBlockId = block?.data.ref?.uuid ?? blockId;
  const isFileBlock = block?.type === BlockType.FILE;
  const userName = useUserName(block?.updatedBy ?? '');
  const transaction = useTransaction();
  const copyBlockRecord = useCopyBlockRecord();
  const multipleDownload = useMultipleDownload();
  const { enableAI } = useEnableAI();
  const openRename = useOpenRename();
  const selectFile = useSelectFile({ uuid: blockId });
  const selectFolder = useSelectFolder({ uuid: blockId });
  const { selectedBlockHistory, selectedBlock } = useSelectedBlockHistory();
  const openMoveTo = useOpenMoveTo();
  const isSharePage = judgeSharePage();
  const asyncTransaction = useAsyncTransaction();
  const openUnSyncDialog = useOpenUnSyncDialog();
  const tableConvert = useTableConvert();
  const openNewDiscussionPopup = useOpenNewDiscussionPopup();
  const shortcutKeyInsetBlock = useShortcutKeyInsertBlock();
  const openDeleteSyncBlockDialog = useOpenDeleteSyncBlockDialog();
  const openPage = useOpenPage();
  const openAIEditor = useOpenAIEditor();
  const updateTask = useUpdateTask();
  const openModal = useOpenModal();
  const showImageBorder = useShowImageBorder(blockId);
  const openImageLinkDialog = useOpenImageLinkDialog();
  const updateChartFormat = useUpdateChartFormat();

  // 被风控了
  const { illegal, role } = usePermissions(blockId);

  if (!block) return null;

  const onReplace = () => {
    const rect = popcorn.getBoundingClientRect();
    if (isFileBlock) {
      selectFile(popcorn, 'bottom', [0, -rect.height / 2]);
    } else {
      selectFolder(popcorn, 'bottom', [0, -rect.height / 2]);
    }
  };

  const { blocks, collectionViews } = cache;
  const allSelectedBlockTypeIsOk = (types: BlockType[]) => {
    return selectedBlock.every((selectBlock) => {
      const _block = blocks[selectBlock.blockId];
      if (!_block) return false;

      if (isCollection(blocks[_block.parentId]?.type)) {
        return false;
      }

      return types.includes(_block.type);
    });
  };
  const alignBlockIds = getAlignBlockIds(selectedBlock);

  const alignBlockCaptionIds = selectedBlock
    .filter((s) => {
      const sb = cache.blocks[s.blockId];
      return supportCaption(sb);
    })
    .map((v) => v.blockId);

  const writerOrEditorRole = selectedBlock.every((selectBlock) => {
    const { role, isRestricted } = getPermissions(selectBlock.blockId);
    // 转化为 如果是受限的块需要先恢复权限，所以必须是全部权限的才可以
    return isRestricted
      ? role === PermissionRole.EDITOR
      : role === PermissionRole.EDITOR || role === PermissionRole.WRITER;
  });

  const allowTableConvert = () => {
    if (block.type === BlockType.TABLE) return true;
    if (block.type === BlockType.COLLECTION_VIEW) {
      const { collectionViews } = cache;
      const validViewIds = block.views?.filter((viewId) => !!collectionViews[viewId]);
      if (!validViewIds || validViewIds.length > 1) return false;
      if (collectionViews[validViewIds[0] ?? '']?.type !== CollectionViewType.TABLE) return false;

      const tableSchema = block.data.schema;
      if (!tableSchema) return false;
      const excludePropertyType = new Set([
        CollectionSchemaType.RELATION,
        CollectionSchemaType.ROLLUP,
        CollectionSchemaType.FORMULA,
        CollectionSchemaType.CREATED_AT,
        CollectionSchemaType.UPDATED_AT,
        CollectionSchemaType.CREATED_BY,
        CollectionSchemaType.UPDATED_BY,
        CollectionSchemaType.FILE,
      ]);
      const schema = Object.values(tableSchema).find(({ type }) => excludePropertyType.has(type));
      return !schema;
    }
  };

  const _isSyncBlock = isSyncBlock(block.type);
  const isRecord = isCollection(blocks[block.parentId]?.type);
  let hiddenRecordColor = true;
  if (isRecord) {
    const view = collectionViews[viewId ?? ''];
    if (
      view &&
      ((view.type === CollectionViewType.TIMELINE &&
        view.format.timelineColorSetting?.type === CardColorSource.CUSTOM) ||
        (view.type === CollectionViewType.CALENDAR &&
          view.format.calendarColorSetting?.type === CardColorSource.CUSTOM))
    ) {
      hiddenRecordColor = false;
    }
  }
  const isImage = isFileBlock && block.data.display === 'image';

  const hasSegmentType = (types: TextType[]) => {
    const segTypes = block.data.segments?.map((seg) => seg.type) ?? [];
    return types.some((t) => segTypes.includes(t));
  };
  let hidePageFormat = true;
  if (cache.ui.selectedBlocks.length === 1 && isPageLike(block.type)) {
    if (
      block.data.format?.cardFormat?.showType &&
      block.data.format?.cardFormat?.showType !== 'default'
    ) {
      hidePageFormat = false;
    }
  }
  /** 需要被隐藏的菜单选项 */
  const hiddenList = {
    ai:
      !CheckMatchAIBlockTypes.has(block.type) || !enableAI || hasSegmentType([TextType.LINK_PAGE]),
    isRefBlock:
      block.type === BlockType.REFERENCE &&
      (block.data.display === 'audio' ||
        block.data.display === 'video' ||
        block.data.display === 'image'),
    copyCutDelete: false,
    tableConvert: !allowTableConvert(),
    /** 转换为 */
    turn: !allSelectedBlockTypeIsOk(TurnBlockList) || !writerOrEditorRole || illegal,
    insert:
      !writerOrEditorRole ||
      Boolean(viewId && cache.collectionViews[viewId]?.type === CollectionViewType.CALENDAR),
    turnInner:
      cache.ui.selectedBlocks.length > 1 ||
      (block.type !== BlockType.COLLECTION_VIEW_PAGE &&
        block.type !== BlockType.REFERENCE_COLLECTION_PAGE &&
        block.type !== BlockType.MIND_MAPPING_PAGE),
    turnToPage: ![
      BlockType.COLLECTION_VIEW,
      BlockType.REFERENCE_COLLECTION,
      BlockType.MIND_MAPPING,
    ].includes(block.type),
    /** 颜色 */
    color:
      !allSelectedBlockTypeIsOk([
        ...TurnWhiteList,
        BlockType.COLLECTION_VIEW_PAGE,
        BlockType.BOOKMARK,
      ]) ||
      illegal ||
      isMindMap(block.type) ||
      [BlockType.CODE].includes(block.type),
    /** 下载，只能下载文件夹&图片&外链图片 */
    down:
      !(
        // 如果是文件，只能是图片块
        (
          ([BlockType.FILE, BlockType.EXTERNAL_FILE].includes(block.type) &&
            (block.data.ossName || block.data.link)) ||
          // 如果是文件夹，内容需要>0
          block.type === BlockType.FOLDER
        )
      ) && !illegal,
    replace: ![BlockType.FILE].includes(block.type) || !block.data.ossName || illegal,
    rename:
      ![BlockType.FILE, BlockType.FOLDER].includes(block.type) ||
      (BlockType.FILE === block.type && !block.data.ossName) ||
      illegal,
    copy: illegal || !Role.contains(getPermissions(block.parentId).role, PermissionRole.WRITER),
    // formatList: !isFirstListLike(),
    formatList: block.type !== BlockType.LIST && block.type !== BlockType.ORDER_LIST,
    move: !writerOrEditorRole,
    wordWrap:
      !supportWordWrap(block) ||
      ![PermissionRole.WRITER, PermissionRole.EDITOR].includes(getPermissions(block.parentId).role),
    caption: !supportCaption(block),
    recognizeImage: !(cache.ui.selectedBlocks.length === 1 && isImage && block.data.ossName),
    updatedBy: ![PermissionRole.WRITER, PermissionRole.EDITOR].includes(role) && !isSharePage,
    template: BlockType.TEMPLATE !== block.type,
    unSync:
      cache.ui.selectedBlocks.length > 1 ||
      !_isSyncBlock ||
      !Role.contains(getPermissions(block.uuid).role, PermissionRole.WRITER),
    editProperty: !isRecord,
    recordColor: hiddenRecordColor,
    comment:
      cache.ui.selectedBlocks.length > 1 ||
      !supportComments(block) ||
      isRecord ||
      !Role.contains(getPermissions(block.uuid).role, PermissionRole.COMMENTER),
    isPageBlock:
      cache.ui.selectedBlocks.length > 1 ||
      isPageLike(block.type) ||
      isLinkPageBlock(block) ||
      isLinkTableBlock(block),
    copyPageLink: !isPageLike(block.type) && !isLinkPageBlock(block) && !isLinkTableBlock(block),
    changeToDoc: !isMindMap(block.type) || cache.ui.selectedBlocks.length > 1, // 导图转换为页面内容
    // feat: https://flowus.cn/80e9ca97-1b7d-41d8-b87e-ed623ec54627
    contentAlignStyle: alignBlockIds.length !== cache.ui.selectedBlocks.length,
    captionAlignStyle: alignBlockCaptionIds.length !== cache.ui.selectedBlocks.length,
    pageFormat: hidePageFormat,
    insertColumn: !writerOrEditorRole || isRecord,
    chart: !(cache.ui.selectedBlocks.length === 1 && isChartBlock(block)),
  };

  if (!Role.contains(role, PermissionRole.WRITER)) {
    Object.keys(hiddenList).forEach((key) => {
      if (key === 'copy' || key === 'comment') return;
      hiddenList[key as keyof typeof hiddenList] = true;
    });
  }
  const items: ListItem[] = [
    {
      type: ListItemType.BLOCK_TRANSFORM,
      isHidden: !hiddenList.isRefBlock,
      disableCustomHoverBg: true,
      render: () => {
        let refPageId = blocks[block.data.ref?.uuid ?? '']?.parentId ?? '';

        let limit = 0; // 避免超出调用栈
        while (
          blocks[refPageId]?.type !== BlockType.PAGE &&
          blocks[refPageId]?.type !== BlockType.FOLDER &&
          blocks[refPageId]?.type !== BlockType.FILE &&
          limit < 100
        ) {
          limit++;
          refPageId = blocks[refPageId]?.parentId ?? '';
        }

        return (
          <div className="rounded w-full pl-2 pr-2">
            <div className="text-grey4 text-t4 ml-1 my-2 pl-1">引用源</div>
            <InlinePage
              uuid={refPageId}
              interactable={true}
              showUnderLine={false}
              className="flex items-center text-black text-t2 break-all line-clamp-1 active-bg animate-hover h-8 pl-2"
            />
          </div>
        );
      },
    },
    {
      isHidden: !hiddenList.isRefBlock,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.ai,
      data: {
        type: MenuListActionType.AI_EDITOR,
        icon: 'IcAiFill',
        iconClassName: 'text-[#A34DD9]',
        title: MenuCategory.aiAssistantWithEmptyDoc,
        rightText: `${ShortcutSystemSymbol}+J`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !hiddenList.isPageBlock || cache.ui.selectedBlocks.length > 1,
      data: {
        type: MenuListActionType.OPEN_TAB,
        icon: 'IcJumpTo',
        title: '在新选项卡中打开',
      },
    },

    {
      type: ListItemType.OPERATION,
      isHidden: !hiddenList.isPageBlock || cache.ui.selectedBlocks.length > 1,
      data: {
        type: MenuListActionType.OPEN_RIGHT_PAGE,
        icon: 'IcOpenInSide',
        title: '在侧边栏中打开',
      },
    },

    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copyCutDelete,
      data: {
        type: MenuListActionType.COPY_CLIPBOARD,
        icon: 'IcCopy',
        title: '复制',
        rightText: `${ShortcutSystemSymbol}+C`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copyCutDelete,
      data: {
        type: MenuListActionType.CUT,
        icon: 'IcCut',
        title: '剪切',
        rightText: `${ShortcutSystemSymbol}+X`,
      },
    },

    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copy,
      data: {
        type: MenuListActionType.COPY,
        icon: 'IcDuplicate',
        title: '拷贝副本',
        rightText: `${ShortcutSystemSymbol}+D`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copy,
      data: {
        type: MenuListActionType.COPY_MOVE,
        icon: 'IcCopyToPage',
        title: '拷贝副本至页面',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.template,
      data: {
        type: MenuListActionType.TEMPLATE,
        icon: 'MIcListSeetings',
        title: '设置模板按钮',
        onClick: () => {
          patchExpand(blockId, true, false);
          dispatch(uiActions.updateSelectBlocks([]));
          focusEditableAt(blockId, segmentsToText(block.data.segments).length);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.turnToPage,
      data: {
        type: MenuListActionType.TURN_TO_PAGE,
        icon: 'IcTurnInto',
        title: '转换为页面',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.changeToDoc,
      data: {
        icon: 'IcTurnInto',
        title: '转换为页面内容',
        onClick: () => {
          transaction(() => {
            const blockType =
              block.data.format?.mindMappingFormat?.oldBlockType ?? BlockType.TEXTAREA;
            convertBlock([block.uuid], {
              type: blockType,
            });
          });
          bizTracker.event('turninto_page_content');
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.tableConvert,
      data: {
        type: MenuListActionType.TABLE_CONVERT,
        icon: 'IcTurnInto',
        title: block.type === BlockType.TABLE ? '转换为多维表' : '转换为简单表格',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.turnInner,
      data: {
        type: MenuListActionType.TURN_TO_EMBED,
        icon: 'IcTurnInto',
        title: '转换为嵌入',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.rename,
      data: {
        type: MenuListActionType.RENAME,
        icon: 'IcEdit02',
        title: '重命名',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.turn,
      data: {
        icon: 'IcTurnInto',
        title: '转换为',
        hasArrow: true,
        renderSubMenu: () => <TransformBlockList {...props} />,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.isPageBlock,
      data: {
        icon: 'IcLink',
        title: '拷贝块访问链接',
        onClick: () => {
          // 同步块内的块链接粘贴后不能转为同步块
          const query = syncId ? '?s=t' : '';
          const pageId = getOwnerPage(block.uuid);
          void writeTextInClipboard(`${getLocationOrigin()}/${pageId}${query}#${block.uuid}`);
        },
        rightText: `F`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copyPageLink || cache.ui.selectedBlocks.length > 1,
      data: {
        icon: 'IcLink',
        title: '拷贝页面访问链接',
        onClick: () => {
          const isLinkPage = isLinkPageBlock(block);
          const reallyBlockId = isLinkPage ? getRefBlock(blockId)?.uuid : blockId;
          bizTracker.event('copy_page_link');
          void writeTextInClipboard(`${getLocationOrigin()}/${reallyBlockId}`);
        },
        rightText: `F`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.copyCutDelete,
      data: {
        type: MenuListActionType.DELETE,
        icon: 'IcTrash',
        title: '删除',
        rightText: 'Del',
      },
    },
    {
      isHidden: hiddenList.move,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.down,
      data: {
        icon: 'IcDownload',
        title: '下载',
        onClick: () => multipleDownload(selectedBlocksToIds(cache.ui.selectedBlocks)),
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.replace,
      data: {
        icon: 'IcTurnInto',
        title: '替换',
        onClick: onReplace,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.unSync,
      data: {
        icon: 'IcMenuUnsync',
        title: block.type === BlockType.SYNC_REFERENCE ? '取消同步' : '取消全部同步',
        onClick: () => {
          const info = getSyncBlockBackLinkInfo(idsToSelectedBlocks(blockId), true);
          const unSync = () => {
            asyncTransaction(async () => {
              if (block.type === BlockType.SYNC_CONTAINER) {
                covertSyncBlockToNormalBlock(blockId);
              } else {
                // eslint-disable-next-line @typescript-eslint/await-thenable
                await convertSyncReferenceBlockToNormalBlock(blockId, copyBlockRecord);
              }
            });
          };
          if (info) {
            openUnSyncDialog(blockId, unSync);
          } else {
            unSync();
          }
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.editProperty,
      data: {
        icon: 'IcList',
        title: '编辑属性',
        hasArrow: true,
        renderSubMenu: () => {
          return (
            <PropertyList
              popcorn={popcorn}
              blockId={block.uuid}
              collectionId={block.parentId}
              selectedBlocks={cache.ui.selectedBlocks}
            />
          );
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.recordColor,
      data: {
        icon: 'IcColor',
        title: '颜色',
        hasArrow: true,
        renderSubMenu: () => {
          return (
            <div className="next-modal py-1.5">
              <ColorSelector
                value={block.data.collectionCardColor}
                onSelect={(color: string) => {
                  if (!viewId) return;

                  transaction(() => {
                    updateBlock(block.uuid, {
                      data: { collectionCardColor: color },
                    });
                  });
                }}
              />
            </div>
          );
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.move,
      data: {
        icon: 'IcMove',
        title: '移动到',
        rightText: `${ShortcutSystemSymbol}+Shift+P`,
        onClick: () => {
          bizTracker.event('moveto_blockmenu_click', { block_name: covertBlockToTitle(blockId) });
          openMoveTo({ popcorn, uuid: blockId, placement: 'left', offset: [0, 20] });
        },
      },
    },
    {
      isHidden: hiddenList.color,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.color,
      data: {
        icon: 'IcColor',
        title: '颜色',
        hasArrow: true,
        renderSubMenu: () => <ColorPickerModule blockId={blockId} onCloseModal={onCloseModal} />,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.pageFormat,
      data: {
        icon: 'IcMenuPageStyle',
        title: '样式设置',
        hasArrow: true,
        renderSubMenu: () => {
          return <PageFormatPanel blockId={block.uuid} onClose={onCloseModal} />;
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.contentAlignStyle,
      data: {
        icon: 'IcAlignLeft',
        title: '对齐方式',
        hasArrow: true,
        renderSubMenu: () => {
          return <AlignPanel blockIds={alignBlockIds} closeModal={onCloseModal} />;
        },
      },
    },

    {
      isHidden: hiddenList.color || hiddenList.contentAlignStyle,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.recognizeImage || __BUILD_IN__,
      data: {
        icon: 'IcShow',
        title: '识别文字',
        onClick: async () => {
          message.loading({ key: 'recognizeImage', content: '识别中' });
          const imgEl = querySelectorFromMainContent(`[data-image-id="${blockId}"]`);
          if (imgEl instanceof HTMLImageElement) {
            let text = await recognizeImageToText(imgEl);
            message.closeMessage('recognizeImage');

            text = fixRecognizeText(text);
            if (!text.trim()) {
              message.warning('当前图片无法识别出文字');
              return;
            }
            openModal.warning({
              content: text,
              minWidth: 300,
              colorType: 'secondary',
              confirmText: '复制文本',
              cancelText: '插入到图片下方',
              cancelColorType: 'active',
              cancel: () => {
                transaction(() => {
                  updateBlock(blockId, {
                    data: {
                      caption: textToSegments(text),
                    },
                  });
                });
              },
              confirm() {
                void writeTextInClipboard(text);
              },
            });
          }
        },
      },
    },
    {
      type: ListItemType.TEXT_SWITCH,
      isHidden: hiddenList.recognizeImage,
      data: {
        switch: showImageBorder,
        icon: 'IcImage',
        title: '图片边框',
        iconSize: 'small',
        onSwitch: (status: boolean) => {
          transaction(() => {
            updateBlock(blockId, {
              data: {
                format: {
                  addImageBorder: status,
                },
              },
            });
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.recognizeImage,
      data: {
        icon: 'IcLink',
        title: '添加超链接',
        onClick: () => {
          openImageLinkDialog(blockId, popcorn, true);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.caption,
      data: {
        icon: 'IcAddDescription',
        title: '添加说明',
        onClick: () => {
          dispatch(uiActions.updateSelectBlocks([]));
          updateBlock(blockId, {
            data: {
              showEmptyCaption: true,
              format: {
                isShowCaption: true,
              },
            },
          });

          // 积分任务
          if (block.type === BlockType.FILE && block.data.display === 'image') {
            void updateTask(ActivityIds.CREATE_IMAGE_BLOCK, ActivitiesListType.basicList, {
              step2: true,
            });
          }
          focusEditableAt(blockId, 0, 'caption');
          bizTracker.event('block_caption', {
            block_name: covertBlockToTitle(blockId),
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.captionAlignStyle,
      data: {
        icon: 'IcDescriptionSetting',
        title: '说明设置',
        hasArrow: true,
        renderSubMenu: () => {
          return (
            <AlignPanel
              blockIds={alignBlockCaptionIds}
              isCaption={true}
              closeModal={onCloseModal}
            />
          );
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.comment,
      data: {
        icon: 'IcComment',
        title: '添加评论',
        rightText: `${ShortcutSystemSymbol}+Shift+M`,
        onClick: () => {
          openNewDiscussionPopup({ blockId });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.chart,
      data: {
        icon: 'IcSettings',
        title: '图表配置',
        onClick: () => {
          openModal.modal({
            autoClose: false,
            modalId: Modals.CHART_PANEL_ID,
            className: 'rounded-md',
            content: () => {
              return <ChartConfigPanel id={props.blockId} />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.TEXT_SWITCH,
      isHidden: hiddenList.chart,
      data: {
        icon: 'IcFileCheckSmall',
        iconSize: 'small',
        title: '图表边框',
        switch: block.data.format?.chartFormat?.border ?? true,
        onSwitch: (status: boolean) => {
          transaction(() => {
            updateChartFormat(block.uuid, {
              border: status,
            });
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.chart,
      data: {
        icon: 'IcDownload',
        iconSize: 'small',
        title: '下载图片',
        onClick: () => {
          emitter.emit('chart2png');
        },
      },
    },

    {
      type: ListItemType.TEXT_SWITCH,
      isHidden: hiddenList.wordWrap,
      data: {
        title: '自动换行',
        switch: block.data.format?.codeWrap ?? false,
        onSwitch: () => {
          transaction(() => {
            updateBlock(blockId, {
              data: {
                format: { codeWrap: !(block.data.format?.codeWrap ?? false) },
              },
            });
          });
        },
      },
    },

    {
      isHidden: hiddenList.formatList,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.formatList,
      data: {
        icon: 'IcMenuListFormat',
        title: '标记格式设置',
        hasArrow: true,
        renderSubMenu: () => <FormatList blockId={blockId} onCloseModal={onCloseModal} />,
      },
    },

    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
      isHidden: hiddenList.insert,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.insert,
      data: {
        icon: 'IcArrowUp02',
        title: '在上方插入块',
        rightText: `A`,
        onClick: () => {
          shortcutKeyInsetBlock({ pos: 'before' });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.insert,
      data: {
        icon: 'IcArrowDown02',
        title: '在下方插入块',
        rightText: `B`,
        onClick: () => {
          shortcutKeyInsetBlock({ pos: 'after' });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: hiddenList.insertColumn,
      data: {
        icon: 'IcBlockRightColumns',
        title: '向右方添加分栏',
        hasArrow: true,
        renderSubMenu: () => <ColumnPanel blockId={blockId} onCloseModal={onCloseModal} />,
      },
    },
    {
      isHidden: hiddenList.updatedBy,
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
  ];
  removeDupLine(items);
  return (
    <ListView
      className={listViewNormalClassName}
      items={items}
      customFooter={
        !hiddenList.updatedBy && (
          <UpdateUserInfo name={userName} updatedAt={block.updatedAt} isByAI={block.data.isByAI} />
        )
      }
      onItemClick={async (item) => {
        if (!item.data.hasArrow) {
          onCloseModal();
          const { type } = item.data;
          const selectBlocks = selectedBlock.length
            ? selectedBlock
            : idsToSelectedBlocks(blockId, viewId, syncId);
          switch (type) {
            case MenuListActionType.CUT: {
              document.execCommand('cut');
              break;
            }
            case MenuListActionType.COPY_CLIPBOARD: {
              document.execCommand('copy');
              break;
            }
            case MenuListActionType.DELETE: {
              // 如果有源同步块但是并没有同步过的话，不返回backLinkInfo,可以直接删除
              const backLinkInfo = getSyncBlockBackLinkInfo(selectBlocks);
              if (backLinkInfo) {
                openDeleteSyncBlockDialog(backLinkInfo.onlyOne, async () => {
                  if (block.type === BlockType.SYNC_CONTAINER) {
                    transaction(() => {
                      archiveBlock(selectedBlocksToIds(selectBlocks));
                      selectedBlockHistory([]);
                    });
                  }
                });
                return;
              }
              // 如果是在思维导图页面的图片块点击删除，则转为文本块(产品要求)
              const pageId = getOwnerPage(blockId);
              if (isMindMap(cache.blocks[pageId ?? '']?.type) && isImage) {
                transaction(() => {
                  convertBlock([blockId], {
                    type: BlockType.TEXTAREA,
                    data: {
                      segments: block.data.caption,
                    },
                  });
                });
                return;
              }

              bizTracker.event('delete', { block_name: covertBlockToTitle(blockId) });
              transaction(() => {
                archiveBlock(selectedBlocksToIds(selectBlocks));
                selectedBlockHistory([]);
              });
              break;
            }
            case MenuListActionType.COPY: {
              bizTracker.event('duplicate', { block_name: covertBlockToTitle(blockId) });
              void copyBlockRecord(selectBlocks, undefined, syncId);
              break;
            }
            case MenuListActionType.COPY_MOVE: {
              openMoveTo({
                popcorn,
                uuid: blockId,
                placement: 'left',
                offset: [0, 20],
                placeholder: '搜索拷贝到...的页面',
                copyAndMove: {
                  syncId,
                  viewId,
                },
              });
              break;
            }
            case MenuListActionType.TABLE_CONVERT: {
              tableConvert(blockId);
              break;
            }
            case MenuListActionType.TURN_TO_EMBED: {
              switch (block.type) {
                case BlockType.COLLECTION_VIEW_PAGE:
                  void request.editor.getDoc(blockId).then((data) => {
                    // 内嵌多维表
                    dispatch(
                      blocksActions.update({ blocks: data.blocks as Record<string, NextBlock> })
                    );
                    dispatch(
                      collectionViewsActions.update(
                        data.collectionViews as Record<string, NextCollectionView>
                      )
                    );
                    transaction(() => {
                      updateBlock(blockId, { type: BlockType.COLLECTION_VIEW });
                    });
                  });
                  break;
                case BlockType.REFERENCE_COLLECTION_PAGE: {
                  // 引用多维表
                  transaction(() => {
                    updateBlock(blockId, { type: BlockType.REFERENCE_COLLECTION });
                  });
                  break;
                }
                case BlockType.MIND_MAPPING_PAGE: {
                  // 内嵌思维导图
                  transaction(() => {
                    updateBlock(blockId, { type: BlockType.MIND_MAPPING });
                  });
                  break;
                }
                default:
              }
              break;
            }
            case MenuListActionType.TURN_TO_PAGE: {
              let convertType = BlockType.PAGE;
              switch (block.type) {
                case BlockType.COLLECTION_VIEW:
                  convertType = BlockType.COLLECTION_VIEW_PAGE;
                  break;
                case BlockType.REFERENCE_COLLECTION:
                  convertType = BlockType.REFERENCE_COLLECTION_PAGE;
                  break;
                case BlockType.MIND_MAPPING:
                  convertType = BlockType.MIND_MAPPING_PAGE;
                  break;
                default:
              }
              transaction(() => {
                updateBlock(blockId, {
                  type: convertType,
                });
              });
              bizTracker.event('turninto_page', { block_type: block.type });
              break;
            }
            case MenuListActionType.RENAME: {
              openRename({ popcorn, blockId });
              break;
            }
            case MenuListActionType.OPEN_RIGHT_PAGE: {
              openPage(refBlockId, { forceOpenInRight: true });
              break;
            }
            case MenuListActionType.OPEN_TAB: {
              window.open(`/${refBlockId}`);
              break;
            }
            case MenuListActionType.AI_EDITOR: {
              let maxIndex = 0;
              const subNodes = blocks[block.parentId]?.subNodes ?? [];
              cache.ui.selectedBlocks.forEach(({ blockId }) => {
                maxIndex = Math.max(subNodes.indexOf(blockId), maxIndex);
              });
              // 虚拟列表会有问题
              const nextBlockId = subNodes[maxIndex] ?? blockId;
              const isRight = pageId === getDynamicPageId('right');
              let blockNode = querySelectorFromMainContent(
                `[data-block-id="${nextBlockId}"]`,
                isRight
              );
              if (!blockNode) {
                blockNode = querySelectorFromMainContent(`[data-block-id="${blockId}"]`, isRight);
              }
              if (!blockNode) return;
              openAIEditor({
                blockId,
                popcorn: blockNode,
                editorScene: AIEditorScene.BlockSelected,
                from: AIEditorFrom.BlockMenu,
              });

              break;
            }
            default:
          }
        }
      }}
    />
  );
};
