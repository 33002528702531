import { Role } from '@flowus/common';
import { ConditionalRender } from '@flowus/common/components/conditional-render';
import { cx } from '@flowus/common/cx';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { isPublicPageDeleted } from 'src/common/utils/is-public-page-deleted';
import { HistoryMenu } from 'src/components/history-menu';
import { Share } from 'src/components/share';
import { PublishButton } from 'src/components/share/publish';
import { HEADER_HEIGHT } from 'src/const/public';
import { MemberVisitHistory } from 'src/editor/editor/uikit/member-visit-history';
import { useReadonly } from 'src/hooks/page';
import { useHasAIBlock } from 'src/hooks/page/use-page-has-aiBlock';
import { useIsNeedPay, usePageMeta } from 'src/hooks/page/use-page-meta';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { useCopyToSpace } from 'src/hooks/share/use-copy-to-space';
import { usePermissions } from 'src/hooks/share/use-permissions';
import type { NextBlock } from 'src/redux/types';
import {
  useAlwaysShowSync,
  useDirty,
  useFileIllegal,
  useGetOffline,
  useIsCollapse,
  useIsEditingPage,
  useSyncUpFault,
} from 'src/services/app/hook';
import { useIsFullscreen } from 'src/services/desktop';
import { $networkStatus } from 'src/services/network-status';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isMacElectron } from 'src/utils/electron';
import {
  getPreviewTemplateId,
  isEmbedTemplate,
  judgeSharePage,
  useGetPageId,
} from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';
import { Logo } from 'src/views/share/logo';
import { set_cycle_header } from 'src/views/share/share-password';
import { TocShowButton } from '../aside/toc-trigger';
import { isRightDrawer, useDrawerOpenStateValue } from '../drawer/shared';
import { BackSpaceButton } from './back-space-button';
import { Breadcrumb } from './breadcrumb';
import { CommentButton } from './comment-button';
import { Favorite } from './favorite';
import { HeaderBar } from './header-bar';
import { HomePage } from './home-page';
import { LockButton } from './lock-button';
import { MenuButton } from './menu-button';
import { PageCommentButton } from './page-comment-button';
import { PageFeedsButton } from './page-feeds-button';
import { GlobalSearchType, Search } from './search';
import { SubscribeButton } from './subscribe-button';

interface Props {
  emptyStyle?: boolean;
}
/** 主页面的头部 */
export const Header: FC<Props> = memo((props: Props) => {
  const { emptyStyle } = props;
  const pageId = useGetPageId();
  const readonly = useReadonly(pageId);
  const pageMeta = usePageMeta(pageId);
  const hasAIBlock = useHasAIBlock(pageId);
  const block = usePickBlock(pageId, ['isTemplate']);
  const alwaysShowSync = useAlwaysShowSync();
  const isInRight = useIsInRight();
  const fileIllegal = useFileIllegal();
  const copyToSpace = useCopyToSpace();
  const drawerOpen = useDrawerOpenStateValue();
  const isNeedPay = useIsNeedPay(pageId);
  // 被风控了
  const {
    role,
    illegal,
    allowDuplicate,
    allowShowBreadcrumb,
    allowShowSidebar,
    allowSubscribe,
    shared,
  } = usePermissions(pageId);
  const illegalPage = illegal || fileIllegal;
  const isSharePage = judgeSharePage();
  // 特殊空间
  const officialSpaceShare = useOfficialSpaceShare(pageId);
  const _isPublicPageDeleted = isPublicPageDeleted(pageId);
  const isCollapsed = useIsCollapse();
  const isFullscreen = useIsFullscreen();
  const isDesktopMode = isMacElectron && !isFullscreen;
  const rootId = useObservableStore(
    (state) => {
      let block: NextBlock | undefined = state.blocks[pageId];
      let result = block;
      while (block) {
        const parent = state.blocks[block.parentId];
        if (parent?.permissions.some((p) => p.shareShowSidebar)) {
          result = parent;
        }
        // @ts-ignore ignore space block
        if (parent && parent.type !== 'SPACE') {
          block = parent;
          continue;
        }
        break;
      }
      return result?.uuid;
    },
    [pageId]
  );

  const optionButtons = useMemo(() => {
    if (isNeedPay) {
      return null;
    }

    return (
      <>
        {isSharePage && !illegal && allowSubscribe && <SubscribeButton />}
        <CommentButton />
        {!__PRIVATE__ && isSharePage && !illegal && allowDuplicate && !officialSpaceShare && (
          <Tooltip
            popup="拷贝到"
            className="animate-click animate-hover"
            onClick={() => {
              bizTracker.event('copytomyspace', {
                page_id: pageId,
              });
              copyToSpace(pageId, { addCopyLimit: true });
            }}
          >
            <Icon size="middle" name="IcCopy" />
          </Tooltip>
        )}
        {!illegal && (role === PermissionRole.WRITER || role === PermissionRole.EDITOR) && (
          <PageCommentButton />
        )}
      </>
    );
  }, [
    allowDuplicate,
    allowSubscribe,
    copyToSpace,
    illegal,
    isNeedPay,
    isSharePage,
    officialSpaceShare,
    pageId,
    role,
  ]);

  const content = useMemo(() => {
    if ($searchParams.print) return null;
    const hiddenBreadcrumb = isSharePage && !allowShowBreadcrumb;

    let _content = null;
    if (
      (block && !(pageMeta?.tag === 'NO_PERMISSION' || role === PermissionRole.NONE)) ||
      illegalPage
    ) {
      _content = (
        <>
          <div className="flex overflow-hidden">
            {isSharePage && <TocShowButton showInSharePage={allowShowSidebar} />}
            {isSharePage && (
              <>
                <ConditionalRender Component1={HomePage} Component2={Logo} />
                <div className="self-center ml-2 h-3 w-px bg-grey3" />
              </>
            )}

            {!_isPublicPageDeleted && (
              <Breadcrumb
                showHistoryButton={!isSharePage}
                ancestorId={
                  isEmbedTemplate ? getPreviewTemplateId() : hiddenBreadcrumb ? pageId : undefined
                }
              />
            )}
            {!isSharePage && <LockButton uuid={pageId} />}
          </div>

          {((block &&
            [
              BlockType.PAGE,
              BlockType.FOLDER,
              BlockType.COLLECTION_VIEW_PAGE,
              BlockType.REFERENCE_COLLECTION,
              BlockType.REFERENCE_COLLECTION_PAGE,
              BlockType.COLLECTION_VIEW,
              BlockType.MIND_MAPPING,
              BlockType.MIND_MAPPING_PAGE,
            ].includes(block.type) &&
            !isInRight) ||
            illegalPage) &&
            !isEmbedTemplate && (
              <div className={'relative flex items-center justify-end h-full flex-shrink-0'}>
                <div className={'flex items-center justify-end space-x-2'}>
                  {hasAIBlock && !__BUILD_IN__ && isSharePage && (
                    <p className={'text-grey4 text-t4 flex items-center'}>
                      <Icon name="IcNote" size="middle" />
                      <span>页面可能含有 AI 生成内容</span>
                    </p>
                  )}
                  <SyncIcon />
                  {!readonly && (
                    <MemberVisitHistory key={pageId} className="px-2.5 flex-1 overflow-x-auto" />
                  )}
                  {shared && allowSubscribe && Role.contains(role, PermissionRole.EDITOR) && (
                    <PublishButton uuid={pageId} />
                  )}
                  {!isSharePage && !illegalPage && !officialSpaceShare && (
                    <Share showType="button" />
                  )}
                  {optionButtons}
                  {!illegal &&
                    (role === PermissionRole.WRITER || role === PermissionRole.EDITOR) && (
                      <PageFeedsButton />
                    )}
                  {!isSharePage && !readonly && !illegal && <Favorite uuid={pageId} />}

                  <MenuButton />

                  {!_isPublicPageDeleted && (
                    <Search
                      type={isSharePage ? GlobalSearchType.share : GlobalSearchType.normal}
                      inPageId={pageId}
                      rootPageId={isSharePage ? rootId : undefined}
                    />
                  )}
                  <BackSpaceButton />
                </div>
              </div>
            )}
        </>
      );
    } else {
      _content = <WithoutBlockHeader />;
    }

    if (emptyStyle) {
      _content = <EmptyHeader />;
    }
    return _content;
  }, [
    _isPublicPageDeleted,
    allowShowBreadcrumb,
    allowShowSidebar,
    allowSubscribe,
    block,
    emptyStyle,
    hasAIBlock,
    illegal,
    illegalPage,
    isInRight,
    isSharePage,
    officialSpaceShare,
    optionButtons,
    pageId,
    pageMeta?.tag,
    readonly,
    role,
    rootId,
    shared,
  ]);

  if ($searchParams.print) return null;
  if ($searchParams.blog) return null;

  return (
    <>
      <div className="flex w-full select-none">
        {!$searchParams.embed && (
          <div
            className={cx(
              'flex justify-between items-center w-full px-4 bg-white1 transition-all border-b border-transparent',
              alwaysShowSync ? 'duration-500 opacity-0' : 'duration-100 opacity-100',
              isDesktopMode && isCollapsed && 'pl-20',
              isDesktopMode && !isCollapsed && 'pl-4',
              isDesktopMode && 'drag-container',
              drawerOpen && !isRightDrawer(drawerOpen) && 'border-grey6'
            )}
            style={{ height: HEADER_HEIGHT }}
          >
            {content}
          </div>
        )}
      </div>
      <HeaderBar />
    </>
  );
});

const SyncIcon = () => {
  const openModal = useOpenModal();
  const offline = useGetOffline();
  const dirty = useDirty();
  const syncUpFault = useSyncUpFault();
  const isEditingPage = useIsEditingPage();

  const recoveryNetwork = () => {
    openModal.warning({
      title: '重置网络状态',
      content: (
        <div className="text-left">
          <p className="mb-2">
            &nbsp;&nbsp;操作系统可能会误判网络状态，如果你的网络正常，点“确定”可以重置网络状态。
          </p>
          <p>&nbsp;&nbsp;如果重置后还是会复现，可以按此步骤切换网络状态</p>
        </div>
      ),
      confirm: () => {
        $networkStatus.setStatus('online');
      },
      confirmText: '确定',
      cancelText: '取消',
    });
  };

  if (syncUpFault.backup) {
    return (
      <Tooltip
        popup={
          <>
            你的本地修改多次同步失败。请<strong>*备份*</strong>
            文档内容并尝试<strong>*刷新*</strong>页面。在同步恢复之前，你将无法继续编辑
          </>
        }
      >
        <span className="inline-flex text-t2 px-3 py-1 rounded items-center text-white bg-red">
          <Icon className="mr-1" name="IcBadgeDanger" size="middle" />
          <span>保存失败</span>
        </span>
      </Tooltip>
    );
  }

  if (offline) {
    if (dirty) {
      return (
        <Tooltip
          className="animate-click"
          popup={<div>🤔&nbsp;网络一切正常？点击立即恢复</div>}
          onClick={recoveryNetwork}
        >
          <span className="inline-flex text-t2 px-3 py-1 rounded items-center text-white bg-red">
            <Icon className="mr-1" name="IcTitleOffline" size="middle" />
            <span>网络故障</span>
          </span>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        className="animate-click"
        popup={
          <div className="text-center">
            <p>离线中</p>
            <p>🤔&nbsp;网络一切正常？点击立即恢复</p>
          </div>
        }
        onClick={recoveryNetwork}
      >
        <Icon className="text-grey4" name="IcTitleOffline" size="middle" />
      </Tooltip>
    );
  }

  if (dirty && !isEditingPage) {
    return (
      <Tooltip popup="同步中">
        <Icon className="text-grey4 animate-spin" name="IcRefresh" size="middle" />
      </Tooltip>
    );
  }

  return null;
};

const WithoutBlockHeader: FC = () => {
  const isReadonly = useReadonly();
  const pageId = useGetPageId();
  const pageMeta = usePageMeta(pageId);
  const isSharePage = judgeSharePage();

  let content = null;

  if (isReadonly || pageMeta?.tag === 'NO_PERMISSION') {
    if (isSharePage) {
      content = <Logo className="sm:hidden" />;
    } else {
      content = (
        <div className="flex items-center">
          <TocShowButton />
          <HistoryMenu />
        </div>
      );
    }
  } else {
    content = (
      <div className="flex items-center">
        <TocShowButton />
        <HistoryMenu />
      </div>
    );
  }

  return content;
};

const EmptyHeader: FC = () => {
  return (
    <>
      <HistoryMenu />
      <Logo className="sm:hidden" />
      <BackSpaceButton />
    </>
  );
};

set_cycle_header(Header);
