import { useCallback, useEffect, useState, type FC } from 'react';
import QRCode from 'qrcode';
import { Icon } from 'src/common/components/icon';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { SearchParams } from 'src/utils';
import { useCurrentUser } from 'src/hooks/user';
import { useGetSharePageUrl } from './helper';
import { useOpenSharePoster } from './share-poster';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePayInfo } from 'src/hooks/page/use-pay-info';

interface Props {
  pageId: string;
}
export const ShareDialog: FC<Props> = (props) => {
  const [qrCode, setQrCode] = useState<string>('');
  const { amount = 0, payInfo } = usePayInfo(props.pageId);
  const currentUser = useCurrentUser();
  const getSharePageUrl = useGetSharePageUrl(props.pageId);
  const { url, textAndUrl } = getSharePageUrl({ inviteCode: currentUser.inviteCode });

  const copyEmbedLink = useCallback(async () => {
    bizTracker.event('click_share_btn', {
      type: 'embedded',
    });
    const urlObj = new URL(url);
    urlObj.searchParams.set(SearchParams.embed, 'true');
    await writeTextInClipboard(urlObj.href, { message: '已复制链接' });
  }, [url]);
  const onCopy = useCallback(() => {
    bizTracker.event('click_share_btn', {
      type: 'link',
    });
    void writeTextInClipboard(textAndUrl, { message: '已复制链接' });
  }, [textAndUrl]);

  const openSharePoster = useOpenSharePoster();

  useEffect(() => {
    void (async () => {
      // 二维码url
      const _qr = await QRCode.toDataURL(`${url}`, {
        errorCorrectionLevel: 'medium',
      });
      setQrCode(_qr);
    })();
  }, [url]);
  return (
    <div className="next-modal p-4 space-y-2 w-64">
      <div className="text-center w-full text-t2-medium py-1">
        {__BUILD_IN__ || !(payInfo?.sharePageDistributionRatio && payInfo.accessFee) ? (
          '分享'
        ) : (
          <div className="space-y-1.5 text-t3">
            <div>分享赚取订阅佣金和会员佣金</div>
            <span>
              {`当有人通过你的分享付费购买该专栏，你可获得其支付金额的${
                payInfo.sharePageDistributionRatio * 100
              }%:`}
            </span>
            <span className="text-red">{amount}元</span>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <img className="w-[120px] h-[120px]" src={qrCode} />
      </div>
      <div className="flex justify-center">
        <div className="flex items-center space-x-1">
          <div className="bg-grey8 h-px w-8" />
          <div className="text-grey3 text-t4-medium">其他分享方式</div>
          <div className="bg-grey8 h-px w-8" />
        </div>
      </div>
      <div className="flex items-center justify-around text-grey3">
        <div className="flex flex-col items-center  p-1" onClick={onCopy}>
          <div className="flex justify-center items-center w-8 h-8 rounded-full bg-grey9 animate-hover">
            <Icon name={'IcLinkSmall'} size="middle" className="text-grey1" />
          </div>
          <div className="mt-1 text-t4">链接分享</div>
        </div>
        <div className="flex flex-col items-center p-1" onClick={copyEmbedLink}>
          <div className="flex justify-center items-center w-8 h-8 rounded-full bg-grey9 animate-hover">
            <Icon name={'IcEmbeddedLinkSmall'} size="middle" className="text-grey1" />
          </div>
          <div className="mt-1 text-t4">内嵌分享</div>
        </div>
        {!__BUILD_IN__ && (
          <div
            className="flex flex-col items-center p-1"
            onClick={() => {
              openSharePoster({
                url,
                pageId: props.pageId,
              });
            }}
          >
            <div className="flex  justify-center items-center w-8 h-8 rounded-full bg-grey9  animate-hover">
              <Icon name={'IcImageSmall'} size="middle" />
            </div>
            <div className="mt-1 text-t4">生成海报</div>
          </div>
        )}
      </div>
    </div>
  );
};
