import { Pc, PcIcon as IPcIcon } from '@next-space/fe-svg-icon';
import type { FC } from 'react';
import { cx } from '../cx';
import { useIsDarkMode } from '../utils/get-dark';
import type { ComponentProp, IconSize } from './types';
import { IconSizeStyle } from './types';

export { IconSizeStyle };

export type PcIconName = keyof typeof Pc;

const pcIconKeys = Object.keys(Pc);

export interface IconProp extends ComponentProp {
  size: IconSize;
  name?: PcIconName | null;
  id?: string;
  boxSize?: IconSize;
}

const isI18Scene = () => {
  try {
    // @ts-ignore bin
    return __BUILD_IN__;
  } catch {
    return false;
  }
};

// 在海外场景下，为了集中替换 icon。采用这个方式在组件中自动替换
const i18SceneIcon: Record<string, PcIconName> = {
  // 普通文字块
  MIcInsertBlockText: isI18Scene() ? 'MIcBinInsertBlockText' : 'MIcInsertBlockText',
};

export const PcIcon: FC<IconProp> = (props) => {
  const isDarkMode = useIsDarkMode();
  const { size = 'middle', className = '', style = {}, onClick, boxSize, ...reset } = props;
  let { name } = props;

  if (!name) return null;
  try {
    if (name.startsWith('DmIc')) {
      // eslint-disable-next-line no-console
      // console.error('不能直接使用DmIc的Icon，请使用MIc。dark环境下会自动转为DmIc');
      return null;
    }
  } catch (err) {
    console.log('name', name);
    return null;
  }
  name = i18SceneIcon[name] ?? name;

  if (name.startsWith('MIc') && isDarkMode) {
    name = name.replace('MIc', 'DmIc') as PcIconName;
    if (!pcIconKeys.includes(name)) {
      // eslint-disable-next-line no-console
      // console.error('缺少深色模式icon', name);
      name = name.replace('DmIc', 'MIc') as PcIconName;
    }
  }

  if (!pcIconKeys.includes(name)) {
    // eslint-disable-next-line no-console
    // console.error('缺少icon', name);
    return null;
  }

  const _class = cx(className, {
    'cursor-pointer animate-click': Boolean(onClick),
  });

  const icon = (
    <IPcIcon
      {...reset}
      data-icon={name}
      className={cx('flex-shrink-0 fill-current inline', IconSizeStyle[size], !boxSize && _class)}
      onClick={onClick}
      style={{
        verticalAlign: '-0.15em',
        ...style,
      }}
      aria-hidden="true"
      name={name as PcIconName}
    />
  );

  if (boxSize) {
    return (
      <span
        className={cx('flex items-center justify-center', IconSizeStyle[boxSize], className, {
          'cursor-pointer animate-click': Boolean(onClick),
        })}
      >
        {icon}
      </span>
    );
  }

  return icon;
};

export const Icon = PcIcon;
