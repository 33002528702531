import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { PRODUCT_NAME } from 'src/const/title';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { OpenSettingFrom } from '../../setting-modal/type';
import { useOpenAskUpgradeSpace } from '../../setting-modal/use-open-ask-upgrade-space';
import { useOpenUpgradeSpace } from '../../setting-modal/use-open-setting-modal';
import { ProIcon } from '../pro-icon';
import type { ExportOption } from './types';
import { WATERMARK } from './types';

interface Props {
  className?: string;
  exportOption: ExportOption;
  onExportOptionChange: (option: Pick<ExportOption, 'transparent' | 'watermark'>) => void;
  onExport?: () => void;
  onCancel?: () => void;
}
/** 由于导出思维导图跟其他页面ui暂时不共用，就先写死一些参数 */
export const ExportPngSetting: FC<Props> = (props) => {
  const { exportOption } = props;
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();

  if (exportOption.type !== 'png') return null;

  return (
    <div className={props.className}>
      <div className="flex mb-4">
        <div className="text-t2 text-grey3">导出格式</div>
        <div className="flex items-center ml-5 ">
          <div className="text-t2">{'PNG'}</div>
          <Icon name="IcArrowDown01" className="ml-2 text-grey4" size="tiny" />
        </div>
      </div>

      <div className={'flex items-start mb-4'}>
        <div className="text-t2 text-grey3">更多设置</div>
        <div className="ml-5">
          <div className="flex items-center mb-4">
            <Icon
              size="large"
              name={exportOption.transparent ? 'IcCheckboxCheck' : 'IcCheckbox'}
              className={cx('mr-0.5 ', exportOption.transparent ? 'text-active_color' : '')}
              onClick={() => {
                props.onExportOptionChange({
                  transparent: !exportOption.transparent,
                  watermark: exportOption.watermark,
                });
              }}
            />
            <div className="text-t2">透明背景</div>
          </div>
          <div className="relative flex items-center">
            <Icon
              size="large"
              name={exportOption.watermark ? 'IcCheckboxCheck' : 'IcCheckbox'}
              className={cx('mr-0.5 ', exportOption.watermark ? 'text-active_color' : '')}
              onClick={() => {
                if (isFreeSpaceLimit) {
                  openAskUpgradeSpace(() => {
                    openUpgradeSpace(OpenSettingFrom.export_watermark);
                  });
                  return;
                }
                props.onExportOptionChange({
                  transparent: exportOption.transparent,
                  watermark: exportOption.watermark ? '' : WATERMARK,
                });
              }}
            />
            <div className="text-t2">{PRODUCT_NAME} 水印</div>
            {isFreeSpaceLimit && <ProIcon className="right-0 -top-1" />}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-24 ml-10">
        <Button onClick={props.onCancel} className="mr-4 text-t2-medium">
          取消
        </Button>

        <Button
          colorType="active"
          className="text-t2-medium text-white"
          onClick={async () => {
            props.onExport?.();
          }}
        >
          导出
        </Button>
      </div>
    </div>
  );
};
