import type { PcIconName } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { SortableList } from 'src/common/components/sortable-list';
import { v4 as uuid } from 'uuid';
import { isSystemPrompt } from './data';
import { usePromptStore } from './store';
import type { Prompt, PromptCategoryType } from './types';

interface Props {
  parentId?: string;
  promptId: string;
  categoryType: PromptCategoryType;
  onChangePrompt: (promptId: string) => void;
  renderHandle: () => React.ReactNode;
}
export const PromptItem: FC<Props> = (props) => {
  const prompt = usePromptStore((s) => s.records[props.promptId]);
  const curentPromptId = usePromptStore((s) => s.curentPromptId);
  const openModal = useOpenModal();
  const [expand, setExpand] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  if (!prompt) return null;
  const isCategory = prompt.subNodes;
  const systemPrompt = isSystemPrompt(prompt.id);
  const createNewPrompt = () => {
    const id = uuid();
    const store = usePromptStore.getState();
    const newPrompt: Prompt = { ...prompt, subNodes: [id, ...(prompt.subNodes ?? [])] };
    usePromptStore.setState({
      records: {
        ...store.records,
        [id]: { name: '', id, type: 'Custom' },
        [props.promptId]: newPrompt,
      },
      curentPromptId: id,
      hasUpdate: true,
    });
  };

  return (
    <div>
      <div
        className={cx('group w-full px-1 animate-hover flex justify-between items-center h-10', {
          'bg-black_006 ': curentPromptId === props.promptId,
        })}
        onClick={() => {
          if (isCategory) {
            setExpand(!expand);
            return;
          }
          props.onChangePrompt(props.promptId);
        }}
      >
        <div className="flex items-center flex-1 w-0">
          {props.renderHandle()}
          {isCategory && (
            <Icon
              name={'IcToggleBlack'}
              style={{
                rotate: expand ? '90deg' : '0deg',
              }}
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                if (isCategory) {
                  setExpand(!expand);
                }
              }}
            />
          )}
          <Icon name={(prompt.icon as PcIconName) || 'IcEdit'} size="middle" />
          {editTitle ? (
            <Input
              autoFocus
              inputClassName="px-1"
              className="h-10"
              showBorder={false}
              placeholder={isCategory ? '未命名指令目录' : '未命名指令'}
              value={prompt.name}
              onBlur={() => {
                setEditTitle(false);
              }}
              onChange={(v) => {
                const store = usePromptStore.getState();
                const newPrompt = { ...prompt, name: v, type: 'Custom' };
                usePromptStore.setState({
                  records: { ...store.records, [prompt.id]: newPrompt },
                  hasUpdate: true,
                });
              }}
            />
          ) : (
            <>
              <div
                className="ml-1 text-ellipsis"
                onClick={() => {
                  setEditTitle(true);
                }}
              >
                {prompt.name || (isCategory ? '未命名指令目录' : '未命名指令')}
              </div>
              {systemPrompt && (
                <Tooltip popup="系统预置指令，暂不支持修改" delay={{ open: 1500 }}>
                  <div className="ml-2 px-1 bg-red text-white rounded text-t5">预置</div>
                </Tooltip>
              )}
            </>
          )}
        </div>
        <div className="flex items-center shrink-0 space-x-1">
          {!systemPrompt && (
            <Tooltip popup={`删除当前指令${isCategory ? '目录' : ''}`}>
              <Icon
                size="middle"
                className="hidden text-grey group-hover:block cursor-pointer"
                name="IcTrash"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal.warning({
                    title: `确定删除该${isCategory ? '指令目录' : '指令'}?`,
                    confirm: () => {
                      const store = usePromptStore.getState();
                      const curPrompt = store.records[props.promptId];
                      const newRecord = { ...store.records };
                      if (isCategory) {
                        // 删除目录的subnodes
                        curPrompt?.subNodes?.forEach((v) => {
                          delete newRecord[v];
                        });
                      }
                      if (props.parentId) {
                        const parent = store.records[props.parentId];
                        if (!parent) {
                          console.log('parent is not found');
                          return;
                        }
                        const promptIds = parent?.subNodes?.filter((v) => v !== prompt.id);
                        const newParent: Prompt = { ...parent, subNodes: promptIds };
                        usePromptStore.setState({
                          records: { ...newRecord, [props.parentId]: newParent },
                          hasUpdate: true,
                        });
                      } else {
                        const promptIds = store[props.categoryType].filter((v) => v !== prompt.id);
                        usePromptStore.setState({
                          [props.categoryType]: promptIds,
                          records: newRecord,
                          hasUpdate: true,
                        });
                      }
                    },
                  });
                }}
              />
            </Tooltip>
          )}

          {prompt.subNodes && !isSystemPrompt(prompt.id) && (
            <Tooltip popup="添加指令" delay={{ open: 1500 }}>
              <Icon
                size="middle"
                name={'IcAdd'}
                className="hidden group-hover:block"
                onClick={(e) => {
                  e.stopPropagation();
                  createNewPrompt();
                }}
              />
            </Tooltip>
          )}
          <Tooltip popup={prompt.hidden ? '显示' : '隐藏' + '当前指令'}>
            <Icon
              size="middle"
              className={cx('text-grey group-hover:block cursor-pointer', {
                hidden: !prompt.hidden, // 只有隐藏才一直显示给用户看
              })}
              name={prompt.hidden ? 'IcShow' : 'IcHide'}
              onClick={(e) => {
                e.stopPropagation();
                const store = usePromptStore.getState();
                usePromptStore.setState({
                  records: {
                    ...store.records,
                    [prompt.id]: { ...prompt, hidden: !prompt.hidden },
                  },
                  hasUpdate: true,
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
      {expand && prompt.subNodes && (
        <SubNodes
          categoryType={props.categoryType}
          parentId={prompt.id}
          subNodes={prompt.subNodes}
          onChangePrompt={props.onChangePrompt}
        />
      )}
      {expand && prompt.subNodes && prompt.subNodes.length === 0 && (
        <div
          className="py-2 animate-hover flex justify-center w-full text-grey text-t4"
          onClick={() => {
            createNewPrompt();
          }}
        >
          添加新的指令
        </div>
      )}
    </div>
  );
};

interface SubNodesProps extends Pick<Props, 'onChangePrompt' | 'categoryType'> {
  subNodes: string[];
  parentId: string;
}
const SubNodes: FC<SubNodesProps> = (props) => {
  const prompts = props.subNodes.map((v) => {
    return { id: v, value: v };
  });
  return (
    <div className="ml-6">
      <SortableList
        onlyHandleDraggable
        onChange={(items) => {
          const store = usePromptStore.getState();
          const parent = store.records[props.parentId];
          if (!parent) {
            console.log("it's impossible");
            return;
          }
          usePromptStore.setState({
            records: {
              ...store.records,
              [props.parentId]: { ...parent, subNodes: items.map((v) => v.id) },
            },
            hasUpdate: true,
          });
        }}
        items={prompts}
        renderItemContent={({ item, renderDragHandle }) => {
          return (
            <PromptItem
              parentId={props.parentId}
              categoryType={props.categoryType}
              promptId={item.id}
              onChangePrompt={props.onChangePrompt}
              renderHandle={() => {
                return renderDragHandle({
                  className: 'cursor-pointer flex items-center justify-center w-5 h-5 text-grey4',
                  size: 'middle',
                });
              }}
            />
          );
        }}
      />
    </div>
  );
};
