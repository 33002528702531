import { isDebugUrl, PayType, requestWebChatCode } from '@flowus/common';
import { useOpenModal } from '@flowus/common/next-modal';
import { isInWechat } from '@flowus/common/url';
import { isInWeapp, PAY_ACCESS } from '@flowus/login/utils';
import { weAppPay, WeappSearchParamsKey } from '@flowus/login/utils/wx';
import type { PreOrderDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';

interface Params {
  preOrder?: PreOrderDTO;
  createOrder?: () => Promise<PreOrderDTO>;
  onCreateOrderId: (orderId: string) => void;
  selectPayType: PayType;
  spaceId: string;
  planId: string;
  couponId: string | undefined;
  people: number | undefined;
  date: number | undefined;
}
export const usePayOrder = () => {
  const openModal = useOpenModal();

  return useCallback(
    async (params: Params) => {
      if (isInWechat && !isInWeapp()) {
        // 微信内支付
        requestWebChatCode(PAY_ACCESS, {
          type: WeappSearchParamsKey.upgrade,
          payType: PayType.wxjsapi,
          planId: params.planId,
          spaceId: params.spaceId,
          couponId: params.couponId,
          date: params.date ? `${params.date}` : undefined,
          people: params.people ? `${params.people}` : undefined,
        });
        return;
      }
      let _preOrder = params.preOrder;
      if (!_preOrder && params.createOrder) {
        _preOrder = await params?.createOrder();
      }
      if (!_preOrder) return;

      params.onCreateOrderId(_preOrder.orderId);

      // 小程序
      if (isInWeapp()) {
        weAppPay({ type: WeappSearchParamsKey.orderId, orderId: _preOrder.orderId });
        openModal.warning({
          title: '已唤起微信支付',
          content: '是否已完成支付？',
          confirmText: '已完成',
          cancelText: '未完成',
          confirm: () => location.reload(),
          cancel: () => location.reload(),
        });
        return;
      }

      if (_preOrder.qrCodeUrl) {
        const url = `${_preOrder.qrCodeUrl}`;
        // 支付宝支付(微信内不会有这个选项)
        if (params.selectPayType === PayType.alipay) {
          location.href = url;
          return;
        }
        // h5调起微信支付
        location.href = url;
      }
    },
    [openModal]
  );
};
