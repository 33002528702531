import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { Highlight } from '@flowus/common/components/hight-light';
import { pull } from 'lodash-es';
import type { CSSProperties, FC, ReactNode } from 'react';
import { memo, useMemo } from 'react';
import { useMenuListKeywords } from 'src/services/app';
import { isSlashSymbol } from 'src/utils/slash-symbol';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import type { IconName } from '../../icon';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';
import type { IconSize } from '@flowus/common/icon-svg';

interface OperationDataScheme {
  title: string;
  context: ReactNode;
  icon?: IconName | ReactNode;
  iconClassName?: string;
  hasArrow?: boolean;
  image?: string;
  imageStyle?: CSSProperties;
  onClick?: () => void;
  keywords?: string[];
  textColor?: string;
  backgroundColor?: string;
  rightText?: any;
  textClassName?: string;
  iconSize?: IconSize;
}
/**
 * block item
 */
export const BlockItem: FC<ElementItemProps<OperationDataScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  const { icon, image, textColor, backgroundColor, iconSize = 'middle' } = dataScheme;
  const isBgColor = backgroundColor !== undefined;
  return (
    <div
      {...props.attribute}
      onClick={(e) => {
        props.onItemClick && props.onItemClick(props.listItem, props.index, e);
      }}
      className={cx(
        listViewItemDefaultClassName,
        'rounded cursor-pointer active-bg animate-hover text-ellipsis',
        {
          'normal-bg': props.selected,
        }
      )}
    >
      <Tooltip
        className={'flex items-center justify-between h-full space-x-2'}
        placement="right"
        theme="none"
        offset={[0, 16]}
        popup={dataScheme.context}
      >
        <div className="flex items-center space-x-2">
          {icon && typeof icon === 'string' ? (
            <Icon name={icon as any} size={iconSize} className={dataScheme.iconClassName} />
          ) : (
            icon
          )}
          {image && (
            <img
              className="flex-shrink-0 object-fill w-5 h-5 rounded border-grey4"
              src={dataScheme.image}
              style={dataScheme.imageStyle}
            />
          )}
          {(textColor || isBgColor) && (
            <div
              className={
                'border border-black_006 rounded-full flex items-center justify-center w-5 h-5'
              }
              style={{
                background: isBgColor ? getBlockBackgroundColor(backgroundColor) : undefined,
              }}
            >
              <span
                className={'text-t2-bold text-black'}
                style={{ color: textColor ? getBlockTextColor(textColor) : undefined }}
              >
                A
              </span>
            </div>
          )}
          <span className="text-black text-t2">{dataScheme.title}</span>
        </div>
        <div className="text-grey3 text-t4 text-ellipsis max-w-[130px]">
          <HighlightKeywords dataScheme={dataScheme} />
        </div>
        {dataScheme.rightText && (
          <span className={cx('text-t3 text-grey3', dataScheme.textClassName)}>
            {dataScheme.rightText}
          </span>
        )}
      </Tooltip>
    </div>
  );
};

const HighlightKeywords = memo(({ dataScheme }: { dataScheme: OperationDataScheme }) => {
  const menuListKeywords = useMenuListKeywords();
  const hasSlash = isSlashSymbol(menuListKeywords[0] ?? '');

  // 筛选slash符号
  const searchKeywords: string = hasSlash ? menuListKeywords.slice(1) : menuListKeywords;

  const keywordsArr = pull([...(dataScheme.keywords ?? [])], undefined);

  const findText = useMemo(() => {
    if (!searchKeywords) {
      return null;
    }
    const text = keywordsArr.find((i) => {
      if (stringToLowerCaseAndRemoveSpace(i).includes(searchKeywords) && i && searchKeywords) {
        return i;
      }
      return null;
    });
    return text;
  }, [keywordsArr, searchKeywords]);

  const renderText = `${findText ?? keywordsArr[0] ?? ''}`;

  return (
    <>
      {hasSlash && !!keywordsArr.length && <span className={'text-active_color'}>/</span>}
      <Highlight text={renderText} search={searchKeywords} />
    </>
  );
});
