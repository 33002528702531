import type { IEditorModel } from '@next-space/fe-inlined';
import { newContent, newElement, sliceContent } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { first } from 'rxjs';
import { PersonList } from 'src/bitable/table-view/cell/person-editor';
import { Input } from 'src/common/components/input';
import { useUsers } from 'src/hooks/page/use-subscription-data';

import { INLINE_PERSON_TAG, SELECT_INLINE_TAG } from '../../../editor/inline/const';

export const InlinePersonPanel: FC<{
  onCloseModal: () => void;
  blockId: string;
  left: number;
  right: number;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  symbol?: string;
}> = ({ onCloseModal, blockId, left, right, symbol = '', getEditorModel }) => {
  const users = useUsers();
  useEffect(() => {
    const editorModel = getEditorModel(blockId);
    if (!editorModel) return;
    const cursorLeft = editorModel.createCursor(left, 'left');
    const { selection } = editorModel;
    if (!selection) return;
    if (!selection.isCollapsed) return;
    const cursorRight = editorModel.createCursor(selection.focusOffset, 'right');

    editorModel.performChange((ctx) => {
      ctx
        .select(left, right)
        .replace(newContent([newElement(SELECT_INLINE_TAG, { tip: '选择成员' })]))
        .collapse('end');
    });
    cursorRight.offset = cursorLeft.offset + 1;
    const subscriptionSelection = editorModel.onSelectionChange.subscribe(() => {
      if (editorModel.selection) {
        const { focusOffset } = editorModel.selection;
        if (focusOffset <= cursorLeft.offset || cursorRight.offset < focusOffset) {
          onCloseModal();
        }
      }
    });
    const subscriptionInput = editorModel.onBeforeInput.pipe(first()).subscribe(() => {
      cleanup();
      onCloseModal();
    });
    let didCleanup = false;
    const cleanup = () => {
      if (didCleanup) return;
      didCleanup = true;
      const content = sliceContent(editorModel.content, cursorLeft.offset, cursorRight.offset);
      const item = content.items[0];
      if (item && item.type === 'element' && item.tag === SELECT_INLINE_TAG) {
        // 如果用户还没有选择行内元素类型，则还原为`[[`符号
        editorModel.performChange((ctx) => {
          ctx.shadow().select(cursorLeft.offset, cursorRight.offset).replace(symbol).release();
        });
        void editorModel.requestFocus();
      }
      cursorLeft.release();
      cursorRight.release();
      subscriptionSelection.unsubscribe();
      subscriptionInput.unsubscribe();
    };

    return () => {
      cleanup();
    };
  }, [blockId, left, onCloseModal, symbol, right, getEditorModel]);
  const [search, setSearch] = useState('');

  return (
    <div className="w-[260px] py-2 next-modal">
      <PersonList
        customHeader={
          <Input
            autoFocus
            className="h-8 mx-2.5 mb-2.5"
            placeholder={`搜索成员`}
            onChange={(value) => {
              setSearch(value);
            }}
          />
        }
        className="py-1.5"
        search={search}
        users={users}
        onSelectPerson={(userId) => {
          const editorModel = getEditorModel(blockId);
          if (!editorModel) return;
          editorModel.performChange((ctx) => {
            ctx
              .shadow()
              .select(left, left + 1)
              .replace(
                newContent([
                  newElement(INLINE_PERSON_TAG, {
                    userId,
                  }),
                ])
              )
              .release();
          });
          void editorModel.requestFocus();
          onCloseModal();
        }}
      />
    </div>
  );
};
