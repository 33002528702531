import CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import { checkFile } from './check-file';
import { uploadInjector } from './init';
import type { UploadFail, UploadSuccess } from './oss/types';
import { TaskPool } from './task';
import type { UploadParams, UploadType } from './types';
import { UploadErrorCode } from './types';
import COS from 'cos-js-sdk-v5';
import { isInChina } from './utils';

const Bucket = 'flowus-1316188996';
const Region = isInChina() ? 'ap-beijing' : 'accelerate';
//flowus-1316188996.cos.accelerate.myqcloud.com

const TASK_POOL_SIZE = 3;
const OSS_DEFAULT_BODY =
  // eslint-disable-next-line no-template-curly-in-string
  // 'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}&imageInfo.height=${imageInfo.height}&imageInfo.width=${imageInfo.width}&imageInfo.format=${imageInfo.format}';
  // eslint-disable-next-line no-template-curly-in-string
  'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}';

/**文档 https://flowus.cn/bde05aa2-a444-432f-b766-e77fca5a7e4b */
/**腾讯COS */
export class UploaderCos {
  private taskPools: Record<UploadType, TaskPool | undefined> = {} as any;

  /**
   * 正在上传的文件有两种情况，一种是正在上传中，一种是在task队列里，取消上传的话需要针对不同情况处理
   * 因此把taskId和uploadId关联，对外只提供uploadId
   * 如果是正在上传中直接用uploader.abort可取消
   * 如果是在队列里则需要通过taskId移除任务
   */
  private uploadIdMappingTaskId = new Map<string, { taskId: string; params: UploadParams }>();
  /**这个是已经在上传的 */
  private uploadIdMappingCos = new Map<string, { taskId: string; cos: COS }>();

  constructor() {}

  private getTaskPool(type: UploadType) {
    if (!this.taskPools[type]) {
      this.taskPools[type] = new TaskPool(TASK_POOL_SIZE);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.taskPools[type]!;
  }

  private async checkFile(params: Pick<UploadParams, 'file' | 'onComplete'>) {
    const fileCheck = await checkFile(params.file);
    if (fileCheck.error) {
      params.onComplete?.({
        success: false,
        errCode: UploadErrorCode.UPLOAD_FAILED,
        errMsg: fileCheck.msg,
      });
    }
    return fileCheck.error;
  }

  private async originSHA256(file: File) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  private async streamSHA256(file: File) {
    const reader = file.stream().getReader();
    const SHA256 = CryptoJS.algo.SHA256.create(); // 创建SHA256实例

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const wordArray = CryptoJS.lib.WordArray.create(value);
      SHA256.update(wordArray);
    }

    const hash = SHA256.finalize();
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    return hashHex;
  }

  private async calculateSHA256(file: File) {
    try {
      // 这里不加 await 无法捕获报错
      return await this.originSHA256(file);
    } catch (err) {
      return this.streamSHA256(file);
    }
  }

  upload(params: UploadParams) {
    const taskPool = this.getTaskPool(params.type);
    const uploadId = uuid();
    const taskId = taskPool.enqueueTask({
      run: async () => {
        if (await this.checkFile(params)) {
          return;
        }

        let cancel = false;

        const timer = setTimeout(() => {
          cancel = true;
          //空间容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.GET_TOKEN_FAILED,
            errMsg: '您的设备网络超时，请刷新后重试',
          });
        }, 5000);
        const speicalSymbol = ['\n', '&'];
        let fileName = params.file.name;
        speicalSymbol.forEach((v) => {
          fileName = fileName.replaceAll(v, '');
        });
        const res = await uploadInjector.request.infra.getTcFileUploadInfo.raw({
          fileName,
          spaceId: params.spaceId,
          type: params.type,
          size: params.file.size,
          dataBaseSingleFileMaxSize: params?.isDatabase,
        });

        clearTimeout(timer);

        if (cancel) return;

        if (res.code === 200) {
          const sha256 = await this.calculateSHA256(params.file);

          const resource = await uploadInjector.request.infra.searchResource({
            sha256,
            size: params.file.size,
          });

          if (resource.ossName) {
            return {
              success: true,
              ossName: resource.ossName,
            } as UploadSuccess;
          }

          params.onCapacitySuccess?.();
          const uploadInfo = res.data;
          let body = `sha256=${sha256}&fileName=${fileName}&spaceId=${params.spaceId}&userId=${params.userId}&type=${params.type}&fileSecret=${uploadInfo.fileSecret}`;
          if (params.searchType) {
            body = `${body}&recentlyImgType=${params.searchType}`;
          }
          //开始上传就需要把task队列关联去掉
          this.uploadIdMappingTaskId.delete(uploadId);
          const cos = new COS({
            SecretId: uploadInfo.token.accessKeyId,
            SecretKey: uploadInfo.token.accessKeySecret,
            SecurityToken: uploadInfo.token.securityToken,
          });
          if (uploadInjector.ossCallbackHost.includes('localhost')) {
            console.log('ossCallbackHost传错了，请检查环境变量是否配错了');
          }
          const callbackString = {
            callbackUrl: `${uploadInjector.ossCallbackHost}/api/upload/v3/callback`,
            callbackBody: `${body.toString()}&${OSS_DEFAULT_BODY}`,
            callbackBodyType: 'application/x-www-form-urlencoded',
          };
          try {
            const ret = await cos.uploadFile({
              Bucket,
              Region,
              SliceSize: 1024 * 1024 * 5, //5M
              Key: uploadInfo.ossName,
              Body: params.file,
              Headers: {
                'x-cos-callback': COS.util.encodeBase64(JSON.stringify(callbackString)),
              },
              onProgress: (p) => {
                params.onProgress?.(p.percent * 100);
              },
              onTaskReady: (taskId: string) => {
                this.uploadIdMappingCos.set(uploadId, { cos, taskId });
              },
            });
            if (ret.statusCode === 200) {
              return {
                success: true,
                ossName: uploadInfo.ossName,
                //@ts-ignore nocheck
                url: ret?.CallbackBody?.url,
              } as UploadSuccess;
            }
            return {
              success: false,
              errCode: UploadErrorCode.UPLOAD_FAILED,
              errMsg: `上传失败[${ret.statusCode}]`,
            } as UploadFail;
          } finally {
            this.uploadIdMappingCos.delete(uploadId);
          }
        }

        //@ts-ignore disable warning
        if (res.code === 2103) {
          //空间容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT,
            //@ts-ignore disable warning
            errMsg: res.msg,
          });
          return;
        }
        //@ts-ignore disable warning
        if (res.code === 2104) {
          //单文件容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT,
            //@ts-ignore disable warning
            errMsg: res.msg,
          });
          return;
        }
        //@ts-ignore disable warning
        if (res !== 200) {
          //@ts-ignore disable warning
          throw Error(res.msg);
        }
      },
      onFailed: (err: Error) => {
        if (err.name === 'cancel') {
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_CANCEL,
            errMsg: `上传取消`,
          });
        } else {
          // eslint-disable-next-line no-console
          console.warn(err);
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_FAILED,
            errMsg: `上传失败[${err.message}]`,
          });
        }
      },
      onSuccess: (res) => {
        res && params.onComplete?.(res);
      },
    });
    this.uploadIdMappingTaskId.set(uploadId, { taskId, params });
    return uploadId;
  }

  private generateSavePath = (prefix: string, fileName: string) => {
    //存储路径生成规则:https://www.notion.so/286dcf5294c0440e92250cefd490244a#b28c0cc9d94247e3994be2e8e2904681
    const name = `${uuid()}/${fileName}`;
    const ossSavePath = `${prefix}${name}`;
    return ossSavePath;
  };

  /**
   * 收集表未登录情况下沿用之前的上传逻辑，为了减少代码的复杂度，特意分开处理，请不要合并这两个上传方法的代码，免得不好维护
   * 由于没有取消的场景，因此取消相关逻辑没有添加上去
   * @param singleFileMaxCapacity 单文件容量大小
   * @returns
   */
  uploadWithoutLogin(
    params: Omit<UploadParams, 'userId'> & { viewId: string; singleFileMaxCapacity: number }
  ) {
    const taskPool = this.getTaskPool(params.type);
    const uploadId = uuid();
    taskPool?.enqueueTask({
      run: async () => {
        if (await this.checkFile(params)) {
          return;
        }

        const res = await uploadInjector.request.infra.getTcUnsignedInfo.raw({
          spaceId: params.spaceId,
          viewId: params.viewId,
          //@ts-ignore why idl没这个字段
          dataBaseSingleFileMaxSize: true,
        });

        //检查容量
        if (params.file.size > params.singleFileMaxCapacity) {
          //单文件容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT,
            errMsg: '单文件大小超出限制',
          });
          return;
        }

        if (res.code !== 200) {
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT,
            //@ts-ignore disable warning
            errMsg: res.msg,
          });
          return;
        }

        if (res.data.currentCapacity + params.file.size > res.data.maxCapacity) {
          //空间容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT,
            errMsg: '对方空间容量超限，请联系管理员升级空间',
          });
          return;
        }

        const sha256 = await this.calculateSHA256(params.file);

        const resource = await uploadInjector.request.infra.searchResource({
          sha256,
          size: params.file.size,
        });

        if (resource.ossName) {
          return {
            success: true,
            ossName: resource.ossName,
          } as UploadSuccess;
        }

        const fileName = params.file.name.replaceAll('\n', '');
        if (res.code === 200) {
          params.onCapacitySuccess?.();
          const savePath = this.generateSavePath(res.data.token.prefix, fileName);
          const uploadInfo = res.data;
          const body = `sha256=${sha256}&saveName=${savePath}&fileName=${fileName}&spaceId=${params.spaceId}&type=${params.type}`;
          this.uploadIdMappingTaskId.delete(uploadId);
          const cos = new COS({
            SecretId: uploadInfo.token.accessKeyId,
            SecretKey: uploadInfo.token.accessKeySecret,
            SecurityToken: uploadInfo.token.securityToken,
          });
          const callbackString = {
            callbackUrl: `${uploadInjector.ossCallbackHost}/api/upload/v2/callback`,
            callbackBody: `${body.toString()}&${OSS_DEFAULT_BODY}`,
          };
          const promiseRet = cos.uploadFile({
            Bucket,
            Region,
            Headers: {
              'x-cos-callback': COS.util.encodeBase64(JSON.stringify(callbackString)),
            },
            Key: savePath,
            Body: params.file,
            onProgress: (p) => {
              params.onProgress?.(p.percent * 100);
            },
            onTaskReady: () => {},
          }) as Promise<COS.UploadFileResult>;
          const ret = await promiseRet;
          if (ret.statusCode === 200) {
            return {
              success: true,
              ossName: savePath,
              //@ts-ignore nocheck
              url: ret?.CallbackBody?.url,
            } as UploadSuccess;
          }
          return {
            success: false,
            errCode: UploadErrorCode.UPLOAD_FAILED,
            errMsg: `上传失败[${ret.statusCode}]`,
          } as UploadFail;
        }

        //@ts-ignore disable warning
        if (res !== 200) {
          //@ts-ignore disable warning
          throw Error(res.msg);
        }
      },
      onFailed: (err: any) => {
        // eslint-disable-next-line no-console
        if (err.name === 'cancel') {
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_CANCEL,
            errMsg: `上传取消`,
          });
        } else {
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_FAILED,
            errMsg: `上传失败[${JSON.stringify(err)}]`,
          });
        }
      },
      onSuccess: (res) => {
        res && params.onComplete?.(res);
      },
    });
    return uploadId;
  }

  abort(uploadId: string) {
    const task = this.uploadIdMappingTaskId.get(uploadId);
    if (task) {
      //去掉回调
      const removed = this.getTaskPool(task.params.type).removeTask(task.taskId);
      removed &&
        task.params.onComplete({
          success: false,
          errCode: UploadErrorCode.UPLOAD_CANCEL,
          errMsg: '已取消',
        });
    } else {
      const cosInfo = this.uploadIdMappingCos.get(uploadId);
      if (cosInfo) {
        this.uploadIdMappingCos.delete(uploadId);
        const { taskId, cos } = cosInfo;
        cos.cancelTask(taskId);
      }
    }
  }
}
