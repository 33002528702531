import type { FC } from 'react';
import { Button } from 'src/common/components/button';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { Images } from 'src/image';

const TeamWechatQr: FC<ModalSchema.CloseModal> = ({ onCloseModal }) => {
  return (
    <div className="flex flex-col items-center py-10 px-20">
      <div className="text-h3 mb-5">恭喜升级成功！</div>
      <div className="text-t1 mb-2.5">添加你的客户经理获取发票和合同</div>
      <img src={Images.teamWechatQr} className="mb-7 w-60 h-60" />
      <Button colorType="active" className="w-full" onClick={() => onCloseModal()}>
        确定
      </Button>
    </div>
  );
};

export const useOpenTeamWechatQr = () => {
  const openModal = useOpenModal();
  return () => {
    openModal.modal({
      modalId: 'paySuccess',
      content: ({ onCloseModal }) => (
        <div>
          <TeamWechatQr onCloseModal={onCloseModal} />
        </div>
      ),
    });
  };
};
