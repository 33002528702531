import { PermissionRole } from '@next-space/fe-api-idl';
import { useMemo } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Icon } from 'src/common/components/icon';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useCloseModal } from 'src/common/components/next-modal';
import { getUrlWithoutDomain } from 'src/common/utils/url-utils';
import { useGetSharePageUrl } from 'src/components/share/helper';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentUser, useLogout } from 'src/hooks/user';
import { Modals } from 'src/modals';
import { SearchParams, ViewPath } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isPageLike } from 'src/utils/block-type-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { locationToUrl } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { useGetQuery } from '../page/use-get-query';
import { useIsNeedPay, usePageMeta } from '../page/use-page-meta';
import { useOfficialSpaceShare } from '../public/use-official-space-share';
import { getUserInfoByApi } from '../user/get-user';
import { getUserName } from '../user/use-remark-name';
import { canCopyToSpace, useCopyToSpace } from './use-copy-to-space';
import { useIsOwner } from './use-owner';
import { useReportIllegal } from './use-report-illegal';

const IS_MOBILE = window.innerWidth <= 640;

export const useGetShareHeaderMenuItems = (uuid: string) => {
  const pageId = useGetPageId();
  const block = usePickBlock(uuid, ['data', 'createdBy'], ['segments']);
  const { allowDuplicate, shared, roleWithoutPublic, illegal } = usePermissions(uuid);
  const currentUser = useCurrentUser();
  const logout = useLogout();
  const isSharePage = judgeSharePage();
  const closeModal = useCloseModal();
  const report = useReportIllegal(uuid);
  const copyToSpace = useCopyToSpace();
  const isOwner = useIsOwner();
  const pageMeta = usePageMeta(pageId);
  const getQuery = useGetQuery(location.search);
  const getSharePageUrl = useGetSharePageUrl(uuid);

  // 特殊空间
  const officialSpaceShare = useOfficialSpaceShare(pageId);
  const isHiddenReport =
    isOwner ||
    pageMeta?.tag === 'NO_PERMISSION' ||
    pageMeta?.tag === 'NOT_FOUND' ||
    illegal ||
    officialSpaceShare ||
    __PRIVATE__;
  const isNeedPay = useIsNeedPay(pageId);
  const isLogin = Boolean(currentUser.uuid);

  return useMemo(() => {
    if (!isSharePage || !block) return [];

    const isOwner = Boolean(roleWithoutPublic !== PermissionRole.NONE && isPageLike(block.type));

    const tempItems: ListItem[] = [
      {
        type: ListItemType.CUSTOM_RENDER,
        render: () => {
          if (isLogin) {
            return (
              <div className="mx-2 flex h-10 items-center justify-between px-2">
                <div className="flex w-3/5 items-center">
                  <Avatar
                    className="!text-t4 mr-2 h-5 w-5 flex-shrink-0 rounded-full text-white"
                    name={getUserName(currentUser.uuid, currentUser.nickname)}
                    color={currentUser.backgroundColor}
                    icon={{ type: 'upload', value: currentUser.avatar }}
                    imgClassName="rounded-full"
                  />
                  <span className="text-grey3 text-t2 text-ellipsis">{currentUser.nickname}</span>
                </div>
                {IS_MOBILE ? null : (
                  <div
                    className="text-active_color text-t4-bold flex-shrink-0"
                    onClick={() => {
                      closeModal(Modals.PAGE_HEADER_MENU_LIST);
                      locationToUrl(getUrlWithoutDomain());
                    }}
                  >
                    回到我的空间
                  </div>
                )}
              </div>
            );
          }
          return (
            <div
              className="mx-2 flex h-10 cursor-pointer items-center px-2"
              onClick={async () => {
                const inviteCode = getQuery(SearchParams.inviteCode);
                const spaceId = getQuery(SearchParams.spaceId);
                closeModal(Modals.PAGE_HEADER_MENU_LIST);
                if (inviteCode && spaceId) {
                  locationToUrl(
                    splicePathSearchParams(ViewPath.login, [
                      {
                        key: SearchParams.callbackPath,
                        value: location.pathname,
                      },
                      {
                        key: SearchParams.inviteCode,
                        value: inviteCode,
                      },
                      {
                        key: SearchParams.spaceId,
                        value: spaceId,
                      },
                    ])
                  );
                  return;
                }
                if (block.createdBy) {
                  const user = await getUserInfoByApi(block.createdBy);
                  locationToUrl(
                    splicePathSearchParams(ViewPath.login, [
                      {
                        key: SearchParams.callbackPath,
                        value: location.pathname,
                      },
                      {
                        key: SearchParams.inviteCode,
                        value: `${user?.inviteCode}`,
                      },
                    ])
                  );
                  return;
                }
                locationToUrl(
                  splicePathSearchParams(ViewPath.login, [
                    {
                      key: SearchParams.callbackPath,
                      value: location.pathname,
                    },
                  ])
                );
              }}
            >
              <Icon size="middle" name="MIcSettingsAccount" className="mr-2"></Icon>
              <span className="text-t2 text-grey3">登录/注册</span>
            </div>
          );
        },
      },
    ];

    if (isLogin && isOwner) {
      tempItems.push(
        {
          type: ListItemType.LINE,
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcEdit',
            title: '进入空间编辑/查看',
            onClick: () => {
              locationToUrl(`${getUrlWithoutDomain()}/${pageId}`);
            },
          },
        }
      );
    }

    if (!illegal) {
      tempItems.push(
        {
          type: ListItemType.LINE,
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcLink',
            title: '复制分享链接',
            onClick: () => {
              bizTracker.event('share_copylink', { is_share: shared });
              const { textAndUrl } = getSharePageUrl();
              void writeTextInClipboard(textAndUrl);
            },
          },
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcGlobeShare',
            title: '复制内嵌模式链接',
            onClick: () => {
              bizTracker.event('share_copylink', { is_share: shared });
              const { textAndUrl } = getSharePageUrl({ embed: true });
              void writeTextInClipboard(textAndUrl);
            },
          },
        }
      );

      if (canCopyToSpace(uuid) && !__PRIVATE__ && !isNeedPay) {
        tempItems.push({
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcDuplicate',
            title: '拷贝到我的空间',
            onClick: () => {
              bizTracker.event('copytomyspace', {
                page_id: uuid,
              });
              void copyToSpace(uuid, { addCopyLimit: true });
            },
          },
        });
      }

      // 特殊空间不展示举报
      if (!isHiddenReport && !__PRIVATE__) {
        tempItems.push(
          {
            type: ListItemType.LINE,
            disableClick: true,
          },
          {
            type: ListItemType.OPERATION,
            data: {
              icon: 'IcMenuReport',
              title: '举报违法信息',
              onClick: report,
            },
          }
        );
      }
    }

    if (isLogin) {
      tempItems.push(
        {
          type: ListItemType.LINE,
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'MIcSettingsLogout',
            title: '退出登录',
            onClick: () => {
              bizTracker.logout();
              void logout();
            },
          },
        }
      );
    }

    if (!illegal && allowDuplicate) {
      tempItems.push(
        {
          type: ListItemType.LINE,
          disableClick: true,
        },
        {
          type: ListItemType.CUSTOM_RENDER,
          disableCustomHoverBg: true,
          render() {
            return (
              <div className="text-grey3 mx-4 my-2 flex items-center">
                <Icon name="IcShareDanger" size="small" className="mr-1" />
                <span className="text-t4">允许拷贝副本、下载和打印</span>
              </div>
            );
          },
        }
      );
    }

    return tempItems;
  }, [
    isSharePage,
    block,
    roleWithoutPublic,
    isLogin,
    illegal,
    allowDuplicate,
    currentUser.uuid,
    currentUser.nickname,
    currentUser.backgroundColor,
    currentUser.avatar,
    closeModal,
    getQuery,
    pageId,
    uuid,
    isNeedPay,
    isHiddenReport,
    shared,
    getSharePageUrl,
    copyToSpace,
    report,
    logout,
  ]);
};
