import { SMART_TEAM_MAX_PEOPLE } from '@flowus/common/const';
import { useMemo } from 'react';
import { request } from 'src/common/request';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import type { AppUiStateType } from '..';
import { $appUiStateCache, setAppUiState, useAppUiState, useGuestsList } from '..';
import { getSpacePlanInfo, useSpacePlanInfo } from '../../capacity/space-plans';

export const useSubscriptionDataUsersById = (id: string) => {
  return useAppUiState((state) => state.$subscriptionData[id]?.users ?? []);
};

// set
export const addSubscriptionData = ({
  spaceId,
  data,
}: {
  spaceId: string;
  data: Awaited<ReturnType<typeof request.infra.getSpaceSubscriptionData>>;
}) => {
  setAppUiState({
    $subscriptionData: { ...$appUiStateCache.$subscriptionData, ...{ [spaceId]: data } },
  });
};

export const useSubscriptionData = (spaceId: string) => {
  return useAppUiState((state) => state.$subscriptionData[spaceId]);
};

/** 获取空间的订阅数据 */
export const fetchSubscriptionData = async (spaceId = getCurrentSpaceId()) => {
  const data = await request.infra.getSpaceSubscriptionData(spaceId);
  addSubscriptionData({ spaceId, data });
  return data;
};

/** 获取空间的限制配置 use */
export const useLimitConfig = (spaceId = getCurrentSpaceId()) => {
  const subscriptionData = useSubscriptionData(spaceId);
  const spacePlanInfo = useSpacePlanInfo(spaceId);
  const guests = useGuestsList();

  return useMemo(() => {
    return getLimitConfig(spaceId, {
      subscriptionData,
      spacePlanInfo,
      guests,
    });
  }, [spaceId, spacePlanInfo, subscriptionData, guests]);
};

/** 默认的块数量限制 */
const limitDefaultValue = {
  maxBlockLimit: -1,
  blockUsage: 0,
  mindMapMaxLimit: -1,
  guestMaxLimit: -1,
};

const formatInfinity = (value: number) => {
  if (value === -1) {
    return Infinity;
  }
  return value;
};

/** 获取空间的限制配置 get */
export const getLimitConfig = (
  spaceId = getCurrentSpaceId(),
  opt?: {
    subscriptionData?: ReturnType<typeof useSubscriptionData>;
    spacePlanInfo?: ReturnType<typeof getSpacePlanInfo>;
    guests?: AppUiStateType['$guestsList'];
  }
) => {
  const {
    subscriptionData = $appUiStateCache.$subscriptionData[spaceId],
    spacePlanInfo = getSpacePlanInfo(spaceId),
  } = opt || {};

  const currentPlan = spacePlanInfo.currentPlan || spacePlanInfo.currentSpacePlanConfig;

  // 最大块数量
  const blocksMaxLimit = formatInfinity(currentPlan?.block.max ?? limitDefaultValue.maxBlockLimit);

  // 已使用的块数量
  const blocksUsage = subscriptionData?.blockUsage ?? limitDefaultValue.blockUsage;

  // 最大导图节点数量
  const mindMapMaxLimit = formatInfinity(
    currentPlan?.mapNodes.max ?? limitDefaultValue.mindMapMaxLimit
  );

  // 最大外部协作数量
  const guestMaxLimit = formatInfinity(currentPlan?.guest?.max ?? limitDefaultValue.guestMaxLimit);

  // 最大成员数量
  const membersMaxLimit = currentPlan?.members.max || SMART_TEAM_MAX_PEOPLE;

  // const isProSpace = getIsProSpace(spaceId);
  // const isMaxGuests = isProSpace ? false : opt?.guests?.length ?? guestMaxLimit <= 0;

  const guestMaxLimitDesc = guestMaxLimit === Infinity ? '无限' : guestMaxLimit;
  const memberMaxLimitDesc = membersMaxLimit === 999 ? '无限' : membersMaxLimit;

  const isMaxGuests = (opt?.guests?.length ?? 0) >= guestMaxLimit;

  return {
    /** 文案 */
    guestMaxLimitDesc,
    /** 最大块数量 */
    blocksMaxLimit,
    /** 已使用的块数量 */
    blocksUsage,
    /** 剩余块数量 */
    blocksStock: Math.max(blocksMaxLimit - blocksUsage, 0),
    /** 是否超过块数量限制 */
    blocksIsMax: blocksUsage >= blocksMaxLimit,
    /** 最大导图节点数量 */
    mindMapMaxLimit,
    /** 最大外部协作数量 */
    guestMaxLimit,
    /** 最大成员数量 */
    membersMaxLimit,
    /** 是否已经最大外部协作者 */
    isMaxGuests,
    /** 最大成员文案 */
    memberMaxLimitDesc,
  };
};
