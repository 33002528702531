import { PermissionRole } from '@next-space/fe-api-idl';
import type { ComponentProps, FC } from 'react';
import React, { useEffect, useState } from 'react';
import { colors } from 'src/colors';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { updateBlock } from 'src/redux/managers/block/update';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';

interface Props {
  uuid: string;
}
export const LockButton: FC<Props> = (props) => {
  const { uuid } = props;
  const transaction = useTransaction();
  const { role } = usePermissions(uuid);
  const locked = useObservableBlock(uuid, (state) => state?.data.format?.locked);
  /** relocked的展示逻辑 跟notion一样，*/
  const [showReLocked, setShowReLock] = useState(false);

  useEffect(() => {
    if (uuid) {
      setShowReLock(false);
    }
  }, [uuid]);

  if (role === PermissionRole.READER) return null;

  if (locked) {
    return (
      <Lock
        uuid={uuid}
        onClick={() => {
          bizTracker.event('page_lock', {
            is_open: false,
          });
          transaction(() => {
            updateBlock(uuid, {
              data: {
                format: {
                  locked: false,
                  lockedBy: $currentUserCache.uuid,
                },
              },
            });
          });
          if (!showReLocked) {
            setShowReLock(true);
          }
        }}
      />
    );
  }
  if (showReLocked) {
    return (
      <ReLock
        uuid={uuid}
        onClick={() => {
          bizTracker.event('page_lock', {
            is_open: true,
          });
          transaction(() => {
            updateBlock(uuid, {
              data: {
                format: {
                  locked: true,
                  lockedBy: $currentUserCache.uuid,
                },
              },
            });
          });
          setShowReLock(false);
        }}
      />
    );
  }
  return null;
};

interface LockProps extends Pick<ComponentProps<'div'>, 'onClick'> {
  uuid: string;
}

const UserTip: FC<{ uuid: string }> = React.memo((props) => {
  const lockedBy = useObservableBlock(props.uuid, (state) => state?.data.format?.lockedBy);
  const userName = useUserName(lockedBy ?? '', '');
  return (
    <div className="flex rounded px-2 py-1 flex-col items-center">
      {userName ? <div>{`已被${userName}锁定`}</div> : <div>{`页面已被锁定`}</div>}
      <div>点击解锁</div>
    </div>
  );
});

export const Lock: FC<LockProps> = (props) => {
  return (
    <Tooltip popup={<UserTip uuid={props.uuid} />}>
      <div
        className="cursor-pointer flex hover:animate-hover py-1 px-2 items-center rounded h-7.5 text-ellipsis"
        {...props}
      >
        <Icon size="normal" name={'IcLock'} style={{ color: 'var(--grey4)' }} />
        <div className="text-t2 text-grey4 ml-0.5 sm:hidden">已锁定</div>
      </div>
    </Tooltip>
  );
};
export const ReLock: FC<LockProps> = (props) => {
  return (
    <Tooltip popup={'点击重新锁定页面'}>
      <div
        className="cursor-pointer flex border-active_color border hover:opacity-90 py-1 px-2 items-center rounded h-7.5 text-ellipsis"
        {...props}
      >
        <Icon size="normal" name={'IcUnlock'} style={{ color: colors.active_color }} />
        <div className="text-t2 text-active_color ml-0.5 sm:hidden">重新锁定</div>
      </div>
    </Tooltip>
  );
};
