import { escape, first } from 'lodash-es';
import isURL from 'validator/lib/isURL';
import { URL_SPLIT_TEXT, X_OSS_PROCESS } from './const';
import { decodeName } from './utils';

export const Regex = {
  Email:
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/,
  Mobile: /^1[3456789]\d{9}$/,
  Password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+-~|\\}\\{\\"\\:\\?\\>\\<,.;'\\[\]]{6,16}$/,
  VerifyCode: /^[0-9]{6}$/,
};

export const isOssUrl = (s?: string) => {
  const hostList = location.host.split('.');

  if (/allflow|buildin|flowus/.test(`${s}`)) return true;

  if (hostList.length === 3) {
    const [_, name, domain] = hostList;
    return s?.includes(`${name}.${domain}`);
  }

  return s?.includes(location.host);
};

export const getFormatUrl = (url?: string) => {
  if (!url) return undefined;
  try {
    return new URL(url);
  } catch {
    return undefined;
  }
};

export const getOssName = (str?: string) => {
  if (!str) return '';
  if (!isOssUrl(str) || !isURL(str)) return '';
  try {
    str = decodeName(str);
    const url = new URL(str);
    if (url.pathname.startsWith('/oss/') || url.pathname.startsWith('/oss-dev/')) {
      return url.pathname.substring(1);
    }

    return '';
  } catch {
    return '';
  }
};

export const isInAlipay = navigator.userAgent.toLowerCase().indexOf('alipayclient') !== -1;
export const isInWechat = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;

export const base64ToBlob = (base64Data: string) => {
  const byteString = atob(`${base64Data.split(',')[1]}`);
  const mimeString = base64Data.split(',')[0]?.split(':')[1]?.split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

/** 是否需要被编码 */
export const shouldEncodeUrl = (url: string) => {
  // 检查是否存在HTML和代码特殊字符
  if (escape(url) !== url) {
    return true;
  }

  // 检查是否存在语法错误
  try {
    const _test = new URL(url);
  } catch {
    return false;
  }

  if ('%'.includes(url)) {
    return false;
  }

  // 检查是否包含其他语言字符
  if (/[\u0080-\uFFFF]/.test(url)) {
    return true;
  }

  return false;
};

export const ossVideoSnapshotUrl = (url = '', w = 800) => {
  return addOssProcessParams(url, `video/snapshot,t_1,f_jpg,w_${w}`);
};

export const ossImageSnapshotUrl = (url = '', w = 640, q = 80) => {
  return addOssProcessParams(url, `image/resize,w_${w}/quality,q_${q}`);
};

//由于用的oss-process的参数，因此这里的水印用的还是阿里云的参数，腾讯会兼容
export const addImageWatermark = (url: string, watermark: string) => {
  let text = 'watermark';
  try {
    text = btoa(watermark);
  } catch {
    text = '';
  }

  return addOssProcessParams(url, `image/watermark,text_${text}`);
};

export const addOssProcessParams = (url: string, params: string) => {
  if (!url) {
    return url;
  }
  //去掉首尾的'/'
  if (params && params.startsWith('/')) {
    params = params.slice(1, params.length);
  }
  if (params && params.endsWith('/') && params.length > 2) {
    params = params.slice(0, params.length - 2);
  }
  if (url && url.includes(X_OSS_PROCESS)) {
    //如果已经存在就加到参数里
    const mUrl = new URL(url);
    const processParams = mUrl.searchParams.get(X_OSS_PROCESS);
    if (processParams) {
      const ret = `${processParams}/${params}`;
      mUrl.searchParams.set(X_OSS_PROCESS, ret);
      return mUrl.toString();
    }
  }
  return `${url}&${X_OSS_PROCESS}=${params}`;
};

/** 解决链接后面带的分割字符串问题，有些浏览器会把空格encode导致无法取到参数 */
export const normalizeUrl = (replace?: (url: string) => void) => {
  const _url = decodeName(window.location.href);
  if (_url.includes(URL_SPLIT_TEXT)) {
    const [url] = _url.split(URL_SPLIT_TEXT);
    if (url) {
      if (replace) {
        replace(url.trim());
      } else {
        window.location.replace(url.trim());
      }
      return true;
    }
  }

  if (_url.includes(' ')) {
    const url = first(_url.split(' '));
    if (url) {
      if (replace) {
        replace(url);
      } else {
        window.location.replace(url.trim());
      }

      return true;
    }
  }
};

/** 清理 url 里所有的参数，并保持页面不刷新 */
export const cleanUrlSearchParams = (keys: string[]) => {
  // 当前 URL
  const currentUrl = window.location.href;

  // 使用 URL 对象解析当前的 URL
  const url = new URL(currentUrl);

  // 移除查询参数
  // url.search = '';
  keys.forEach((k) => {
    url.searchParams.delete(k);
  });

  // 更新浏览器的 URL
  history.replaceState({}, '', url.toString());
};
