import { cx } from '@flowus/common/cx';
import { isInWechat } from '@flowus/common/url';
import { PayType } from '@flowus/common/utils/pay-info';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { message } from 'src/common/components/message';
import { publicLink } from 'src/common/const';
import { getDateUnit } from 'src/components/pay-cycle-button';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useGetPriceAndUnit } from 'src/views/main/setting-modal/upgrade-setting/upgrade-setting-content';
import { TransitionNumber } from '../../transition-number';
import type { SelectUpgradePlanProps } from '../common';
import { UpgradeCouponList, UpgradeLabel, UpgradePaddingUiKit } from '../common';
import { CustomNumberInput } from '../hook/use-upgrade-support';
import { UpgradeComponents } from '../upgrade-components';
import {
  RenewalType,
  UpgradeTeamPlanContext,
  useOpenChangePeopleModal,
  useUpgradeTeamPlanContext,
} from './hook';

// #region 壳子
export const UpgradeTeamPlan: FC<SelectUpgradePlanProps> = (props) => {
  return (
    <UpgradeTeamPlanContext {...props}>
      <UpgradeTeamPlanBuildInContent />
    </UpgradeTeamPlanContext>
  );
};
// #endregion

// #region FlowUs
// #region 核心
// export const UpgradeTeamPlanContent: FC = memo(() => {
//   const {
//     state,
//     patchState,
//     calculateQuote,
//     completionTime,
//     loading,
//     getOrderParams,
//     payOrder,
//     couponList,
//     upgradeTitle,
//     close,
//     currentCoupon,
//   } = useUpgradeTeamPlanContext();

//   return (
//     <>
//       {/* 用户信息 */}
//       <UpgradeComponents.Header close={close} />
//       <UpgradeComponents.BODY className={cx('px-8', loading && 'opacity-60')}>
//         <UpgradePaddingUiKit height={54} className="text-h2">
//           {upgradeTitle}
//         </UpgradePaddingUiKit>
//         {/* 付费内容规格 */}
//         <PlanList />
//         {/* 优惠券 */}
//         <UpgradeCouponList
//           onSelectId={(id) => patchState('couponId', id)}
//           couponList={couponList}
//           selectId={state.couponId}
//           className={'my-2.5'}
//         />
//         {/* 付费方式 */}
//         <SelectPay />
//       </UpgradeComponents.BODY>
//       <UpgradeComponents.PayBar
//         price={calculateQuote?.amount}
//         priceUnit={calculateQuote?.amountUnit}
//         disablePay={loading}
//         onPay={payOrder}
//         priceAfter={
//           <Tooltip
//             className="ml-1.5 text-grey4 inline"
//             placement="bottom"
//             maxWidth="none"
//             interactive
//             popup={
//               <div>
//                 <div>{'计算方式'}</div>
//                 <div>
//                   {'总价 = 人数 * 购买时长 * 当前版本每人价格'}
//                   <span hidden={!currentCoupon}> + {'优惠券'}</span>
//                 </div>
//                 <div>
//                   <span>{getCurrencySymbols(calculateQuote?.amountUnit)}</span>
//                   <span>{calculateQuote?.amount}&nbsp;=&nbsp;</span>
//                   <span>
//                     {getOrderParams().addPeople}
//                     {'人'}&nbsp;*&nbsp;
//                   </span>
//                   <span>
//                     {state.selectedRenewalType === RenewalType.time ? (
//                       <>
//                         {getOrderParams().date}
//                         {'年'}
//                       </>
//                     ) : (
//                       <>
//                         {!!completionTime.year && (
//                           <>
//                             {completionTime.year}
//                             {'年'}
//                           </>
//                         )}
//                         {!!completionTime.day && (
//                           <>
//                             {completionTime.day}
//                             {'天'}
//                           </>
//                         )}
//                       </>
//                     )}
//                     &nbsp;*&nbsp;
//                   </span>
//                   <span>
//                     {getCurrencySymbols(calculateQuote?.amountUnit)}
//                     {
//                       getOrderParams({ ...state, selectedRenewalType: RenewalType.time })
//                         .currentPlan?.currentPrice
//                     }
//                     {'/人/年'}
//                   </span>
//                   <span hidden={!currentCoupon}> + {currentCoupon?.name}</span>
//                 </div>
//               </div>
//             }
//           >
//             <Icon name="IcInviteHelp" size="middle" />
//           </Tooltip>
//         }
//       />
//     </>
//   );
// });
// #endregion

// #region 付费规格选项
const PlanList: FC = memo(() => {
  const { isRenew, isNeedPayDiff } = useUpgradeTeamPlanContext();

  if (isNeedPayDiff) {
    return (
      <>
        <div className="text-t2 text-grey3 space-y-1">
          <div>正在升级套餐计划，请支付订单完成版本转换</div>
          <div>订单完成后，您可以选择增加人数或者续费有效期</div>
        </div>
        <SelectPayPeople readonly />
      </>
    );
  }

  /** 续费 -> 付费团队 */
  if (isRenew) {
    return <SelectPlanType />;
  }

  /** 升级 -> 付费团队 */

  return (
    <>
      <SelectPayYear />
      <SelectPayPeople />
    </>
  );
});
// #endregion

// #region 组件库

/** 购买时长 */
const SelectPayYear: FC = memo(() => {
  const { state, patchState, calculateQuote, planList } = useUpgradeTeamPlanContext();

  const fixedPayTime = useMemo(
    () => (
      <div className="grid text-center grid-cols-3 w-full gap-2.5">
        {[
          { title: '3 年', value: 36 },
          { title: '每年', value: 12 },
          { title: '每月', value: 1 },
        ].map((item, index) => {
          const active = state.selectedTime.value === item.value;
          const plan = planList.find((i) => i.monthNum === item.value);

          // @ts-ignore await
          const curLabel = plan?.label;
          if (!plan) return null;
          return (
            <div
              key={index}
              className={cx(
                'flex items-center px-2 py-2.5 justify-center animate-click border border-grey5 rounded relative',
                active && 'border-black'
              )}
              onClick={() =>
                patchState('selectedTime', {
                  type: 'fixed',
                  value: item.value,
                })
              }
            >
              {item.title}
              {curLabel && <UpgradeLabel>{curLabel}</UpgradeLabel>}
            </div>
          );
        })}
      </div>
    ),
    [patchState, planList, state.selectedTime.value]
  );
  return (
    <>
      <div className="text-t2 flex h-9 items-center">购买时长</div>
      <div className={'text-t2 py-2.5'}>{fixedPayTime}</div>
      <div className="text-t2 py-2 text-grey3">
        {'购买后有效期至'}
        {dayjs(calculateQuote?.desiredExpireTime).format('YYYY年MM月DD日')}
      </div>
    </>
  );
});

/** 购买人数 */
const SelectPayPeople: FC<{ readonly?: boolean }> = memo((props) => {
  const { readonly } = props;
  const { state, patchState, spaceMinPeople, inputCustomPeopleTimer, spaceMaxPeople } =
    useUpgradeTeamPlanContext();
  const isProSpace = useIsProSpace();
  const openChangePeopleModal = useOpenChangePeopleModal();

  return (
    <>
      <div className="text-t2 mt-2.5 flex h-9 items-center">购买人数</div>
      <div className={'py-2.5 pr-4 w-full'}>
        <CustomNumberInput
          showBorder
          readonly={readonly}
          className={cx(
            'border-[1.5px] border-grey5',
            readonly && 'pointer-events-none opacity-30'
          )}
          value={state.customPeople}
          afterText={'人'}
          onChange={(num) => {
            patchState('customPeople', Math.min(Math.max(num, 0), 99999));
            inputCustomPeopleTimer.current && clearTimeout(inputCustomPeopleTimer.current);
            inputCustomPeopleTimer.current = setTimeout(() => {
              inputCustomPeopleTimer.current && clearTimeout(inputCustomPeopleTimer.current);
              const minNum = Math.max(num, spaceMinPeople);
              if (minNum > num) {
                message.warning(`当前空间人数为${spaceMinPeople}人，请先移除多余成员`);
              }
              patchState('customPeople', Math.min(minNum, 99999));
            }, 1000);
          }}
        />
      </div>
      <div className="text-t2 py-2 text-grey3">
        <span>{`购买后上限${state.customPeople}人，当前上限${spaceMaxPeople}人。`}</span>
        {isProSpace && (
          <span className="text-active_color animate-click" onClick={openChangePeopleModal}>
            减少人数
          </span>
        )}
      </div>
    </>
  );
});

/** 增加人数 */
const SelectAddPeople: FC = memo(() => {
  const { state, patchState, spaceMaxPeople } = useUpgradeTeamPlanContext();
  return (
    <>
      <div className="text-t2 mt-2.5 flex h-9 items-center">添加人数</div>
      <div className="w-1/2 py-2.5 pr-4">
        <CustomNumberInput
          showBorder
          className="border-[1.5px] border-grey5"
          value={state.addPeople}
          afterText="人"
          onChange={(num) => {
            patchState('addPeople', Math.min(Math.max(num, 1), 99999 - spaceMaxPeople));
          }}
        />
      </div>
      <div className="text-t2 py-2 text-grey3">
        {`购买后上限${spaceMaxPeople + state.addPeople}人，当前上限${spaceMaxPeople}人。`}
      </div>
      <div className="text-t2 mt-2.5 flex h-9 items-center">添加人数</div>
    </>
  );
});

/** 续费类型 */
const SelectPlanType: FC = memo(() => {
  const { state, patchState, completionTime, calculateQuote } = useUpgradeTeamPlanContext();

  return (
    <>
      <div className="text-t2 flex h-9 items-center">购买类型</div>
      <div className="text-t2 grid grid-cols-2 gap-2.5 py-2.5">
        {[
          { type: RenewalType.people, text: '席位' },
          { type: RenewalType.time, text: '时长' },
        ].map((item) => (
          <div
            key={item.type}
            onClick={() => patchState('selectedRenewalType', item.type)}
            className={cx(
              'text-t2-medium animate-hover flex p-2 items-center justify-center border-[1.5px] border-grey5',
              state.selectedRenewalType === item.type && 'border-black'
            )}
          >
            增加 {item.text}
          </div>
        ))}
      </div>
      <div className="text-t2 py-2 text-grey3">
        {state.selectedRenewalType === RenewalType.people
          ? '只增加当前空间的人数，不调整到期时间。'
          : '只增加当前空间的到期时间，不调整人数。'}
      </div>
      {state.selectedRenewalType === RenewalType.people ? (
        <>
          <div className="text-t2 mt-2.5 flex h-9 items-center">购买时长</div>
          <div className="text-t2 my-2.5 mr-4 flex h-10 w-2/4 items-center justify-center rounded border-[1.5px] border-grey6 text-black/30 space-x-0.5">
            {!!completionTime.year && (
              <>
                <TransitionNumber value={completionTime.year} />
                <span>年</span>
              </>
            )}
            {!!completionTime.day && (
              <>
                <TransitionNumber value={completionTime.day} />
                <span>天</span>
              </>
            )}
          </div>
          <div className="text-t2 py-2 text-grey3">
            {'为保证所有空间成员到期时间均为'}&nbsp;
            {dayjs(calculateQuote?.desiredExpireTime).format('YYYY年MM月DD日')}
            {'，新成员需购买到期剩余时长。'}
          </div>
          <SelectAddPeople />
        </>
      ) : (
        <>
          <SelectPayYear />
          <SelectPayPeople readonly />
        </>
      )}
    </>
  );
});

/** 付费方式 */
// const SelectPay: FC = memo(() => {
//   const { state, patchState, getOrderParams, calculateQuote } = useUpgradeTeamPlanContext();
//   const currentSpace = useCurrentSpace();
//   const { currentPlan } = getOrderParams();
//   const payList = currentPlan?.payList;

//   const renderPayList = [
//     {
//       type: PayType.alipay,
//       enable: payList?.includes(PayType.alipay),
//       text: (
//         <div className="flex items-center">
//           <Icon name="IcAlipayPay" size="middle" className="mr-2 text-[#1677FF]" />
//           支付宝支付
//         </div>
//       ),
//     },
//     {
//       type: PayType.wxpay,
//       enable: payList?.includes(PayType.wxpay),
//       text: (
//         <span className="flex">
//           <Icon name="IcWechatPay" size="large" className="mr-2 text-[#07C160]" />
//           微信支付
//         </span>
//       ),
//     },
//     {
//       type: PayType.offline,
//       enable: payList?.includes(PayType.offline),
//       text: '对公转账',
//     },
//   ].filter((i) => i.enable);

//   return (
//     <>
//       <div className="text-h2 mt-2.5 py-2.5">支付方式</div>
//       <div
//         className="grid py-2.5 gap-4"
//         style={{
//           gridTemplateColumns: `repeat(${renderPayList.length}, minmax(0, 1fr))`,
//         }}
//       >
//         {renderPayList.map((item) => (
//           <div
//             key={item.type}
//             onClick={() => patchState('selectedPayType', item.type)}
//             className={cx(
//               'text-t2-medium animate-hover flex h-10 items-center justify-center border-[1.5px] border-grey5',
//               state.selectedPayType === item.type && 'border-black'
//             )}
//           >
//             {item.text}
//           </div>
//         ))}
//       </div>
//       <UpgradePaddingUiKit
//         className="justify-end text-t2"
//         hidden={(calculateQuote?.diffDays || 0) + (calculateQuote?.dailyAverageAmount || 0) <= 0}
//       >
//         <p>
//           {getSpacePlanTypeName(currentSpace.planType)}空间权益剩余
//           <span className="text-active_color mx-1 whitespace-nowrap">
//             {calculateQuote?.diffDays}
//           </span>
//         </p>
//       </UpgradePaddingUiKit>
//     </>
//   );
// });
// #endregion

// #endregion

// #region BuildIn
const OrderInfo: FC = memo(() => {
  const {
    upgradePlanName,
    calculateQuote,
    state,
    getOrderParams,
    completionTime,
    isRenew,
    isNeedPayDiff,
  } = useUpgradeTeamPlanContext();
  const orderInfo = getOrderParams();
  const month = orderInfo.currentPlan?.monthNum || 1;

  const isPayPeople =
    (isRenew && state.selectedRenewalType === RenewalType.people) || isNeedPayDiff;
  const orderDateUnit = isPayPeople ? '天' : getDateUnit(month);

  const getPriceAndUnit = useGetPriceAndUnit();
  const { date } = getPriceAndUnit(month < 12, calculateQuote, orderInfo.currentPlan);
  const dateUnit = orderDateUnit;
  const yearText = completionTime.year ? `${completionTime.year} 年` : '';
  const dayText = completionTime.day ? `${completionTime.day} 天` : '';
  const peopleNum = isPayPeople ? orderInfo.addPeople : orderInfo.people;
  const payPeople = [`${peopleNum} 人 × ${date} ${dateUnit}`];

  const payTime = [`${peopleNum} 人 × ${yearText} ${dayText}`];

  return (
    <UpgradeComponents.OrderInfo
      payType={state.selectedPayType}
      className="space-y-2"
      planName={upgradePlanName}
      price={calculateQuote?.amount}
      priceUnit={calculateQuote?.amountUnit}
      info={isPayPeople ? payTime : payPeople}
    />
  );
});

export const UpgradeTeamPlanBuildInContent: FC = memo(() => {
  const {
    loading,
    upgradeTitle,
    close,
    payOrder,
    state,
    getOrderParams,
    patchState,
    couponList,
    isNeedPayDiff,
  } = useUpgradeTeamPlanContext();
  const { currentPlan } = getOrderParams();
  const payList = currentPlan?.payList;

  const binPayList = [PayType.stripe, PayType.yoomoneyRu, PayType.sbpRu, PayType.cardRu];
  const fuPayList = isMobile ? [] : [PayType.alipay, PayType.wxpay, PayType.offline];
  if (isMobile) {
    if (isInWechat) {
      fuPayList.push(PayType.wxpay);
    } else {
      fuPayList.push(PayType.alipay);
      fuPayList.push(PayType.wxpay);
    }
  }
  const renderPayList = (__BUILD_IN__ ? binPayList : fuPayList).filter(
    (type) => payList?.includes(type) ?? true
  );

  const protocols = __BUILD_IN__
    ? [
        {
          title: 'Marketing Program Participant Terms',
          link: publicLink.marketingProgramParticipantTerms,
        },
      ]
    : [];

  return (
    <div className="flex flex-col">
      <UpgradeComponents.Header close={close} />
      <UpgradeComponents.BODY
        className={cx('px-8 pt-2.5 pb-0', isMobile && 'px-6', loading && 'animate-pulse')}
      >
        <div
          className={cx('grid grid-cols-2 gap-5 pb-5', {
            'grid-cols-1': isMobile,
          })}
        >
          <div>
            <UpgradePaddingUiKit height={54} className="text-h2">
              {upgradeTitle}
            </UpgradePaddingUiKit>
            {/* 付 费内容规格 */}
            <PlanList />
          </div>
          <div className="space-y-2 bg-grey9 px-6 pb-4">
            <UpgradeComponents.SelectPay
              active={state.selectedPayType}
              payList={renderPayList}
              onChange={(key) => patchState('selectedPayType', key)}
            />
            {!isNeedPayDiff && (
              <UpgradeCouponList
                onSelectId={(id) => patchState('couponId', id)}
                couponList={couponList}
                selectId={state.couponId}
                className={'my-2.5'}
              />
            )}
            <OrderInfo />
          </div>
        </div>
        <UpgradeComponents.StripePayBar protocols={protocols} onClick={payOrder} />
      </UpgradeComponents.BODY>
    </div>
  );
});
// #endregion
