import { cx } from '@flowus/common/cx';
import { FileRegex } from '@flowus/common/regex';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { colors } from 'src/colors';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { PageNavigator } from 'src/common/components/page-navigator';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT, UNTITLED_DRIVE } from 'src/common/const';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { segmentsToText } from 'src/editor/utils/editor';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useGetBreadcrumb } from 'src/hooks/block/use-navs';
import { useFileDownLimit } from 'src/hooks/limit/file-down-limit';
import { useReadonly } from 'src/hooks/page';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { getDownloadUrl } from 'src/hooks/public/use-resource';
import { useCopyToSpace } from 'src/hooks/share/use-copy-to-space';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useGetShareHeaderMenuItems } from 'src/hooks/share/use-share-header-menu-list';
import { useIsFavoritePage } from 'src/hooks/user';
import { Images } from 'src/image';
import { cache, getState } from 'src/redux/store';
import { useIsMobileSize } from 'src/services/app/hook';
import { useIsFullscreen } from 'src/services/desktop';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { isMacElectron } from 'src/utils/electron';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocationOrigin, previewPrefix } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { TestIds, bindDataTestId } from 'src/utils/qa-utils';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { Favorite } from 'src/views/main/header/favorite';
import { ProIcon } from 'src/views/main/header/pro-icon';
import { PageScene } from 'src/views/main/scene-context';
import { Logo } from 'src/views/share/logo';
import { SpeedLimitButtonTips } from 'src/views/share/speed-limit-tips';
import { FileNameUiKit } from '../file-name-uikit';
import type { MoveToProps } from '../move-to';
import { useOpenMoveTo } from '../move-to';
import { useOpenCompleteDelete } from '../open-complete-delete';
import { Share } from '../share';
import { useFilePreviewMode } from './hook';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { supportWpsFileEditor } from '@flowus/common/wps';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { useBlock } from 'src/hooks/block/use-block';
import { useOpenImageWatermark } from 'src/hooks/space/use-open-image-watermark';
import { addImageWatermark } from '@flowus/common/url';

interface HeaderProps {
  downloadUrl: string;
  isPreviewPage?: boolean;
  uuid: string;
  moveBlock?: ({ popcorn, uuid }: MoveToProps) => void;
  pageScene?: PageScene;
  onClose?: () => void;
}

const useFileNavs = (uuid: string) => {
  const block = usePickBlock(uuid, []);
  const getBreadcrumb = useGetBreadcrumb();

  if (!block) {
    return [];
  }

  return getBreadcrumb(block.uuid);
};

export const PreviewHeader: FC<HeaderProps> = ({
  isPreviewPage,
  uuid,
  downloadUrl,
  pageScene,
  onClose,
}) => {
  const isFavorite = useIsFavoritePage(uuid);
  const moveBlock = useOpenMoveTo();
  const readonly = useReadonly(uuid);
  const file = usePickBlock(uuid, ['data'], ['segments', 'pdfAnnotation', 'size']);
  const isMobileSize = useIsMobileSize();
  // 被风控了
  const { illegal, allowDuplicate, allowDownload } = usePermissions(uuid);
  const { blocks } = getState();

  const { role: parentRole } = usePermissions(file?.parentId ?? '');
  const parentExist = blocks[file?.parentId ?? ''];
  const fileDownLimit = useFileDownLimit();
  const block = useBlock(uuid);
  const { openImageWatermark, imageWatermarkText } = useOpenImageWatermark(block?.spaceId);

  const isAnnotation = file?.type === BlockType.PDF_ANNOTATION;
  const openModal = useOpenModal();
  const popcorn = useRef<any>();
  let fileName = illegal ? ILLEGAL_TEXT : segmentsToText(file?.data.segments);
  if (isAnnotation) {
    fileName = '图片标注.png';
  }
  const { extName } = getFileNameInfo(fileName);
  const navs = useFileNavs(uuid);
  if (isAnnotation) {
    const pdfBlock = blocks[file.parentId ?? ''];
    if (pdfBlock) {
      navs.push(pdfBlock);
    }
  }
  const menuItems = useGetShareHeaderMenuItems(uuid);
  const openCompleteDelete = useOpenCompleteDelete(uuid);
  const copyToSpace = useCopyToSpace();
  const isSharePage = judgeSharePage();
  const isTemplateCenter = pageScene === PageScene.TEMPLATE;
  // 特殊空间
  const officialSpaceShare = useOfficialSpaceShare(uuid) || isTemplateCenter;
  const isFullscreen = useIsFullscreen();
  const isDesktopMode = isMacElectron && !isFullscreen;
  const isImage = FileRegex.image.test(extName);
  const openInNewWindow = useCallback(() => {
    window.open(`/${previewPrefix()}/${uuid}`);
  }, [uuid]);

  const deleteBlock = useCallback(() => {
    archiveBlock(uuid);
  }, [uuid]);

  const move = useCallback(() => {
    if (!isPreviewPage) {
      moveBlock({
        popcorn: popcorn.current,
        uuid,
      });
    }
  }, [isPreviewPage, moveBlock, uuid]);

  const copy = useCallback(() => {
    void writeTextInClipboard(`${getLocationOrigin()}/${previewPrefix()}/${uuid}`);
  }, [uuid]);

  const downloadResource = async () => {
    await fileDownLimit();
    if (isImage) {
      let res = await getDownloadUrl(uuid);
      if (res) {
        if (openImageWatermark && imageWatermarkText) {
          res = addImageWatermark(res, imageWatermarkText);
        }
        void downloadUrlFile(res);
      } else {
        message.error('下载资源失败');
      }
    } else {
      void downloadUrlFile(downloadUrl);
    }
  };

  const items = useMemo(() => {
    if (!isSharePage) {
      if (illegal) {
        return [
          {
            type: ListItemType.OPERATION,
            data: {
              icon: 'IcTrash',
              title: '彻底删除',
              onClick: () => openCompleteDelete(),
            },
          },
        ];
      }
      return [
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcMenuOpenNew',
            title: '新窗口打开',
            onClick: openInNewWindow,
          },
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcMenuOpenNew',
            title: '删除',
            onClick: deleteBlock,
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: readonly || parentRole !== PermissionRole.EDITOR,
          data: {
            icon: 'IcLink',
            title: '拷贝链接',
            onClick: copy,
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: readonly || (parentExist && parentRole !== PermissionRole.EDITOR),
          data: {
            icon: 'IcMove',
            rightText: `${ShortcutSystemSymbol}+Shift+P`,
            title: '移动到',
            onClick: move,
          },
        },
      ] as ListItem[];
    }

    return menuItems;
  }, [
    isSharePage,
    menuItems,
    illegal,
    openInNewWindow,
    deleteBlock,
    readonly,
    parentRole,
    copy,
    parentExist,
    move,
    openCompleteDelete,
  ]);

  const openMenu = useCallback(
    (event) => {
      popcorn.current = event.currentTarget;

      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom-end',
        offset: [0, 10],
        content: ({ onCloseModal }) => (
          <ListView
            className="py-2 rounded next-modal w-52"
            items={items}
            onItemClick={() => onCloseModal()}
          />
        ),
      });
    },
    [items, openModal]
  );

  const showDuplicate =
    isSharePage && allowDuplicate && !illegal && !officialSpaceShare && !__PRIVATE__;

  if (!file) return null;

  return (
    <div
      className={cx(
        'absolute top-0 left-0 z-20 flex justify-between w-screen min-h-[55px] bg-black-base/40 backdrop-blur-sm',
        navs.length === 0 ? 'items-center' : 'items-start'
      )}
    >
      <div className={'flex h-full'}>
        {isSharePage && (
          <Logo
            logoImage={Images.logoWhiteWide}
            className={'ml-4 h-[18px] self-center border-r pr-2 sm:pr-1'}
          />
        )}
        <div
          className={cx('flex', {
            'relative top-2': navs.length > 0,
            'pl-20': isDesktopMode,
          })}
        >
          {!isPreviewPage && cache.blocks[file.parentId] && (
            <Icon
              name="IcArrowBack"
              className={cx('animate-click text-white sm:ml-0', isSharePage ? 'ml-2' : 'ml-3')}
              size="middle"
              onClick={onClose}
            />
          )}
          <div className={'flex px-3 text-white sm:ml-1'}>
            <Icon name={getFileIcon(extName)} size="middle" className="flex-shrink-0 mr-2" />
            <div
              className={
                'flex flex-col justify-between w-[calc(100vw-450px)] sm:w-[calc(100vw-250px)]'
              }
            >
              <FileNameUiKit
                fileName={fileName}
                className="text-t2"
                addonAfter={
                  !isMobileSize ? (
                    <span className="ml-2 text-grey3">
                      {getReadableFileSizeString(
                        isAnnotation ? file.data.pdfAnnotation?.size : file.data.size
                      )}
                    </span>
                  ) : null
                }
              />
              <PageNavigator
                navs={navs}
                className="text-white"
                placement="bottom"
                untitled={UNTITLED_DRIVE}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'flex items-center self-center flex-shrink-0 pr-4 space-x-2'}>
        {showDuplicate && (
          <SpeedLimitButtonTips uuid={uuid}>
            <Button
              className="text-t2-medium flex-shrink-0"
              onClick={() => {
                bizTracker.event('copytomyspace', {
                  page_id: uuid,
                });
                copyToSpace(uuid);
              }}
            >
              拷贝到
            </Button>
          </SpeedLimitButtonTips>
        )}
        {!isSharePage && !illegal && !officialSpaceShare && (
          <Share showType="button" uuid={uuid} color="text-white" />
        )}
        <OfficeEditorBtn extName={extName} />
        {allowDownload && !officialSpaceShare && !illegal && (
          <Tooltip
            popup="下载"
            className="flex items-center justify-center animate-hover"
            onClick={() => {
              void downloadResource();
            }}
          >
            <Icon
              name="IcDownload"
              className="text-white"
              size="middle"
              {...bindDataTestId(TestIds.menuDown)}
            />
          </Tooltip>
        )}
        {!isSharePage && !illegal && !isTemplateCenter && (
          <Favorite uuid={uuid} style={{ color: isFavorite ? colors.yellow : colors.white }} />
        )}
        {!location.pathname.includes('preview') && (
          <Icon
            name="IcMore"
            className="menu_more text-white"
            size="middle"
            {...bindDataTestId(TestIds.menuMore)}
            onClick={openMenu}
          />
        )}
      </div>
    </div>
  );
};

export const OfficeEditorBtn: FC<{ extName?: string }> = (props) => {
  const { extName = '' } = props;
  const { officeOptions } = useFilePreviewMode();
  const isProSpace = useIsProSpace();

  if (!officeOptions.canEditor) return null;
  if (!supportWpsFileEditor(extName)) return null;

  return (
    <div className="flex items-center animate-click text-white" onClick={officeOptions.openEditor}>
      <Icon name="IcBtnEdit" size="middle" />
      {!isProSpace && <ProIcon absolute={false} />}
    </div>
  );
};
