import { useMemo } from 'react';
import { useDocPublicData } from './use-fetch-page';

export const usePayInfo = (uuid: string) => {
  const docPublicData = useDocPublicData(uuid);

  const { amount, payInfo } = useMemo(() => {
    if (!docPublicData?.sharePageDistributionRatio || !docPublicData?.accessFee) {
      return { amount: null, payInfo: docPublicData };
    }

    return {
      amount: Number(
        (docPublicData.accessFee * docPublicData.sharePageDistributionRatio).toFixed(2)
      ),
      payInfo: docPublicData,
    };
  }, [docPublicData]);

  return {
    payInfo,
    amount,
  };
};
