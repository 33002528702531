import { sleep } from '@flowus/common/async';
import { message } from '@flowus/common/components/message';
import { useEffect, useRef } from 'react';
import { request } from 'src/common/request';
import { useJumpQueryCallback } from 'src/hooks/public/use-jump-query-callback';

export const useCheckOrder = (orderId: string | undefined, onFinish: () => void) => {
  const jumpCallback = useJumpQueryCallback();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const waitRef = useRef(false);
  const finishFef = useRef(onFinish);
  finishFef.current = onFinish;
  useEffect(() => {
    if (!orderId) return;
    const refreshOrder = async () => {
      const res = await request.infra.getOrderStatus.raw(orderId);
      if (res.code === 200) {
        switch (res.data.status) {
          case 20: {
            message.success('支付成功');
            await sleep(1000);
            finishFef.current();
            // 支付成功，然后跳转
            void jumpCallback();
            break;
          }
          case 30: {
            timerRef.current && clearInterval(timerRef.current);
            // 已取消，这个就甭管了,不需要跳转回去
            break;
          }
          default:
        }
      }
    };
    timerRef.current = setInterval(() => {
      if (waitRef.current) return;
      try {
        waitRef.current = true;
        void refreshOrder();
      } finally {
        waitRef.current = false;
      }
    }, 5000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [jumpCallback, orderId]);
};
