import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { useSize } from 'src/common/utils/use-size';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { reSetDropInfo } from 'src/services/app/hook/use-drop-info';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { useBitable } from '../../context';
import { useShowAddViewPanel } from './add-view';
import { ViewButton } from './view-button';
import { ViewList } from './view-list';
import { SelectRecordToolbar } from './select-record-toolbar';
import { judgeSharePage } from 'src/utils/getPageId';

export const ViewsManager: FC<{ className?: string }> = ({ className }) => {
  const { pageManager, viewParentId, viewId, isLocked, readonly } = useBitable();
  const showAddPanel = useShowAddViewPanel();
  const transaction = useTransaction();
  const openModal = useOpenModal();
  const views = useObservableBlock(viewParentId, (block) => block?.views ?? []);
  const [showLength, setShowLength] = useState(views.length);
  const titleRef = useRef<HTMLDivElement>(null);
  const shadowTitleRef = useRef<HTMLDivElement>(null);
  const view = useObservableStore(({ collectionViews }) => collectionViews[viewId], [viewId]);
  const { width } = useSize(pageManager?.current);
  const { width: titleWidth } = useSize(shadowTitleRef.current);
  const isSharePage = judgeSharePage();

  useEffect(() => {
    const manager = pageManager?.current;
    const lastNode = manager?.lastElementChild;
    const shadowTitle = shadowTitleRef.current;
    if (!lastNode || !shadowTitle) return;

    const lastNodeWidth = lastNode.clientWidth;
    const shadowTitleWidth = shadowTitle.clientWidth;

    const manageStyle = window.getComputedStyle(manager);
    const paddingLeft = parseInt(manageStyle.paddingLeft, 10);
    const paddingRight = parseInt(manageStyle.paddingRight, 10);
    const maxWidth = manager.clientWidth - paddingLeft - paddingRight - lastNodeWidth - 150;
    if (shadowTitleWidth < maxWidth) {
      setShowLength(views.length);
      return;
    }

    let hiddenLength = 0;
    let hiddenWidth = 0;
    Array.from(shadowTitle.children)
      .reverse()
      .find((node) => {
        hiddenLength++;
        hiddenWidth += node.clientWidth;

        return shadowTitleWidth - hiddenWidth < maxWidth;
      });

    setShowLength(Math.max(views.length - hiddenLength, 1));
  }, [pageManager, showLength, titleWidth, views.length, width]);

  const showViewList = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content({ onCloseModal }) {
        return <ViewList onClose={onCloseModal} />;
      },
    });
  };

  if (!view) return null;

  const showIds = views.slice(0, showLength);
  if (!showIds.includes(viewId)) {
    showIds.pop();
    showIds.push(viewId);
  }

  return (
    <>
      <SortableList
        isHorizontalAxis
        strategy={horizontalListSortingStrategy}
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        disabled={readonly || isLocked}
        className={cx('flex h-full flex-1', className)}
        listRef={titleRef}
        appendChild={
          showLength !== views.length ? (
            <div className={cx('flex items-center mr-2 text-t2')} onClick={showViewList}>
              <button className="flex items-center text-grey4 animate-hover p-1 whitespace-nowrap">
                {views.length - showLength} 更多...
              </button>
            </div>
          ) : (
            !(readonly || isLocked) && (
              <div className={cx('flex items-center mr-2')}>
                <button
                  className="flex items-center text-grey4 animate-hover p-1"
                  onClick={(event) => showAddPanel(event.currentTarget, () => {}, 'bottom')}
                >
                  <Icon name={'IcAdd'} size="middle" />
                </button>
              </div>
            )
          )
        }
        onDragStart={() => reSetDropInfo()}
        items={showIds.map((viewId) => ({ id: viewId }))}
        renderItemContent={({ item }) => <ViewButton key={item.id} uuid={item.id} />}
        onChange={(newViews) => {
          transaction(() => {
            const newViewIds = newViews.map((item) => item.id);
            updateBlock(viewParentId, {
              views: newViewIds.concat(views.filter((uuid) => !newViewIds.includes(uuid))),
            });
          });
        }}
      />
      {!isSharePage && !readonly && <SelectRecordToolbar />}
      <div
        className="absolute opacity-0 flex top-0 right-0 overflow-hidden pointer-events-none "
        ref={shadowTitleRef}
      >
        {views.map((id) => (
          <ViewButton key={id} uuid={id} />
        ))}
      </div>
    </>
  );
};
