import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { fastEqual } from '@flowus/common/utils/tools';
import type {
  CollectionFilter,
  CollectionFilterGroup,
  CollectionSchema,
  CollectionViewDTO,
  UserDTO,
} from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, PermissionRole, TextType } from '@next-space/fe-api-idl';
import { createSelector } from 'reselect';
import { buildFilterFunc } from 'src/bitable/table-view/body/filters';
import { buildCompareFunc } from 'src/bitable/table-view/body/sorters';
import { readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { getRelationRecords } from 'src/bitable/table-view/cell/relation/get-relation-records';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $currentUserCache } from 'src/services/user/current-user';
import { $searchParams } from 'src/utils';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import { $appUiStateCache, useCollectionSearchById } from '../../services/app/hook';
import { getViewFormat } from '../block/get-view-format';
import { getFormulaTool } from '../block/use-formula-tool';
import { getPermissions } from '../share/use-permissions';
import { useCurrentUserId } from '../user';
import { getUserName } from '../user/use-remark-name';
import { selectCollectionGroups } from './table-groups/select-collection-groups';
import { getFilter } from 'src/redux/managers/collection-view';
import { getSubitemStyle, isNestedStyleViewType, isOpenSubitem } from '../block/use-open-subitem';

export const useGetSortedRecordIds = (viewId: string, deps?: any[], openSubitem = true) => {
  const pageId = useGetPageId();
  // 图表也用到了这个方法，不一定是多维表用到
  // const { collectionId } = useBitable();
  const collectionId = useObservableStore((s) => s.collectionViews[viewId]?.parentId, [viewId]);
  const keyword = useCollectionSearchById(viewId);
  const currentUserId = useCurrentUserId();

  return useObservableStore(
    (state) => {
      return sortedRecordIdsSelector(
        viewId,
        state,
        false,
        currentUserId,
        pageId,
        keyword,
        openSubitem
      );
    },
    [viewId, collectionId, currentUserId, keyword, ...(deps || [])],
    { allUser: true, waitMode: 'throttle', wait: 200, ignoreOtherData: false }
  );
};

const getRelationMap = (
  collection: NextBlock,
  view: CollectionViewDTO,
  filteredRecordIds: string[],
  state: RootState
) => {
  const parentPropertyId = collection.data.format?.openSubItem?.parentPropertyId;
  const parent2ChildMap = new Map<string, string[]>();
  const child2ParentMap = new Map<string, string[]>();

  if (parentPropertyId) {
    const filterIdSet = new Set(filteredRecordIds);
    collection.subNodes.forEach((childId) => {
      const { relationRecords: parentRelationRecords } = getRelationRecords(
        childId,
        parentPropertyId
      );
      parentRelationRecords.forEach((pId) => {
        // 记录父关系
        const childIds = parent2ChildMap.get(pId) ?? [];
        childIds.push(childId);
        parent2ChildMap.set(pId, childIds);
        // 记录子-父关系
        const parentIds = child2ParentMap.get(childId) ?? [];
        parentIds.push(pId);
        child2ParentMap.set(childId, parentIds);
      });
    });
    parent2ChildMap.forEach((ids, k, map) => {
      const sortedRecords = sortRecords({
        collectionId: collection.uuid,
        records: ids.filter((i) => filterIdSet.has(i)),
        tableSchema: collection.data.schema ?? {},
        users: state.users,
        sorters: view.format.sorters,
        sort: view.pageSort ?? [],
        state,
      });
      map.set(k, sortedRecords);
    });
  }
  return { parent2ChildMap, child2ParentMap };
};
const getParentIds = (
  child2ParentMap: Map<string, string[]>,
  childId: string,
  ancestorsSet: Set<string>,
  deep = 0
) => {
  if (deep >= 10) return;
  const parentIds = child2ParentMap.get(childId);
  if (parentIds) {
    parentIds.forEach((v) => {
      if (ancestorsSet.has(v)) {
        return;
      }
      ancestorsSet.add(v);
      getParentIds(child2ParentMap, v, ancestorsSet, deep + 1);
    });
  }
};

export const sortedRecordIdsSelector = MemoizeByBlockVersion.create(
  createSelector(
    (viewId: string) => viewId,
    (_: string, state: ReturnType<typeof getState>) => state,
    (_: string, __: ReturnType<typeof getState>, ignoreSearch: boolean) => ignoreSearch,
    (_: string, __: ReturnType<typeof getState>, ___: boolean, currentUserId: string) =>
      currentUserId,
    (_: string, __: ReturnType<typeof getState>, ___: boolean, ____: string, pageId: string) =>
      pageId,
    (
      _: string,
      __: ReturnType<typeof getState>,
      ___: boolean,
      ____: string,
      _____: string,
      keyword?: string
    ) => keyword,
    (
      _: string,
      __: ReturnType<typeof getState>,
      ___: boolean,
      ____: string,
      _____: string,
      ______?: string,
      openSubitem?: boolean
    ) => openSubitem,
    (
      viewId: string,
      state: ReturnType<typeof getState>,
      ignoreSearch: boolean,
      currentUserId: string,
      pageId: string,
      keyword?: string,
      _openSubitem?: boolean
    ) => {
      const { users } = state;
      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return { sortedRecordIds: [] };

      const { view, collection } = viewInfo;
      const { role } = getPermissions(collection.uuid);
      const saveLocal = role === PermissionRole.READER || role === PermissionRole.NONE;

      if ($searchParams.dataBaseRange === 'all') {
        return { sortedRecordIds: collection.subNodes };
      }
      let filter = saveLocal ? getFilter(viewId) : view.format.filter;
      if (saveLocal && (!filter || filter.filters.length === 0)) {
        filter = view.format.filter;
      }

      const collectionRecords = getCollectionRecords(collection.uuid, state, ignoreSearch, keyword);
      const subitemStyle = getSubitemStyle(viewId, state);
      const openSubitem = isOpenSubitem(collection.uuid) && _openSubitem;
      const parentPropertyId = collection.data.format?.openSubItem?.parentPropertyId;
      const filteredRecords = filterRecords({
        collectionId: collection.uuid,
        records: collectionRecords,
        schemas: collection.data.schema,
        userId: currentUserId,
        filter,
        state,
        pageId,
      });

      const filteredRecordIds = filteredRecords.map((v) => v.uuid);
      // 保存父子关系
      const { parent2ChildMap: _parent2ChildMap, child2ParentMap: _child2ParentMap } =
        getRelationMap(collection, view, [], state);
      const filteredRecordIdSet = new Set<string>();
      // 只有table,board和timeline这种折叠才需要显示父级，其他视图不显示，因此如果父级不符合就不会被过滤出来（notion也是这个逻辑）

      if (
        parentPropertyId &&
        openSubitem &&
        subitemStyle === 'Nested' &&
        isNestedStyleViewType(view.type)
      ) {
        const alreadyExistIdSet = new Set<string>(filteredRecordIds);
        // 如果开启了子任务且加了过滤条件，即便父级没有符合也需要显示
        filteredRecords.forEach((v) => {
          // 有些用户自己设置了父记录导致了层级较多，算10层也堆栈溢出了，这里去重一下
          const ancestorsSet = new Set<string>();
          getParentIds(_child2ParentMap, v.uuid, ancestorsSet);
          ancestorsSet.forEach((aId) => {
            if (!alreadyExistIdSet.has(aId)) {
              alreadyExistIdSet.add(aId);
              filteredRecordIds.push(aId);
              filteredRecordIdSet.add(aId);
            }
          });
        });
      }
      // 过滤之后的父子关系
      const { parent2ChildMap, child2ParentMap } = getRelationMap(
        collection,
        view,
        filteredRecordIds,
        state
      );

      const sortedRecordIds = sortRecords({
        collectionId: collection.uuid,
        records: filteredRecordIds,
        tableSchema: collection.data.schema ?? {},
        users,
        sorters: view.format.sorters,
        sort: view.pageSort ?? [],
        state,
      });
      if (openSubitem && subitemStyle !== 'Flattenlist') {
        // 第一层的，没有父记录的存起来再遍历
        const topLevelRecords: string[] = [];
        sortedRecordIds.forEach((v) => {
          const parentIds = child2ParentMap.get(v) ?? [];
          if (parentIds.length === 0) {
            topLevelRecords.push(v);
          }
        });
        return {
          sortedRecordIds: topLevelRecords,
          child2ParentMap,
          parent2ChildMap,
          filteredRecordIdSet,
        };
      }

      return { sortedRecordIds, child2ParentMap, parent2ChildMap, filteredRecordIdSet };
    }
  ),
  (viewId, _, ignoreSearch, currentUserId, pageId, keyword, openSubitem) => [
    viewId,
    ignoreSearch,
    pageId,
    currentUserId,
    keyword,
    openSubitem,
  ],
  { enable: false }
);

interface Params {
  viewId: string;
  state?: RootState;
  ignoreSearch?: boolean;
  groupValue?: string;
  /** auto-filter需要当前页面来进行过滤，由于可能是右侧打开，所以无法通过url来进行判断，只能传pageId */
  pageId: string;
  openSubItem?: boolean;
}

export const getSortedRecordIds = ({
  viewId,
  state = getState(),
  ignoreSearch = false,
  groupValue,
  pageId,
  openSubItem,
}: Params) => {
  const { sortedRecordIds, parent2ChildMap, child2ParentMap } = sortedRecordIdsSelector(
    viewId,
    state,
    ignoreSearch,
    $currentUserCache.uuid,
    pageId,
    $appUiStateCache.$collectionSearch[viewId],
    openSubItem
  );
  if (!groupValue) return { sortedRecordIds, parent2ChildMap, child2ParentMap };

  const { blocks, users, collectionViews } = state;
  const groups = selectCollectionGroups({
    blocks,
    collectionViews,
    users,
    viewId,
    sortedRecordIds,
  });

  const group = groups?.groups?.find((group) => group.value === groupValue);
  return { sortedRecordIds: group?.recordIds ?? [], parent2ChildMap, child2ParentMap };
};

const searchBlock = (
  schemas: Record<string, CollectionSchema>,
  block: NextBlock,
  keyword: string,
  searchOptioin?: { onlyTitle: boolean }
): boolean => {
  const allKeywords = keyword.split(/\s+/).map((s) => stringToLowerCaseAndRemoveSpace(s));

  for (const [propertyId, schema] of Object.entries(schemas)) {
    if (searchOptioin?.onlyTitle && schema.type !== CollectionSchemaType.TITLE) {
      continue;
    }

    let str = '';

    switch (schema.type) {
      case CollectionSchemaType.TEXT: {
        const segments = block.data.collectionProperties?.[propertyId];
        str = segmentsToText(segments);
        break;
      }
      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL: {
        const segments = block.data.collectionProperties?.[propertyId];
        str = readUrlFromSegments(segments);
        break;
      }

      case CollectionSchemaType.TITLE: {
        str = segmentsToText(block.data.segments);
        break;
      }

      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
        const tags = text
          .split(/\s*,\s*/g)
          .map((item) => schema.options?.find((option) => option.value === item)?.value)
          .filter((item): item is string => !!item);

        str = tags.join('');
        break;
      }

      case CollectionSchemaType.NUMBER: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        let num = parseFloat(value);
        if (Number.isNaN(num)) {
          num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }

        if (!Number.isNaN(num)) {
          str = String(num);
        }
        break;
      }

      case CollectionSchemaType.RELATION: {
        const result = getRelationRecords(block.uuid, propertyId).relationRecords;
        const { blocks } = getState();
        const titles = result.map((uuid) => {
          const block = blocks[uuid];
          if (!block) return '';
          return segmentsToText(block.data.segments);
        });
        str = titles.join('');
        break;
      }

      case CollectionSchemaType.CHECKBOX: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        str = formatCheckBoxValue(value) ? 'yes' : 'no';
        break;
      }

      case CollectionSchemaType.FILE: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        const result = segments
          .filter((item) => item.type === TextType.URL && item.fileStorageType === 'internal')
          .map((segment) => segment.text);

        str = result.join('');
        break;
      }

      case CollectionSchemaType.PERSON: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        segments.forEach((item) => {
          if (item.type === TextType.USER && item.uuid) {
            const userName = getUserName(item.uuid);
            if (userName) {
              str += userName;
            }
          }
        });

        break;
      }

      default:
        break;
    }
    str = stringToLowerCaseAndRemoveSpace(str);
    if (allKeywords.find((keyword) => str.includes(keyword))) {
      return true;
    }
  }

  return false;
};

export const getCollectionRecords = (
  collectionId: string,
  state = getState(),
  ignoreSearch = false,
  keyword = '',
  searchOptioin?: { onlyTitle: boolean }
) => {
  const { blocks } = state;
  const collection = blocks[collectionId];
  if (!collection) return [];

  return collection.subNodes.reduce<NextBlock[]>((records, id) => {
    const record = blocks[id];
    if (record?.type !== BlockType.PAGE) return records;
    // 临时的多维表页面不进行展示
    if (record?.hidden) return records;

    const schemas = collection.data.schema;
    if (!ignoreSearch && keyword && schemas) {
      if (record && !searchBlock(schemas, record, keyword, searchOptioin)) {
        return records;
      }
    }

    const { role } = getPermissions(id, state);
    if (record && (judgeSharePage() || role !== PermissionRole.NONE)) {
      records.push(record);
    }
    return records;
  }, []);
};

export const getCurrentGroupRecordIds = (
  sortedIds: string[],
  groupProperty: string,
  currentRecordId: string
) => {
  const { blocks } = getState();
  const currentGroupName =
    segmentsToText(blocks[currentRecordId]?.data.collectionProperties?.[groupProperty] ?? []).split(
      ','
    )[0] ?? '';

  return sortedIds.filter((uuid: string) => {
    const block = blocks[uuid];
    if (!block) return false;

    const segment = block.data.collectionProperties?.[groupProperty];
    const text = segmentsToText(segment).split(',')[0] ?? '';

    return currentGroupName === text;
  });
};

interface SortRecordsArguments {
  // state: RootState;
  collectionId: string;
  // records: NextBlock[];
  records: string[];
  tableSchema: Record<string, CollectionSchema> | undefined;
  users: Record<string, UserDTO>;
  sorters: CollectionViewDTO['format']['sorters'];
  sort: string[];
  state?: RootState;
}

export const sortRecords = createSelector(
  [
    ({ collectionId }: SortRecordsArguments) => collectionId,
    ({ records }: SortRecordsArguments) => records,
    ({ tableSchema }: SortRecordsArguments) => tableSchema,
    ({ users }: SortRecordsArguments) => users,
    ({ sorters }: SortRecordsArguments) =>
      (sorters ?? []).filter((it) => {
        if (it.disable) return false;
        return true;
      }),
    ({ sort }: SortRecordsArguments) => sort,
    ({ state }: SortRecordsArguments) => state,
  ],
  (collectionId, records, tableSchema, users, sorters, sort, state) => {
    if (sorters.length === 0 && sort.length === 0) return records;
    const ranking = new Map<string, number>();
    sort.forEach((recordId, index) => {
      ranking.set(recordId, index);
    });

    let nextRank = sort.length;
    for (const record of records) {
      if (!ranking.has(record)) {
        ranking.set(record, nextRank);
        nextRank += 1;
      }
    }

    const formulaTool = getFormulaTool(collectionId);
    const compareFunc = buildCompareFunc(sorters, {
      collectionId,
      schemas: tableSchema,
      ranking,
      users,
      formulaTool,
      state,
    });
    const newRecords = records.slice(0);
    newRecords.sort(compareFunc);
    return newRecords;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
    },
  }
);

interface FilterRecordsArguments {
  collectionId: string;
  records: NextBlock[];
  schemas: Record<string, CollectionSchema> | undefined;
  userId: string;
  filter: CollectionFilter | CollectionFilterGroup | undefined;
  state?: RootState;
  /** 当前页面id,auto-filter需要用得到 */
  pageId: string;
}

export const filterRecords = createSelector(
  [
    ({ records }: FilterRecordsArguments) => records,
    ({ schemas }: FilterRecordsArguments) => schemas,
    ({ userId }: FilterRecordsArguments) => userId,
    ({ filter }: FilterRecordsArguments) => filter,
    ({ collectionId }: FilterRecordsArguments) => collectionId,
    ({ state }: FilterRecordsArguments) => state,
    ({ pageId }: FilterRecordsArguments) => pageId,
  ],
  (inBlocks, schemas, userId, filter, collectionId, state, pageId) => {
    const filterFunc = buildFilterFunc(filter, {
      collectionId,
      schemas,
      userId,
      state,
      curpageId: pageId,
    });
    return inBlocks.filter(filterFunc);
  },
  {
    memoizeOptions: {
      equalityCheck: fastEqual,
      resultEqualityCheck: fastEqual,
    },
  }
);
