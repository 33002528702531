import { getTagBackgroundColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useRemoveGroup } from 'src/hooks/block/use-remove-group';
import { useUpdateGroupVisible } from 'src/hooks/block/use-update-group-visible';
import { useUpdatePropertyOption } from 'src/hooks/block/use-update-property-option';
import { realGroupName } from 'src/hooks/collection-view/table-groups';
import { useGroupOption } from 'src/hooks/collection-view/use-group-option';
import { useBitable } from './context';
import { ColorSelector } from './table-view/cell/select/color-selector';

interface GroupMoreProps {
  isSubGroup?: boolean;
  groupName: string;
  visible?: boolean;
  className: string;
}

export const GroupMore: FC<GroupMoreProps> = ({
  groupName,
  visible = true,
  className,
  isSubGroup,
}) => {
  const openModal = useOpenModal();
  const { readonly, managerReadonly, isLocked } = useBitable();

  const openMenu = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content({ onCloseModal }) {
        return (
          <GroupMoreMenuList
            isSubGroup={isSubGroup}
            closeModal={onCloseModal}
            visible={visible}
            groupName={groupName}
          />
        );
      },
    });
  };

  if (readonly || managerReadonly || isLocked) return null;
  return (
    <button
      onClick={openMenu}
      className={cx('animate-hover flex h-5 w-5 cursor-pointer items-center rounded-sm', className)}
    >
      <Icon name="IcMore" size="middle" />
    </button>
  );
};

enum OperationEnum {
  Delete,
  Hide,
}

interface GroupMenuListProps {
  isSubGroup?: boolean;
  closeModal: () => void;
  visible: boolean;
  groupName: string;
}

const GroupMoreMenuList: FC<GroupMenuListProps> = ({
  closeModal,
  visible,
  groupName,
  isSubGroup,
}) => {
  const updateOption = useUpdatePropertyOption();
  const { collectionId, viewId } = useBitable();
  const removeGroup = useRemoveGroup();
  const updateGroupVisible = useUpdateGroupVisible();
  const openModal = useOpenModal();
  const option = useGroupOption(viewId, realGroupName(groupName), isSubGroup);

  const menuItems = [
    {
      type: ListItemType.OPERATION,
      isHidden: !realGroupName(groupName),
      data: {
        icon: 'IcTrash',
        type: OperationEnum.Delete,
        title: '删除',
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: visible ? 'IcHide' : 'IcShow',
        type: OperationEnum.Hide,
        title: visible ? '隐藏' : '显示',
      },
    },
    {
      type: ListItemType.LINE,
      isHidden: !option,
      data: {},
      disableClick: true,
    },
  ];

  return (
    <div className="next-modal w-[240px] py-2">
      <ListView
        className="rounded-r"
        items={menuItems}
        onItemClick={(item) => {
          if (item.data.type === OperationEnum.Delete) {
            if (realGroupName(groupName)) {
              openModal.warning({
                title: (
                  <p className="mt-5 mb-10 w-60">
                    是否确定删除分组？
                    <br />
                    将同时删除分组内的所有记录
                  </p>
                ),
                confirmText: '删除',
                confirm() {
                  removeGroup(viewId, groupName, isSubGroup);
                  closeModal();
                },
              });
            }
          }

          if (item.data.type === OperationEnum.Hide) {
            closeModal();
            updateGroupVisible({
              viewId,
              groupName: realGroupName(groupName),
              visible: !visible,
              isSubGroup,
            });
          }
        }}
      />

      {option && (
        <ColorSelector
          value={option.color}
          bgColorFn={getTagBackgroundColor}
          onSelect={(color) => {
            const { groupBy, subGroupBy } = getViewFormat(viewId) ?? {};
            const propertyId = isSubGroup ? subGroupBy?.property : groupBy?.property;
            if (!propertyId) return;
            updateOption(collectionId, propertyId, {
              id: option.id,
              color,
            });
          }}
        />
      )}
    </div>
  );
};
