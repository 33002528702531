/** 由于只需要保存一份剪切的数据且不需要响应式渲染，因此一个全局变量足矣 */

import { observableStoreOp } from 'src/services/rxjs-redux/map';
import { getIsCutKey } from 'src/services/rxjs-redux/patch';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';

/**
 * 同一个空间同一个窗口剪切粘贴的情况下，会优先使用[移动到]逻辑而不是[重新创建]
 */
interface CutStore {
  blockIds: string[];
  spaceId: string;
}
let cutStore: CutStore | undefined = undefined;

export const setCutStore = (store: CutStore | undefined) => {
  let mapKeys = store?.blockIds;
  if (!mapKeys) {
    mapKeys = cutStore?.blockIds;
  }
  cutStore = store;
  mapKeys?.forEach((uuid) => observableStoreOp.onlyPatch(getIsCutKey(uuid)));
};

export const getCurStore = () => {
  return cutStore;
};

export const useIsCut = (uuid: string) => {
  return useObservableStore(() => cutStore?.blockIds.includes(uuid), [uuid], { onlyCut: true });
};
