import type { SpacePlanType } from '@flowus/shared';
import * as Sentry from '@sentry/browser';
import { keys } from 'lodash-es';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { CREATE_SPACE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { $spacesState } from 'src/services/spaces/spaces';
import { useFetchMe } from '../user/use-fetch-me';

interface Options {
  title: string;
  planType: SpacePlanType.freePersonal | SpacePlanType.freeTeam;
  industry?: string;
  size?: string;
  scene?: string;
}
/**
 * 创建空间
 */
export const useCreateSpace = () => {
  const fetchMe = useFetchMe();
  return useCallback(
    async (userId: string, options: Options) => {
      // 目前创建空间依赖于后台接口
      const res = await request.infra.createUserSpace(userId, options);
      try {
        await request.infra.initSpacePage.raw(res.uuid);
      } catch (err) {
        Sentry.captureException(err);
      }
      dispatch(CREATE_SPACE({ space: res }));
      void fetchMe();
      return res.uuid;
    },
    [fetchMe]
  );
};

export const isNeedCreateSpace = () => {
  return keys($spacesState.getState()).length === 0;
};
