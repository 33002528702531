import Cookies from 'js-cookie';
import { useFinalValue } from './react-utils';

export const LanguageKey = 'next_lng';

export enum Language {
  en = 'en',
  zhHans = 'zh-Hans',
  zhHant = 'zh-Hant',
  ja = 'ja',
  ko = 'ko',
  fr = 'fr',
  de = 'de',
  es = 'es',
  ru = 'ru',
}

export const LanguageList = [
  {
    title: 'English',
    value: Language.en,
  },
  {
    title: 'Русский',
    value: Language.ru,
  },
  {
    title: '繁體中文',
    value: Language.zhHant,
  },
  {
    title: '简体中文',
    value: Language.zhHans,
  },
];

export const useLanguage = () => {
  const currentLanguage = useFinalValue(() => {
    const lng = getCurrentLanguage();
    return LanguageList.find((i) => i.value === lng) || LanguageList[0]!;
  });

  const changeLanguage = (value: string) => {
    Cookies.set(LanguageKey, value, {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
  };

  return { currentLanguage, changeLanguage };
};

export const getCurrentLanguage = () => {
  // 获取浏览器设置的首选语言
  // @ts-ignore 旧浏览器
  // const lng = navigator.language;

  // 处理语言字符串，提取主要语言部分
  const primaryLanguage = String(Cookies.get(LanguageKey));

  return adaptLanguage(primaryLanguage.trim());
};

export const adaptLanguage = (lng: string) => {
  const lowLng = lng.toLocaleLowerCase();

  const tw = ['zh-TW', 'zh-HK', 'zh-MO', 'zh-TW', 'zh-CHT', 'zh-Hant', 'tc'].map((i) =>
    i.toLocaleLowerCase()
  );

  if (tw.includes(lowLng)) {
    //繁体中文
    return Language.zhHant;
  }

  //简体中文
  const zh = ['zh-Hans', 'hans', 'zh-CN', 'zh'].map((i) => i.toLocaleLowerCase());
  if (zh.includes(lowLng)) {
    return Language.zhHans;
  }

  const ru = ['ru-RU', 'ru'].map((i) => i.toLocaleLowerCase());
  // 俄语
  if (ru.includes(lowLng)) {
    return Language.ru;
  }

  return lng;
};
