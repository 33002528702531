import type { BlockDataDTO, BlockStatus, PermissionDTO } from '@next-space/fe-api-idl';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';

export const ROLE_WEIGHT = {
  [PermissionRole.NONE]: 0,
  [PermissionRole.READER]: 1,
  [PermissionRole.COMMENTER]: 2,
  [PermissionRole.WRITER]: 3,
  [PermissionRole.EDITOR]: 4,
};

export const PAGE_TYPES = [
  BlockType.PAGE,
  BlockType.FOLDER,
  BlockType.COLLECTION_VIEW_PAGE,
  BlockType.COLLECTION_VIEW,
  BlockType.REFERENCE_COLLECTION,
  BlockType.REFERENCE_COLLECTION_PAGE,
  BlockType.MIND_MAPPING,
  BlockType.MIND_MAPPING_PAGE,
];

export const EXPORT_PAGE_TYPES = [
  BlockType.PAGE,
  BlockType.COLLECTION_VIEW_PAGE,
  BlockType.COLLECTION_VIEW,
  BlockType.REFERENCE_COLLECTION,
  BlockType.REFERENCE_COLLECTION_PAGE,
  BlockType.MIND_MAPPING,
  BlockType.MIND_MAPPING_PAGE,
];

export const COLLECTION_TYPE = [
  BlockType.COLLECTION_VIEW_PAGE,
  BlockType.COLLECTION_VIEW,
  BlockType.REFERENCE_COLLECTION,
  BlockType.REFERENCE_COLLECTION_PAGE,
];

export interface Share {
  shared: boolean;
  title?: string;
  /** 被风控了 */
  illegal: boolean;
  parentId?: string;
  isRestricted: boolean;
  /** 允许复制、打印、下载 */
  allowDuplicate: boolean;
  allowDownload: boolean;
  allowSelectionCopy: boolean;
  permissions: PermissionDTO[];
  role: PermissionRole;
  roleWithoutPublic: PermissionRole;
  /** 分享页显示侧边导航目录 */
  allowShowSidebar: boolean;
  /** 分享页显示面包屑导航 */
  allowShowBreadcrumb: boolean;
  password?: string;
  /** 分享权限 */
  publicRole: PermissionRole;
  /** 分享开启seo */
  allowSeo: boolean;
  /** 显示 AI 会话 */
  allowShowAIChat: boolean;
  /**是否允许订阅 */
  allowSubscribe: boolean;
  /**分享有效时长，undefinded或者空串为长期有效，其他则是有效截止日期的时间戳*/
  linkValidDate?: number;
  /**付费分享页面金额 */
  accessFee?: number;
  /**付费分享页面金额 */
  sharePageDistributionRatio?: number;
  /**是否支持预览 */
  openPreview?: boolean;
  /**付费后有效期 (单位是天) */
  accessFeeValidDays?: number;
  /**预览块个数 */
  previewBlockCount?: number;
  originPrice?: number;
}

export interface NextBlock {
  /** 块 uuid */
  uuid: string;
  /** 空间 uuid */
  spaceId: string;
  /** 父节点 uuid */
  parentId: string;
  /** 块类型 */
  type: BlockType;
  /** 块背景色 */
  backgroundColor: string;
  /** 块文字色 */
  textColor: string;
  /** 块数据 */
  data: BlockDataDTO & {
    // 下面这些参数都是本地使用的
    /** 这个localUrl是本地使用的，图片/文件/封面上传后需要临时用到 */
    localUrl?: string;
    /** 由于封面图已经使用了localUrl这个变量，所以图标上传只能使用另外的变量 */
    iconUrl?: string;
    /** 是否展示空的说明块(空的说明块blur的时候需要不显示) */
    showEmptyCaption?: boolean;
    /** 多维表的顶部的 描述(description) */
    showEmptyDescription?: boolean;
    /** SEO 的标题 */
    seoTitle?: string;
    /** SEO 的描述 */
    seoDescription?: string;
  };
  moved?: {
    movedAt: number;
    movedBy: string;
    movedToBlockId: string;
    movedToSpaceId: string;
  };
  /** 是否多维表的模板页面 */
  isTemplate?: boolean;
  /** 模板页面列表，仅 collection 有此字段 */
  templatePages?: string[];
  /** 块版本 */
  version: number;
  /** 状态，1正常，-1删除 */
  status: BlockStatus;
  /** 子元素 */
  subNodes: string[];
  /** 表格视图 */
  views?: string[];
  /** 是否本地创建 */
  local?: boolean;
  /** 更新时间 */
  updatedAt?: number;
  /** 更新者 */
  updatedBy?: string;
  /** 创建时间 */
  createdAt?: number;
  /** 创建者 */
  createdBy?: string;
  /** 删除时间 */
  deletedAt?: number;
  /** 删除者 */
  deletedBy?: string;
  /** 权限 */
  permissions: PermissionDTO[];
  /** block 的合法性 */
  legal?: Legal;
  /** 块评论  */
  discussions?: string[];
  /** 隐藏当前 block，在 page 中不显示  */
  hidden?: boolean;
}

export enum Legal {
  UNKNOWN,
  LEGAL,
  ILLEGAL,
}

export const RoleLevel: Record<PermissionRole, number> = {
  [PermissionRole.EDITOR]: 5,
  [PermissionRole.WRITER]: 4,
  [PermissionRole.COMMENTER]: 3,
  [PermissionRole.READER]: 2,
  [PermissionRole.NONE]: 1,
};
