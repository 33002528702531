import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, CollectionViewType, TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { BitableContext } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { List } from 'src/common/components/virtual';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { LIST_AFTER_COLLECTION_VIEW_PAGESORT } from 'src/redux/actions/collection-view';
import { addBlock } from 'src/redux/managers/block/add';
import { cache, dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { ScrollRefContext } from 'src/views/main/page-doc/context';
import type { CollectionProperty } from '../../../bitable-manager/property-list';
import { DEFAULT_COL_WIDTH, DEFAULT_ROW_HEIGHT } from '../../../const';
import { getPropertySegments } from '../../../hooks';
import { ListRow } from '../../body/list-row';
import { Header } from '../../header';
import { antiCycleSet_RelationEditor } from '../editor';
import type { CellEditorProps } from '../types';
import { getRelationRecords } from './get-relation-records';
import { useRelationRecords } from './relation-value';
import { getCollectionRecords } from 'src/hooks/collection-view/use-get-sorted-records';
import { useOpenPageWay } from 'src/hooks/collection-view/use-collection-view';
import { useSearchUI } from 'src/components/search/use-search-ui';
import { LoadingContainer } from 'src/common/components/loading-container';

export const RelationEditor: FC<CellEditorProps> = ({
  recordIds,
  recordId,
  propertyId,
  width,
  viewId,
}) => {
  const { relationRecords, relationCollection } = useRelationRecords(recordId, propertyId);
  const readonly = useReadonly(recordId, false);
  const updatePropertyValue = useUpdatePropertyValue();
  const openPage = useOpenPage();
  const openWay = useOpenPageWay(viewId ?? '');
  const relationRecordContainer = useRef<HTMLDivElement>(null);
  const transaction = useTransaction();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [searchWord, setSearchWord] = useState('');
  const relationCollectionId = relationCollection?.uuid ?? '';

  const { resultList, onInputChange, keyword, loadMore, hasMore, loading, firstTime } = useSearchUI(
    {
      type: 'collection',
      fileType: undefined,
      source: 'relationMenu',
      parentId: relationCollectionId,
      canSearchWithEmpty: readonly ? false : true,
      getPerPage: () => 100,
    }
  );
  const finalList = useMemo(() => {
    let list = getCollectionRecords(relationCollectionId, getState(), false, keyword);
    list = list.sort((a, b) => {
      const updateAtA = a.updatedAt ?? 0;
      const updateAtB = b.updatedAt ?? 0;
      return updateAtB - updateAtA;
    });
    const idSet = new Set(resultList.map((v) => v.uuid));
    const tempList = resultList.slice(0);
    list.forEach((v) => {
      if (!idSet.has(v.uuid)) {
        idSet.add(v.uuid);
        tempList.push({
          ...v,
          title: '',
        });
      }
    });
    return tempList;
  }, [keyword, relationCollectionId, resultList]);

  const context: BitableContext = useMemo(() => {
    return {
      collectionId: relationCollection?.uuid ?? '',
      viewParentId: relationCollection?.uuid ?? '',
      viewType: CollectionViewType.TABLE,
      viewId: '',
      readonly: true,
      managerReadonly: true,
      relationEditor: true,
      changeView() {},
    };
  }, [relationCollection?.uuid]);

  useEffect(() => focus(), [relationRecords]);

  const removeRelationRecord = (uuid: string) => {
    const segments = getPropertySegments(recordId, propertyId) ?? [];
    const newSegments = [...segments].filter((item) => {
      return !(item.type === TextType.LINK_PAGE && item.uuid === uuid);
    });

    updatePropertyValue(recordIds ?? recordId, propertyId, newSegments);
  };

  const focus = () => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      inputRef.current?.focus();
    });
  };

  const updateRelationRecord = (uuid: string) => {
    const segments = getPropertySegments(recordId, propertyId) ?? [];
    const { relationRecords } = getRelationRecords(recordId, propertyId);
    let newSegments: SegmentDTO[] = [];

    if (relationRecords.includes(uuid)) {
      newSegments = segments.filter((segment) => segment.uuid !== uuid);
    } else {
      newSegments = segments.concat({
        type: TextType.LINK_PAGE,
        uuid,
        text: '',
        enhancer: { code: false },
      });
    }

    updatePropertyValue(recordIds ?? recordId, propertyId, newSegments);
  };

  const createRecord = () => {
    transaction(
      () => {
        const { blocks } = getState();
        const collection = blocks[relationCollectionId];

        const blockInfo: Partial<NextBlock> = { type: BlockType.PAGE };
        blockInfo.data = { segments: textToSegments(keyword) };
        const newRecordId = addBlock(blockInfo, {
          parentId: relationCollectionId,
          last: true,
        });

        collection?.views?.forEach((uuid) => {
          dispatch(LIST_AFTER_COLLECTION_VIEW_PAGESORT({ uuid: newRecordId, viewId: uuid }));
        });

        updateRelationRecord(newRecordId);
      },
      { noThrottle: true }
    );
  };

  const clearAll = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onInputChange('');
    focus();
  };

  // const subNodesSet = new Set(relationCollection?.subNodes ?? []);
  const searchList = finalList
    .filter(
      (item) =>
        item.type === BlockType.PAGE &&
        // subNodesSet.has(item.uuid) &&
        !relationRecords.includes(item.uuid)
    )
    .map((item) => item.uuid);

  const renderRelationRecords = relationRecords.filter((uuid) => {
    const block = cache.blocks[uuid];
    return (
      block?.type === BlockType.PAGE &&
      segmentsToText(block.data.segments).includes(readonly ? searchWord : keyword)
    );
  });

  if (!relationCollection) return null;

  const properties: CollectionProperty[] = [];
  Object.entries(relationCollection.data.schema ?? {}).forEach(([property, schema]) => {
    const isTitle = property === 'title';
    const item = {
      property,
      visible: true,
      name: schema.name,
      type: schema.type,
      width: isTitle ? 1.5 * DEFAULT_COL_WIDTH : DEFAULT_COL_WIDTH,
    };

    if (isTitle) {
      properties.unshift(item);
    } else {
      properties.push(item);
    }
  });

  const renderItem = (index: number) => {
    const recordId = renderRelationRecords[index];
    if (!recordId) return null;

    const icon = (
      <Icon
        className={cx('sticky left-2 z-10 self-center', readonly && 'opacity-0')}
        name="MIcCircleDelete"
        onClick={(event) => {
          if (readonly) return;
          event.stopPropagation();
          removeRelationRecord(recordId);
        }}
        size="large"
      />
    );

    return (
      <ListRow
        index={index}
        recordId={recordId}
        properties={properties}
        settingIcon={icon}
        onClick={(_, event) => {
          const { illegal } = getPermissions(recordId);
          openPage(recordId, {
            illegal,
            forceOpenInRight: event.altKey,
            openWay,
            forceOpenNewTab: event.ctrlKey || event.metaKey,
          });
        }}
      />
    );
  };

  const renderUnselectedItem = (index: number) => {
    const recordId = searchList[index];
    if (!recordId) return null;

    const iconName = relationRecords.includes(recordId) ? 'MIcCircleDelete' : 'MIcCircleAdd';
    const settingIcon = (
      <Icon
        className={cx(
          'sticky left-2 self-center z-10',
          iconName === 'MIcCircleAdd' && 'invisible group-hover:visible'
        )}
        name={iconName}
        size="large"
      />
    );
    return (
      <ListRow
        index={index}
        recordId={recordId}
        properties={properties}
        settingIcon={settingIcon}
        onClick={(uuid: string) => updateRelationRecord(uuid)}
      />
    );
  };

  return (
    <div className={cx('relative w-[800px] pb-4')} style={{ width }}>
      <div
        className="flex items-center justify-between py-1 border-b relative pr-3"
        ref={containerRef}
      >
        <Input
          autoFocus
          placeholder={readonly ? '查找页面' : '查找或创建新页面'}
          className="h-8 flex-grow bg-white2 border-none"
          showBorder={false}
          onChange={readonly ? (val) => setSearchWord(val) : onInputChange}
          inputRef={inputRef}
        />
        <div className="flex items-center">
          <Icon
            size="middle"
            name="IcUploadCancel"
            onClick={clearAll}
            className="text-grey4 cursor-pointer animate-hover"
          />

          <div
            className="flex items-center h-8 ml-4 px-1 text-t2 max-w-[150px] "
            onClick={(event) => {
              const { illegal } = getPermissions(recordId);
              openPage(relationCollectionId, {
                illegal,
                forceOpenInRight: event.altKey,
                openWay,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              });
            }}
          >
            <InlinePage
              uuid={relationCollectionId}
              interactable={true}
              className="whitespace-nowrap text-ellipsis"
            />
          </div>
        </div>
      </div>

      {loading && firstTime && renderRelationRecords.length === 0 && (
        <LoadingContainer className="w-full h-[60px]" />
      )}

      {renderRelationRecords.length > 0 && (
        <>
          <div className="px-4 mt-2 mb-2 text-grey3 text-t2">
            {renderRelationRecords.length} 条关联记录
          </div>
          {relationRecords.length === 0 ? (
            <div className="text-t2 flex h-[60px] w-full items-center justify-center text-grey3">
              {readonly ? '关联记录为空' : '搜索查找要关联的记录'}
            </div>
          ) : (
            <div className="max-h-[250px] w-full overflow-auto" ref={relationRecordContainer}>
              <ScrollRefContext.Provider value={relationRecordContainer}>
                <div className="relative w-max min-w-full">
                  <BitableContext.Provider value={context}>
                    <Header properties={properties} className="pl-8 z-50 bg-white2" />
                    <List
                      bufferSize={10}
                      scrollRef={relationRecordContainer}
                      getItemSize={() => DEFAULT_ROW_HEIGHT}
                      count={renderRelationRecords.length}
                      getItemKey={(index: number) => renderRelationRecords[index] ?? `row-${index}`}
                      renderItem={renderItem}
                    />
                  </BitableContext.Provider>
                </div>
              </ScrollRefContext.Provider>
            </div>
          )}
        </>
      )}

      {!readonly && (
        <>
          {searchList.length > 0 && (
            <div className="px-4 mb-2 mt-4 text-grey3 text-t sticky top-0">选择关联记录</div>
          )}

          <div className="w-full max-h-[250px] overflow-auto" ref={scrollRef}>
            {searchList.length > 0 && (
              <ScrollRefContext.Provider value={scrollRef}>
                <div className="relative w-max min-w-full">
                  <BitableContext.Provider value={context}>
                    <Header properties={properties} className="pl-8 z-50 bg-white2" />
                    <InfiniteScroll
                      pageStart={1}
                      initialLoad={false}
                      useWindow={false}
                      loadMore={loadMore}
                      getScrollParent={() => scrollRef.current}
                      hasMore={hasMore}
                    >
                      <List
                        bufferSize={10}
                        scrollRef={scrollRef}
                        getItemSize={() => DEFAULT_ROW_HEIGHT}
                        count={searchList.length}
                        getItemKey={(index: number) => searchList[index] ?? `row-${index}`}
                        renderItem={renderUnselectedItem}
                      />
                    </InfiniteScroll>
                  </BitableContext.Provider>
                </div>
              </ScrollRefContext.Provider>
            )}
          </div>

          {keyword && hasMore && (
            <div
              className="w-full h-[38px] text-t2 text-grey3 flex items-center pl-2.5 border-b cursor-pointer animate-hover border-t"
              onClick={loadMore}
            >
              加载更多
            </div>
          )}

          {keyword && (
            <div
              className="w-full h-[38px] text-t2 text-grey3 flex items-center pl-2.5 border-b cursor-pointer animate-hover"
              onClick={createRecord}
            >
              <Icon size="middle" name="IcAddBoard" className="mr-2.5" />
              在&nbsp;
              <InlinePage uuid={relationCollectionId} />
              &nbsp;中创建&nbsp;
              <span className="text-black">{keyword}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

antiCycleSet_RelationEditor(RelationEditor);

// const RecordItem: FC<{ uuid: string }> = ({ uuid }) => {
//   const block = usePickBlock(uuid, ['data'], ['segments']);
//   const { illegal } = usePermissions(uuid);

//   if (!block) return null;

//   return (
//     <div className="py-px animate-hover-black3 flex h-9 cursor-pointer items-center justify-between rounded pr-1">
//       {illegal && (
//         <div className="flex min-w-[240px] items-center whitespace-nowrap opacity-30">
//           <IconTrigger
//             className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
//             blockId={uuid}
//             trigger={false}
//             iconSize={20}
//             defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
//           />
//           <span className="text-t2 text-ellipsis border-b border-grey6 align-middle">
//             {ILLEGAL_TEXT}
//           </span>
//         </div>
//       )}

//       {!illegal && (
//         <>
//           <div className={'flex min-w-[240px] items-center whitespace-nowrap'}>
//             <IconTrigger
//               className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
//               blockId={uuid}
//               trigger={false}
//               iconSize={20}
//               defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
//             />
//             <RichText
//               className="text-t2-medium inline break-words align-middle"
//               plugins={TITLE_EDITOR_PLUGINS}
//               segments={block?.data.segments}
//               placeholder={UNTITLED}
//               interactable={false}
//             />
//           </div>

//           <PropertyValues recordId={uuid} />
//         </>
//       )}
//     </div>
//   );
// };
