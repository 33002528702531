import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useRef } from 'react';
import type { BitableManager as BitableManager0 } from 'src/bitable/bitable-manager';
import { BoardView } from 'src/bitable/board-view';
import { CalendarView } from 'src/bitable/calendar-view';
import { BitableContext } from 'src/bitable/context';
import { FormView } from 'src/bitable/form-view';
import { GalleryView } from 'src/bitable/gallery-view';
import { TableListView } from 'src/bitable/list-view';
import { TableView } from 'src/bitable/table-view';
import { TimelineView } from 'src/bitable/timeline-view';
import { NextModalProvider } from 'src/common/components/next-modal';
import { Footer } from 'src/components/footer';
import { LinkPageTabs } from 'src/components/link-page-tabs';
import { AiBlankArea } from 'src/editor/editor/uikit/blank-area';
import { useAddCollectionView } from 'src/hooks/block/use-add-collection-view';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCheckCollectionRecord } from 'src/hooks/block/use-check-collection-record';
import { useTableCellWrap } from 'src/hooks/collection-view/use-collection-view';
import { useCollectionViewId } from 'src/hooks/collection-view/use-collection-view-id';
import { useSyncCollectionView } from 'src/hooks/collection-view/use-sync-collection-view';
import { useReadonly, useSaveLayoutInfo } from 'src/hooks/page';
import { usePageLayoutStyle } from 'src/hooks/page/use-page-layout';
import { useCtrlDragScroll } from 'src/hooks/utils/use-ctrl-drag-scroll';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { PageOpenDiscussions } from 'src/views/comments/page-discussions';
import { PageScrollRefContext } from '../page-doc/context';
import { PageHeader } from '../page-doc/page-header';

let BitableManager: typeof BitableManager0;

export const antiCycleSet_BitableManager = (BitableManager0: typeof BitableManager) => {
  BitableManager = BitableManager0;
};

export const PageBitable: FC<{ uuid: string }> = memo(({ uuid, children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const pageManager = useRef<HTMLDivElement | null>(null);
  const collectionId = useObservableStore(
    ({ blocks }) => {
      let collectionId = uuid;
      const block = blocks[uuid];
      if (
        block?.type === BlockType.REFERENCE_COLLECTION ||
        block?.type === BlockType.REFERENCE_COLLECTION_PAGE
      ) {
        collectionId = block.data.ref?.uuid ?? '';
      }
      return collectionId;
    },
    [uuid]
  );
  const isLocked = useBlockLocked(uuid);
  const readonly = useReadonly(collectionId, false);
  const managerReadonly = useReadonly(uuid, false);
  const [viewId, setViewId] = useCollectionViewId(uuid);
  const addCollectionView = useAddCollectionView();
  useSyncCollectionView(viewId);
  useSaveLayoutInfo(uuid, scrollRef);
  useCheckCollectionRecord(collectionId);
  const viewType = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId ?? '']?.type,
    [viewId]
  );
  const BitableView = getBiTableView(viewType);
  const { fontStyle } = usePageLayoutStyle(uuid);
  const enableDragScroll = viewType && ![CollectionViewType.TIMELINE].includes(viewType);
  useCtrlDragScroll(scrollRef, { enable: enableDragScroll });
  const commentAlignment = useObservableStore(
    (state) => state.blocks[collectionId]?.data.format?.commentAlignment ?? 'top',
    [collectionId]
  );
  const tableCellWrap = useTableCellWrap(viewId);

  useEffect(() => {
    if (readonly) return;

    // 无视图时创建视图
    if (!viewId) {
      const newViewId = addCollectionView(uuid, '表格', CollectionViewType.TABLE);
      if (newViewId) {
        setViewId(newViewId);
      }
    }
  }, [addCollectionView, readonly, viewId, uuid, setViewId]);

  const context: BitableContext = useMemo(() => {
    return {
      tableCellWrap,
      collectionId,
      viewParentId: uuid,
      viewType,
      viewId: viewId ?? '',
      readonly,
      managerReadonly,
      isLocked,
      changeView: setViewId,
      pageManager,
    };
  }, [
    collectionId,
    isLocked,
    managerReadonly,
    readonly,
    setViewId,
    tableCellWrap,
    uuid,
    viewId,
    viewType,
  ]);

  if (!collectionId || !viewId) return null;

  return (
    <BitableContext.Provider value={context}>
      <NextModalProvider>
        <div
          className={cx(
            'overflow-auto next-space-page will-change-scroll',
            $searchParams.print ? 'overflow-x-hidden' : 'relative flex flex-col flex-1'
          )}
          ref={scrollRef}
          data-page-id={uuid}
          onScroll={() => emitter.emit('removeHoverMenu')}
          style={fontStyle}
        >
          <PageScrollRefContext.Provider value={scrollRef}>
            <PageHeader uuid={uuid} className="md:px-12 px-24 sticky left-0" />
            <BitableManager className="md:px-12 px-24 left-0 mt-5 sticky top-[-1px] z-50" />
            <div className="relative flex-1 md:px-12 px-24">
              <article className="block-content">
                <BitableView scrollRef={scrollRef} />
              </article>
            </div>
            {commentAlignment === 'bottom' && (
              <div className="sticky left-0 px-24 md:px-12">
                <PageOpenDiscussions pageId={collectionId} isBottom={true} />
              </div>
            )}
            <div hidden={readonly} className="sticky left-0 px-24 md:px-12 my-24">
              <LinkPageTabs uuid={uuid} />
            </div>
            <AiBlankArea />
            {children}
            {readonly && (
              <div className="sticky left-0">
                <Footer />
              </div>
            )}
          </PageScrollRefContext.Provider>
        </div>
      </NextModalProvider>
    </BitableContext.Provider>
  );
});

export const getBiTableView = (type: CollectionViewType | undefined) => {
  if ($searchParams.print) {
    return TableView;
  }

  switch (type) {
    case CollectionViewType.TABLE:
      return TableView;
    case CollectionViewType.BOARD:
      return BoardView;
    case CollectionViewType.GALLERY:
      return GalleryView;
    case CollectionViewType.LIST:
      return TableListView;
    case CollectionViewType.TIMELINE:
      return TimelineView;
    case CollectionViewType.CALENDAR:
      return CalendarView;
    case CollectionViewType.FORM:
      return FormView;
    default:
      return TableView;
  }
};
