import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { ILLEGAL_TEXT } from 'src/common/const';
import { request } from 'src/common/request';
import { useReadonly } from 'src/hooks/page';
import { useGetIsOwner } from 'src/hooks/share/use-owner';
import { useRestorePermission } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useSpaceDomainPath } from 'src/hooks/space';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { getBlockPageLikeTitle } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { AppealResult } from '../appeal-result';
import { ShareAudit } from '../share-audit';
import type { ShareProps } from '../types';

export const ShareMainFooter: FC<Pick<ShareProps, 'uuid'>> = ({ uuid }) => {
  const { parentId, isRestricted, illegal, role, shared } = usePermissions(uuid, {
    wait: 100,
  });
  // 继承的被风控了
  const { illegal: parentIsIllegal } = usePermissions(parentId ?? uuid, { wait: 100 });
  const readonly = useReadonly(uuid);
  const openAudit = useOpenAudit(uuid);
  const restorePermission = useRestorePermission(uuid);
  const openModal = useOpenModal();
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const showAudit = useShowAudit(uuid);
  const domainPath = useSpaceDomainPath(block?.spaceId);

  if (!isRestricted && !illegal) return null;
  if (!block) return null;
  // 根目录受限时不展示
  if (block.parentId === block.spaceId) return null;
  if (!shared) return null;

  const onClick = () => {
    openModal.warning({
      title: (
        <div className="px-5 w-80">
          <div>确认恢复权限继承？</div>
          <div>将继承上级页面的协作分享设置，同时保留已有协作者</div>
        </div>
      ),
      confirmText: '确认',
      confirm: () => {
        restorePermission();
        dispatch(TRANSACTION_FIRE());
        bizTracker.event('restore_to_inherit');
      },
    });
  };

  return (
    <div className="flex justify-between items-center py-4 px-3 text-grey3 bg-grey9 border-t border-grey6 text-t2">
      <div className="flex items-center text-t4">
        {(illegal || parentId) && <Icon name="IcShareDanger" size="small" className="mr-1" />}

        {illegal ? (
          <span>
            该页面涉嫌违反
            <NavLink
              to={`${domainPath}/${uuid}`}
              className="ml-1 text-active_color underline animate-click"
            >
              平台分享政策
            </NavLink>
            ，无法继续分享
          </span>
        ) : (
          <>
            {parentId && (
              <div className="flex font-medium text-grey3 text-t4">
                {isRestricted ? '不延用上级' : '延用上级'}
                <NavLink
                  to={`${domainPath}/${parentId}`}
                  className={'text-black animate-click max-w-[80px] text-ellipsis mx-1'}
                >
                  {parentIsIllegal
                    ? ILLEGAL_TEXT
                    : getBlockPageLikeTitle(block.type, block.data.segments)}
                </NavLink>
                {isRestricted ? '的权限' : '的分享'}
              </div>
            )}
          </>
        )}
      </div>

      {!readonly &&
        (illegal && showAudit ? (
          <div
            onClick={openAudit}
            className="shrink-0 font-medium text-black text-t4-medium animate-click"
          >
            我要申诉
          </div>
        ) : (
          isRestricted &&
          role === PermissionRole.EDITOR && (
            <div
              onClick={onClick}
              className="shrink-0 self-start mr-1 text-black text-t4-medium animate-click"
            >
              恢复权限继承
            </div>
          )
        ))}
    </div>
  );
};

export const useOpenAudit = (
  uuid: string,
  opt?: {
    blockType?: 'block' | 'form';
  }
) => {
  const openModal = useOpenModal();

  return useCallback(() => {
    // https://dev.allflow.cn/swagger/#/editor/editor.shareCheck
    enum ShareCheckType {
      report = 'complain', // 举报
      justice = 'appeal', // 申诉
    }

    void request.editor.shareCheck(uuid, ShareCheckType.justice).then((canReport) => {
      if (canReport === false) {
        openModal.modal({
          content: ({ onCloseModal: onClose }) => (
            <AppealResult success={false} type="justice" onCloseModal={onClose} />
          ),
        });
      } else {
        openModal.modal({
          content: ({ onCloseModal }) => (
            <ShareAudit
              blockType={opt?.blockType}
              type="justice"
              uuid={uuid}
              onCloseModal={onCloseModal}
            />
          ),
        });
      }
    });
  }, [uuid, openModal, opt?.blockType]);
};

export const useShowAudit = (uuid: string) => {
  const { role } = usePermissions(uuid);
  const getIsOwner = useGetIsOwner();

  const showBtn =
    [
      PermissionRole.COMMENTER,
      PermissionRole.READER,
      PermissionRole.EDITOR,
      PermissionRole.WRITER,
    ].includes(role) || getIsOwner(uuid);
  return showBtn;
};
