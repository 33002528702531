import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { cx } from '@flowus/common/cx';
import { OverlayContainerContext } from '@flowus/common/hooks/react-utils';
import { deepEqual } from '@flowus/common/utils/tools';
import { CollectionSchemaType, CollectionViewType, CoverType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { assign, clamp, throttle } from 'lodash-es';
import { css } from 'otion';
import type { CSSProperties, FC, KeyboardEvent } from 'react';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { ImagesProvider } from 'src/components/images-provider';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { segmentsToText } from 'src/editor/utils/editor';
import { useClearSelectedCellValue } from 'src/hooks/block/use-clear-selected-cell-value';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import { useSendPresenceData } from 'src/hooks/user/use-send-presence-data';
import { cleanSelectBlock } from 'src/hooks/utils/clean-select';
import { getIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import type { SelectCell } from 'src/redux/types';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { reSetDropInfo } from 'src/services/app/hook/use-drop-info';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { getAutoScrollY } from 'src/utils/auto-scroll';
import { handleLastMouseEventInCells } from 'src/utils/cell-utils';
import { useScrollRef } from 'src/views/main/page-doc/context';
import { COMPUTED_PROPERTIES, READONLY_PROPERTIES } from '../../const';
import { RowContext } from '../body/row-context';
import { RecordCreatedFrom } from '../types';
import { CheckboxValue } from './checkbox';
import { CreatedAtValue, DateValue, UpdatedAtValue } from './date';
import { DragCycle } from './drag-cycle';
import { CellEditor } from './editor';
import { FilesValue, useAddUploadTask } from './files';
import { FormulaValue } from './formula';
import { IdNumberValue } from './id-number';
import { EmailValue, PhoneValue, UrlValue } from './link';
import { NumValue } from './num';
import { CreatedByValue, PersonValue, UpdatedByValue } from './person';
import { RelationValue } from './relation/relation-value';
import { RichTextValue } from './rich-text';
import { RollupValue } from './rollup';
import { MultiSelectValue, SelectValue } from './select';
import './style.css';
import { TextValue } from './text';
import { TitleValue } from './title';
import type { CellViewProps } from './types';
import { Site } from './types';
import { propertiesSelector } from 'src/hooks/collection-view/use-properties';
import { getDynamicPageId } from 'src/utils/getPageId';
import { getAllExpandIds } from 'src/hooks/block/use-open-subitem';

const MOVE_OFFSET_NUM = 5;

type CellProps = CellViewProps & { style: React.CSSProperties };

export const Cell: FC<CellProps> = memo((props) => {
  const {
    recordId,
    propertyId,
    groupValue,
    style,
    isLastCell,
    propertyIndex,
    expandNode,
    idnumberIndex,
  } = props;
  const { collectionId, viewId, relationEditor, tableCellWrap, viewType } = useBitable();
  const { illegal, pageId, readonly, index } = useContext(RowContext);
  const scrollContainer = useScrollRef();
  const cellRef = useRef<HTMLDivElement>(null);
  const alreadySelectCell = useRef(false);
  const [editing, setEditing] = useState(false);
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const [nodeCreateTime] = useState(Date.now());
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const syncId = useSyncId();
  const cellType = useObservableBlock(collectionId, (block) => {
    return block?.data.schema?.[propertyId]?.type;
  });
  const CellValueComponent = getCellValueComponent(cellType);
  const updateValue = useUpdatePropertyValue();
  const clearSelectedCellValue = useClearSelectedCellValue();
  const sendPresenceData = useSendPresenceData();
  // const isSelectBlock = useIsSelected(recordId, viewId, groupValue);
  const isReadonlyProp =
    !cellType || READONLY_PROPERTIES.includes(cellType) || COMPUTED_PROPERTIES.includes(cellType);
  const { isSelectedCell, isFocused } = useObservableStore(
    ({ ui: { selectedCells } }) => {
      const selectCell = selectedCells.find(
        (cell) =>
          cell.recordId === recordId &&
          cell.propertyId === propertyId &&
          cell.viewId === viewId &&
          cell.groupValue === groupValue
      );

      const isSelectedCell = Boolean(selectCell);
      const focusCell = selectedCells[0];
      const _isFocused = focusCell
        ? focusCell.recordId === recordId &&
          focusCell.propertyId === propertyId &&
          focusCell.viewId === viewId &&
          focusCell.groupValue === groupValue
        : false;

      return { isSelectedCell, isFocused: _isFocused };
    },
    [recordId, propertyId, viewId, groupValue],
    {
      obsSelectCell: [{ recordId, propertyId, viewId }],
      wait: 50,
      waitMode: 'throttle',
      ignoreDeep: true,
    }
  );
  // #region 弹窗行为
  const modalId = `cell-${recordId}-${propertyId}-${viewId}-${groupValue}`;

  useEffect(() => {
    return () => closeModal(modalId);
  }, [modalId, closeModal]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!cellRef.current || !editing) return;

    let animation: ModalSchema.RenderDropdown['animation'];
    const edge = 3;

    const rect = cellRef.current.getBoundingClientRect();
    const modalStyle: CSSProperties = {};

    if (
      cellType === CollectionSchemaType.TEXT ||
      cellType === CollectionSchemaType.TITLE ||
      cellType === CollectionSchemaType.NUMBER ||
      cellType === CollectionSchemaType.EMAIL ||
      cellType === CollectionSchemaType.URL ||
      cellType === CollectionSchemaType.PHONE
    ) {
      animation = 'fadeIn';
      modalStyle.overflow = 'auto';
      if (tableCellWrap) {
        modalStyle.width = rect.width + edge;
        modalStyle.minHeight = Math.min(rect.height + edge, window.innerHeight * 0.8);
      } else {
        modalStyle.minWidth = rect.width + edge;
        modalStyle.maxWidth = 320;
      }
    }

    if (
      cellType === CollectionSchemaType.SELECT ||
      cellType === CollectionSchemaType.MULTI_SELECT
    ) {
      modalStyle.minWidth = rect.width + edge;
      modalStyle.maxWidth = 320;
    }

    if (cellType === CollectionSchemaType.FILE) {
      modalStyle.minWidth = 262;
      modalStyle.maxWidth = 320;
    }

    const offset = cellType === CollectionSchemaType.DATE ? rect.height : 0;
    sendPresenceData(pageId, recordId);
    openModal.dropdown({
      modalId,
      animation,
      placement: 'bottom-start',
      offset: [-edge / 2, -rect.height - edge / 2 + offset],
      popcorn: {
        getBoundingClientRect: () => rect,
      },
      closeBeforeCallBack() {
        setEditing(false);

        if (
          window.event &&
          window.event.type === 'keydown' &&
          isHotkey('Enter')(window.event as unknown as KeyboardEvent)
        ) {
          let { sortedRecordIds } = getSortedRecordIds({
            viewId,
            groupValue,
            pageId,
            openSubItem: true,
          });
          sortedRecordIds = getAllExpandIds(collectionId, viewId, sortedRecordIds);
          let recordIndex = sortedRecordIds.findIndex((rowId) => rowId === recordId);
          recordIndex += 1;
          recordIndex = clamp(recordIndex, 0, sortedRecordIds.length - 1);
          const nextRecordId = sortedRecordIds[recordIndex] ?? recordId;
          dispatch(
            uiActions.update({
              selectedCells: [{ propertyId, recordId: nextRecordId, viewId, groupValue }],
            })
          );
        } else {
          dispatch(
            uiActions.update({
              selectedCells: [{ propertyId, recordId, viewId, syncId, groupValue }],
            })
          );
        }
      },
      content({ onCloseModal }) {
        return (
          <div
            className="next-modal max-h-[80vh]"
            style={modalStyle}
            ref={containerRef}
            onKeyDown={(e) => {
              if (isHotkey('Tab')(e)) {
                onCloseModal();
                handleKeyDown(e);
              }
            }}
          >
            <OverlayContainerContext.Provider value={containerRef}>
              <CellEditor
                site={Site.CELL}
                viewId={viewId}
                recordId={recordId}
                propertyId={propertyId}
                onUpdate={() => {}}
                onClose={onCloseModal}
              />
            </OverlayContainerContext.Provider>
          </div>
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cellType,
    editing,
    groupValue,
    index,
    modalId,
    openModal,
    pageId,
    propertyId,
    recordId,
    sendPresenceData,
    syncId,
    tableCellWrap,
    viewId,
  ]);
  // #endregion
  useEffect(() => {
    if (isFocused && !editing && cellRef.current) {
      /**
       * 由于外层column用了虚拟列表，因此在选中一个单元格后左右滑动，focus方法会多次触发导致ui定位问题。
       * 所以这里做了一个创建时间间隔判断，避免触发上面说的情况.（按方向键操作验证过是ok的）
       * 还有个case是多选checkbox，enter批量选中/取消选中，需要focus响应事件
       */
      if (Date.now() - nodeCreateTime > 1000 || cellType === CollectionSchemaType.CHECKBOX) {
        cellRef.current.focus();
      }
    }
  }, [isSelectedCell, editing, isFocused, nodeCreateTime, cellType]);

  useEffect(() => {
    const newCreatedRecord = $appUiStateCache.$newCreatedRecord;
    const isNewCreateRecordId =
      recordId === newCreatedRecord?.id && viewId === newCreatedRecord.viewId;

    if (
      isNewCreateRecordId &&
      cellType === CollectionSchemaType.TITLE &&
      (!newCreatedRecord.from || newCreatedRecord.from === RecordCreatedFrom.TIMELINE_TABLE)
    ) {
      setEditing(true);
      setAppUiState({ $newCreatedRecord: undefined });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suppressClickEditRef = useRef(false);

  const handleClick = (event: React.MouseEvent) => {
    if (!cellType) return;
    if (alreadySelectCell.current) return;

    // ID属性不能编辑
    if (cellType === CollectionSchemaType.ID_NUMBER && !readonly) {
      // 参照notion，如果当前已经选中，则不做任何处理
      if (
        getIsSelected({
          blocks: cache.blocks,
          selectedBlocks: cache.ui.selectedBlocks,
          uuid: recordId,
          groupValue,
          syncId,
          viewId,
        })
      ) {
        return;
      }
      if (event.shiftKey) {
        dispatch(
          uiActions.addSelectBlock({
            blockId: recordId,
            syncId,
            viewId,
          })
        );
      } else {
        dispatch(
          uiActions.updateSelectBlocks([
            {
              blockId: recordId,
              syncId,
              viewId,
            },
          ])
        );
      }
      return;
    }
    if (
      readonly &&
      cellType !== CollectionSchemaType.FILE &&
      cellType !== CollectionSchemaType.RELATION
    ) {
      return;
    }
    if ($appUiStateCache.$isSelecting) return;
    if (suppressClickEditRef.current) return;

    if (illegal) return;
    if (cellType === CollectionSchemaType.CHECKBOX) {
      const { blocks } = getState();
      const value = blocks[recordId]?.data.collectionProperties?.[propertyId];
      const text = segmentsToText(value);
      updateValue(recordId, propertyId, formatCheckBoxValue(text) ? 'NO' : 'YES');
      return;
    }

    if (editing) {
      // 有时会存在 editing 是 true，但是也没有显示 dropdown 的情况，closeBeforeCallback 那有点问题
      setEditing(false);
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setEditing(true);
      });
    } else {
      // 公式属性是特例，点击单元格可以编辑公式
      if (isReadonlyProp && cellType !== CollectionSchemaType.FORMULA) {
        return;
      }
      setEditing(true);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (illegal || !cellType || $appUiStateCache.$isOpenImagePreview) return;

    const { ui, blocks, collectionViews } = getState();
    const focusedCell = ui.selectedCells[0];
    const lastSelectedCell = ui.selectedCells[ui.selectedCells.length - 1];
    if (!lastSelectedCell || !focusedCell) return;
    // #region 方向键
    if (
      isHotkey([
        'ArrowUp',
        'shift+ArrowUp',
        'ArrowDown',
        'shift+ArrowDown',
        'ArrowLeft',
        'shift+ArrowLeft',
        'ArrowRight',
        'shift+ArrowRight',
        'Tab',
      ])(event)
    ) {
      event.preventDefault();
      const { selectedCells } = ui;
      // 当前未选中任何内容
      if (selectedCells.length === 0) return;
      const isShift = event.shiftKey;

      let { sortedRecordIds } = getSortedRecordIds({
        viewId,
        groupValue,
        pageId,
        openSubItem: true,
      });
      sortedRecordIds = getAllExpandIds(collectionId, viewId, sortedRecordIds);

      const view = collectionViews[viewId];
      const [properties, timelineTableProperties] = propertiesSelector(
        viewId,
        { includeIdNumber: true },
        CoverType.NONE,
        cache,
        view?.type
      );
      const propertyIds = (isTimeline ? timelineTableProperties : properties)
        ?.filter((property) => property.visible)
        .map((item) => item.property);

      if (!propertyIds) return;

      let newSelectedPropertyIndex = propertyIds.findIndex(
        (id) => id === (isShift ? lastSelectedCell.propertyId : focusedCell.propertyId)
      );
      let newSelectedRecordIndex = sortedRecordIds.findIndex(
        (rowId) => rowId === (isShift ? lastSelectedCell.recordId : focusedCell.recordId)
      );

      if (newSelectedPropertyIndex === -1 || newSelectedRecordIndex === -1) {
        cleanSelectBlock('cell');
        return;
      }

      if (isHotkey(['ArrowUp', 'shift+ArrowUp'])(event)) {
        newSelectedRecordIndex -= 1;
      } else if (isHotkey(['ArrowDown', 'shift+ArrowDown'])(event)) {
        newSelectedRecordIndex += 1;
      } else if (isHotkey(['ArrowLeft', 'shift+ArrowLeft'])(event)) {
        newSelectedPropertyIndex -= 1;
      } else if (isHotkey(['ArrowRight', 'shift+ArrowRight', 'Tab'])(event)) {
        newSelectedPropertyIndex += 1;
      }
      if (isShift) {
        // shift左右多选情况下，当前已经是第一列或者最后一列时不再继续往下执行了
        if (newSelectedPropertyIndex < 0 || newSelectedPropertyIndex >= propertyIds.length) {
          return;
        }
        // shift上下多选情况下，当前已经是第一行或者最后一行时不再继续往下执行了
        if (newSelectedRecordIndex < 0 || newSelectedRecordIndex >= sortedRecordIds.length) {
          return;
        }
      }

      if (newSelectedPropertyIndex < 0) {
        newSelectedRecordIndex -= 1;
        newSelectedPropertyIndex = propertyIds.length - 1;
      }
      if (newSelectedPropertyIndex > propertyIds.length - 1) {
        newSelectedPropertyIndex = 0;
        newSelectedRecordIndex += 1;
      }
      newSelectedRecordIndex = clamp(newSelectedRecordIndex, 0, sortedRecordIds.length - 1);
      const focusedCellPropertyIndex = propertyIds.findIndex((id) => id === focusedCell.propertyId);
      const focusedSelectedRecordIndex = sortedRecordIds.findIndex(
        (rowId) => rowId === focusedCell.recordId
      );

      const selectedPropertyId = propertyIds[newSelectedPropertyIndex];
      const selectedRecordId = sortedRecordIds[newSelectedRecordIndex];
      if (selectedPropertyId && selectedRecordId) {
        if (isShift) {
          // 从上到下选中record
          const isTopToBottom = newSelectedRecordIndex > focusedSelectedRecordIndex;
          // 从左到右选中cell
          const isLeftToRight = newSelectedPropertyIndex > focusedCellPropertyIndex;
          const selectedCells: SelectCell[] = [];
          // 算出多选时，从开始到结束的 行与列
          // 如果你对下面这段逻辑有疑惑，可以找我讨论一下 --> by lxw
          for (
            let recordIndex = focusedSelectedRecordIndex;
            isTopToBottom
              ? recordIndex <= newSelectedRecordIndex
              : recordIndex >= newSelectedRecordIndex;
            isTopToBottom ? recordIndex++ : recordIndex--
          ) {
            for (
              let propertyIndex = focusedCellPropertyIndex;
              isLeftToRight
                ? propertyIndex <= newSelectedPropertyIndex
                : propertyIndex >= newSelectedPropertyIndex;
              isLeftToRight ? propertyIndex++ : propertyIndex--
            ) {
              const propertyId = propertyIds[propertyIndex];
              const recordId = sortedRecordIds[recordIndex];
              if (propertyId && recordId) {
                selectedCells.push({
                  propertyId,
                  recordId,
                  viewId,
                  syncId,
                  groupValue,
                });
              }
            }
          }
          dispatch(uiActions.update({ selectedCells }));
        } else {
          dispatch(
            uiActions.update({
              selectedCells: [
                {
                  propertyId: selectedPropertyId,
                  recordId: selectedRecordId,
                  viewId,
                  syncId,
                  groupValue,
                },
              ],
            })
          );
        }
      } else {
        dispatch(uiActions.update({ selectedCells: [] }));
      }

      return;
    }
    // #endregion
    if (readonly) return; // 下面的是只读不可操作
    const value = blocks[recordId]?.data.collectionProperties?.[propertyId];

    if (isHotkey('Enter')(event)) {
      if (
        cellType === CollectionSchemaType.CHECKBOX ||
        cellType === CollectionSchemaType.ROLLUP ||
        READONLY_PROPERTIES.includes(cellType)
      ) {
        if (cellType === CollectionSchemaType.CHECKBOX) {
          const text = segmentsToText(value);
          const recordIds = cache.ui.selectedCells
            .filter((cell) => cell.propertyId === propertyId)
            .flatMap((cell) => cell.recordId);
          updateValue(recordIds, propertyId, formatCheckBoxValue(text) ? 'NO' : 'YES');
          // 交互上如果是多个的话就不需要选中下面一个
          if (recordIds.length > 1) return;
        }

        let { sortedRecordIds } = getSortedRecordIds({
          viewId,
          groupValue,
          pageId,
          openSubItem: true,
        });
        sortedRecordIds = getAllExpandIds(collectionId, viewId, sortedRecordIds);

        let recordIndex = sortedRecordIds.findIndex((rowId) => rowId === recordId);
        recordIndex += 1;
        recordIndex = clamp(recordIndex, 0, sortedRecordIds.length - 1);
        const nextRecordId = sortedRecordIds[recordIndex] ?? recordId;
        dispatch(
          uiActions.update({
            selectedCells: [{ propertyId, recordId: nextRecordId, viewId, syncId, groupValue }],
          })
        );
      } else {
        cleanSelectBlock('cell');
        setEditing(true);
      }
      event.preventDefault();
      return;
    }

    if (isHotkey(['Backspace', 'Delete'], { byKey: true })(event)) {
      clearSelectedCellValue(cache.ui.selectedCells);
    }
  };
  /** TODO: 这里的单/多选cell逻辑 快捷键相关是否单独移出来比较好？ */
  const handleMouseDown = (mouseDownEvent: React.MouseEvent) => {
    if (mouseDownEvent.button === 2) return;
    if (
      mouseDownEvent.target instanceof Element &&
      mouseDownEvent.target.closest('[data-disable-select], [data-draggable]')
    ) {
      return;
    }

    mouseDownEvent.preventDefault();

    if (!isSelectedCell && cellType !== CollectionSchemaType.CHECKBOX) {
      suppressClickEditRef.current = true;
    }

    const { autoScrollY, rafY } = getAutoScrollY();
    const container = scrollContainer?.current;
    if (!container) return;
    const mouseDownPos = { x: mouseDownEvent.clientX, y: mouseDownEvent.clientY };
    let isMoving = false;
    // 这里和 use-handle-mousedown 那边冲突了，加个 shiftKey 判断下

    const handleMouseMove = throttle((event: MouseEvent) => {
      if (!isMoving) {
        if (
          Math.abs(mouseDownPos.x - event.clientX) < MOVE_OFFSET_NUM &&
          Math.abs(mouseDownPos.y - event.clientY) < MOVE_OFFSET_NUM
        ) {
          return;
        }
        isMoving = true;
      }

      if (cache.ui.selectedCells.length === 0) {
        alreadySelectCell.current = true;
        setAppUiState({ $isSelecting: true });
        dispatch(
          uiActions.update({
            selectedCells: [{ recordId, propertyId, viewId, syncId, groupValue }],
          })
        );
      }

      autoScrollY({ y: event.clientY, container });

      handleLastMouseEventInCells(event);
    }, 60);

    const handleMouseUp = () => {
      cancelAnimationFrame(rafY.id);
      if (
        !alreadySelectCell.current &&
        !mouseDownEvent.shiftKey &&
        cellType !== CollectionSchemaType.ID_NUMBER
      ) {
        dispatch(
          uiActions.update({
            selectedCells: [{ recordId, propertyId, viewId, syncId, groupValue }],
          })
        );
      }
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      setTimeout(() => {
        suppressClickEditRef.current = false;
        alreadySelectCell.current = false;
        // 选框选一个cell的时候会触发handleClick，这里稍微延迟一下，
        // 在handleClick的时候发现isSelecting为true时不弹出编辑框
        setAppUiState({ $isSelecting: false });
      }, 0);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const addUploadTask = useAddUploadTask();
  const [isDragOver, setDragOver] = useState(false);

  const onDragOver = (event: React.DragEvent) => {
    if (
      cellType === CollectionSchemaType.FILE &&
      [...event.dataTransfer.items].some((it) => it.kind === 'file')
    ) {
      setDragOver(true);
    }
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
    reSetDropInfo();
    if (cellType === CollectionSchemaType.FILE) {
      [...event.dataTransfer.items]
        .filter((it) => it.kind === 'file')
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((it) => it.getAsFile()!)
        .forEach((file) => {
          void addUploadTask(file, [recordId], propertyId);
        });
    }
  };

  const showQuickEditBtn =
    cellType === CollectionSchemaType.SELECT ||
    cellType === CollectionSchemaType.FILE ||
    cellType === CollectionSchemaType.MULTI_SELECT ||
    cellType === CollectionSchemaType.RELATION ||
    cellType === CollectionSchemaType.FORMULA;

  const quickIconName =
    cellType === CollectionSchemaType.FILE
      ? 'IcBtnNew'
      : cellType === CollectionSchemaType.FORMULA
      ? 'IcFormula'
      : 'IcNaviUnfold';
  const attribute = {};
  if (cellType === CollectionSchemaType.ID_NUMBER) {
    assign(attribute, { 'data-no-cancel-selected': '' });
  }
  return (
    <div
      {...attribute}
      data-property-id={propertyId}
      ref={cellRef}
      tabIndex={-1}
      style={style}
      className={cx('min-h-full flex relative outline-none transition-all ease-out', {
        'selected-cell': isSelectedCell,
        'focused-cell': isFocused,
        'h-9': !tableCellWrap,
        // 'h-full': !tableCellWrap,
        'bg-black_006': isDragOver,
        'pointer-events-none': relationEditor,
        'border-r': !(isTimeline && isLastCell && !$searchParams.print),
      })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      onDragOver={onDragOver}
      onDragLeave={() => setDragOver(false)}
      onDragEnd={() => setDragOver(false)}
      onDrop={onDrop}
    >
      {illegal && cellType !== CollectionSchemaType.TITLE ? null : (
        <div
          className={cx(
            'w-full flex flex-shrink-0 relative overflow-hidden text-t2',
            {
              'break-words whitespace-pre-wrap min-h-full': tableCellWrap,
              'whitespace-nowrap flex overflow-ellipsis h-full': !tableCellWrap,
              'hover-box': showQuickEditBtn,
            },
            css({
              selectors: {
                '& > *': { flexShrink: !tableCellWrap ? 0 : undefined },
              },
            })
          )}
        >
          {propertyIndex === 0 && (
            <div
              className="mt-[5px]"
              style={{
                alignSelf: 'normal',
              }}
            >
              {expandNode}
            </div>
          )}
          <ImagesProvider>
            <CellValueComponent
              site={Site.CELL}
              recordId={recordId}
              propertyId={propertyId}
              idnumberIndex={idnumberIndex}
            />
          </ImagesProvider>
          {showQuickEditBtn && !readonly && (
            <button
              onClick={() => setEditing(true)}
              className={cx(
                'animate-hover-opaque border-grey6 absolute top-1.5 right-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded border bg-white1 opacity-0'
              )}
            >
              <Icon name={quickIconName} size="middle" className="text-grey3" />
            </button>
          )}
        </div>
      )}
      {!readonly && isFocused && !isReadonlyProp && (
        <DragCycle className="absolute z-[9] -right-0 -bottom-0" />
      )}
    </div>
  );
}, deepEqual);

/**
 * 根据 valueType 获取对应渲染的组件
 */
export const getCellValueComponent = (type?: CollectionSchemaType): FC<CellViewProps> => {
  switch (type) {
    case CollectionSchemaType.TITLE:
      return TitleValue;
    case CollectionSchemaType.NUMBER:
      return NumValue;
    case CollectionSchemaType.CHECKBOX:
      return CheckboxValue;
    case CollectionSchemaType.SELECT:
      return SelectValue;
    case CollectionSchemaType.MULTI_SELECT:
      return MultiSelectValue;
    case CollectionSchemaType.DATE:
      return DateValue;
    case CollectionSchemaType.PERSON:
      return PersonValue;
    case CollectionSchemaType.UPDATED_AT:
      return UpdatedAtValue;
    case CollectionSchemaType.CREATED_AT:
      return CreatedAtValue;
    case CollectionSchemaType.UPDATED_BY:
      return UpdatedByValue;
    case CollectionSchemaType.CREATED_BY:
      return CreatedByValue;
    case CollectionSchemaType.TEXT:
      return RichTextValue;
    case CollectionSchemaType.URL:
      return UrlValue;
    case CollectionSchemaType.EMAIL:
      return EmailValue;
    case CollectionSchemaType.PHONE:
      return PhoneValue;
    case CollectionSchemaType.FILE:
      return FilesValue;
    case CollectionSchemaType.FORMULA:
      return FormulaValue;
    case CollectionSchemaType.RELATION:
      return RelationValue;
    case CollectionSchemaType.ROLLUP:
      return RollupValue;
    case CollectionSchemaType.ID_NUMBER:
      return IdNumberValue;

    default:
      return TextValue;
  }
};
