import { throttle } from 'lodash-es';
import type { ObservableDataValueType } from './map';
import { observableStoreOp } from './map';

// 清空没地方监听的 obs
export const globalRxTimer = {
  run: throttle(() => {
    const map = observableStoreOp.getMap();
    const clean = (item: ObservableDataValueType, key: string) => {
      const observed = item.subscribe.listenerCount(key);
      if (!observed) {
        observableStoreOp.deleteObs(key);
      }
    };
    map.normal.forEach(clean);
    map.select.forEach(clean);
  }, 3000),
};
