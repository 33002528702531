import { LOCAL_LNG } from '@flowus/common/const';
import { newContent, newElement } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { INLINE_LINK_PAGE_TAG } from 'src/editor/editor/inline/const';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { cache } from 'src/redux/store';
import type { InlinePanelProps } from '../types';
import { CategoryType } from '../types';
import { useCreateInlinePageSegment } from '../use-create-inline-page';
import { useEditorKeyword } from '../use-editor-keyword';
import { useSearchPage } from '../use-search-page';
import { AddNewPageIn } from './add-new-page-in';

export const InlineLinkPagePanel: FC<InlinePanelProps> = (props) => {
  const { onCloseModal, blockId, left, right, getEditorModel, setFakeFocus } = props;
  const updateTask = useUpdateTask();
  const createInlinePageSegment = useCreateInlinePageSegment();
  const [showSearch, setShowSearch] = useState(false);

  const keyword = useEditorKeyword({
    ...props,
    symbol: '[[',
    tip: '输入内容进行搜索或创建',
    changeTipWhenComposing: true,
  });

  const { pageList, setPageFold, loading } = useSearchPage({ keyword });

  const items = useMemo(() => {
    const pageTitle = keyword ? `"${keyword}"` : '';

    return [
      {
        type: ListItemType.BLOCK_TEXT,
        data: {
          title: LOCAL_LNG.linkToPage,
          icon: loading && <LoadingIcon size="large" />,
        },
      },
      ...pageList,
      {
        type: ListItemType.LINE,
        data: {},
        disableClick: true,
      },
      {
        type: ListItemType.CUSTOM_RENDER,
        render() {
          return (
            <div
              className="flex items-center py-2 animate-hover px-4"
              onClick={(event) => {
                event.stopPropagation();

                const editorModel = getEditorModel(blockId);
                if (!editorModel) return;

                let realBlockId = blockId;
                if (!cache.blocks[realBlockId]) {
                  realBlockId = (editorModel as any).editorElement.dataset.realBlockId;
                }

                createInlinePageSegment({
                  setFakeFocus,
                  editorModel,
                  blockId: realBlockId,
                  title: keyword,
                  left,
                  right,
                });

                onCloseModal();
              }}
            >
              <Icon name="IcBtnNew" size="middle" />
              <div className="ml-1 text-t2 text-grey3 text-ellipsis">{`创建行内页面 ${pageTitle}`}</div>
            </div>
          );
        },
      },
      {
        type: ListItemType.CUSTOM_RENDER,
        render() {
          return (
            <div
              className="flex items-center py-2 animate-hover px-4"
              onClick={() => {
                setFakeFocus?.(true);
                setShowSearch(true);

                // 积分任务
                void updateTask(ActivityIds.CREATE_REF_PAGE, ActivitiesListType.advancedList);
              }}
            >
              <Icon name="IcTitleRelation" size="middle" />
              <div className="ml-1 text-t2 text-grey3 text-ellipsis">{`创建新的行内引用页面 ${pageTitle}`}</div>
            </div>
          );
        },
      },
    ];
  }, [
    keyword,
    loading,
    pageList,
    getEditorModel,
    blockId,
    createInlinePageSegment,
    setFakeFocus,
    left,
    right,
    onCloseModal,
    updateTask,
  ]);

  return showSearch ? (
    <AddNewPageIn {...props} title={keyword} />
  ) : (
    <ListView
      virtualList
      defaultActiveIndex={1}
      className="w-60 next-modal py-2 overflow-y-auto"
      items={items}
      onItemClick={(item, _, event) => {
        const editorModel = getEditorModel(blockId);
        if (!editorModel) return;

        if (item.data.categoryType === CategoryType.page) {
          // 积分任务
          void updateTask(ActivityIds.CREATE_REF_PAGE, ActivitiesListType.advancedList);
        }

        if (item.data.categoryType === CategoryType.foldPage) {
          event.preventDefault();
          setPageFold(false);
          void editorModel.requestFocus();
          return;
        }

        if (!editorModel.selection) return;
        const { focusOffset } = editorModel.selection;

        let offset = focusOffset;

        if (!setFakeFocus && keyword) {
          offset = left + keyword.length + 1;
        }

        editorModel.performChange((ctx) => {
          ctx
            .shadow()
            .select(left, offset)
            .replace(
              newContent([
                newElement(INLINE_LINK_PAGE_TAG, {
                  pageId: item.data.uuid,
                }),
              ])
            )
            .release();
          ctx.select(offset);
        });
        void editorModel.requestFocus();

        // 积分任务
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_LINK_PAGE, ActivitiesListType.advancedList);

        onCloseModal();
      }}
    />
  );
};
