export { SpacePlanType } from '@flowus/shared';
import type { CollectionViewDTO, SegmentDTO } from '@next-space/fe-api-idl';
import type * as reducers from './reducers';
import type { store } from './store';
export type { Legal, NextBlock } from '@flowus/common/block/type';

export type RootState = {
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>;
};
declare module 'react-redux' {
  export function useDispatch(): typeof store.dispatch;
  /** @deprecated use useFineSelector */
  export function useSelector<T>(
    selector: (state: RootState) => T,
    equalityFn?: (left: T, right: T) => boolean
  ): T;
  export function useStore(): typeof store;
}

// export enum SpacePlanType {
//   /** 个人专业版 */
//   pro = 'personal',
//   /** 个人免费版 */
//   freePersonal = 'freePersonal',
//   /** 小组试用版 */
//   freeTeam = 'freeTeam',
//   /** 小组版 */
//   smallTeam = 'smallTeam',
//   /** 团队版 */
//   team = 'team',
// }

// export interface NextBlock {
//   /** 块 uuid */
//   uuid: string;
//   /** 空间 uuid */
//   spaceId: string;
//   /** 父节点 uuid */
//   parentId: string;
//   /** 块类型 */
//   type: BlockType;
//   /** 块背景色 */
//   backgroundColor: string;
//   /** 块文字色 */
//   textColor: string;
//   /** 块数据 */
//   data: BlockDataDTO & {
//     // 下面这些参数都是本地使用的
//     /** 这个localUrl是本地使用的，图片/文件/封面上传后需要临时用到 */
//     localUrl?: string;
//     /** 由于封面图已经使用了localUrl这个变量，所以图标上传只能使用另外的变量 */
//     iconUrl?: string;
//     /** 是否展示空的说明块(空的说明块blur的时候需要不显示) */
//     showEmptyCaption?: boolean;
//     /** 多维表的顶部的 描述(description) */
//     showEmptyDescription?: boolean;
//   };
//   moved?: {
//     movedAt: number;
//     movedBy: string;
//     movedToBlockId: string;
//     movedToSpaceId: string;
//   };
//   /** 是否多维表的模板页面 */
//   isTemplate?: boolean;
//   /** 模板页面列表，仅 collection 有此字段 */
//   templatePages?: string[];
//   /** 块版本 */
//   version: number;
//   /** 状态，1正常，-1删除 */
//   status: BlockStatus;
//   /** 子元素 */
//   subNodes: string[];
//   /** 表格视图 */
//   views?: string[];
//   /** 是否本地创建 */
//   local?: boolean;
//   /** 更新时间 */
//   updatedAt?: number;
//   /** 更新者 */
//   updatedBy?: string;
//   /** 创建时间 */
//   createdAt?: number;
//   /** 创建者 */
//   createdBy?: string;
//   /** 删除时间 */
//   deletedAt?: number;
//   /** 删除者 */
//   deletedBy?: string;
//   /** 权限 */
//   permissions: PermissionDTO[];
//   /** block 的合法性 */
//   legal?: Legal;
//   /** 块评论  */
//   discussions?: string[];
//   /** 隐藏当前 block，在 page 中不显示  */
//   hidden?: boolean;
// }

// export enum Legal {
//   UNKNOWN,
//   LEGAL,
//   ILLEGAL,
// }

export type NextCollectionView = CollectionViewDTO;

export interface NextWhere {
  parentId: string;
  first?: boolean;
  last?: boolean;
  after?: string;
  before?: string;
  groupValue?: string;
  subGroupValue?: string;
  sourceRelationParentId?: string;
  targetRelationParentId?: string;
}

export interface CollectionWhere extends Omit<NextWhere, 'parentId'> {}

/** ui state 文件上传状态 */
export enum UploadStatus {
  uploading = 'uploading',
  success = 'success',
  failure = 'failure',
}

export interface UploadFile {
  id: string;
  blockId?: string; // 在image/video/audio/file block下上传文件时，此id用于具体的block查找进度条
  parentId?: string;
  spaceId: string;
  progress: number;
  status: UploadStatus;
  updatedAt: number;
  data: {
    size: number;
    segments: SegmentDTO[];
  };
  fileType?: 'icon' | 'cover' | 'avatar' | 'spaceIcon' | 'file' | 'import' | 'private';
}
export interface UploadInfo {
  key: string;
  uploadId?: string;
  name?: string;
  progress?: number;
  status?: UploadStatus;
  createdAt?: number;
  size?: number;
  url?: string;
  inTransferList?: boolean;
  /** 传输列表跳转需要 */
  pageId?: string;
  blockId?: string;
}

export interface ReadonlyProp {
  readonly?: boolean;
}

/** 视图方式 */
export enum ViewModel {
  normal = 'normal',
  card = 'card',
  preview = 'preview',
}
export type ViewModeType = 'normal' | 'card' | 'preview';

/** 成员状态 */
export interface MemberState {
  userId: string;
  pageId: string;
  activityTime: number; // 超过10分钟自动算退出
  blockId: string;
  present: boolean;
}

export type SegmentType = 'segments' | 'caption' | 'description';

export interface SelectCell {
  recordId: string;
  propertyId: string;
  viewId: string;
  groupValue?: string;
  syncId?: string;
}
export interface SelectBlock {
  blockId: string; // blockId
  viewId?: string;
  groupValues?: Record<string, string[]>;
  syncId?: string;
  editorKey?: string;
  mindMapId?: string;
}
