import { SearchParams } from '@flowus/const-config';
import { getSessionOp, SessionKeys, setSessionStorage } from '../storage';
import { VITE_WECHAT_ACCOUNT } from '../const';

export const requestWebChatCode = (toPath: string, state: Record<string, string | undefined>) => {
  const path = splicePathSearchParams(toPath, [
    {
      key: SearchParams.inviteCode,
      value: getSessionOp.inviteCode.get(),
    },
    {
      key: SearchParams.callbackPath,
      value: location.pathname,
    },
  ]);

  // 微信后台只固定了这一个域名，所以这里也只能固定这个域名
  let origin = 'https://flowus.cn';
  // let { origin } = location;

  if (/alpha|test|test-tc|dev/.test(location.origin)) {
    origin = location.origin;
  }

  setSessionStorage(SessionKeys.WECHAT_PAY_INFO, JSON.stringify(state));
  let callback = `${origin}${path}`;

  if (isDebugUrl()) {
    callback += '&~~debug~~';
  }

  //由于微信callback使用了code参数，我们的邀请码需要临时把关键字替换一下
  callback = encodeURIComponent(
    callback.replace(`${SearchParams.inviteCode}=`, `${SearchParams.inviteCodeByWeChat}=`)
  );
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${VITE_WECHAT_ACCOUNT}&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=flowus#wechat_redirect`;
  console.log('url: ', url);
  window.location.href = url;
};
export const isDebugUrl = () => {
  return location.href.includes('~~debug~~');
};

type LocationSearchParams =
  | { key: SearchParams; value: string | undefined }
  | { key: SearchParams; value?: string }[];

export const splicePathSearchParams = (path: string, params: LocationSearchParams) => {
  const query = new URLSearchParams(location.search);
  if (Array.isArray(params)) {
    params.forEach(({ key, value }) => value && query.set(key, value));
  } else {
    params.value && query.set(params.key, params.value);
  }
  const queryString = query.toString();
  return `${path}${queryString ? `?${queryString}` : ''}`;
};
