import { addSubscribe } from '@flowus/common';
import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { request } from 'src/common/request';
import { useOpenLogin } from 'src/components/empty/open-login';
import { useOpenShareLinkList } from 'src/components/share/share-buttons';
import { LocalStorageKey } from 'src/const/local-storage-state-key';
import { useIsNeedPay } from 'src/hooks/page/use-page-meta';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentUser, useCurrentUser } from 'src/hooks/user';
import { bizTracker } from 'src/utils/biz-tracker';
import { findFirstAllowSubscribePage } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useOpenButtonTips } from './helper';

/** 订阅按钮 */
export const SubscribeButton: FC<{}> = () => {
  const openLogin = useOpenLogin();
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, ['createdBy']);
  const currentUser = useCurrentUser();
  const isLogin = Boolean(currentUser.uuid);
  const { allowSubscribe } = usePermissions(pageId);
  const [subscribed, refreshSubscribed] = useApiState({
    getApi: () => request.infra.getSubscribeStatus(pageId),
    defaultValue: false,
    enable: isLogin,
  });
  const isNeedPay = useIsNeedPay(pageId);
  const ref = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();

  const openButtonTips = useOpenButtonTips({
    localKey: LocalStorageKey.SUBSCRIBE_TIP,
    endDay: 0,
  });

  useEffect(() => {
    if (!isLogin) return;
    const params = new URLSearchParams(location.search);
    const hasPopup = params.has('subscribe_popup');
    if (hasPopup) {
      openButtonTips(ref, {
        content: '本文开启了订阅功能,点击即可订阅',
      });
    }
  }, [isLogin, openButtonTips]);

  useEffect(() => {
    if (!isLogin) return;
    void refreshSubscribed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  if (!allowSubscribe) return null;
  if (__PRIVATE__) return null;

  return (
    <>
      <ShareRebateUrlButton />
      <Button
        colorType={subscribed ? 'active' : 'secondary'}
        className={'text-t2-medium flex-shrink-0'}
        onClick={async () => {
          if (!isLogin) {
            // 跳去登录
            openLogin();
            return;
          }
          const targetId = findFirstAllowSubscribePage(pageId) ?? pageId;

          await addSubscribe(
            {
              targetId,
              subscribed: !subscribed,
              addSubscribe: request.infra.addSubscribe,
              cancelSubscribe: request.infra.cancelSubscribe,
              user: currentUser,
              openDialog: openModal.warning,
              callback: (s) => s && location.reload(),
            },
            {
              pageId,
              needPay: isNeedPay,
              userId: getCurrentUser().uuid,
              createdBy: block?.createdBy,
              spaceId: block?.spaceId,
              event: bizTracker.event,
            }
          );

          void refreshSubscribed();
        }}
      >
        <span ref={ref}>{subscribed ? '订阅中' : '订阅'}</span>
      </Button>
    </>
  );
};

export const ShareRebateUrlButton: FC = () => {
  const pageId = useGetPageId();
  const onCopyRebateUrl = useOpenShareLinkList(pageId);

  // const { amount, payInfo } = usePayInfo(pageId);

  return (
    <>
      {/* {!!(payInfo?.sharePageDistributionRatio && payInfo.accessFee) && (
        <Tooltip popup={`当前文档已开启${payInfo?.sharePageDistributionRatio * 100}%付费分佣`}>
          <ProIcon
            text="💰 分销联盟计划"
            absolute={false}
            className="!rounded px-2 h-8"
            spanClass="text-t4-medium"
          />
        </Tooltip>
      )} */}
      <Button className="relative overflow-visible" onClick={onCopyRebateUrl}>
        {/* {!!amount && (
          <span
            className="absolute text-t4 text-white-base px-1.5 z-10 -top-2 -translate-y-0.5 scale-[0.8]"
            style={{
              background: 'linear-gradient(to right,#FF912B,#F94559)',
              borderRadius: '6px 6px 6px 0px',
              boxShadow: '0px 3px 6px #F9455950',
            }}
          >
            赚¥{amount}
          </span>
        )} */}
        <span>分享</span>
      </Button>
    </>
  );
};
