import { cx } from '@flowus/common/cx';
import { Dropdown } from '@flowus/common/next-modal/dropdown';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { DateFormat, DaySetting, PermissionType, TimeFormat } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import { omit } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DayModifiers, Modifier } from 'react-day-picker';
import DayPicker from 'react-day-picker';
import { Icon } from 'src/common/components/icon';
import { Switch } from 'src/common/components/switch';
import { Tooltip } from 'src/common/components/tooltip';
import { PRIORITY_PORTAL, globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { OptionItem } from 'src/components/option-item';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useReadonly } from 'src/hooks/page';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useCurrentSpaceDaySetting } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { $currentUserCache } from 'src/services/user/current-user';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from 'src/utils/date-utils';
import { DateInput } from './date-input';
import { findMatchReminderIndex, getValidDateFormat } from './find-match';
import { FormatSetting } from './format-setting';
import { RemindPerson } from './remind-person';
import './style.css';
import type { PickerDate } from './types';
import { remindDayOptions, remindTimeOptions } from './types';
import { TIME_FORMAT } from 'src/common/const';

const NavbarElement: FC<{
  onPreviousClick: (callback?: () => void) => void;
  onNextClick: (callback?: () => void) => void;
}> = ({ onPreviousClick, onNextClick }) => {
  return (
    <div className="absolute right-4 flex justify-between top-5">
      <Tooltip className="mr-2 rounded-sm animate-hover">
        <Icon name="IcArrowDateBack" size="middle" onClick={() => onPreviousClick()} />
      </Tooltip>
      <Tooltip className="rounded-sm animate-hover">
        <Icon name="IcArrowDateNext" size="middle" onClick={() => onNextClick()} />
      </Tooltip>
    </div>
  );
};

const CaptionElement: FC<{ date: Date }> = ({ date }) => (
  <div className="table-caption mt-2.5 h-10">
    <span className="flex items-center h-full text-black text-t2-bold">
      {dayjs(date).format('YYYY年MM月')}
    </span>
  </div>
);

interface Props {
  dateInfo: PickerDate;
  uiOption?: {
    /** 是否有时间点这个选项 default true*/
    hasSwitchTimeOption?: boolean;
    /** 是否有结束时间,这个暂未实现,可参考已实现的DateTimePickerExt组件逻辑 default false */
    hasEndTime?: boolean;
    /** 是否有时间格式设定选项 default true*/
    hasFormatSetting?: boolean;
    /** 去掉shadow default false*/
    noShadow?: boolean;
    /** 是否设置成提醒 default false*/
    hasRemind?: boolean;
    /** 置灰某些天数 */
    disabledDays?: Modifier | Modifier[];
    /** footer */
    footer?: React.ReactNode;
  };
  onChange: (date: PickerDate) => void;
  onClear?: () => void;
  closeDatePicker?: () => void;
}
/** 这个组件还没完全实现时间范围的选择，只是留了相关的props参数和部分逻辑 */
export const DateTimePicker: FC<Props> = (props) => {
  const { dateInfo, onClear, closeDatePicker, uiOption = {} } = props;
  const [formatDropDownVisible, setFormatDropDownVisible] = useState(false);
  const updateTask = useUpdateTask();
  const { includeTime = true, reminder } = dateInfo;
  const {
    hasSwitchTimeOption = true,
    hasEndTime = false,
    noShadow = false,
    hasFormatSetting = true,
    hasRemind = false,
  } = uiOption;
  const { timeFormat = TimeFormat.H_MM } = dateInfo;
  let { dateFormat = DateFormat.YYYY_MM_DD } = dateInfo;
  dateFormat = getValidDateFormat(dateFormat);
  const isGuest = useIsGuest();
  const readOnly = useReadonly();
  const daySetting = useCurrentSpaceDaySetting();

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('enter')(event)) {
        closeDatePicker?.();
      }
    };

    globalListenerHelper.addEventListener('keydown', handleKeyDown, PRIORITY_PORTAL, true);
    return () => {
      globalListenerHelper.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [closeDatePicker]);

  const hasReminder = Boolean(reminder);
  useEffect(() => {
    if (hasReminder && !isGuest && !readOnly) {
      // 需要拉协作者
      void getSpaceGuests(getCurrentSpaceId());
    }
  }, [hasReminder, isGuest, readOnly]);

  const onChange = useCallback(
    (partial: Partial<PickerDate>) => {
      props.onChange({ ...dateInfo, ...partial });
    },
    [dateInfo, props]
  );

  const handleTimeSwitch = (open: boolean) => {
    const from = dayjs(dateInfo.from);
    let fromDate: Date;
    const now = dayjs(Date.now());
    if (open) {
      fromDate = from.hour(now.hour()).minute(now.minute()).toDate();
    } else {
      fromDate = from.hour(0).minute(0).toDate();
    }
    let to: Date | undefined;
    if (hasEndTime) {
      const toDayjs = dayjs(dateInfo.to);
      if (open) {
        to = toDayjs.hour(12).minute(0).toDate();
      } else {
        to = toDayjs.hour(0).minute(0).toDate();
      }
    }
    onChange({
      from: fromDate,
      to,
      reminder: undefined,
      includeTime: open,
    });
  };

  const handleDayClick = useCallback(
    (day: Date, modifiers: DayModifiers, e) => {
      if (modifiers.disabled) return;
      e.stopPropagation();
      // NOTE 如果你想实现时间范围，可参考DateTimePickerExt
      const hour = dateInfo.from.getHours();
      const minutes = dateInfo.from.getMinutes();
      // keep time
      const startDate = dayjs(day).hour(hour).minute(minutes).toDate();
      onChange({
        from: startDate,
        to: undefined,
      });

      if (!includeTime) {
        closeDatePicker?.();
      }
    },
    [closeDatePicker, dateInfo.from, includeTime, onChange]
  );
  const remindOptions = includeTime ? remindTimeOptions : remindDayOptions;
  const remindOptionIndex = useMemo(() => {
    return findMatchReminderIndex(reminder, includeTime);
  }, [includeTime, reminder]);
  return (
    <div className={cx('w-72 bg-white2 pt-2.5 pb-[5px]', { 'next-modal': !noShadow })}>
      <DateInput
        date={dateInfo.from}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        includeTime={includeTime}
        autoFocus={true}
        onChange={(date) => {
          const remindOption = remindOptions[remindOptionIndex];
          const me = [{ userId: $currentUserCache.uuid, type: PermissionType.USER }];
          const userPermissions: PermissionDTO[] | undefined =
            dateInfo.reminder?.userPermissions ?? me;
          if (!hasReminder) {
            onChange({
              from: date,
            });
            return;
          }
          // 如果是包含时间点的提醒，需要更新一下时间点
          if (includeTime) {
            const time = dayjs(date).format(TIME_FORMAT);
            onChange({
              from: date,
              reminder: {
                ...omit(remindOption, 'name'),
                time,
                userPermissions,
              },
            });
          }
        }}
      />
      <DayPicker
        className="flex justify-center w-full"
        fixedWeeks
        disabledDays={props.uiOption?.disabledDays}
        selectedDays={dateInfo.from}
        firstDayOfWeek={daySetting === DaySetting.MON ? 1 : 0}
        navbarElement={NavbarElement}
        captionElement={CaptionElement}
        locale="zh-cn"
        month={dateInfo.from}
        months={MONTHS}
        onDayClick={handleDayClick}
        weekdaysShort={WEEKDAYS_SHORT}
        weekdaysLong={WEEKDAYS_LONG}
      />

      {hasSwitchTimeOption && (
        <>
          <div className={`mx-4 h-px bg-grey6 my-1.5`} />
          <div>
            <div className="flex items-center my-1 justify-between h-10 px-4">
              <span className="text-t2">时间点</span>
              <Switch open={includeTime} onSwitch={handleTimeSwitch} />
            </div>
          </div>
        </>
      )}
      {hasRemind && (
        <>
          <div className="mx-4 h-px bg-grey6 my-1.5" />
          <div>
            <div className="flex items-center my-1 justify-between h-10 px-4">
              <Icon name="IcMenuAlarm" size={'middle'} />
              <OptionItem
                name="提醒"
                className="w-full ml-2"
                selectedIndex={remindOptionIndex}
                options={remindOptions.map((v) => {
                  return { ...v, id: v.name };
                })}
                onSelect={(_, index) => {
                  if (index === 0) {
                    onChange({ reminder: undefined });
                    return;
                  }

                  // 积分任务
                  void updateTask(ActivityIds.CREATE_DATE_MENTION, ActivitiesListType.basicList, {
                    step2: true,
                  });

                  /**
                   * by wangzhen
                   * 补充一个时间提醒相关的需求策略：
                   * 当设置提醒用户为外部协作者时，开启提醒，默认提醒成员为该用户，且该用户不可修改；
                   * 已开始提醒时，该用户均无法修改提醒成员。相关研发及测试同事留意
                   */
                  const remindOption = remindOptions[index];
                  const me = [{ userId: $currentUserCache.uuid, type: PermissionType.USER }];
                  const userPermissions: PermissionDTO[] | undefined =
                    dateInfo.reminder?.userPermissions ?? me;

                  if (remindOption) {
                    if (includeTime) {
                      const time = dayjs(dateInfo.from).format(TIME_FORMAT);
                      onChange({
                        reminder: {
                          ...omit(remindOption, 'name'),
                          time,
                          userPermissions,
                        },
                      });
                    } else {
                      onChange({
                        reminder: {
                          ...omit(remindOption, 'name'),
                          userPermissions,
                        },
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
      {hasRemind && remindOptionIndex > 0 && (
        <RemindPerson
          userPermissions={dateInfo.reminder?.userPermissions ?? []}
          onSelect={(permission) => {
            onChange({ reminder: { ...dateInfo.reminder, userPermissions: permission } });
          }}
        />
      )}
      {hasFormatSetting && (
        <>
          <Dropdown
            visible={formatDropDownVisible}
            placement="right"
            content={
              <FormatSetting
                disableTimeFormat={!includeTime}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                onSelectDateFormat={(format) => {
                  setFormatDropDownVisible(false);
                  onChange({
                    dateFormat: format,
                  });
                }}
                onSelectTimeFormat={(format) => {
                  setFormatDropDownVisible(false);
                  onChange({
                    timeFormat: format,
                  });
                }}
              />
            }
          >
            <div className="h-px bg-grey6 mx-4" />
            <div className="cursor-pointer">
              <div className="flex items-center h-10 my-1 px-4">
                <Icon name={'IcSettings'} size={'middle'} />
                <span className="text-t2 ml-2">日期格式</span>
              </div>
            </div>
          </Dropdown>
        </>
      )}
      {props.onClear && (
        <>
          <div className="h-px bg-grey6 my-1 mx-4" />
          <div className="flex items-center h-10 pl-4 cursor-pointer text-t2" onClick={onClear}>
            清除
          </div>
        </>
      )}
      {props.uiOption?.footer}
    </div>
  );
};
