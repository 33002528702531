import type { FC } from 'react';
import { Site } from './table-view/cell/types';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { Tooltip } from 'src/common/components/tooltip';
import { Icon } from 'src/common/components/icon';
import { cx } from '@flowus/common/cx';

interface Props {
  text: string;
  site: Site;
  className?: string;
}
export const CopyButton: FC<Props> = (props) => {
  if (!props.text || props.text === 'undefined') return null;
  return (
    <button
      data-disable-select
      className={cx(
        'flex w-6 h-6 right-1 space-x-1 group-scope-hover:opacity-100 absolute top-[5px] opacity-0 transition-opacity',
        props.className
      )}
      hidden={![Site.CELL, Site.FIELD].includes(props.site)}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          void writeTextInClipboard(props.text);
        }}
        className="rounded border bg-white1 p-0.5 animate-hover animate-click"
      >
        <Tooltip popup={'复制'}>
          <Icon size="middle" name={'IcMenuCopy'} className="text-grey3" />
        </Tooltip>
      </span>
    </button>
  );
};
