import { split, toLower } from 'lodash-es';

/** 获取string的文字，去掉空格 */
export const cleanText = (str = '') => {
  return str
    .replace(/[\u200B-\u200D\uFEFF]/g, '') // 移除零宽字符
    .replace(/[\n\r\t\f\v]/g, '') // 移除所有空白和控制字符
    .replace(/\s+/g, ' ') // 将连续空白替换为单个空格
    .trim(); // 去除首尾空格
};

/** 获取string的文字，转小写并去掉空格 */
export const stringToLowerCaseAndRemoveSpace = (str = '') => {
  return toLower(cleanText(str));
};

export const getIsChinese = (str = '') => {
  const reg = /^[\u4e00-\u9FA5]+$/;
  return reg.test(str);
};

export const insertString = (str: string, char: string, start: number, end?: number) => {
  if (!str) {
    return char;
  }
  if (!char) {
    return str;
  }

  const arr = split(str, '');
  arr.splice(start, end ?? 0, char);
  return arr.join('');
};

export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toLowerCaseFirstLetter = (str = '') => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
