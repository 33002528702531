import { useOpenModal } from '@flowus/common/next-modal';
import html2canvas from '@flowus/html2canvas';
import { Modals } from '@flowus/shared';
import QRCode from 'qrcode';
import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Icon } from 'src/common/components/icon';
import { request } from 'src/common/request';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { BlockRenderer } from 'src/editor/editor/uikit/block';
import { segmentsToText } from 'src/editor/utils/editor';
import { ReadonlyContext } from 'src/hooks/block/use-block-locked';
import { useSpace } from 'src/hooks/space';
import { Images } from 'src/image';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { bizTracker } from 'src/utils/biz-tracker';
import { getFirstTextBlockId } from 'src/utils/block-utils';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { urlFetcher } from 'src/utils/url-fetcher';

interface Props {
  pageId: string;
  url: string;
}
export const SharePoster: FC<Props> = (props) => {
  const spaceId = useObservableBlock(props.pageId, (s) => s?.spaceId);
  const divRef = useRef<HTMLDivElement>();
  const openModal = useOpenModal();

  return (
    <div className="w-[420px] overflow-auto max-h-[90vh] bg-white1 relative">
      <Header
        onDownload={async () => {
          if (divRef.current) {
            const id = openModal.loading({ title: '下载中' }).modalId;
            const canvas = await html2canvas(divRef.current, {
              useCORS: true,
              scale: window.devicePixelRatio,
            });
            const title = segmentsToText(cache.blocks[props.pageId]?.data.segments);
            const res = canvas.toDataURL('image/png', 1);
            const binaryData = atob(res.split(',')[1]!);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < binaryData.length; i++) {
              uint8Array[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'image/png' });
            void downloadUrlFile(URL.createObjectURL(blob), `${title}.png`);
            openModal.closeModal(id);
          }
        }}
      />
      <div className="mt-14 text-grey3 text-t4-medium w-full text-center">
        *页面介绍会默认显示第一个「文字块」的内容
      </div>
      <div
        ref={(ref) => {
          if (ref) {
            divRef.current = ref;
          }
        }}
        className="w-full px-8 bg-white1 -mb-1 -mr-0.5"
      >
        <Cover {...props} />
        <SpaceInfo spaceId={spaceId} />

        <PageInfo pageId={props.pageId} />
        <QRInfo url={props.url} />
      </div>
    </div>
  );
};

const Header: FC<{ onDownload: () => Promise<void> }> = (props) => {
  const openModal = useOpenModal();
  return (
    <div className="z-[1] fixed top-0 h-12 w-full grid grid-cols-3 text-center items-center justify-between px-4 border-b border-grey6 bg-white1">
      <Icon
        name={'IcClose'}
        size="small"
        onClick={() => {
          openModal.closeModal(Modals.SHARE_POSTER);
        }}
      />
      <span className="text-t1-medium">海报分享</span>
      <div
        className="text-right text-t2-medium text-active_color shrink-0 animate-click"
        onClick={() => {
          void props.onDownload();
        }}
      >
        下载
      </div>
    </div>
  );
};

const Cover: FC<Props> = (props) => {
  const cover = useObservableBlock(props.pageId, (s) => s?.data.cover);
  const [url, setUr] = useState<string>();
  useEffect(() => {
    if (url) return;
    // 获取封面
    const loadCover = async () => {
      const res = await request.infra.getKnowledgeCover.raw(props.pageId);
      let knowledgeCover = cover;
      if (res.code === 200 && res.data.cover) {
        knowledgeCover = res.data.cover;
      }
      const coverOssName = knowledgeCover;
      if (coverOssName) {
        if (coverOssName.startsWith('http')) {
          setUr(coverOssName);
        } else {
          const res = await urlFetcher.fetchImageUrl({
            blockId: props.pageId,
            ossName: coverOssName,
          });
          setUr(res);
        }
      }
    };
    void loadCover();
  }, [cover, props.pageId, url]);
  if (!url) return null;
  return (
    <>
      <div className="h-4"></div>
      <img src={url}></img>
    </>
  );
};

const SpaceInfo: FC<{ spaceId?: string }> = (props) => {
  const space = useSpace(props.spaceId);
  return (
    <div className="mt-4 flex items-center">
      <Avatar
        name={space.title}
        color={space.backgroundColor}
        icon={space.icon}
        iconSize={40}
        style={{ fontSize: '28px' }}
      />
      <div className="text-normal ml-2 text-h4 font-medium text-ellipsis">{space.title}</div>
    </div>
  );
};
const PageInfo: FC<{ pageId: string }> = (props) => {
  const title = useObservableBlock(
    props.pageId,
    (s) => segmentsToText(s?.data.segments) || getUntitledName(s?.type)
  );
  const firstTextBlockId = useObservableStore(
    (state) => {
      return getFirstTextBlockId(props.pageId, state.blocks);
    },
    [props.pageId]
  );

  return (
    <div className="mt-6">
      <div className="text-h2 text-normal">{title}</div>
      <ReadonlyContext.Provider value={true}>
        <div className="text-grey2 mt-2">
          {firstTextBlockId && <BlockRenderer id={firstTextBlockId} />}
        </div>
      </ReadonlyContext.Provider>
    </div>
  );
};
const QRInfo: FC<{ url: string }> = (props) => {
  const [qrCode, setQrCode] = useState<string>('');
  useEffect(() => {
    const loadQR = async () => {
      const _qr = await QRCode.toDataURL(`${props.url}`, {
        errorCorrectionLevel: 'H',
        margin: 2,
      });
      setQrCode(_qr);
    };
    void loadQR();
  }, [props.url]);
  if (!qrCode) return null;
  return (
    <div className="mt-8">
      <div className="flex">
        <img className="w-[120px]" src={qrCode} />
        <div className="ml-3 space-y-2 text-t2-medium flex flex-col justify-center">
          <div>微信扫码查看专栏</div>
          <div>
            点击右下角
            <span>
              <Icon name={'IcListMessage2'} size="small" />
            </span>
            订阅
          </div>
          <div>订阅后可在微信获得最新推送</div>
        </div>
      </div>
      <div className="w-full mt-8 flex justify-end ">
        <img className="h-8" src={Images.logoWithText} />
      </div>
      <div className="h-4 w-full"></div>
    </div>
  );
};

export const useOpenSharePoster = () => {
  const openModal = useOpenModal();
  return useCallback(
    (params: { url: string; pageId: string }) => {
      bizTracker.event('click_share_btn', {
        type: 'poster',
      });
      openModal.modal({
        modalId: Modals.SHARE_POSTER,
        containerClassName: '!overflow-hidden',
        content: () => {
          return <SharePoster pageId={params.pageId} url={params.url} />;
        },
      });
    },
    [openModal]
  );
};
