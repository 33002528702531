import type { FC } from 'react';
import { useState } from 'react';
import type { FilePreviewProps } from '.';
import { ReactReader } from 'react-reader';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { Button } from 'src/common/components/button';
import { judgeSharePage } from 'src/utils/getPageId';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { useCloseModal } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';

export const EPUBView: FC<FilePreviewProps> = (props) => {
  const block = usePickBlock(props.uuid, []);
  const pageScene = usePageScene();
  const isShare = judgeSharePage();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const closeModal = useCloseModal();
  // And your own state logic to persist state
  const [location, setLocation] = useState<string | number>(1);
  const locationChanged = (epubcifi: any) => {
    // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
    setLocation(epubcifi);
  };
  const isFreeSpaceLimit = useIsFreeSpaceLimit(block?.spaceId);
  if (isFreeSpaceLimit) {
    return (
      <div
        className="w-full h-full flex flex-col items-center justify-center"
        onClick={() => {
          closeModal(Modals.FILE_PREVIEW);
        }}
      >
        <p className="text-[70px] leading-[80px] text-center">👀</p>
        <p className={`${pageScene === PageScene.PAGE_LITE_PREVIEW}?'text-black' :text-white`}>
          {isShare ? '当前格式不支持预览' : '仅支持【专业版】或【团队版】预览'}
        </p>
        {!isShare && (
          <Button
            className="mt-3"
            onClick={(e) => {
              e.stopPropagation();
              openUpgradeSpace(OpenSettingFrom.view_epub);
            }}
          >
            升级空间
          </Button>
        )}
      </div>
    );
  }
  return (
    <div className="w-full h-full">
      <ReactReader
        epubInitOptions={{
          openAs: 'epub',
        }}
        location={location}
        locationChanged={locationChanged}
        url={props.downloadUrl}
      />
    </div>
  );
};
