import type { IEditorModel, IPerformChangeContext, ISelection } from '@next-space/fe-inlined';
import { sliceContent } from '@next-space/fe-inlined';
import { contentToString, newContent, newElement, newText } from '@next-space/fe-inlined/utils';
import { useCallback } from 'react';
import { CODE_TAG, INLINE_EQUATION_TAG } from 'src/editor/editor/inline/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

interface Option {
  model?: IEditorModel;
}
/** hook方法 修改selection中的文字为行内公式 */
export const useShortcutEquation = (option?: Option) => {
  const { model: _model } = option || {};
  const getEditorModel = useGetOrInitEditorModel();

  return useCallback(
    (uuid: string) => {
      const model = _model || getEditorModel(uuid);
      if (!model) return;
      model.performChange((ctx) =>
        performChangeShortcutEquation(model, { ctx, selection: ctx.selection })
      );
    },
    [_model, getEditorModel]
  );
};

interface PerformChangeShortcutEquationOption {
  ctx: IPerformChangeContext;
  selection: Readonly<ISelection> | null;
}
/**  修改selection中的文字为行内公式 */
export const performChangeShortcutEquation = (
  model: IEditorModel,
  option: PerformChangeShortcutEquationOption
) => {
  const { ctx, selection } = option;
  if (!selection) return;
  const content = sliceContent(model.content, selection.offset, selection.endOffset);
  const text = contentToString(content);
  const allIsEquation = content.items.every((i) => {
    if (i.type === 'element' && i.tag === INLINE_EQUATION_TAG) {
      return true;
    }
    if (i.type === 'element' && i.tag === CODE_TAG) {
      return i.content?.items.every((j) => j.type === 'element' && j.tag === INLINE_EQUATION_TAG);
    }
    return false;
  });

  // 选中的区域里全都是公式块，就都替换成文字
  if (allIsEquation) {
    ctx
      .shadow()
      .select(selection.offset, selection.endOffset)
      .replace(newContent([newText(`${text}`, model.format as any)]))
      .release();
    void model.requestFocus();
  } else {
    // 或者把所有文字都替换为公式块
    ctx
      .select(selection.offset, selection.endOffset)
      .replace(
        newContent([
          newElement(INLINE_EQUATION_TAG, {
            text,
            textFormat: model.format,
          }),
        ])
      )
      .collapse('end');
  }
};
