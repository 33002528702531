import type { IEditorModel } from '@next-space/fe-inlined';
import { contentToString, newContent, newElement, sliceContent } from '@next-space/fe-inlined';
import { useEffect, useState } from 'react';
import { SELECT_INLINE_TAG } from 'src/editor/editor/inline/const';

interface Props {
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  blockId: string;
  left: number;
  right: number;
  onCloseModal: () => void;
  tip?: string;
  symbol?: string;
  changeTipWhenComposing?: boolean;
  setFakeFocus?: (status: boolean) => void;
}

export const useEditorKeyword = ({
  left,
  right,
  setFakeFocus,
  blockId,
  symbol = '@',
  tip = '',
  onCloseModal,
  getEditorModel,
  changeTipWhenComposing,
}: Props) => {
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const editorModel = getEditorModel(blockId);
    if (!editorModel) return;

    const cursorLeft = editorModel.createCursor(left, 'left');
    const cursorRight = editorModel.createCursor(right, 'right');

    if (setFakeFocus) {
      setKeyword(
        contentToString(sliceContent(editorModel.content, cursorLeft.offset, cursorRight.offset))
      );
      return;
    }

    editorModel.performChange((ctx) => {
      ctx
        .select(left, right)
        .replace(newContent([newElement(SELECT_INLINE_TAG, { tip, symbol })]))
        .collapse('end');
    });

    if (symbol.length > 1) {
      // 上面替换了行内元素，cursorRight一定是left+1
      cursorRight.offset = cursorLeft.offset + 1;
    }

    /** 判断光标是否超出左侧 */
    const subscriptionSelection = editorModel.onSelectionChange.subscribe(() => {
      if (editorModel.selection) {
        const { focusOffset } = editorModel.selection;
        if (focusOffset <= cursorLeft.offset) {
          onCloseModal();
        }
      }
    });

    const subscriptionComposingChange = editorModel.onComposingChange.subscribe(() => {
      if (!changeTipWhenComposing) return;
      const content = sliceContent(editorModel.content, cursorLeft.offset, cursorLeft.offset + 1);
      const item = content.items[0];
      if (
        editorModel.isComposing &&
        item &&
        item.type === 'element' &&
        item.tag === SELECT_INLINE_TAG &&
        item.props.tip
      ) {
        editorModel.performChange((ctx) => {
          ctx
            .shadow()
            .select(left, left + 1)
            .replace(newContent([newElement(SELECT_INLINE_TAG, { tip: '', symbol })]))
            .release();
        });
      }
    });

    /** 获取输入的文字 */
    const subscriptionContentChange = editorModel.onContentChange.subscribe(() => {
      const content = sliceContent(editorModel.content, cursorLeft.offset, cursorRight.offset);
      const text = contentToString(content);

      const item = content.items[0];

      if (item && item.type === 'element' && item.tag === SELECT_INLINE_TAG) {
        const props = { tip, symbol };

        if (text || editorModel.isComposing) {
          props.tip = '';
        }

        if (item.props.tip !== props.tip) {
          editorModel.performChange((ctx) => {
            ctx
              .shadow()
              .select(cursorLeft.offset, cursorLeft.offset + 1)
              .replace(newContent([newElement(SELECT_INLINE_TAG, props)]))
              .release();
          });
        }

        setKeyword(text);
      }
    });

    return () => {
      const content = sliceContent(editorModel.content, cursorLeft.offset, cursorRight.offset);
      const text = contentToString(content);
      const item = content.items[0];
      if (item && item.type === 'element' && item.tag === SELECT_INLINE_TAG) {
        // 如果用户还没有选择行内元素类型，则还原为 symbol 符号
        editorModel.performChange((ctx) => {
          ctx
            .shadow()
            .select(cursorLeft.offset, cursorRight.offset)
            .replace(`${symbol}${text}`)
            .release();
        });
      }
      cursorLeft.release();
      cursorRight.release();
      subscriptionSelection.unsubscribe();
      subscriptionContentChange.unsubscribe();
      subscriptionComposingChange.unsubscribe();
    };
  }, [
    blockId,
    getEditorModel,
    onCloseModal,
    left,
    right,
    setKeyword,
    symbol,
    tip,
    changeTipWhenComposing,
    setFakeFocus,
  ]);

  return keyword;
};
