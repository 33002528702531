import { getBlockBackgroundColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { useIsDarkMode } from '@flowus/common/utils/get-dark';
import type { CardFormat } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { ColorSelector } from 'src/bitable/table-view/cell/select/color-selector';
import type { ColorKey } from 'src/colors';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { ColorImage } from 'src/components/color-picker';
import { COLOR_PICKER_DATA_BACKGROUND_COLOR } from 'src/components/color-picker/default-data';
import { InputContent } from 'src/editor/editor/input-content';
import { textToSegments } from 'src/editor/utils/editor';
import { VITE_CDN_HOST } from 'src/env';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { bizTracker } from 'src/utils/biz-tracker';
import { isMindMap } from 'src/utils/block-type-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { ProIcon } from 'src/views/main/header/pro-icon';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenAskUpgradeSpace } from 'src/views/main/setting-modal/use-open-ask-upgrade-space';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';

interface Props {
  blockId: string;
  onClose?: () => void;
}

export const PageFormatPanel: FC<Props> = (props) => {
  const block = usePickBlock(props.blockId, ['data', 'backgroundColor']);
  const transaction = useTransaction();
  const openModal = useOpenModal();
  const textRef = useRef<string | undefined>(block?.data.format?.cardFormat?.cardCaption);
  const isDarkMode = useIsDarkMode();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const mindMap = isMindMap(block?.type);

  const cardStyle = block?.data.format?.cardFormat?.cardStyle;
  const showType = block?.data.format?.cardFormat?.showType ?? 'default';
  const activeIndex = getActiveIndex(cardStyle);
  // 思维导图没有图表和封面，因此设置无用
  const hiddenIconSetting =
    mindMap || cardStyle === 'thumbnailImage' || cardStyle === 'thumbnailPage';
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const showAndHasCover =
    (Boolean(block?.data.cover) && block?.data.format?.cardFormat?.isShowCover) ?? true;
  // 没有背景图或者第一/三个样式就可以填充颜色
  let canFillColor = false;
  if (cardStyle === 'regular') {
    canFillColor = true;
  } else if (!showAndHasCover) {
    canFillColor = true;
  }

  if (!block) return null;

  const getImageName = (index: number) => {
    // https://cdn2.flowus.cn/emoji/website/large-card-0.png
    const imagePrefix = showType === 'large' ? 'large' : 'small';
    const darkModeImage = (index === 0 || index === 2) && isDarkMode;
    const darkModeSuffix = darkModeImage ? 'dark-' : '';
    const dir = __BUILD_IN__ ? 'buildin/' : '';
    const name = `${dir}${imagePrefix}-card-${darkModeSuffix}${index}.png`;
    return name;
  };

  const items: ListItem[] = [
    {
      type: ListItemType.CUSTOM_RENDER,
      disableCustomHoverBg: true,
      render: () => (
        <div className="flex space-x-3 px-3 mt-4 mb-2.5">
          {Array.from({ length: 4 }).map((_, index) => {
            return (
              <div className="relative">
                <img
                  key={index}
                  className={cx(
                    'cursor-pointer hover:opacity-90 border border-transparent rounded-sm',
                    {
                      'border-active_color': activeIndex === index,
                      'h-[64px]': showType === 'large',
                      'h-[35px]': showType === 'small',
                    }
                  )}
                  src={`${VITE_CDN_HOST}emoji/website/${getImageName(index)}`}
                  onClick={() => {
                    if (index === 3 && isFreeSpaceLimit) {
                      openAskUpgradeSpace(() => {
                        openUpgradeSpace(OpenSettingFrom.page_card);
                      });
                      return;
                    }
                    let cardStyle: CardFormat['cardStyle'] = 'regular';
                    switch (index) {
                      case 1:
                        cardStyle = 'fill';
                        break;
                      case 2:
                        cardStyle = 'thumbnailPage';
                        break;
                      case 3:
                        cardStyle = 'thumbnailImage';
                        break;
                      default:
                    }
                    transaction(() => {
                      updateBlock(props.blockId, {
                        data: {
                          format: {
                            cardFormat: {
                              ...block.data.format?.cardFormat,
                              cardStyle,
                            },
                          },
                        },
                      });
                    });
                    bizTracker.event('page_card_style', {
                      style_type: {
                        showType: block?.data.format?.cardFormat?.showType ?? 'default',
                        cardStyle,
                        isShowCover: block?.data.format?.cardFormat?.isShowCover,
                        isShowIcon: block?.data.format?.cardFormat?.isShowIcon,
                        cardCaption: Boolean(block?.data.format?.cardFormat?.cardCaption),
                      },
                    });
                  }}
                />
                {index === 3 && isFreeSpaceLimit && <ProIcon />}
              </div>
            );
          })}
        </div>
      ),
    },

    {
      type: ListItemType.TEXT_SWITCH,
      isHidden: hiddenIconSetting,
      data: {
        title: '显示页面图标',
        switch: block.data.format?.cardFormat?.isShowIcon ?? true,
        onSwitch: (status: boolean) => {
          transaction(() => {
            updateBlock(props.blockId, {
              data: {
                format: {
                  cardFormat: { ...block.data.format?.cardFormat, isShowIcon: status },
                },
              },
            });
          });
          bizTracker.event('page_card_style', {
            style_type: {
              showType: block?.data.format?.cardFormat?.showType ?? 'default',
              cardStyle: block?.data.format?.cardFormat?.cardStyle ?? 'regular',
              isShowCover: block?.data.format?.cardFormat?.isShowCover,
              isShowIcon: block?.data.format?.cardFormat?.isShowIcon,
              cardCaption: Boolean(block?.data.format?.cardFormat?.cardCaption),
            },
          });
        },
      },
    },
    {
      type: ListItemType.TEXT_SWITCH,
      // 思维导图配图，因此隐藏
      isHidden: mindMap,
      data: {
        title: '显示配图',
        switch: block.data.format?.cardFormat?.isShowCover ?? true,
        onSwitch: (status: boolean) => {
          transaction(() => {
            updateBlock(props.blockId, {
              data: {
                format: {
                  cardFormat: { ...block.data.format?.cardFormat, isShowCover: status },
                },
              },
            });
          });
          bizTracker.event('page_card_style', {
            style_type: {
              showType: block?.data.format?.cardFormat?.showType ?? 'default',
              cardStyle: block?.data.format?.cardFormat?.cardStyle ?? 'regular',
              isShowCover: block?.data.format?.cardFormat?.isShowCover,
              isShowIcon: block?.data.format?.cardFormat?.isShowIcon,
              cardCaption: Boolean(block?.data.format?.cardFormat?.cardCaption),
            },
          });
        },
      },
    },
    {
      type: ListItemType.CUSTOM_RENDER,
      disableCustomHoverBg: true,
      render: () => (
        <div
          className={cx('mx-1.5 h-11 pl-2 flex justify-between items-center', {
            'animate-hover': canFillColor,
            'cursor-not-allowed': !canFillColor,
          })}
          onClick={(e) => {
            if (!canFillColor) return;
            openModal.dropdown({
              popcorn: e.currentTarget,
              content: ({ onCloseModal }) => {
                return (
                  <div className="next-modal py-1.5">
                    <ColorSelector
                      hideHeader={true}
                      className="pb-2"
                      colors={IconColors}
                      value={block.backgroundColor}
                      selectedIcon={
                        <Icon name="IcCheck02" size="normal" className={'text-[18px] text-white'} />
                      }
                      onSelect={(key) => {
                        onCloseModal();
                        transaction(() => {
                          updateBlock(props.blockId, {
                            backgroundColor: key,
                            textColor: '',
                          });
                        });
                      }}
                      bgColorFn={getBlockBackgroundColor}
                    />
                  </div>
                );
              },
            });
          }}
        >
          <Tooltip
            disabled={canFillColor}
            popup={'配图开启后无法填充卡片颜色'}
            className={cx('w-full flex justify-between items-center')}
          >
            <span className="text-t2">卡片填充色</span>
            <ColorImage
              disable={!canFillColor}
              colorkey={block.backgroundColor}
              desc=""
              selected={false}
              hover={false}
              isBgColor={true}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '自定义说明文字',
        onClick: (e: React.MouseEvent) => {
          props.onClose?.();
          openModal.dropdown({
            popcorn: elementToGetBoundingClientRect(e.currentTarget),
            closeAfterCallBack: () => {
              if (textRef.current !== undefined) {
                transaction(() => {
                  updateBlock(props.blockId, {
                    data: {
                      format: {
                        cardFormat: {
                          ...block.data.format?.cardFormat,
                          cardCaption: textRef.current,
                        },
                      },
                    },
                  });
                });
                bizTracker.event('page_card_style', {
                  style_type: {
                    showType: block?.data.format?.cardFormat?.showType ?? 'default',
                    cardStyle: block?.data.format?.cardFormat?.cardStyle ?? 'regular',
                    isShowCover: block?.data.format?.cardFormat?.isShowCover,
                    isShowIcon: block?.data.format?.cardFormat?.isShowIcon,
                    cardCaption: Boolean(textRef.current),
                  },
                });
              }
            },
            content: ({ onCloseModal }) => {
              return (
                <InputContent
                  content={textToSegments(block.data.format?.cardFormat?.cardCaption)}
                  onTextChange={(text) => {
                    textRef.current = text;
                  }}
                  onEnter={(event) => {
                    event.preventDefault();
                    onCloseModal();
                  }}
                />
              );
            },
          });
        },
      },
      disableClick: true,
    },
  ];

  return (
    <div className="next-modal w-[400px] pb-1">
      <ListView items={items} className="w-full mt-1" />
    </div>
  );
};

const getActiveIndex = (cardStyle?: CardFormat['cardStyle']) => {
  let activeIndex = 0;
  if (cardStyle === 'fill') {
    activeIndex = 1;
  } else if (cardStyle === 'thumbnailPage') {
    activeIndex = 2;
  } else if (cardStyle === 'thumbnailImage') {
    activeIndex = 3;
  }
  return activeIndex;
};

const IconColors = COLOR_PICKER_DATA_BACKGROUND_COLOR.items.map((v) => {
  return { key: v.colorkey as ColorKey, name: v.desc };
});
