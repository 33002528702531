import { CardSize, CollectionSchemaType, CollectionCardImageDir } from '@next-space/fe-api-idl';
import { keyBy } from 'lodash-es';
import { ColorKey } from 'src/colors';
import type { IconName } from 'src/common/components/icon';
import type { CollectionProperty } from 'src/hooks/collection-view/use-properties';

export const CardSizeWidthMap = {
  [CardSize.SMALL]: 192,
  [CardSize.MEDIUM]: 272,
  [CardSize.LARGE]: 332,
};

export const getImageRadio = (imageDir: CollectionCardImageDir) => {
  return imageDir === CollectionCardImageDir.VERTICAL ? 1.5 : 0.55;
};

export const getCardSize = (cardSize: CardSize, imageDir: CollectionCardImageDir) => {
  const size = CardSizeWidthMap[cardSize];
  return imageDir === CollectionCardImageDir.VERTICAL ? size - 10 : size;
};

export const CardContentWidthMap = {
  [CardSize.SMALL]: 100,
  [CardSize.MEDIUM]: 150,
  [CardSize.LARGE]: 180,
};

export const getPropertyWidth = (property: CollectionProperty | undefined) => {
  const defaultWidth =
    property?.type === CollectionSchemaType.ID_NUMBER ? ID_NUMBER_DEFAULT_WIDTH : DEFAULT_COL_WIDTH;
  const width = !property?.width ? defaultWidth : Math.max(MIN_COL_WIDTH, property.width);
  return width;
};

export const DEFAULT_COL_WIDTH = 180;
export const ID_NUMBER_DEFAULT_WIDTH = 66;
export const MIN_COL_WIDTH = 38;
export const MAX_COL_WIDTH = 1200;
export const DEFAULT_ROW_HEIGHT = 37;
export interface MetaColor {
  key: ColorKey;
  name: string;
}

export const COLORS = [
  { key: ColorKey.grey, name: '灰色' },
  { key: ColorKey.brown, name: '棕色' },
  { key: ColorKey.orange, name: '橘色' },
  { key: ColorKey.yellow, name: '黄色' },
  { key: ColorKey.green, name: '绿色' },
  { key: ColorKey.blue, name: '蓝色' },
  { key: ColorKey.purple, name: '紫色' },
  { key: ColorKey.pink, name: '粉色' },
  { key: ColorKey.red, name: '红色' },
  // { key: ColorKey.light_blue, name: '荧光蓝' },
  // { key: ColorKey.light_red, name: '荧光红' },
  // { key: ColorKey.light_yellow, name: '荧光黄' },
  // { key: ColorKey.light_purple, name: '荧光紫' },
  // { key: ColorKey.light_green, name: '荧光绿' },
] as const;

export const ICON_MAP: Record<CollectionSchemaType, IconName> = {
  [CollectionSchemaType.TITLE]: 'IcTitle',
  [CollectionSchemaType.NUMBER]: 'IcChartNumber',
  [CollectionSchemaType.TEXT]: 'IcPropertyText',
  [CollectionSchemaType.CHECKBOX]: 'IcCheck',
  [CollectionSchemaType.SELECT]: 'IcExclusiveChoice',
  [CollectionSchemaType.MULTI_SELECT]: 'IcMultipleChoice',
  [CollectionSchemaType.CREATED_AT]: 'IcClockPlus',
  [CollectionSchemaType.UPDATED_AT]: 'IcClockCheck',
  [CollectionSchemaType.DATE]: 'IcCalendar',
  [CollectionSchemaType.PERSON]: 'IcMember',
  [CollectionSchemaType.FILE]: 'IcPaperclip',
  [CollectionSchemaType.URL]: 'IcLink',
  [CollectionSchemaType.EMAIL]: 'IcMail',
  [CollectionSchemaType.PHONE]: 'IcCall',
  [CollectionSchemaType.CREATED_BY]: 'IcUserAdd',
  [CollectionSchemaType.UPDATED_BY]: 'IcUserEdit',
  [CollectionSchemaType.FORMULA]: 'IcFormula',
  [CollectionSchemaType.RELATION]: 'IcAssociation',
  [CollectionSchemaType.ROLLUP]: 'IcSearch',
  [CollectionSchemaType.ID_NUMBER]: 'IcMenuNum',
} as const;

export const PROPERTIES = [
  { type: CollectionSchemaType.TEXT, title: '文本', icon: ICON_MAP.text },
  { type: CollectionSchemaType.NUMBER, title: '数字', icon: ICON_MAP.number },
  { type: CollectionSchemaType.CHECKBOX, title: '勾选框', icon: ICON_MAP.checkbox },
  { type: CollectionSchemaType.SELECT, title: '单选', icon: ICON_MAP.select },
  { type: CollectionSchemaType.MULTI_SELECT, title: '多选', icon: ICON_MAP.multi_select },
  { type: CollectionSchemaType.DATE, title: '日期', icon: ICON_MAP.date },
  { type: CollectionSchemaType.ID_NUMBER, title: 'ID', icon: ICON_MAP.id_number },
  { type: CollectionSchemaType.PERSON, title: '人员', icon: ICON_MAP.person },
  { type: CollectionSchemaType.FILE, title: '文件媒体', icon: ICON_MAP.file },
  { type: CollectionSchemaType.URL, title: '网址链接', icon: ICON_MAP.url },
  { type: CollectionSchemaType.EMAIL, title: '邮箱', icon: ICON_MAP.email },
  { type: CollectionSchemaType.PHONE, title: '电话', icon: ICON_MAP.phone },
  { type: CollectionSchemaType.FORMULA, title: '公式', icon: ICON_MAP.formula },
  { type: CollectionSchemaType.RELATION, title: '关联', icon: ICON_MAP.relation },
  { type: CollectionSchemaType.ROLLUP, title: '汇总', icon: ICON_MAP.rollup },
  { type: CollectionSchemaType.CREATED_AT, title: '创建时间', icon: ICON_MAP.created_at },
  { type: CollectionSchemaType.CREATED_BY, title: '创建人', icon: ICON_MAP.created_by },
  { type: CollectionSchemaType.UPDATED_AT, title: '编辑时间', icon: ICON_MAP.updated_at },
  { type: CollectionSchemaType.UPDATED_BY, title: '编辑人', icon: ICON_MAP.updated_by },
] as const;

export const PROPERTIES_FORM = [
  { type: CollectionSchemaType.TEXT, title: '文本', icon: ICON_MAP.text },
  { type: CollectionSchemaType.NUMBER, title: '数字', icon: ICON_MAP.number },
  { type: CollectionSchemaType.CHECKBOX, title: '勾选框', icon: ICON_MAP.checkbox },
  { type: CollectionSchemaType.SELECT, title: '单选', icon: ICON_MAP.select },
  { type: CollectionSchemaType.MULTI_SELECT, title: '多选', icon: ICON_MAP.multi_select },
  { type: CollectionSchemaType.DATE, title: '日期', icon: ICON_MAP.date },
  { type: CollectionSchemaType.PERSON, title: '人员', icon: ICON_MAP.person },
  { type: CollectionSchemaType.FILE, title: '文件媒体', icon: ICON_MAP.file },
  { type: CollectionSchemaType.URL, title: '网址链接', icon: ICON_MAP.url },
  { type: CollectionSchemaType.EMAIL, title: '邮箱', icon: ICON_MAP.email },
  { type: CollectionSchemaType.PHONE, title: '电话', icon: ICON_MAP.phone },
] as const;

/** 收集表需要过滤的属性 */
export const FILTER_PROPERTIES_FORM = [
  CollectionSchemaType.CREATED_AT,
  CollectionSchemaType.CREATED_BY,
  CollectionSchemaType.UPDATED_AT,
  CollectionSchemaType.UPDATED_BY,
  CollectionSchemaType.RELATION,
  CollectionSchemaType.ROLLUP,
  CollectionSchemaType.FORMULA,
];

export const READONLY_PROPERTIES = [
  CollectionSchemaType.CREATED_AT,
  CollectionSchemaType.UPDATED_AT,
  CollectionSchemaType.CREATED_BY,
  CollectionSchemaType.UPDATED_BY,
];

export const COMPUTED_PROPERTIES = [CollectionSchemaType.FORMULA, CollectionSchemaType.ROLLUP];

export const PROPERTIES_MAP = keyBy(PROPERTIES, 'type');
export const PROPERTIES_FORM_MAP = keyBy(PROPERTIES_FORM, 'type');

export const TableClassName = {
  tableFooter: 'table-footer',
  tableHeader: 'table-header',
  tableSortManager: 'table-sort-manager',
};

export enum Direction {
  left = 'left',
  right = 'right',
}
export const DirectionMap = [Direction.left, Direction.right];

export const DEFAULT_BITABLE_PAGE_SIZE = 50;
export const MAX_BITABLE_PAGE_SIZE = 9999;

export const OVERSCAN = 200;

export const isEnableVirtuoso = (count: number) => count >= 300;

export const AUTO_LOAD_COUNT = 10;
