import { useMindMapScale } from '@flowus/mind-map';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { KeyboardShortcut } from 'src/components/keyboard-shortcut';
import { AIEditorFrom, AIEditorScene } from 'src/editor/editor/uikit/ai-editor/const';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { FeatureFlags } from 'src/feature-flags';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useReadonly } from 'src/hooks/page';
import { HISTORY_REDO, HISTORY_UNDO } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { setAppUiState, usePressedSpace } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { judgeSharePage } from 'src/utils/getPageId';
import { getVirtualElement } from 'src/utils/virtualElement';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { MindMapStylePanel } from './mind-map-style-panel';
import { ProgressBar } from './progress-bar';
import { ToolButtonWrapper } from './tool-button-wrapper';
import { Images } from 'src/image';
import { ExpandNodeList } from './expand-node-list';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';

/**
 * 导图左下角的toolbar
 */
export const AsideToolbar: FC<{ id: string }> = (props) => {
  const scale = useMindMapScale();
  const readonly = useReadonly(props.id);
  const isLocked = useBlockLocked(props.id);
  const pageScene = usePageScene();
  const pressedSpace = usePressedSpace();
  const openAIEditor = useOpenAIEditor();
  const openModal = useOpenModal();
  const { enableAI } = useEnableAI();
  const isShare = judgeSharePage();

  if (pageScene && [PageScene.PAGE_HISTORY, PageScene.PAGE_LITE_PREVIEW].includes(pageScene)) {
    return null;
  }
  if (isLocked) return null;
  return (
    <div
      data-ignore-double-click
      className="aside-toolbar absolute p-1 left-10 bottom-7 shadow-light next-modal"
      style={{
        bottom: '30px',
      }}
    >
      {enableAI && FeatureFlags.AI_MIND_MAP && !isShare && (
        <Tooltip appendTo="parent" placement="right" popup={`AI助手`}>
          <ToolButtonWrapper
            className="w-10 h-10"
            onClick={(event) => {
              const node = event.currentTarget.closest('[data-block-id]');
              openAIEditor({
                popcorn: node ?? getVirtualElement(event),
                blockId: props.id,
                from: AIEditorFrom.mindMap,
                editorScene: AIEditorScene.PageHasContent,
                isMindMap: true,
                showInput: false,
                placement: node ? 'bottom-start' : 'top-end',
              });
            }}
          >
            <Icon name={'IcAi'} size="middle" />
          </ToolButtonWrapper>
        </Tooltip>
      )}
      <Tooltip appendTo="parent" className="w-10 h-10" placement="right" popup="展开节点">
        <ToolButtonWrapper
          className="w-10 h-10"
          onClick={(e) => {
            openModal.dropdown({
              placement: 'right',
              modalId: Modals.EXPAND_NODE,
              popcorn: e.currentTarget,
              content: () => {
                return <ExpandNodeList />;
              },
            });
          }}
        >
          <Icon name={'IcMindmap'} size="middle" />
        </ToolButtonWrapper>
      </Tooltip>
      <Tooltip
        animation="shift-away"
        appendTo="parent"
        delay={0}
        className="w-10 h-10"
        theme="next-modal"
        placement="right"
        interactive={true}
        maxWidth={1000}
        popup={<ProgressBar />}
      >
        <ToolButtonWrapper>
          <div className="text-t5-medium text-center">{`${scale}%`}</div>
        </ToolButtonWrapper>
      </Tooltip>
      <Tooltip
        appendTo="parent"
        placement="right"
        popupClass="w-32 text-center"
        popup={pressedSpace ? '切换到编辑模式' : '切换到拖拽模式'}
      >
        <ToolButtonWrapper
          selected={pressedSpace}
          className="w-10 h-10"
          onClick={() => {
            setAppUiState({ $pressedSpace: !pressedSpace });
          }}
        >
          <Icon name={'IcPreviewMove'} size="middle" />
        </ToolButtonWrapper>
      </Tooltip>
      <Tooltip
        animation="shift-away"
        appendTo="parent"
        delay={0}
        className="w-10 h-10"
        theme="next-modal"
        placement="right"
        interactive={true}
        maxWidth={1000}
        popup={<MindMapStylePanel mindMapId={props.id} />}
      >
        <ToolButtonWrapper>
          <Icon name={'IcMindmapStyle'} size="middle" />
        </ToolButtonWrapper>
      </Tooltip>

      {!readonly && (
        <Tooltip
          appendTo="parent"
          className="w-10 h-10"
          placement="right"
          popup={
            <>
              {'撤销'}
              <KeyboardShortcut className="ml-1" shortcut="Z" />
            </>
          }
          popupClass="text-center"
        >
          <ToolButtonWrapper
            onClick={() => {
              dispatch(HISTORY_UNDO());
              bizTracker.event('cancel_redo');
            }}
          >
            <Icon name={'IcUndo'} size="middle" />
          </ToolButtonWrapper>
        </Tooltip>
      )}

      {!readonly && (
        <Tooltip
          appendTo="parent"
          className="w-10 h-10"
          placement="right"
          popupClass="text-center"
          popup={
            <>
              {'重做'}
              <KeyboardShortcut className="ml-1" shortcut="shift+Z" />
            </>
          }
        >
          <ToolButtonWrapper
            onClick={() => {
              dispatch(HISTORY_REDO());
              bizTracker.event('cancel_redo');
            }}
          >
            <Icon name={'IcRedo'} size="middle" />
          </ToolButtonWrapper>
        </Tooltip>
      )}
    </div>
  );
};
