import { PRICE_ROUND } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import type { OverseaPlanDTO, PlanDTO } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import { round } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from 'src/common/components/button';
import { Tooltip } from 'src/common/components/tooltip';
import type { request } from 'src/common/request';
import { getDateUnit, PayCycleButton, usePayCycleButton } from 'src/components/pay-cycle-button';
import { getPlanList } from 'src/components/select-upgrade-plan/hook/use-upgrade-support';
import { UpgradePro, UpgradeTeam } from 'src/components/select-upgrade-plan/upgrade-components';
import { getCurrentSpace, useCurrentSpace } from 'src/hooks/space';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useCurrentUser } from 'src/hooks/user';
import { SpacePlanType } from 'src/redux/types';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { AsyncReturnType } from 'type-fest';
import { useSetting } from '../common';
import { OpenSettingFrom } from '../type';
import { useOpenUpgradePlan } from '../use-open-upgrade-plan';
import { PriceTable } from './price-data';

const validSpacePlanType = (plan: string) => {
  const curPlan = getCurrentSpace().planType;
  if (curPlan === SpacePlanType.smallTeam && plan === SpacePlanType.team) return true;
  return curPlan === plan;
};

// #region type
type QuoteValueType = AsyncReturnType<typeof request.infra.getQuote> & {
  unitPrice?: number;
  originUnitPrice?: number;
};

const defaultCalculateQuote: {
  team: QuoteValueType | undefined;
  personal: QuoteValueType | undefined;
  smallTeam: QuoteValueType | undefined;
} = {
  team: undefined,
  personal: undefined,
  smallTeam: undefined,
};
// #endregion

// #region hook
export const useSpacePlanDataHeader = ({ isFrom = OpenSettingFrom.package }) => {
  const currentSpace = useCurrentSpace();
  const isTeam = validSpacePlanType(SpacePlanType.team);
  const isSmall = validSpacePlanType(SpacePlanType.smallTeam);
  const currentUser = useCurrentUser();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const { editor } = getSpaceRolePermission(currentUser.uuid);
  const { membersMaxLimit } = useLimitConfig();
  const [planList, setPlanList] = useState<(PlanDTO & OverseaPlanDTO)[]>([]);
  const { switchPayType, setSwitchPayType } = usePayCycleButton({
    defaultTimeType: 'year',
  });
  const isMonth = switchPayType === 'month';

  const openUpgradePlan = useOpenUpgradePlan();

  /** 后端计算的价格 */
  const [calculateQuote, setCalculateQuote] = useState(defaultCalculateQuote);

  // 从后端获取套餐的价格
  useEffect(() => {
    void getPlanList(currentSpace.uuid, 'all').then(
      (res) => res.list?.length && setPlanList(res.list)
    );
  }, [currentSpace.uuid]);

  // 通过支付方式查找对应的套餐列表
  const getPlanTypeData = useCallback(
    (spacePlanType: SpacePlanType) => {
      const upgradeObj = spacePlanType === SpacePlanType.team ? UpgradeTeam : UpgradePro;
      const upgradeType = upgradeObj.upgrade;

      const list = planList.filter((p) => {
        if (isMonth) {
          return p.monthNum === 1;
        }
        if (!isMonth) {
          return p.monthNum === 12;
        }
        return false;
      });

      return list.find((i) => {
        return (__BUILD_IN__ ? i.orderPackageType : i.planType) === upgradeType;
      });
    },
    [isMonth, planList]
  );

  // 两种tab的价格内容
  const playTypeData = useMemo(() => {
    // 专业版兑换套餐
    const personalPro = getPlanTypeData(SpacePlanType.pro);

    // 团队版兑换套餐
    const teamOfCash = getPlanTypeData(SpacePlanType.team);

    return [
      //  个人套餐
      [
        {
          //  个人版
          hidden: false,
          type: SpacePlanType.freePersonal,
          originalPrice: 0,
          currentPrice: 0,
          description: getSpacePlanTypeName(SpacePlanType.freePersonal),
        },
        {
          updatePlan: currentSpace.planType !== SpacePlanType.team,
          //  专业版
          hidden: false,
          type: SpacePlanType.pro,
          currentPrice: calculateQuote[SpacePlanType.pro]?.unitPrice,
          currentPriceUnit: getCurrencySymbols(personalPro?.currentPriceUnit),
          originalPrice: calculateQuote[SpacePlanType.pro]?.originUnitPrice,
          originalPriceUnit: getCurrencySymbols(personalPro?.originalPriceUnit),
          description: getSpacePlanTypeName(SpacePlanType.pro),
          // @ts-ignore idl
          label: personalPro?.label,
          id: personalPro?.id,
          unit: getDateUnit(1, { prefix: '/' }),
          tips: null,
        },
      ],
      //  团队套餐
      [
        {
          updatePlan: true,
          //  团队版
          hidden: false,
          type: SpacePlanType.team,
          currentPrice: calculateQuote[SpacePlanType.team]?.unitPrice,
          currentPriceUnit: getCurrencySymbols(teamOfCash?.currentPriceUnit),
          originalPrice: calculateQuote[SpacePlanType.team]?.originUnitPrice,
          originalPriceUnit: getCurrencySymbols(teamOfCash?.originalPriceUnit),
          description: getSpacePlanTypeName(SpacePlanType.team),
          // @ts-ignore idl
          label: personalPro?.label,
          id: teamOfCash?.id,
          unit: getDateUnit(1, { prefix: '/' }),
        },
      ],
    ];
  }, [calculateQuote, currentSpace.planType, getPlanTypeData]);

  const planData = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      proPerson: playTypeData[0]![1]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      freePerson: playTypeData[0]![0]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      team: playTypeData[1]![0]!,
    }),
    [playTypeData]
  );

  const upgradePlan = useCallback(
    (plan: SpacePlanType) => {
      openUpgradePlan(plan, isFrom);
    },
    [isFrom, openUpgradePlan]
  );

  const [personalPlanId, teamPlanId] = useMemo(() => {
    return [planData.proPerson.id, planData.team.id];
  }, [planData]);

  useDebounceEffect(
    () => {
      // 专业版兑换套餐
      const personalPro = getPlanTypeData(SpacePlanType.pro);

      // 团队版兑换套餐
      const teamOfCash = getPlanTypeData(SpacePlanType.team);

      const newCalculateQuote = { ...defaultCalculateQuote };

      void (async () => {
        if (teamPlanId && teamOfCash) {
          const monthNum = teamOfCash.monthNum || 1;
          const originalPrice = teamOfCash.originalPrice || 0;
          const currentPrice = teamOfCash.currentPrice || 0;

          const unitPrice =
            monthNum === 1 ? currentPrice : round(currentPrice / monthNum, PRICE_ROUND);
          const originUnitPrice =
            monthNum === 1 ? originalPrice : round(originalPrice / monthNum, PRICE_ROUND);

          newCalculateQuote.team = { unitPrice, originUnitPrice };
        }

        if (personalPlanId && personalPro) {
          const monthNum = personalPro.monthNum || 1;
          const originalPrice = personalPro.originalPrice || 0;
          const currentPrice = personalPro.currentPrice || 0;

          const unitPrice =
            monthNum === 1 ? currentPrice : round(currentPrice / monthNum, PRICE_ROUND);
          const originUnitPrice =
            monthNum === 1 ? originalPrice : round(originalPrice / monthNum, PRICE_ROUND);

          newCalculateQuote.personal = { unitPrice, originUnitPrice };
        }
      })().then(() => {
        setCalculateQuote(newCalculateQuote);
      });
    },
    [personalPlanId, teamPlanId, isTeam, isSmall, membersMaxLimit],
    { wait: 200 }
  );

  return {
    playTypeData,
    planData,
    planList,
    upgradePlan,
    editor,
    switchPayType,
    setSwitchPayType,
  };
};
// #endregion

// #region render
export const UpgradeSettingContent: FC = () => {
  return (
    <div className={'shrink-0 transition-opacity min-w-[720px]'}>
      {/* 付费升级按钮区域 */}
      <PlanDataHeader />
      <PriceTable type="spacePlan" />
    </div>
  );
};

export const PlanDataHeader: FC = () => {
  const { isFrom } = useSetting();
  const { playTypeData, upgradePlan, editor, switchPayType, setSwitchPayType, planList } =
    useSpacePlanDataHeader({ isFrom });
  return (
    <div
      className={cx(
        'grid border-b border-grey5 opacity-0 transition-opacity grid-cols-4',
        planList.length && 'opacity-100',
        isMobile && 'grid-cols-1 border-none'
      )}
    >
      <PayCycleButton onSwitch={setSwitchPayType} payType={switchPayType} />
      {playTypeData.map((data) =>
        data.map((item, index) => {
          const currentPlanType = validSpacePlanType(item.type);
          const disabled = item.currentPrice !== 0 && !item.currentPrice;
          const isFree =
            item.type === SpacePlanType.freePersonal || item.type === SpacePlanType.freeTeam;

          if (item.hidden) return null;
          let btn = <></>;

          if (item.updatePlan) {
            if (!disabled) {
              btn = (
                <Tooltip
                  className="flex-1 flex text-t2-medium"
                  popup={editor ? '' : '仅管理员可操作'}
                >
                  <Button
                    disable={!editor}
                    colorType="active"
                    className={cx('flex-1', isMobile && 'rounded h-10')}
                    onClick={() => upgradePlan(item.type)}
                  >
                    {currentPlanType ? '续费' : '升级'}
                  </Button>
                </Tooltip>
              );
            }
          } else {
            btn = (
              <Tooltip
                className="cursor-not-allowed flex-1"
                popup={currentPlanType ? undefined : '当前空间暂不支持升级到该空间计划'}
              >
                <Button
                  className={cx(
                    'text-t2-medium pointer-events-none w-full bg-white text-grey3 rounded',
                    isMobile && 'h-10'
                  )}
                >
                  {isFree ? <>免费版</> : <>{currentPlanType ? '当前版本' : '升级'}</>}
                </Button>
              </Tooltip>
            );
          }

          let tag = <></>;
          if (item.label) {
            tag = (
              <span className="mb-5 bg-red/20 w-fit text-red rounded-sm py-px px-1 text-t3">
                {item.label}
              </span>
            );
          }

          return (
            <div
              key={`${item}-${index}`}
              className={cx(
                'flex flex-col justify-between border-l border-grey5 p-2.5 flex-grow-0 flex-shrink-0',
                index % 2 === 0 && 'bg-black_003',
                isMobile && 'border-none px-8 py-4 bg-transparent',
                isMobile && isFree && 'w-1/2'
              )}
            >
              <div className="flex-1">
                <div className={cx('text-h4 mb-2.5', { 'mb-1 text-h2': isMobile })}>
                  {item.description}
                </div>
                <div className={cx('mb-5 space-x-1', { 'mb-1 text-h4': isMobile })}>
                  <span
                    className={cx(
                      'text-t2',
                      item.currentPrice !== 0 && !item.currentPrice && 'text-grey4',
                      isMobile && 'text-t1'
                    )}
                  >
                    {disabled
                      ? '暂未公布'
                      : item.currentPrice === 0
                      ? '免费'
                      : `${item.currentPriceUnit}${item.currentPrice}`}
                  </span>
                  <span className="text-t1 text-grey4" hidden={!item.currentPrice}>
                    {`${item.unit}/人`}
                  </span>
                  {tag}
                  <div
                    hidden={__BUILD_IN__}
                    className={cx(
                      'text-t1 text-grey4',
                      (!item.currentPrice ||
                        !item.originalPrice ||
                        item.currentPrice >= item.originalPrice) &&
                        'opacity-0'
                    )}
                  >
                    <span className="line-through">
                      {item.originalPriceUnit}
                      {item.originalPrice}
                    </span>

                    {`人${item.unit}`}
                  </div>
                </div>
              </div>
              {/*  升级按钮 */}
              <div>{btn}</div>
            </div>
          );
        })
      )}
    </div>
  );
};
// #endregion

export const useGetPriceAndUnit = () => {
  return useCallback((isMonth: boolean, cur?: QuoteValueType, plan?: PlanDTO) => {
    let totalPrice = cur?.amount ?? plan?.currentPrice ?? plan?.originalPrice ?? 0;

    if (isMonth) {
      const monthNum = plan?.monthNum ?? 1;
      totalPrice = round(totalPrice / monthNum, PRICE_ROUND);
    }

    const amount = cur?.amount ?? 0;
    const increasedPeople = cur?.increasedPeople || 1;
    const increasedMonth = round(
      cur?.diffDays ? cur?.diffDays / 30 : cur?.increasedMonth ?? 1,
      PRICE_ROUND
    );
    const increasedYear = round(increasedMonth / 12, PRICE_ROUND);

    // 计算单价
    const monthlyUnitPrice = round(amount / increasedPeople / increasedMonth, PRICE_ROUND);
    const yearlyUnitPrice = round(monthlyUnitPrice * 12, PRICE_ROUND);

    return {
      totalPrice,
      currentPrice: isMonth ? monthlyUnitPrice : yearlyUnitPrice,
      monthlyUnitPrice,
      yearlyUnitPrice,
      date: isMonth ? increasedMonth : increasedYear,
    };
  }, []);
};
