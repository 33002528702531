import { useCallback } from 'react';
import { SearchParams, ViewPath } from 'src/utils';
import { locationToUrl } from 'src/utils/location-utils';
import { checkPageId } from '../page/check-page-id';
import { useGetQuery } from '../page/use-get-query';

/** 如果携带路由里有callback参数，就跳到参数路径里
 * 否则跳转到main
 * */
export const useJumpQueryCallback = (search?: string) => {
  const getQuery = useGetQuery(search);

  return useCallback(async () => {
    let callbackPath = getQuery(SearchParams.callbackPath);
    if (callbackPath) {
      const prefix = '/share/';
      if (callbackPath.startsWith(prefix)) {
        const pageId = callbackPath.slice(prefix.length);
        const res = await checkPageId(pageId);
        // 如果这是个share并且对应的id是自己的，就把share去掉，否则该页面跳转了也显示不了
        if (res.code === 200) {
          callbackPath = callbackPath.replace('/share', '');
        }
      }
      locationToUrl(callbackPath);
      return;
    }
    locationToUrl(ViewPath.main);
  }, [getQuery]);
};
