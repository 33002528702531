import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import type { ElementType, FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BitableManager } from 'src/bitable/bitable-manager';
import { BitableContext, useBitable } from 'src/bitable/context';
import { LoadingContainer } from 'src/common/components/loading-container';
import { NextModalProvider } from 'src/common/components/next-modal';
import { NoAccess } from 'src/components/no-access';
import { useAddCollectionView } from 'src/hooks/block/use-add-collection-view';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCheckCollectionRecord } from 'src/hooks/block/use-check-collection-record';
import { useIsFoldListChildren } from 'src/hooks/block/use-is-fold-list-children';
import { useIsMarkListChildren } from 'src/hooks/block/use-is-mark-children';
import { useCollectionView, useTableCellWrap } from 'src/hooks/collection-view/use-collection-view';
import { useCollectionViewId } from 'src/hooks/collection-view/use-collection-view-id';
import { useSyncCollectionView } from 'src/hooks/collection-view/use-sync-collection-view';
import { useFetchPage, useReadonly } from 'src/hooks/page';
import { useCtrlDragScroll } from 'src/hooks/utils/use-ctrl-drag-scroll';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { FlexibleInteractionProvider } from 'src/services/store-context/components';
import { isInColumnList } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useGetRightPageId } from 'src/utils/right-utils';
import { getBiTableView } from 'src/views/main/page-bitable';
import { HScrollRefContext, usePageScrollRef } from 'src/views/main/page-doc/context';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';

export const EmbedBitable: BlockElement = ({ id, root }) => {
  const fetchPage = useFetchPage();
  const pageScene = usePageScene();
  const collection = useObservableStore(
    ({ blocks }) => {
      let collectionId = id;
      const block = blocks[id];
      if (block?.type === BlockType.REFERENCE_COLLECTION) {
        collectionId = block.data.ref?.uuid ?? '';
      }
      return blocks[collectionId];
    },
    [id]
  );

  // 页面历史或者本地创建的多维表 不用手动拉取多维表记录
  const [loading, setLoading] = useState(
    pageScene !== PageScene.PAGE_HISTORY && !collection?.local
  );

  useEffect(() => {
    if (loading) {
      // 内嵌多维表页面需要手动拉数据
      const needFetchPage = collection?.subNodes.some((id) => !cache.blocks[id]);
      if (collection && needFetchPage) {
        fetchPage(collection.uuid, { force: true }).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!collection) {
    return (
      <BlockDrop id={id} className="my-2">
        <NoAccess id={id} />
      </BlockDrop>
    );
  }

  // 未分享的引用多维表展示无访问权限
  if (loading) return <LoadingContainer className="h-[300px]" />;

  return (
    <FlexibleInteractionProvider extendsStore>
      <EmbedBitableContent collectionId={collection.uuid} viewParent={id} root={root} />
    </FlexibleInteractionProvider>
  );
};

const EmbedBitableContent: ElementType<
  JSX.IntrinsicElements['div'] & {
    collectionId: string;
    viewParent: string;
    root?: boolean;
  }
> = (props) => {
  const { collectionId, viewParent } = props;
  const scrollXRef = useRef<HTMLElement | null>(null);
  const pageManager = useRef<HTMLDivElement>(null);
  const pageId = useGetPageId();
  const isColumnListChildren = isInColumnList(viewParent, pageId);
  const isFoldListChildren = useIsFoldListChildren(viewParent, pageId);
  const readonly = useReadonly(collectionId, false);
  const managerReadonly = useReadonly(viewParent, false);
  const viewIsLocked = useBlockLocked(viewParent);
  const [viewId, changeView] = useCollectionViewId(viewParent, true);
  const view = useCollectionView(viewId);
  const addCollectionView = useAddCollectionView();
  const tableCellWrap = useTableCellWrap(viewId);
  useSyncCollectionView(viewId);
  useCheckCollectionRecord(collectionId);

  useEffect(() => {
    if (readonly) return;

    // 无视图时创建视图
    if (!viewId) {
      const newViewId = addCollectionView(viewParent, '表格', CollectionViewType.TABLE);
      if (newViewId) {
        changeView(newViewId);
      }
    }
  }, [addCollectionView, changeView, readonly, viewId, viewParent]);

  const context: BitableContext | undefined = useMemo(() => {
    if (!collectionId || !viewId || !view) return;
    return {
      viewId,
      tableCellWrap,
      collectionId,
      readonly,
      isLocked: viewIsLocked,
      managerReadonly,
      changeView,
      embed: true,
      isColumnListChildren,
      isFoldListChildren,
      viewType: view.type,
      viewParentId: view.parentId,
      pageManager,
    };
  }, [
    changeView,
    collectionId,
    isColumnListChildren,
    isFoldListChildren,
    managerReadonly,
    readonly,
    tableCellWrap,
    view,
    viewId,
    viewIsLocked,
  ]);

  const enableDragScroll = view?.type && ![CollectionViewType.TIMELINE].includes(view.type);

  useCtrlDragScroll(scrollXRef, { enable: enableDragScroll });

  if (!context) return null;

  const node = <BitableViewContent viewParent={viewParent} />;

  return (
    <BlockDrop id={viewParent} className="my-2 line-default">
      <BitableContext.Provider value={context}>
        <NextModalProvider>
          <BitableManager embed />
          {/* 下面这段代码不知道有啥用，但会造成一个bug（https://flowus.cn/a1b1f55e-8e4b-4ae7-b969-7b0d798c1687），先去掉  */}
          {/* {!isPageHistory && syncId ? <div className="overflow-x-hidden">{node}</div> : node} */}
          {node}
        </NextModalProvider>
      </BitableContext.Provider>
    </BlockDrop>
  );
};

const BitableViewContent: FC<{
  viewParent: string;
}> = (props) => {
  const { viewId, isFoldListChildren, isColumnListChildren } = useBitable();
  const { viewParent } = props;
  const pageId = useGetPageId();
  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = usePageScrollRef();
  const view = useCollectionView(viewId);
  const BitableView = getBiTableView(view?.type);
  const sendHideHoverMenu = useRef(
    debounce(() => emitter.emit('removeHoverMenu'), 100, { leading: true })
  );

  const enableDragScroll =
    view?.type && ![CollectionViewType.TIMELINE, CollectionViewType.FORM].includes(view.type);
  const isOverFlowHide =
    view?.type && ![CollectionViewType.CALENDAR, CollectionViewType.FORM].includes(view.type);
  const isMarkChildren = useIsMarkListChildren(viewParent, pageId);
  const rightPageId = useGetRightPageId();

  const pageBlock = usePickBlock(pageId, ['data'], ['pageFixedWidth', 'directoryMenu']);
  const { pageFixedWidth = true, directoryMenu } = pageBlock?.data || {};

  useCtrlDragScroll(ref, { enable: enableDragScroll });
  const specialCase =
    !isColumnListChildren && !isFoldListChildren && !isMarkChildren && !pageFixedWidth;
  const tableViewClassName =
    specialCase && view?.type === CollectionViewType.TABLE ? 'px-24' : undefined;
  const otherViewClassName =
    specialCase && view?.type !== CollectionViewType.TABLE ? 'px-24' : undefined;

  return (
    <div
      key={viewId}
      ref={ref}
      onClick={(event) => event.stopPropagation()}
      className={cx(
        'will-change-scroll',
        isOverFlowHide && 'overflow-x-auto overflow-y-hidden',
        specialCase && '-mx-24 sm:-mx-12 sm:px-12',
        (directoryMenu || rightPageId) && 'mr-0 pr-0',
        otherViewClassName
      )}
      onScroll={sendHideHoverMenu.current}
    >
      <HScrollRefContext.Provider value={ref}>
        <BitableView scrollRef={scrollRef} className={tableViewClassName} />
      </HScrollRefContext.Provider>
    </div>
  );
};
