import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';

export const useUpdatePropertyVisible = () => {
  const { getState } = useStore();
  const transaction = useTransaction();

  const updatePropertyVisible = (
    collectionId: string,
    viewId: string,
    propertyId: string,
    visible: boolean,
    isTimelineTable = false
  ) => {
    if (viewId) {
      const { collectionViews } = getState();

      const view = collectionViews[viewId];
      if (!view) return;

      const { properties = [], timelineTableProperties = [], propertiesName } = getViewFormat(view);
      const newProperties = (isTimelineTable ? timelineTableProperties : properties).map((item) => {
        if (item.property === propertyId) {
          return {
            ...item,
            visible,
          };
        }
        return item;
      });
      const found = newProperties.find((v) => v.property === propertyId);
      // 添加引用多维表后，原多维表新增属性，引用多维表是不知道的，所以如果找不到就需要塞进去
      if (!found) {
        newProperties.push({
          visible,
          property: propertyId,
        });
      }

      transaction(() => {
        const newViewFormat: CollectionViewDTO['format'] = {
          [isTimelineTable ? 'timelineTableProperties' : propertiesName]: newProperties,
        };

        if (isTimelineTable) {
          newViewFormat.timelineShowTable = !!newProperties.find((item) => item.visible);
        }

        CollectionViewManager.update(viewId, { format: newViewFormat });
      });
    } else {
      const { blocks } = getState();

      const collection = blocks[collectionId];
      if (!collection) return;

      let newCollectionPageProperties = [...(collection.data.collectionPageProperties ?? [])];
      if (newCollectionPageProperties.length === 0) {
        newCollectionPageProperties = Object.keys(collection.data.schema ?? {}).map((property) => ({
          property,
          visible: property === propertyId ? visible : true,
        }));
      } else {
        newCollectionPageProperties = newCollectionPageProperties.map((item) => {
          if (item.property === propertyId) {
            return {
              ...item,
              visible,
            };
          }
          return item;
        });
      }

      transaction(() => {
        updateBlock(collectionId, {
          data: {
            collectionPageProperties: newCollectionPageProperties,
          },
        });
      });
    }
  };

  return useCallback(updatePropertyVisible, [getState, transaction]);
};
