/* eslint-disable no-console */
import { sleep } from '@flowus/common/async';
import { Button } from '@flowus/common/components';
import { message } from '@flowus/common/components/message';
import { IpcMessage } from '@flowus/shared';
import { useEffect } from 'react';
import { isWindows } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useOpenModal } from 'src/common/components/next-modal';
import { publicLink } from 'src/common/const';
import { createActivityModalAnchor } from 'src/components/activities/create-activity-modal-anchor';
import { setAppUiState } from 'src/services/app';
import { setDesktopState } from 'src/services/desktop';
import { ipcInvoke, ipcOnMessage, ipcSendMessage, isFlowUsApp } from 'src/utils/electron-util';
import { useDoubleClick } from '../page/use-double-click';
import { updateDesktopVersion, useAppVersion } from '../public/use-version';

/** 同步客户端的状态 */
export const useDesktopIpc = () => {
  useDoubleClick();
  const appVersion = useAppVersion();
  const { pathname } = useLocation();
  const openModal = useOpenModal();

  // on message
  // useEffect(() => {
  //   ipcOnMessage(IpcMessage.HotUpdateComplete, () => {
  //     setAppUiState({ $readyUpdate: true });
  //     void updateDesktopVersion();
  //     const activityModalAnchor = createActivityModalAnchor();

  //     openModal.dropdown({
  //       popcorn: activityModalAnchor.popcorn,
  //       placement: 'top-start',
  //       offset: [0, 10],
  //       closeAfterCallBack: () => {
  //         activityModalAnchor.remove();
  //       },
  //       content: (_) => (
  //         <div className="next-modal p-5 flex flex-col space-y-4 w-72">
  //           <div className="text-t1-medium">新版已就绪</div>
  //           <div className="text-t4 text-grey3 space-y-2.5">
  //             问：为什么更新后没有新版本日志弹窗？
  //             <br />
  //             答：有弹窗是功能更新，没有则是漏洞修复
  //           </div>
  //           <Button
  //             colorType="active"
  //             onClick={() => {
  //               _.onCloseModal();
  //               ipcSendMessage(IpcMessage.Reload);
  //             }}
  //           >
  //             极速更新
  //           </Button>
  //         </div>
  //       ),
  //     });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // 更新state
  useEffect(() => {
    const patchIsFullScreen = async () => {
      const status = await ipcInvoke(IpcMessage.IsFullScreen);
      if (status && isWindows) {
        message.success('已切换全屏，按F11退出全屏模式');
      }
      setDesktopState({ isFullscreen: status });
    };

    ipcOnMessage(IpcMessage.OnFullScreenToggle, patchIsFullScreen);
    ipcOnMessage(IpcMessage.UpdateDownloadComplete, () => {
      setDesktopState({ isUpdateReload: true });
    });
    ipcOnMessage(IpcMessage.UpdateAvailable, () => {
      setDesktopState({ isUpdateAvailable: true });
    });
    ipcOnMessage(IpcMessage.UpdateNotAvailable, () => {
      setDesktopState({ isUpdateAvailable: false });
    });

    // 初始化
    void patchIsFullScreen();
  }, []);

  // log
  useEffect(() => {
    // 检查更新
    ipcSendMessage(IpcMessage.CheckForUpdates);

    ipcOnMessage(IpcMessage.Log, (_, ...args) => {
      console.log(args);
    });

    ipcOnMessage(IpcMessage.Message, (_, ...args) => {
      console.log(args);
    });
  }, []);

  useEffect(() => {
    void sleep(5000).then(() => {
      if (appVersion) {
        const version = Number(appVersion.split('.').join(''));
        if (version <= 121) {
          return;
        }
        openModal.warning({
          modalId: 'update-desktop',
          title: '客户端有更新',
          content: '前往下载',
          colorType: 'active',
          confirm: () => {
            window.open(publicLink.appDownload);
          },
        });
      }
    });
  }, [appVersion, openModal]);

  // 路由变化时的输出
  useEffect(() => {
    if (isFlowUsApp.check) {
      console.log('当前版本号', appVersion);
      console.log('当前页面路径', pathname);
    }
  }, [appVersion, pathname]);
};
