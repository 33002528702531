import Papa from 'papaparse';

export const exportCsv = (fileContent: string[][]) => {
  const csv = Papa.unparse(fileContent);

  // 使用 BOM (Byte Order Mark) 来确保正确的 UTF-8 编码
  const bomHeader = '\ufeff';
  const csvContent = bomHeader + csv;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);

  return url;
};
