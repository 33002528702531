import { atom } from 'jotai';

export const TEMP_DISCUSSION_ID = '58d28742-1074-4e7d-9e5e-d5499d528a31';

// 是否显示添加评论按钮点击后创建的临时 discussionId，不能放到 store 里。
export const $showTempDiscussion = atom(false);
// 引用多维表需要
export const $showTempTitle = atom(false);
// 用于解决需要 focus 到编辑器，但评论组可能还没渲染的问题
export const $toFocusDiscussion = atom<string | null>(null);
