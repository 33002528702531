import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import type { CellViewProps } from './types';
import { TextValueView } from './text';

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  className?: string;
}

export const ArrayValueView: FC<Props> = ({ children, className, noWrap, ...rest }) => {
  return (
    <div {...rest} className={cx(className, noWrap ? 'whitespace-nowrap' : 'break-words')}>
      {children}
    </div>
  );
};

export const ArrayValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as any[];
        return ret.map((v) => v.toString()).join(',');
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};
