import { cx } from '@flowus/common/cx';
import { formula } from '@flowus/formula';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertyAggregation } from 'src/hooks/block/use-update-property-aggregation';
import { filterRecords } from 'src/hooks/collection-view/use-get-sorted-records';
import { useCurrentUserId } from 'src/hooks/user';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { DEFAULT_COL_WIDTH } from '../../const';
import { formatNumber } from '../cell/num';
import { calculateAggregationValue } from './calculate-aggregation-value';
import { AggregationActionUiMap, isNumberAggregation } from './helper';
import { getAvailableAggregations, getAvailableAggregationsForMenu } from './menu-item';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { Uuid } from '@flowus/common';
import { uniqueId } from 'lodash-es';
import { AggregationActionCategory } from './menu-helper';
import { getFilter } from 'src/redux/managers/collection-view';
import { getPermissions } from 'src/hooks/share/use-permissions';

interface FooterItemProps {
  recordIds: string[];
  propertyId: string;
  width?: number;
  index: number;
}

export const FooterItem: FC<FooterItemProps> = ({ recordIds, propertyId, width, index }) => {
  const { collectionId, viewId, managerReadonly, isLocked } = useBitable();
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  const currentUserId = useCurrentUserId();
  const updatePropertyAggregation = useUpdatePropertyAggregation();
  const { propertySchema, targetPropertySchema } = usePropertySchema(collectionId, propertyId);
  const closeModal = useCloseModal();

  const footerAggregation = useObservableStore(
    ({ collectionViews }) => {
      const action = collectionViews[viewId]?.format.aggregations?.[propertyId]?.action;

      const allAggregations = getAvailableAggregations(collectionId, propertyId);
      const validAggregation = allAggregations.find(
        (item) => item.type === action && action !== AggregationAction.NONE
      );

      return validAggregation?.type;
    },
    [viewId, propertyId]
  );

  const { aggregation, type: propertySchemaType } = propertySchema ?? {};

  const aggregationValue = useObservableStore(
    (state) => {
      if (!footerAggregation || !propertySchema) return;

      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return;

      const { view, collection } = viewInfo;
      const { blocks } = state;
      const { role } = getPermissions(collection.uuid);
      const saveLocal = role === PermissionRole.READER || role === PermissionRole.NONE;
      let filter = saveLocal ? getFilter(viewId) : view.format.filter;
      if (saveLocal && (!filter || filter.filters.length === 0)) {
        filter = view.format.filter;
      }
      const visibleRecords = filterRecords({
        collectionId,
        records: recordIds.map((uuid) => blocks[uuid]).filter((item): item is NextBlock => !!item),
        schemas: collection.data.schema,
        userId: currentUserId,
        filter,
        pageId,
      });
      const formulaTool = getFormulaTool(collectionId);
      const aggregationValue = calculateAggregationValue(
        visibleRecords,
        propertyId,
        footerAggregation,
        collectionId,
        formulaTool
      );

      const result = aggregationValue?.result;
      const decorateNumber = (schema: CollectionSchema | undefined, value?: string | number) => {
        if (
          schema?.type === CollectionSchemaType.NUMBER &&
          schema.numberFormat &&
          typeof value !== 'undefined'
        ) {
          return formatNumber(Number(value), schema.numberFormat);
        }

        if (schema?.type === CollectionSchemaType.FORMULA) {
          const type = formulaTool.getType(propertyId);
          if (
            type === formula.ValueTool.numberType &&
            schema.numberFormat &&
            typeof value !== 'undefined'
          ) {
            return formatNumber(Number(value), schema.numberFormat);
          }
        }

        return value;
      };

      if (propertySchemaType === CollectionSchemaType.ROLLUP) {
        if (isNumberAggregation(footerAggregation) && isNumberAggregation(aggregation)) {
          return decorateNumber(targetPropertySchema, result);
        }
        return result;
      }

      if (isNumberAggregation(footerAggregation)) {
        return decorateNumber(propertySchema, result);
      }

      return result;
    },
    [
      collectionId,
      viewId,
      propertyId,
      footerAggregation,
      propertySchema,
      targetPropertySchema,
      recordIds,
    ],
    {
      ignoreOtherData: false,
    }
  );

  const handleClick = (event: MouseEvent) => {
    if (managerReadonly || isLocked) return;
    const modalId = uniqueId();
    const categories = getAvailableAggregationsForMenu(collectionId, propertyId);
    const selectedAggregation = footerAggregation ?? AggregationAction.NONE;
    const items = categories.map((item) => {
      const listItems = {
        type: ListItemType.OPERATION,
        data: {
          ...item,
          hasArrow: item.subItems,
          selected: item.type === (selectedAggregation as any),
          renderSubMenu: item.subItems
            ? () => {
                const subListItems =
                  item.subItems?.map((v) => {
                    const subListItem = {
                      type: ListItemType.OPERATION,
                      data: { ...v, selected: v.type === selectedAggregation },
                    };
                    return subListItem;
                  }) ?? [];
                return (
                  <ListView
                    className="w-[200px] py-2 next-modal"
                    items={subListItems}
                    onItemClick={(item) => {
                      updatePropertyAggregation(viewId, propertyId, item.data.type);
                      closeModal(modalId);
                    }}
                  />
                );
              }
            : undefined,
        },
      };
      return listItems;
    });

    openModal.dropdown({
      modalId,
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="w-[200px] py-2 next-modal"
            items={items}
            onItemClick={(item) => {
              if (item.data.type === AggregationActionCategory.NONE) {
                updatePropertyAggregation(viewId, propertyId, AggregationAction.NONE);
              }
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  return (
    // {/* FIXME:下面的逻辑是有问题的，目前统计的按钮除了第一个之外都没有hover状态，但逻辑究竟是咋样的我不太清楚。 */}
    // 尝试修复了一次，修改了 DOM 层级，和 index === 0 的逻辑，实践发现第0个也是可设置的。为什么忽略可能是别的 case？
    <button
      dir="rtl"
      onClick={handleClick}
      style={{ width: width ?? DEFAULT_COL_WIDTH }}
      className={cx(
        'flex-shrink-0 text-right overflow-hidden whitespace-nowrap duration-150',
        'opacity-0 animate-hover-black3 w-full h-full px-2 flex items-center text-grey3',
        isLocked || managerReadonly ? 'opacity-100 pointer-events-none' : 'group-hover:opacity-100',
        footerAggregation ? 'opacity-100' : 'opacity-0'
      )}
    >
      {footerAggregation ? (
        <>
          {aggregationValue !== undefined && (
            <span className="ml-2 text-t2" dir="ltr">
              {aggregationValue}
            </span>
          )}
          <span className="text-t2 text-grey3">{AggregationActionUiMap[footerAggregation]}</span>
        </>
      ) : (
        <>
          <Icon name="IcArrowDown01" size="xxxsmall" className="ml-1 text-grey4" />
          <span className="text-t2 text-grey4">统计</span>
        </>
      )}
    </button>
  );
};
