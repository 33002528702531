import { Tab, TabPane } from '@flowus/common/components';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import { ViewPath } from '@flowus/common/static-const';
import { exportCsv } from '@flowus/common/utils/export-file';
import { Modals, SpacePlanType } from '@flowus/shared';
import type { SharePagePayRecord, WithdrawOrderDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_TIME_FORMAT, HOST_NAME } from 'src/common/const';
import { request } from 'src/common/request';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { DefaultPage } from 'src/editor/editor/raw/default-page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useIsTeamProSpace } from 'src/hooks/space/use-is-pro-space';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { blocksActions } from 'src/redux/reducers/blocks';
import { usersActions } from 'src/redux/reducers/users';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { usePickBlock } from 'src/utils/pick-block';
import { SegmentPlainText } from '../../aside/toc/helper';
import { ProIcon } from '../../header/pro-icon';
import { useOpenUpgradeModal } from '../upgrade-setting/upgrade-setting-header';

export const IncomeRecord: FC = () => {
  const openModal = useOpenModal();
  return (
    <Tab
      active={0}
      className="w-[70vw] px-4 h-[500px]"
      childrenClassName="h-[calc(100%-50px)]"
      customButtons={() => (
        <Icon
          name="IcClose"
          size="middle"
          className="animate-click"
          onClick={() => openModal.closeModal(Modals.INCOME_RECORD)}
        />
      )}
    >
      <TabPane title="收支明细">
        <IncomeList />
      </TabPane>
      <TabPane title="提现记录">
        <CashWithdrawList />
      </TabPane>
    </Tab>
  );
};

const CashWithdrawList: FC = () => {
  const [orderList, setOrderList] = useState<WithdrawOrderDTO[]>();
  useEffect(() => {
    // 每天只允许提现一次，所以loadmore就先不做了吧)
    void request.infra.getWithdrawOrderList(1, 365, getCurrentSpaceId()).then((res) => {
      setOrderList(res.list);
    });
  }, []);
  if (orderList === undefined) return null;
  const getPayStatusDesc = (status?: number) => {
    // 状态 0 可提现, 1 已提现, 2 已取消
    switch (status) {
      case 0: {
        return '可提现';
      }
      case 1: {
        return '已提现';
      }
      default: {
        return '已取消';
      }
    }
  };
  return (
    <div className="h-full px-1 flex flex-col justify-between items-center overflow-y-hidden overscroll-x-auto flex-1">
      <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2 border-b">
        <div className="col-span-3">记录内容</div>
        <div className="col-span-1">状态</div>
        <div className="col-span-1">金额</div>
        <div className="col-span-3">收款帐户</div>
        <div className="col-span-2">创建时间</div>
      </div>
      <div className="overflow-y-auto w-full h-full">
        {orderList.map((item, index) => {
          item.total;
          return (
            <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2" key={index}>
              <div className="col-span-3 break-words">{item.description ?? '空'}</div>
              <div className="break-words col-span-1">
                <div>{getPayStatusDesc(item.status)}</div>
              </div>
              <div className="break-words col-span-1">
                {/* TODO 需要后台支持 */}
                {getCurrencySymbols(undefined)}
                {item.total}
              </div>
              <Tooltip className="col-span-3" popup={getUserName(item.userId)}>
                <div className="text-ellipsis">
                  {`${getUserName(item.userId)}(${cache.users[item.userId ?? '']?.phone})`}
                </div>
              </Tooltip>
              <div className="break-words col-span-2">
                {item.createdAt ? dayjs(new Date(item.createdAt)).format(DATE_TIME_FORMAT) : ''}
              </div>
            </div>
          );
        })}
        {orderList.length === 0 && (
          <div className="text-t4 text-center text-grey3 mt-3">暂无提现记录</div>
        )}
      </div>
    </div>
  );
};
const IncomeList: FC = () => {
  const isTeamSpace = useIsTeamProSpace('', true);
  const [payRecord, setPayRecord] = useState<SharePagePayRecord[]>();
  // const openSettingModal = useOpenSettingModal()
  const openUpgradeModal = useOpenUpgradeModal();

  const load = async (index = 1) => {
    const maxSize = 200;
    const res = await request.infra.getSharePaidRecords(getCurrentSpaceId(), index, maxSize);
    res.recordMap.blocks &&
      dispatch(
        blocksActions.update({
          blocks: res.recordMap.blocks as Record<string, NextBlock>,
        })
      );
    res.recordMap.users && dispatch(usersActions.updateSpaceUsers({ users: res.recordMap.users }));

    return {
      isMore: maxSize <= res.results.length,
      results: res.results,
    };
  };

  useEffect(() => {
    void load().then((res) => {
      setPayRecord(res.results);
    });
  }, []);

  // 转换数据为 CSV 格式
  const convertToCSV = (data: SharePagePayRecord[]) => {
    // CSV 表头
    const headers = ['页面ID', '用户', '手机号', '创建时间', '金额', '有效期'];

    // 转换数据行
    const csvRows = data.map((item) => {
      return [
        `${HOST_NAME}${ViewPath.share}${item.blockId}`,
        getUserName(item.userId),
        cache.users[item.userId ?? '']?.phone,
        dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'), // 使用 dayjs 格式化时间
        item.total,
        (item.payValidDays || 0) <= 0 ? '永久有效' : item.payValidDays,
      ].map((value) => `${value}`);
    });

    // 组合表头和数据行
    const res = [headers, ...csvRows];
    const url = exportCsv(res);

    void downloadUrlFile(url, `subscribe-${dayjs().format('YYYY-MM-DD_HH:mm:ss')}.csv`);
  };

  const exportData = async () => {
    if (!isTeamSpace) {
      openUpgradeModal(SpacePlanType.team);
      return;
    }
    if (payRecord) {
      const loading = message.loading('正在导出数据');
      let more = true;
      let index = 2;
      const _payRecord = payRecord;
      while (more) {
        const res = await load(index);
        if (res.isMore) {
          more = true;
          _payRecord.push(...res.results);
          index++;
        } else {
          more = false;
        }
      }

      message.closeMessage(loading);
      convertToCSV(payRecord);
    } else {
      message.error('暂无内容');
    }
  };

  if (payRecord === undefined) return null;
  const getPayStatusDesc = (status?: number) => {
    // 状态 0 未付款, 1 已支付, 2 已取消,3 已提现
    switch (status) {
      case 0: {
        return '未付款';
      }
      case 2: {
        return '已取消';
      }
      default: {
        return '已支付';
      }
    }
  };
  return (
    <div className="h-full px-1 flex flex-col justify-between items-center overflow-y-hidden overscroll-x-auto flex-1">
      <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2 border-b">
        <div className="col-span-3">页面来源</div>
        <div className="col-span-1">类型</div>
        <div className="col-span-1">金额</div>
        <div className="col-span-3">付款人</div>
        <div className="col-span-2">创建时间</div>
        {/* <div className="col-span-1">操作</div> */}
      </div>
      <div className="overflow-y-auto w-full h-full">
        {payRecord.map((item, index) => {
          item.blockId;
          return (
            <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2" key={index}>
              <div className="col-span-3 break-words">
                <Page id={item.blockId ?? ''} />
              </div>
              <div className="break-words col-span-1">
                <div>{getPayStatusDesc(item.status)}</div>
              </div>
              <div className="break-words col-span-1">
                {/* TODO 需要后台支持 */}
                {getCurrencySymbols(undefined)}
                {item.total}
              </div>
              <Tooltip className="col-span-3" popup={getUserName(item.userId)}>
                <div className="text-ellipsis">
                  {`${getUserName(item.userId)}(${cache.users[item.userId ?? '']?.phone})`}
                </div>
              </Tooltip>
              <div className="break-words col-span-2">
                {item.createdAt ? dayjs(new Date(item.createdAt)).format(DATE_TIME_FORMAT) : ''}
              </div>
            </div>
          );
        })}
        {payRecord.length === 0 && (
          <div className="text-t4 text-center text-grey3 mt-3">暂无收支明细</div>
        )}
      </div>
      <div
        className="text-active_color py-2.5 animate-click text-t2 flex items-center space-x-2"
        onClick={exportData}
      >
        <span>导出数据</span>
        {!isTeamSpace && <ProIcon absolute={false} showIcon text="团队版" />}
      </div>
    </div>
  );
};

const Page: FC<{ id: string }> = (props) => {
  const { illegal } = usePermissions(props.id);
  const block = usePickBlock(props.id, ['data'], ['segments']);
  const title = !block ? '无法访问' : <SegmentPlainText uuid={props.id ?? ''} />;
  const openPage = useOpenPage();
  const handlePageClick = () => {
    openPage(props.id, {
      forceOpenNewTab: true,
    });
  };
  return <DefaultPage id={props.id} title={title} illegal={illegal} onClick={handlePageClick} />;
};
