import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';

/** 跳去升级之前先询问一下 */
export const useOpenAskUpgradeSpace = () => {
  const openModal = useOpenModal();
  return useCallback(
    (onConfirm: () => void, opt?: { title?: string }) => {
      openModal.warning({
        title: opt?.title ?? '升级「专业版」可体验完整功能',
        confirmText: '立即升级',
        cancelText: '取消',
        colorType: 'active',
        confirm: onConfirm,
      });
    },
    [openModal]
  );
};
