import { cx } from '@flowus/common/cx';
import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { last, take } from 'lodash-es';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DirectionScroller } from 'src/common/components/direction-scroller';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import type { request } from 'src/common/request';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { PagePreview } from 'src/components/page-preview';
import { useGetAncestors } from 'src/hooks/block/use-get-ancestors';
import { useNormalizePath } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { isPersonalSpace } from 'src/hooks/space/use-is-pro-space';
import { $currentUserCache } from 'src/services/user/current-user';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import type { Instance, Props as TippyProps } from 'tippy.js';
import { segmentsToText } from '../../utils/editor';

interface Props {
  total: number;
  backLinks: Awaited<ReturnType<typeof request.editor.getDocBacklinks>>['results'];
}

const DEFAULT_BACK_LINK_COUNT = 10;
export const SyncBackList: FC<Props> = (props) => {
  const { backLinks, total } = props;
  const [showAll, setShowAll] = useState(backLinks.length <= DEFAULT_BACK_LINK_COUNT);
  const divRef = useRef<HTMLDivElement>(null);
  const showBackLinks = showAll ? backLinks : take(backLinks, DEFAULT_BACK_LINK_COUNT);
  const restCount = backLinks.length - showBackLinks.length;
  const noPermissionCount = total - backLinks.length;
  return (
    <div
      ref={divRef}
      className="shadow-modal bg-white1 border border-grey6 rounded w-[400px] max-h-[65vh] overflow-scroll"
    >
      <DirectionScroller
        defaultIndex={-1}
        listenPriority={PRIORITY_DIALOG}
        containerRef={divRef}
        itemCount={showBackLinks.length}
      >
        {(activeIndex: number) => {
          return showBackLinks.map((item, index) => {
            return (
              <SyncBackLinkItem
                // index={index}
                uuid={item.uuid}
                pageId={item.pageId}
                key={item.uuid}
                selected={activeIndex === index}
              />
            );
          });
        }}
      </DirectionScroller>
      {restCount > 0 && (
        <>
          <div className="bg-grey6 h-px w-full text-t4 "></div>
          <div
            className="flex py-4 items-center ml-4 cursor-pointer text-t4 text-grey3"
            onClick={() => {
              setShowAll(true);
            }}
          >
            其他 {restCount} 个页面
          </div>
        </>
      )}
      {showAll && noPermissionCount > 0 && (
        <>
          <div className="bg-grey6 h-px w-full"></div>
          <div className="flex items-center text-t4 ml-4 text-grey3 py-4">
            <Icon
              name="IcLock"
              size="middle"
              style={{
                color: 'var(--grey3)',
              }}
            />
            <div>还有 {noPermissionCount} 个页面你暂无权限</div>
          </div>
        </>
      )}
    </div>
  );
};
interface ItemProps {
  uuid?: string;
  pageId?: string;
  selected: boolean;
  // index: number;
}

const SyncBackLinkItem: FC<ItemProps> = (props) => {
  const { uuid = '', pageId = '', selected = false, ...rest } = props;
  const pageBlock = usePickBlock(pageId, ['data', 'permissions'], ['segments']);
  const block = usePickBlock(uuid, []);
  const curPageId = useGetPageId();
  const getAncestors = useGetAncestors();
  const space = useCurrentSpace();
  const isPersonal = isPersonalSpace(space.planType);
  // 被风控了
  const { illegal } = usePermissions(pageId);
  const { permissions } = usePermissions(uuid);
  const path = useNormalizePath(pageId);
  const openPage = useOpenPage();
  const tippyInstance = useRef<Instance<TippyProps>>();

  if (!pageBlock || !pageId || !uuid) return null;
  return (
    <Tooltip
      lazyLoad={true}
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      theme="none"
      interactive={true}
      popupClass="next-modal"
      offset={[0, 5]}
      disabled={curPageId === pageId}
      placement="top"
      className="w-full"
      maxWidth={10000}
      delay={[500, 50]}
      animation={'shift-away'}
      popup={
        <PagePreview
          pageId={pageId}
          blockId={uuid}
          onCloseOutsideModel={() => {
            tippyInstance.current?.hide();
          }}
        />
      }
    >
      <NavLink
        to={`${path}#${uuid}`}
        className={cx(
          'flex items-center text-t2-medium w-full animate-hover rounded-sm h-10  p-4 justify-between text-ellipsis',
          illegal && 'opacity-30',
          { 'normal-bg': selected }
        )}
        {...rest}
        onClick={(e) => {
          if (curPageId === pageId) return;
          e.preventDefault();
          openPage(pageId, {
            hash: `#${uuid}`,
            forceOpenInRight: e.altKey,
            forceOpenNewTab: e.ctrlKey || e.metaKey,
          });
        }}
      >
        <div className="flex items-center overflow-hidden">
          <IconTrigger
            blockId={pageId}
            trigger={false}
            iconSize={18}
            defaultIcon={<BlockDefaultIcon uuid={pageId} size="middle" />}
          />
          <div className="text-ellipsis underline ml-1 mr-1.5 text-black decoration-grey5">
            {illegal
              ? ILLEGAL_TEXT
              : segmentsToText(pageBlock.data.segments) || getUntitledName(pageBlock.type)}
          </div>
        </div>
        <div className="flex text-t2">
          {permissions.every((o) => {
            const lastBlockId = last([...getAncestors(uuid)]);
            if (lastBlockId !== pageBlock.spaceId || isPersonal) {
              return false;
            }
            if (o.type === PermissionType.SPACE || o.type === PermissionType.GROUP) {
              return o.role === PermissionRole.NONE;
            }
            if (o.type === PermissionType.USER) {
              return o.role !== PermissionRole.NONE && o.userId === $currentUserCache.uuid;
            }
            return true;
          }) && (
            <span className="flex items-center text-red text-t4-medium mr-1">
              <Icon name="IcLock" size="middle" />
              <span>仅我可见</span>
            </span>
          )}
          {block?.type === BlockType.SYNC_CONTAINER && (
            <div className="px-1.5 bg-grey/20 rounded text-grey2/80">源同步块</div>
          )}
          {curPageId === pageId && (
            <div className="ml-1 px-1.5 bg-grey/20 rounded text-grey2/80">当前页</div>
          )}
        </div>
      </NavLink>
    </Tooltip>
  );
};
