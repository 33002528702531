import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import type { CellViewProps } from './types';
import { TextValueView } from './text';
import { arrayToStyle } from '@flowus/common';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  className?: string;
}

// export const RichTextValueView: FC<Props> = ({ children, className, noWrap, ...rest }) => {
//   return (
//     <div {...rest} className={cx(className, noWrap ? 'whitespace-nowrap' : 'break-words')}>
//       {children}
//     </div>
//   );
// };

export const StyleTextValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segments = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as string[];
        return arrayToStyle(ret);
      }
    },
    [recordId, propertyId, collectionId]
  );

  if (!segments) return null;

  return (
    <div className="p-2 w-full group-scope">
      <RichText
        className={cx('leading-[20px]', className)}
        segments={segments}
        interactable={true}
      />
      {/* <CopyButton text={text} site={site} /> */}
    </div>
  );
};
