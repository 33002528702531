import { Input } from '@flowus/common/components/input';
import { Tooltip } from '@flowus/common/components/tooltip';
import { type FC } from 'react';
import { Switch } from 'src/common/components/switch';
import { useModel } from 'src/common/create-model';
import { HelperIconBox } from 'src/components/helper-icon';
import { useCurrentSpace, useUpdateSpace } from 'src/hooks/space';
import { isTeamProSpace } from 'src/hooks/space/use-is-pro-space';
import { transaction } from 'src/hooks/use-transaction';
import { SettingModalCommon, SettingProvider } from '../common';
import { Option } from '../option';
import { OpenSettingFrom } from '../type';

export const SpaceSecurity: FC = () => {
  const {
    spaceSettingState: {
      abandonedPublicShare,
      abandonedGuest,
      abandonedExport,
      openWatermark,
      openImageWatermark,
      imageWatermarkText,
      setImageWatermarkText,
    },
  } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  const updateSpace = useUpdateSpace();

  const disabled = !isTeamProSpace(currentSpace.planType);

  return (
    <div className="mt-4">
      <SettingModalCommon.SettingDivider />
      <Option
        showUpgradeDialog={disabled}
        showSplitLine={false}
        showSpacePlan={true}
        from={OpenSettingFrom.space_security}
        upgradeTip="升级「团队版」可使用安全性选项功能"
        title={
          <HelperIconBox
            showBuildin
            iconClassName="pointer-events-none"
            popup="升级「团队版」可使用安全性选项功能"
          >
            安全性选项
          </HelperIconBox>
        }
        titleClassName="text-t1-medium text-red"
      />
      <Option
        className="mt-0"
        showSplitLine={false}
        title="禁止页面公开分享"
        description={`开启后，团队空间所有页面（包括个人页面）均无法开启分享(已开启分享的页面不受影响)`}
        rightPart={
          <Tooltip popup="仅管理员可设置" delay={1500}>
            <Switch
              disabled={disabled}
              open={abandonedPublicShare}
              onSwitch={(open) => {
                transaction(() => {
                  updateSpace(currentSpace.uuid, {
                    setting: {
                      ...currentSpace.setting,
                      abandonedPublicShare: open,
                    },
                  });
                });
              }}
            />
          </Tooltip>
        }
      />
      <Option
        showSplitLine={false}
        title="禁止添加外部协作者"
        description={`开启后，团队空间所有页面（包括个人页面）都不允许邀请外部协作者进行查看和编辑(已邀请过的协作者不受影响)`}
        rightPart={
          <Tooltip popup="仅管理员可设置" delay={1500}>
            <Switch
              disabled={disabled}
              open={abandonedGuest}
              onSwitch={(open) => {
                transaction(() => {
                  updateSpace(currentSpace.uuid, {
                    setting: {
                      ...currentSpace.setting,
                      abandonedGuest: open,
                    },
                  });
                });
              }}
            />
          </Tooltip>
        }
      />
      <Option
        showSplitLine={false}
        title="禁止导出"
        description={`开启后，空间所有成员将不能导出页面`}
        rightPart={
          <Tooltip popup="仅管理员可设置" delay={1500}>
            <Switch
              disabled={disabled}
              open={abandonedExport}
              onSwitch={(open) => {
                transaction(() => {
                  updateSpace(currentSpace.uuid, {
                    setting: {
                      ...currentSpace.setting,
                      abandonedExport: open,
                    },
                  });
                });
              }}
            />
          </Tooltip>
        }
      />
      <Option
        showSplitLine={false}
        title="空间水印"
        description={`开启后，页面背景会有「用户昵称 - 手机尾号后4位」的水印标识`}
        rightPart={
          <Tooltip popup="仅管理员可设置" delay={1500}>
            <Switch
              disabled={disabled}
              open={openWatermark}
              onSwitch={(open) => {
                transaction(() => {
                  updateSpace(currentSpace.uuid, {
                    setting: {
                      ...currentSpace.setting,
                      openWatermark: open,
                    },
                  });
                });
              }}
            />
          </Tooltip>
        }
      />
      {!__BUILD_IN__ && (
        <Option
          showSplitLine={false}
          title="图片水印"
          description={
            <span>
              开启后，<span className="text-black text-t3-medium">分享页</span>的图片会加上水印标识
              {openImageWatermark && (
                <Input
                  focusWidthBorder={false}
                  maxLength={20}
                  placeholder="请输入文字水印"
                  className="w-full h-7 mt-2 "
                  inputClassName="text-grey3"
                  value={imageWatermarkText}
                  onChange={(str) => {
                    setImageWatermarkText(str);
                  }}
                />
              )}
            </span>
          }
          rightPart={
            <Tooltip popup="仅管理员可设置" delay={1500}>
              <Switch
                disabled={disabled}
                open={openImageWatermark}
                onSwitch={(open) => {
                  transaction(() => {
                    updateSpace(currentSpace.uuid, {
                      setting: {
                        ...currentSpace.setting,
                        openImageWatermark: open,
                      },
                    });
                  });
                }}
              />
            </Tooltip>
          }
        />
      )}
    </div>
  );
};
